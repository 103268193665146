import React, { useEffect, useState } from "react";
import AppConstants, { BLOB_URL } from '../../../AppConstants';
import * as API from "../../../api"
import Loader from '../../../icons/loader.svg'
import ProjectThumb from '../../../icons/ProjectThumb.png'
import dateFormat from 'dateformat'
import { getLimitedName, getLocalStorageValue, skipAnnotationTaskTour, skipDetectionTaskTour, skipTrainingTaskTour } from "../../../ReusableComponents/reusableFunctions";
import { AnimatedModal } from "../../../ReusableComponents/AnimatedModal";
import Plus from '../../../assets/Icons/plus.png'
import ReactJoyride, { BeaconRenderProps, TooltipRenderProps, ACTIONS, EVENTS, STATUS } from 'react-joyride';
import ButtonWithIcon from "../../../ReusableComponents/ButtonWithIcon";
import { PICSTORK_FEATURES } from "../../../Teams";
import Autocomplete from "react-google-autocomplete";
import L from "leaflet";
import MarkerIcon from '../../../assets/marker.png'
import SingleMap from "../../../ReusableComponents/map"

const nextButton = {
    position: "absolute",
    bottom: "60px",
    right: "60px"
}

let mapInState = undefined
let mapMarker = undefined

const CreateTaskView = (props) => {
    const [task, setTask] = useState(props.task || {
        task_id: undefined,
        task_name: undefined
    })
    const [runTour, setRunTour] = useState("")
    const [guideSteps, setGuideSteps] = useState([
        {
            disableBeacon: true,
            target: '#input-step',
            content: (<div style={AppConstants.TEXTSTYLING}><p>Enter a unique task name</p></div>),
            title: (<div style={AppConstants.TITLESTYLING}>Task Name</div>),
            placement: 'left',
            styles: {
                options: {
                    width: 250,
                },
            },
        },

        {
            disableBeacon: true,
            target: '#project-grid',
            content: (<div style={AppConstants.TEXTSTYLING}><p>Select from the existing projects</p></div>),
            title: (<div style={AppConstants.TITLESTYLING}>Select Project</div>),
            placement: 'right',
            styles: {
                options: {
                    width: 250,
                },
            },
        },
        {
            disableBeacon: true,
            target: '#create-project',
            content: (<div style={AppConstants.TEXTSTYLING}><p>Alternatively, create a new project</p></div>),
            title: (<div style={AppConstants.TITLESTYLING}>Select Project</div>),
            placement: 'left',
            styles: {
                options: {
                    width: 250,
                },
            },
        },
    ])

    const handleJoyrideCallback = data => {
        const { action, index, status, type } = data;
        if ([STATUS.FINISHED].includes(status)) {
            if (props.taskType === 1) localStorage.setItem('createTaskTour1', 1)
            else if (props.taskType === 2) localStorage.setItem('createTaskTour2', 1)
            else if (props.taskType === 4) localStorage.setItem('createTaskTour3', 1)
            props.setTourComplete(1)
            setRunTour(false)
        } else if ([STATUS.SKIPPED].includes(status)) {
            if (props.taskType === 1) skipTrainingTaskTour()
            else if (props.taskType === 2) skipDetectionTaskTour()
            else if (props.taskType === 4) skipAnnotationTaskTour()
            props.setTourComplete(1)
            setRunTour(false)
        }
    };


    const [project, setProject] = useState({
        id: undefined,
        project_name: undefined
    })

    const [loader, setLoader] = useState(false);
    const [errorMessage, setError] = useState(undefined)
    const [allProjects, setAllProjects] = useState(undefined)
    const [showAddProjectPopup, setShowAddProjectPopup] = useState(undefined)
    const [newProjectName, setNewProjectName] = useState(undefined)
    const [popupError, setPopupError] = useState(undefined)
    const [center, setCenter] = useState({ lat: 19.993982, lng: 73.790416 })
    const [locationName, setLocationName] = useState('')

    useEffect(() => {
        getAllProjects()
        setProject(props.project)
        allowToCreateTask(task ? task.task_name : null, props.project)
        // if ((props.taskType === 1 && getLocalStorageValue('createTaskTour1') == null) || 
        // (props.taskType === 2 && getLocalStorageValue('createTaskTour2') == null) || 
        // (props.taskType === 3 && getLocalStorageValue('createTaskTour3') == null)) {
        //     console.log("SETTING TOUR TIRGGER : ", props.startTour)
        //     setRunTour(props.startTour)
        // }
    }, [])

    const ErrorMessageComponent = () => {
        return (<div style={{
            color: "red",
            fontSize: "12px",
            padding: "5px 0 0 3px"
        }}>{errorMessage}</div>)
    }

    const LoaderComponent = () => {
        return (<div style={{ justifyContent: 'center', height: "52%", width: "100%", display: "flex", textAlign: "center", alignItems: 'center', flexDirection: 'column' }}>
            <img src={Loader} style={{ width: "175px", margin: "0px 0 -50px 80px" }} />
            <div className="content-text">Loading projects, please wait...</div>
        </div>)
    }

    //project 

    const getAllProjects = async () => {
        setLoader(true)
        let key = ' '
        API.getAllProjects(key, 10000, 0, undefined, undefined, AppConstants.SERVICES.PICSTORK).then(async (data) => {
            let projectData = data.projects.length > 0 ? data.projects.filter((data) => { return !data.sharedData.isShared }) : []
            setAllProjects(projectData)
            if (project && project.id && document.getElementById('project-grid')) {
                // let i = allProjects.findIndex((pr) => pr.id == project.id)
            }
            setLoader(false)
        }, (e) => {
            setLoader(false)
            setError("Something went wrong while loading projects.")
            console.log(e);
        })
    }

    const createNewProject = () => {
        return new Promise((resolve, reject) => {
            let dt = new Date();
            let date = dt.getDate().toString().padStart(2, "0");
            let month = (dt.getMonth() + 1).toString().padStart(2, "0");
            let year = dt.getFullYear();
            let hour = dt.getHours();
            let minute = dt.getMinutes();
            let seconds = dt.getSeconds();
            let dateTime = `${year}-${month}-${date} ${hour}:${minute}:${seconds}`;
            let newProject = {
                service: AppConstants.SERVICES.PICSTORK,
                projectDetails: {
                    Client_Name: "",
                    "Date and Time": dateTime,
                    FlightCompletedCount: 0,
                    projectName: newProjectName,
                    SoftwareVerison: "1.0",
                    locationName: locationName,
                    locationLatLng: center
                }
            }
            API.createNewProject(newProject).then(async (data) => {
                if (data.message == "OK") {
                    setProject(data.project)
                    allowToCreateTask(task ? task.task_name : null, data.project)
                    setShowAddProjectPopup(false)
                    let proj = allProjects
                    proj.unshift(data.project)
                    setAllProjects(proj)
                    setLoader(false)
                }
                else if (data.error) {
                    setLoader(false);
                    setPopupError(data.error)
                }
                else {
                    setLoader(false);
                    setPopupError("Something went wrong.")
                }
            })
        })
    }

    const projectNameHandler = (e) => {
        let format = /[!@#$%^&*()+\-`=\[\]{};':"\\|,.<>\/?]+/;
        if (format.test(e.target.value)) {
            setPopupError("Special characters are not allowed.")
        } else if (e.target.value.includes(' ')) {
            setPopupError("Spaces are not allowed.")
        } else {
            setPopupError(undefined)
            setNewProjectName(e.target.value)
        }
    }

    const onChangeProject = (project) => {
        if (project) {
            setProject(allProjects.find(pro => pro.id === project.id));
            allowToCreateTask(task.task_name, project)
        }
        else setProject({
            project_name: "",
            project_id: ""
        });
    }

    const taskNameHandler = (e) => {
        e.preventDefault()
        let format = /[!@#$%^&*()+\-`=\[\]{};':"\\|,.<>\/?]+/;
        if (format.test(e.target.value)) {
            setError("Special characters are not allowed.")
        } else if (e.target.value.includes(' ')) {
            setError("Spaces are not allowed.")
        } else {
            setError(undefined)
            setTask({
                ...task,
                task_name: e.target.value
            })
            allowToCreateTask(e.target.value, project)
        }
    }

    const allowToCreateTask = (task_name, project) => {
        if (!props.task?.task_id) {
            let new_task = {}
            if (task_name && task_name.length > 0 && project) {
                new_task = {
                    task_name: task_name,
                    project_name: project.project_name,
                    project_id: project.id,
                    taskType: "training",
                    status: AppConstants.TASK.STATUS.CREATING,
                    type: []
                }
            }
            props.taskCreationDone(project, new_task)
        }
    }


    const changeMarkerPosition = (mark) => {
        mapInState.setView(mark);
        mapMarker.setLatLng(mark);
    }

    const onchangePlace = (e) => {
        console.log("Place changed : ", e.target.value);
        let val = e.target.value.trim()
        let seperator = val.indexOf(",") != -1 ? ',' : val.indexOf(" ") != -1 ? " " : " "
        let latlng = val.trim().split(seperator)
        if (latlng.length == 2) {
            setCenter({ lat: latlng[0], lng: latlng[1] })
        }
    }

    const handleMapClick = (e) => {
        console.log(e, mapMarker)
        let center = { lat: e.latlng.lat, lng: e.latlng.lng }
        mapMarker.setLatLng(center);
        mapInState.setView(center);
        setCenter(center)
    }

    const initMap = (map) => {
        var markerOptions = {
            title: "MyLocation",
            clickable: true,
            draggable: false,
            icon: new L.Icon({ iconUrl: MarkerIcon, iconSize: [20, 28], iconAnchor: [10, 28] })
        }
        let marker1 = new L.Marker(center, markerOptions).addTo(map)
        console.log('marker', marker1);
        mapMarker = marker1
        map.setView(center, 17);
    };

    const handleKeyDown = (event) => {
        console.log(event);
        if (event.key === 'Enter') {
            console.log('Enter key pressed !')
            mapMarker.setLatLng(center);
        }
    }


    return (
        <>
            <ReactJoyride
                callback={(e) => handleJoyrideCallback(e)}
                continuous={true}
                run={props.startTour}
                hideBackButton={true}
                hideCloseButton={true}
                showSkipButton={true}
                steps={guideSteps}
                disableOverlayClose={true}
                floaterProps={AppConstants.FLOATER_PROPS}
                locale={AppConstants.LOCALE_BUTTONS}
                styles={{
                    buttonNext: AppConstants.NEXT_BUTTON,
                    buttonSkip: AppConstants.SKIP_BUTTON,
                    spotlight: AppConstants.SPOTLIGHT,
                    options: AppConstants.MAIN_OPTIONS
                }}
            />
            {/*-------------------Create Project Popup-------------------- */}
            <AnimatedModal
                height={"500px"}
                width={"700px"}
                zIndex={5}
                onRequestClose={() => {
                    setShowAddProjectPopup(false)
                    setNewProjectName('')
                }}
                isOpen={showAddProjectPopup}
            >
                <div className='content-text'>
                    <div style={{ padding: '10px', background: '#F7F7FA', }} className="services-content-title">{"Add new project"}</div>
                    <div style={{ margin: '15px 0px 0px 8%', display: 'flex', textAlign: 'left', flexDirection: 'column', }}>
                        <div style={{ marginBottom: '5px' }}>Name <span style={{ color: 'red' }}>*</span> </div>
                        <input style={{ width: '352px' }} type="text" value={newProjectName} maxLength={AppConstants.POPUP_INPUT_TEXT_LIMIT} className="add-user-form-text" placeholder="eg. Project_1"
                            onChange={projectNameHandler} />
                        <div style={{ fontSize: '10px', margin: '8px 0px 0px 2px', textAlign: 'left', height: "12px", color: 'red' }}>{popupError ? popupError : ''}</div>
                    </div>
                    <div style={{ height: '250px', margin: '20px 0px', marginLeft: '8%', width: "84%", }}>
                        <div style={{ position: 'absolute', zIndex: '1', background: '#3c3c3c99', padding: '5px' }}>
                            <Autocomplete
                                style={{ width: '270px' }}
                                className="input-transparent1"
                                placeholder='Search location'
                                apiKey="AIzaSyBuxfUtRZSEUT2QrbZA8zUsG58lZUYYmwI"
                                onChange={(e) => { onchangePlace(e) }}
                                onKeyDown={(e) => handleKeyDown(e)}
                                onPlaceSelected={(place) => {
                                    try {
                                        console.log('place', place);
                                        let lat = place.geometry.location.lat();
                                        let lng = place.geometry.location.lng();
                                        let cntr = {};
                                        cntr.lat = parseFloat(lat);
                                        cntr.lng = parseFloat(lng);
                                        setCenter(cntr)
                                        setLocationName(place.formatted_address)
                                        changeMarkerPosition(cntr)
                                    } catch { }
                                }}
                                types={[]}
                            />
                        </div>
                        <SingleMap
                            initCenter={[center.lat, center.lng]}
                            initZoom={22}
                            handleMapClick={handleMapClick}
                            maxZoom={23}
                            setMap={(map) => {
                                mapInState = map;
                                initMap(map)
                            }}
                        />
                    </div>
                </div>

                <div style={{ display: "flex", justifyContent: "space-between", marginLeft: '8%', width: "84%", marginTop: "25px" }}>
                    <ButtonWithIcon
                        text="Cancel"
                        onClick={() => {
                            setShowAddProjectPopup(false)
                            setNewProjectName('')
                            setPopupError("")
                        }}
                    />
                    <ButtonWithIcon
                        text="Add" width="74px" isBtnActive
                        btnType={"active"}
                        isDisabled={!newProjectName}
                        onClick={newProjectName ? createNewProject : false}
                    />
                </div>
            </AnimatedModal>
            {/* ----------------------------------------------------- */}

            {/* --------------------------------------------------------------- */}
            <>
                <div
                    className="services-content-title"
                    style={{
                        margin: "1%",
                        display: "flex",
                        height: "30px"
                    }}
                >
                    Create Task

                </div>
                <div style={{ padding: '2% 0% 0% 3%', margin: "1%", display: "flex", flexDirection: "column", textAlign: "left", justifyContent: "left", background: "#fff", boxShadow: "0px 6px 18px 6px rgb(0 0 0 / 18%)", height: '90%', width: '98%' }}>
                    <div id="input-step" style={{ width: "44%" }}>
                        <div className="itemValue" style={{ textTransform: "none", margin: '0px 0px 10px 0px' }}>
                            Task name
                        </div>
                        <div className="add-user-form-text-wrapper">
                            <input
                                type="text"
                                maxLength={50}
                                className="add-user-form-text textbox-shadow"
                                onChange={taskNameHandler}
                                name="task_name"
                                value={task.task_name}
                                style={{ backgroundColor: task.task_id || !props.permissions?.[PICSTORK_FEATURES.TASKS].EDIT ? 'rgba(102, 102, 102, 0.1)' : undefined }}
                                disabled={task.task_id || !props.permissions?.[PICSTORK_FEATURES.TASKS].EDIT}
                            />
                            {errorMessage ? <ErrorMessageComponent /> : <></>}
                        </div>
                    </div>
                    <div id="project-grid" style={{ marginTop: '10px', marginRight: '10px' }}>
                        <div className="itemValue" style={{ display: 'flex', justifyContent: 'space-between', width: '98%', fontSize: "13px", marginTop: '5px', marginBottom: '5px' }}>
                            Select Project
                            {!props.task || !props.task.task_id ?
                                <div>
                                    {/* <ImportProjectFrom currentService={AppConstants.SERVICES.PICSTORK} callBack={() => { getAllProjects() }} /> */}
                                    <ButtonWithIcon
                                        isDisabled={!props.activeTeam?.isOwner || loader}
                                        text="New Project"
                                        icon={Plus}
                                        onClick={() => { if (!loader) setShowAddProjectPopup(true) }}
                                    />
                                </div>
                                : <></>}
                        </div>
                        {!loader && allProjects && allProjects.length > 0 ?
                            <div style={{ height: '57vh', overflow: 'auto' }}>
                                <div className="cards-tray-projects"
                                    style={{
                                        columnGap: "10px",
                                        rowGap: "10px",
                                        minHeight: '0',
                                        gridTemplateColoumn: "repeat(auto-fill, minmax(190px, 200px))",
                                        width: "100%",
                                    }}>
                                    {allProjects.map((prj, key) => {
                                        return (
                                            <div className={'selected-project-outline'} style={{ border: project?.id == prj.id ? '2px solid #2989cf' : 'none', cursor: !props.task || !props.task.task_id ? 'pointer' : 'default' }} onClick={() => { if (!props.task || !props.task.task_id) onChangeProject(prj) }}>
                                                {<div key={key} className="customer-cards-dashboard card">
                                                    {<div className="card-header customer-cards-dashboard-logo project-card-header" style={{ borderRadius: '5px 5px 0px 0px' }}>
                                                        <img id="ProjectImage" src={`${BLOB_URL}/${props.permissions?.container}/FlightImages/collection-${prj.collection_id}/thumb-${prj.image}?${props.permissions?.st}`} onError={(e) => { e.target.onerror = null; e.target.src = ProjectThumb }} style={{ objectFit: 'cover' }} />
                                                    </div>}
                                                    <div className="card-body change-color customer-cards-dashboard-text project-card-body" >
                                                        <div style={{
                                                            height: "24px",
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            fontSize: '11px'
                                                        }}>
                                                            <span>{getLimitedName(prj.project_name, 15)}</span>
                                                        </div>
                                                        <div style={{ display: 'flex', fontSize: '10px', justifyContent: 'space-between', width: "100%", marginTop: "-2px" }}>
                                                            <div>{dateFormat(prj.created_date, "dd mmm yyyy")}</div>
                                                            <div>{dateFormat(prj.created_date, "hh:MM TT")}</div>
                                                        </div>
                                                    </div>
                                                </div>}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div> : <></>}
                    </div>

                    {loader ? <LoaderComponent /> : <></>}
                </div>
            </>
        </>
    )
}

export default CreateTaskView