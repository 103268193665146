import { ADD_PROJECT_DETAILS } from "./projectTypes";
import { UPDATE_UNIT_CONVERSION } from "./projectTypes";
import { ADD_PLAN } from "./projectTypes";
import { ADD_URL } from "./projectTypes";
const initialState = {
  projectName: '',
  clientName: '',
  planName: '',
  dateAndTime: '',
  Distance: '',
  speed: '',
  temprature: '',
  area: '',
  gmarkers: [],
  gmarkers1: [],
  gpoly: [],
  gpoly1: [],
  url: ''

};

const projectReducer = (state = initialState, action) => {
  console.log("actionin reducer:", action);
  switch (action.type) {
    case 'CLEAR_PROJECT': return initialState
    case ADD_PROJECT_DETAILS:
      return {
        ...state,
        projectName: action.payload.projectName,
        clientName: action.payload.clientName,
        planName: action.payload.planName,
        dateAndTime: action.payload.dateAndTime
      };
    case UPDATE_UNIT_CONVERSION:
      return {
        ...state,
        Distance: action.payload.Distance,
        speed: action.payload.speed,
        temprature: action.payload.temprature,
        area: action.payload.area,
      };
    case ADD_PLAN:
      console.log(action.payload);
      return {
        ...state,
        gmarkers: action.payload.gmarkers,
        gmarkers1: action.payload.gmarkers1,
        gpoly: action.payload.gpoly,
        gpoly1: action.payload.gpoly1,
      };
    case ADD_URL:
      console.log(action.payload);
      return {
        ...state,
        url: action.payload.url
      };
    default:
      return state;
  }
};
export default projectReducer;
