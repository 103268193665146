import React, { Component, useEffect, useState } from 'react';
import SideBar from '../SideBar';
import {
    Chart as ChartJS,
    BarElement,
    ArcElement, Tooltip, Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
} from 'chart.js';
import Planner from "../icons/calenderInactive.png"
// import EMPTYLIST from "../icons/emptylist.png"
import { Pie, Line, Bar } from "react-chartjs-2";
import ServiceInActive from "../sidebaricon/Services.png"
import TeamsInActive from "../sidebaricon/TeamsInactive.png"
import Calender from "../sidebaricon/calenderActive.png"
import Help from "../sidebaricon/Help.png"
import Settings from "../sidebaricon/Settings.png"
import DropDownWithButton from '../ReusableComponents/DropDownWithButton'
import { ROLES, getActiveTeam } from '../Teams';
import * as API from "../api"
import LoaderComponent from '../ReusableComponents/LoaderComponent';
import { PLANNER } from '../AppConstants';
import greenDownArrow from '../icons/greenDownArrow.png'
import { SelectComponent } from '../ReusableComponents/SelectComponent';
import totalOtherSotrage from '../icons/totalOtherSotrage.png'
import totalmapsStorage from '../icons/totalmapsStorage.png'
import totalVideoStorage from '../icons/totalVideoStorage.png'
import totalImageStorage from '../icons/totalImageStorage.png'
import totalStorage from '../icons/totalStorage.png'
import noLeaseGraph from '../icons/noLeaseGraph.png'
import dateFormat from 'dateformat';
import compDashboardActive from '../icons/compDashboardActive.png'
import { convertInMbGB } from '../ReusableComponents/reusableFunctions'
const colorBar = '#FF9F24'
const colorBar1 = "#5978EC"
class AssignTaskStat extends Component {
    state = {
        pieData: undefined,
        dataNotAvail: true,
    }

    componentWillMount() {
        ChartJS.register(BarElement,
            ArcElement, Legend,
            CategoryScale,
            LinearScale,
            PointElement,
            LineElement,
            Title,
            Tooltip,);
        if (this.props.assignedTasksStats?.pending == 0 && this.props.assignedTasksStats?.inProgress == 0 && this.props.assignedTasksStats?.completed == 0) {
            this.setState({ dataNotAvail: true })
        } else {
            this.setState({
                pieData: {
                    labels: ['Todo', 'Inprogress', 'Completed'],
                    datasets: [
                        {
                            label: 'Request Status',
                            data: [Math.round((Number(this.props.assignedTasksStats?.pending) * 100) / Number(this.props.assignedTasksStats?.totalCount)),
                            Math.round((Number(this.props.assignedTasksStats?.inProgress) * 100) / Number(this.props.assignedTasksStats?.totalCount)),
                            Math.round((Number(this.props.assignedTasksStats?.completed) * 100) / Number(this.props.assignedTasksStats?.totalCount))],
                            backgroundColor: [
                                PLANNER.STATUS.TO_DO,
                                PLANNER.STATUS.IN_PROGRESS,
                                PLANNER.STATUS.COMPLETED
                            ],
                            borderColor: [
                                PLANNER.STATUS.TO_DO,
                                PLANNER.STATUS.IN_PROGRESS,
                                PLANNER.STATUS.COMPLETED
                            ],
                            borderWidth: 1,
                        },
                    ]
                }
            }, () => {
                this.setState({ dataNotAvail: false })
            })
        }
    }

    render() {
        return (
            <div className='projectStatCard' >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ fontSize: '15px', fontWeight: '500' }}>Request Status</div>
                    {this.state.dataNotAvail ? <></>
                        : <ul style={{ marginBottom: '5px' }}>
                            <li style={{ fontSize: '11px', color: '#3c3c3c' }}>{Math.round((Number(this.props.assignedTasksStats?.completed) * 100) / Number(this.props.assignedTasksStats?.totalCount))}% Completed</li>
                            <li style={{ fontSize: '11px', color: '#3c3c3c' }}>Total open request: {this.props.assignedTasksStats?.totalCount || 0}</li>
                            <li style={{ fontSize: '11px', color: '#3c3c3c' }}>Completed request: {this.props.assignedTasksStats?.completed || 0}</li>
                        </ul>}
                </div>
                {this.state.dataNotAvail ? <div style={{ width: '100%', height: 'calc(100% - 20px)', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }} >
                    <img src={noLeaseGraph} style={{ width: '25%', filter: 'grayscale(100%)', opacity: '0.5' }} />
                    <div style={{ margin: '10px 0px', fontSize: '13px' }}>No data available</div>
                </div> : <>
                    <div style={{
                        paddingBottom: "5px",
                        paddingTop: '15px',
                        height: 'calc(100% - 50px)',
                        width: '100%'
                    }}>
                        <Pie
                            height={60}
                            width={100}
                            options={{
                                responsive: true,
                                maintainAspectRatio: true,
                                tooltips: {
                                    enabled: false,
                                },
                                labels: {
                                    font: {
                                        family: "Poppins",
                                        size: "10",
                                        style: "normal",
                                        color: "#3c3c3c",
                                    },
                                },
                                legend: {
                                    display: true,
                                    position: "bottom",
                                    onClick: null,
                                    labels: {
                                        style: 'normal',
                                        fontSize: 11,
                                        boxWidth: 15,
                                        padding: 30,
                                        fontColor: '#3c3c3c'
                                    }
                                },
                            }}
                            plugins={{
                                afterDatasetsDraw: function (context, easing) {
                                    var ctx = context.chart.ctx;
                                    context.data.datasets.forEach(function (dataset) {
                                        for (var i = 0; i < dataset.data.length; i++) {
                                            if (dataset.data[i] != 0) {
                                                var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;
                                                var midAngle = model.startAngle + (model.endAngle - model.startAngle) / 2;
                                                var startX = model.x + Math.cos(midAngle) * 30
                                                var startY = model.y + Math.sin(midAngle) * 30
                                                ctx.font = ChartJS.helpers.fontString(
                                                    ChartJS.defaults.global.defaultFontSize + 2,
                                                    'bold',
                                                    ChartJS.defaults.global.defaultFontFamily,
                                                );
                                                ctx.textAlign = 'center';
                                                ctx.textBaseline = 'bottom';
                                                ctx.fillStyle = '#FFFFFF'
                                                ctx.fillText(Math.round(dataset.data[i]) + '%', startX, startY);
                                            }
                                        }
                                    });
                                },
                            }}
                            data={this.state.pieData}
                        />
                    </div>
                </>}
            </div >
        )
    }
}

class ServiceTaskState extends Component {
    state = {
        barData: undefined,
        dataNotAvail: true,
    }

    // createDiagonalPattern = (color = 'black', pos1, pos2) => {
    //     let shape = document.createElement('canvas')
    //     shape.width = 10
    //     shape.height = 10
    //     let c = shape.getContext('2d')
    //     c.strokeStyle = color
    //     c.beginPath()
    //     c.moveTo(2, 0)
    //     c.lineTo(10, 8)
    //     c.stroke()
    //     c.beginPath()
    //     c.moveTo(0, 8)
    //     c.lineTo(2, 10)
    //     c.stroke()
    //     return c.createPattern(shape, 'repeat')
    // }

    componentWillMount() {
        if (this.props.serviceTaskStats?.droneNaksha?.pending > 0 && this.props.serviceTaskStats?.droneNaksha?.inProgress > 0 && this.props.serviceTaskStats?.droneNaksha?.completed > 0
            && this.props.serviceTaskStats?.picsTork?.pending > 0 && this.props.serviceTaskStats?.picsTork?.inProgress > 0 && this.props.serviceTaskStats?.picsTork?.completed == 0) {
            this.setState({ dataNotAvail: true })
        } else {
            this.setState({
                barData: {
                    labels: ['Pending', 'Inprogress', 'Completed'],
                    datasets: [
                        {
                            label: 'Processing',
                            data: [Math.round((this.props.serviceTaskStats?.droneNaksha?.pending * 100) / this.props.serviceTaskStats?.droneNaksha?.totalCount),
                            Math.round((this.props.serviceTaskStats?.droneNaksha?.inProgress * 100) / this.props.serviceTaskStats?.droneNaksha?.totalCount),
                            Math.round((this.props.serviceTaskStats?.droneNaksha?.completed * 100) / this.props.serviceTaskStats?.droneNaksha?.totalCount)],
                            fill: true,
                            backgroundColor: colorBar,
                            stack: '0',
                            barPercentage: 0.9,
                            // categoryPercentage: 0.7,
                        },
                        {
                            label: 'Analysis',
                            data: [Math.round((this.props.serviceTaskStats?.picsTork?.pending * 100) / this.props.serviceTaskStats?.picsTork?.totalCount),
                            Math.round((this.props.serviceTaskStats?.picsTork?.inProgress * 100) / this.props.serviceTaskStats?.picsTork?.totalCount),
                            Math.round((this.props.serviceTaskStats?.picsTork?.completed * 100) / this.props.serviceTaskStats?.picsTork?.totalCount)],
                            fill: true,
                            backgroundColor: colorBar1,
                            stack: '1',
                            barPercentage: 0.9,
                            // categoryPercentage: 0.7,
                        },
                    ],
                }
            }, () => {
                this.setState({ dataNotAvail: false })
            })
        }
    }

    render() {
        return (
            <div className='projectStatCard' >
                <div style={{ fontSize: '15px', fontWeight: '500' }}>Data Processing and Analysis</div>
                {this.state.dataNotAvail ? <></>
                    : <div style={{ width: '100%', marginTop: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            <div style={{ height: '15px', fontSize: '11px', color: '#3c3c3c', margin: '5px 0px' }}>Total Processing Task: {this.props.serviceTaskStats?.droneNaksha?.totalCount || 0}</div>
                            <div style={{ height: '15px', fontSize: '11px', color: '#3c3c3c', margin: '5px 0px' }}>Total Analysis Task: {this.props.serviceTaskStats?.picsTork?.totalCount || 0}</div>
                        </div>
                        <div style={{ fontSize: '11px', color: '#3c3c3c' }}>
                            <div style={{ display: 'flex', margin: '5px 0px', alignItems: 'center' }}>
                                <div style={{ outline: `1px solid ${colorBar}`, background: `${colorBar}`, height: '11px', width: '11px', marginRight: '10px' }} />
                                <div>Processing</div>
                            </div>
                            <div style={{ display: 'flex', margin: '5px 0px', alignItems: 'center' }}>
                                <div style={{ outline: `1px solid ${colorBar1}`, background: `${colorBar1}`, height: '11px', width: '11px', marginRight: '10px' }} />
                                <div>Analysis</div>
                            </div>
                        </div>

                    </div>}
                {this.state.dataNotAvail ? <div style={{ width: '100%', height: 'calc(100% - 20px)', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }} >
                    <img src={noLeaseGraph} style={{ width: '25%', filter: 'grayscale(100%)', opacity: '0.5' }} />
                    <div style={{ margin: '10px 0px', fontSize: '13px' }}>No data available</div>
                </div> : <>
                    <div style={{ marginBottom: '5px', marginTop: '10px', height: 'calc(100% - 40px)', width: '100%' }}>
                        <Bar
                            width={100}
                            height={50}
                            options={{
                                maintainAspectRatio: true,
                                responsive: true,
                                tooltips: {
                                    enabled: false,
                                },
                                labels: {
                                    font: {
                                        family: "Poppins",
                                        size: "10",
                                        style: "normal",
                                        color: "#3c3c3c",
                                    },
                                },
                                legend: {
                                    display: false,
                                    position: 'top',
                                    align: 'end',
                                    labels: {
                                        style: 'normal',
                                        fontSize: 12,
                                        boxWidth: 15,
                                        fontColor: '#3c3c3c',
                                    },
                                },
                                scales: {
                                    xAxes: [
                                        {
                                            gridLines: {
                                                color: "#c2c2c2",
                                                display: true,
                                                drawOnChartArea: false,
                                                drawTicks: true,
                                                lineWidth: 1,
                                            },
                                            beginAtZero: true,
                                            ticks: {
                                                padding: 7,
                                                fill: "black",
                                                display: true,
                                                fontSize: 10,
                                                fontFamily: "Poppins",
                                                fontStyle: "normal",
                                                fontColor: "#3c3c3c",
                                                lineHeight: "auto",
                                                maxTicksLimit: 10,
                                            },
                                            scaleLabel: {
                                                display: false,
                                            },
                                        },
                                    ],
                                    yAxes: [{
                                        gridLines: {
                                            color: "#C2C2C2",
                                            value: "none",
                                            display: true,
                                            drawOnChartArea: true,
                                            drawTicks: false,
                                            lineWidth: 1,
                                        },
                                        beginAtZero: true,
                                        ticks: {
                                            padding: 7,
                                            fill: "black",
                                            display: true,
                                            fontSize: 12,
                                            fontFamily: "Poppins",
                                            beginAtZero: true,
                                            fontStyle: "normal",
                                            fontColor: "#3c3c3c",
                                            lineHeight: "20px",
                                        },
                                        scaleLabel: {
                                            display: false,
                                        },
                                    }],
                                },
                            }}

                            plugins={{
                                id: "legendMargin",
                                beforeInit: function (chart) {
                                    const fitValue = chart.legend.fit;
                                    chart.legend.fit = function fit() {
                                        fitValue.bind(chart.legend)();
                                        return (this.height += 10);
                                    };
                                },
                                // afterDatasetsDraw: function (context, easing) {
                                //     var ctx = context.chart.ctx;
                                //     context.data.datasets.forEach(function (dataset) {
                                //         for (var i = 0; i < dataset.data.length; i++) {
                                //             if (dataset.data[i] != 0) {
                                //                 var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;
                                //                 ctx.font = Chart.helpers.fontString(
                                //                     Chart.defaults.global.defaultFontSize - 2,
                                //                     'normal',
                                //                     Chart.defaults.global.defaultFontFamily,
                                //                 );
                                //                 ctx.textAlign = 'center';
                                //                 ctx.textBaseline = 'bottom';
                                //                 ctx.fillStyle = '#3c3c3c'
                                //                 ctx.fillText(Math.round(dataset.data[i]) + '%', model.x, model.y);
                                //             }
                                //         }
                                //     });
                                // },
                            }}
                            data={this.state.barData}
                        />
                    </div >

                </>}
            </div>
        )
    }
}

const IconWithText = ({ icon, text, value, imageBackColor }) => {
    return (<div style={{ display: 'flex', alignItems: 'center', height: '100%', width: '100%' }}>
        <div className='storageStatImg'>
            {icon ? <img src={icon} style={{ width: '90%', height: 'auto' }} /> : ''}
        </div>
        <div style={{ margin: '0px 10px' }}>
            <div style={{ fontSize: '11px' }}>{text ? text : ''}</div>
            <div style={{ fontSize: '16px', fontWeight: '500' }}>{value != undefined ? `${value}` : ''}</div>
        </div>
    </div>)
}
class StorageStats extends Component {

    render() {
        return (
            <div className='projectStatCard' style={{ padding: '0px' }} >
                <div style={{ fontSize: '15px', fontWeight: '500' }}>Storage</div>
                <div style={{ display: 'flex', height: 'calc(100% - 40px)', width: '100%' }}>
                    <div className='storageMainDiv' >
                        <div className='storageDiv'>
                            <IconWithText
                                icon={totalStorage}
                                text={'Total Storage'}
                                value={convertInMbGB(Number(this.props.storageStats.videos) + Number(this.props.storageStats.maps) + Number(this.props.storageStats.images) + Number(this.props.storageStats.other))}
                                imageBackColor={'#A3EFD5'} />
                        </div>
                        <div className='storageDiv'>
                            <IconWithText icon={totalVideoStorage}
                                text={'Videos'}
                                value={convertInMbGB(Number(this.props.storageStats.videos))}
                                imageBackColor={'#A3EFD5'} />
                        </div>
                        <div className='storageDiv'>
                            <IconWithText
                                icon={totalmapsStorage}
                                text={'Maps'}
                                value={convertInMbGB(Number(this.props.storageStats.maps))}
                                imageBackColor={'#A3EFD5'} />
                        </div>
                    </div>
                    <div className='storageMainDiv'>
                        <div className='storageDiv'>
                            <IconWithText
                                icon={totalImageStorage}
                                text={'Images'}
                                value={convertInMbGB(Number(this.props.storageStats.images))}
                                imageBackColor={'#A3EFD5'} />
                        </div>
                        <div className='storageDiv'>
                            <IconWithText
                                icon={totalOtherSotrage} text={'Others'}
                                value={convertInMbGB(Number(this.props.storageStats.other))}
                                imageBackColor={'#A3EFD5'} />
                        </div>
                        {/* <div className='storageDiv'></div> */}
                    </div>
                </div>
                <div style={{ height: '15px', width: 'fit-content', fontSize: '11px', }}>Note: others includes DSM, DTM, 3D, .kml etc. files</div>
            </div>
        )
    }
}

class ComparedProject extends Component {
    state = {
        selectedItem: undefined,
        projectData: undefined,
        assignedTasksStats: undefined,
        serviceTaskStats: undefined,
        storageStats: undefined,
        loading: true,
        projects: [],
        noProjectAvail: false,
    }

    componentWillMount() {
        this.setState({
            projects: this.props.projects,
            selectedItem: this.props.projects[this.props.keyData] ? [{ value: this.props.projects[this.props.keyData].id, label: this.props.projects[this.props.keyData].project_name.length > 16 ? `${this.props.projects[this.props.keyData].project_name.substring(0, 16)}..` : this.props.projects[this.props.keyData].project_name }] : [{ value: 'Select project', label: 'Select project' }]
        }, () => {
            this.getProjectStat()
        })
    }

    getProjectStat = (id) => {
        if (this.state.selectedItem[0].value !== 'Select project' && this.state.selectedItem[0].label !== 'Select project') {
            API.getProjectStats(this.state.selectedItem[0].value).then((data) => {
                console.log('data', data);
                this.setState({
                    projectData: data,
                    assignedTasksStats: data.assignedTasksStats,
                    serviceTaskStats: data.serviceTasksStats,
                    storageStats: data.storageStats,
                }, () => {
                    this.setState({ loading: false })
                })
            })
        } else {
            this.setState({ noProjectAvail: true })
        }

    }

    onChangeEvent = (project) => {
        this.setState({
            selectedItem: project,
            loading: true
        }, () => {
            this.getProjectStat()
        })
    }

    render() {
        return (<div style={{ height: "calc(50% - 30px)", minHeight: '300px', margin: '10px 20px 0px 20px' }}>
            {!this.state.noProjectAvail ? <>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ fontSize: '20px', fontWeight: '500' }}>{this.state.selectedItem[0].label}</div>
                    <div style={{ border: 'none', outline: '1px solid #c6c6c657', background: 'white', borderRadius: '6px', padding: '2px 0px', width: 'max-content' }}>
                        <SelectComponent
                            selected={this.state.selectedItem}
                            itemsList={this.props.projects.map((project) => { return ({ value: project.id, label: project.project_name.length > 16 ? `${this.props.projects[this.props.keyData].project_name.substring(0, 16)}..` : project.project_name }) })}
                            selectIcon={greenDownArrow}
                            iconSize={"20px"}
                            fontSize={"14px"}
                            width={"200px"}
                            fontWeight={500}
                            padding={"0px 5px 0px 10px"}
                            selectedColor={'#3c3c3c'}
                            displayLength={'20'}
                            height={'30px'}
                            onchangeHandler={(value) => this.onChangeEvent(value)}
                        />
                    </div>
                </div>

                <div className='projectStat' >
                    {!this.state.loading ? <>
                        <AssignTaskStat assignedTasksStats={this.state.assignedTasksStats} />
                        <div style={{ width: '1px', height: '100%', margin: '0px 20px', borderLeft: '1px solid #c6c6c657' }} />
                        <ServiceTaskState serviceTaskStats={this.state.serviceTaskStats} />
                        <div style={{ width: '1px', height: '100%', margin: '0px 20px', borderLeft: '1px solid #c6c6c657' }} />
                        <StorageStats storageStats={this.state.storageStats} />
                    </> : <LoaderComponent />}
                </div>
            </>
                : <></>}

        </div>
        )
    }
}

class ProjectComparison extends Component {
    state = {
        activeTeam: undefined,
        user: '',
        loading: true,
        projects: [],
        notifications: [],
        plannerTask: [],
    }

    componentWillMount() {
        document.title = "Dashboard";
        getActiveTeam().then((activeTeam) => {
            this.setState({ activeTeam }, () => {
                if (this.props.location.state) {
                    let { user } = this.props.location.state;
                    this.setState((state) => ({
                        ...state,
                        user,
                    }), async () => {
                        this.getAllProjectsData()
                        this.getNotifications()
                        this.getAssignedTasks()
                    })
                } else {
                    API.getUserType().then(([blah, type, user]) => {
                        this.setState((state) => ({
                            ...state,
                            user,
                            type
                        }), async () => {
                            this.getAllProjectsData()
                            this.getNotifications()
                            this.getAssignedTasks()
                        })
                    }, (e) => {
                        window.location.replace("/login");
                    })
                }
            })
        })

    }

    getAllProjectsData = async () => {
        API.getAllProjects(" ", 10000, 0, undefined, this.state.activeTeam.isOwner ? false : ROLES.MANAGER) //if user is not owner of team then fetch projects of active team which user has MANAGER ROLE.
            .then(async (data) => {
                this.setState({
                    projects: (data?.projects?.filter(p => !(p.sharedData?.isShared)) || [])
                }, () => {
                    this.setState({ loading: false })
                })
            }, (e) => {
                console.log(e);
            })
    }

    getNotifications = () => {
        API.getNotifications(5).then((result) => {
            console.log("Notifications : ", result)
            if (result.notifications) {
                this.setState({
                    notifications: result.notifications,
                })
            } else {
                this.setState({
                    notifications: [],
                })
            }

        }).catch(e => {
            console.log(e);
        })
    }

    getAssignedTasks = () => {
        API.getAssignedTasksAPI(false, false, 5).then((result) => {
            console.log("planner task : ", result)
            if (result.assignedTasks) {
                this.setState({
                    plannerTask: result.assignedTasks,
                })
            } else {
                this.setState({
                    plannerTask: [],
                })
            }
        })
    }

    render() {
        let sidebarConfigurations = {
            options: [
                {
                    icon: ServiceInActive,
                    text: "Services",
                    path: "/",

                },
                {
                    icon: TeamsInActive,
                    text: "Teams",
                    path: "/teams",
                },
                {
                    icon: Planner,
                    text: "Planner",
                    path: "/planner"

                },
                {
                    icon: compDashboardActive,
                    text: "Dashboard",
                    path: "/progress-dashboard",
                    selected: true,
                },
                {
                    icon: Settings,
                    text: "Settings",
                    path: "/profile-settings",
                },
                {
                    icon: Help,
                    text: "Help",
                    path: "/feedback",
                },
            ],
            user: this.state.user
        };

        return (<div className="wrapper" >
            <SideBar config={sidebarConfigurations} height={"initial"} />
            <div className="right-content">
                <div className="top-bar">
                    <div className="top-bar-text">Dashboard</div>
                </div>
                {this.state.loading ? <LoaderComponent />
                    : <div className="main-content" style={{ display: 'flex', justifyContent: 'space-between', height: 'calc(100vh - 40px)' }}>
                        {this.state.projects.length > 0 ?
                            <div style={{ width: "74%", outline: '1px solid #c6c6c657', height: '100%', overflow: 'auto', background: '#FFFFFF' }}>
                                <ComparedProject keyData={0} projects={this.state.projects} />
                                <hr style={{ margin: '15px 0px', background: '#c6c6c657' }} />
                                <ComparedProject keyData={1} projects={this.state.projects} />
                            </div>
                            : <div style={{ width: "74%", fontSize: '13px', outline: '1px solid #c6c6c657', height: '100%', overflow: 'auto', background: '#FFFFFF', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                No data available
                            </div>}
                        <div style={{ width: '25%', height: '100%', overflow: 'auto', padding: '1px' }}>
                            <div style={{ height: '50%', width: '100%' }}>
                                <div style={{ background: '#FFFFFF', outline: '1px solid #c6c6c657', }}>
                                    <div style={{ fontSize: '16px', fontWeight: '500', padding: '10px 10px 5px 10px', height: '45px', width: '100%', display: 'flex', alignItems: 'center', background: '#EAEAEA' }}>Recent Notification</div>
                                    <div style={{ height: 'calc(100% - 50px)', overflow: 'auto' }}>
                                        {this.state.notifications.length > 0 ?
                                            this.state.notifications.map((notif, i) => <>
                                                <div className='recentRowData' style={{ flexDirection: 'column' }}>
                                                    <div>{notif.content?.length > 30 ? notif.content.substring(0, 30) : notif.content}</div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: '300', alignItems: 'center', fontSize: '10px' }}>
                                                        <div>{dateFormat(notif.created_date, 'dd-mm-yyyy')}</div>
                                                        <div>{dateFormat(notif.created_date, 'h:MM TT')}</div>
                                                    </div>
                                                </div >
                                                {i !== this.state.notifications.length - 1 ? <hr style={{ margin: '0px', background: '#c6c6c657' }} /> : ""}

                                            </>)
                                            : <div style={{ width: '100%', height: '100%', fontSize: '13px', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '50px 0px' }}>No notification available</div>}
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: '50%', width: '100%' }}>
                                <div style={{ background: '#FFFFFF', outline: '1px solid #c6c6c657', }}>
                                    <div style={{ fontSize: '16px', fontWeight: '500', padding: '10px 10px 5px 10px', height: '45px', width: '100%', display: 'flex', alignItems: 'center', background: '#EAEAEA' }}>Recent Task</div>
                                    <div style={{ height: 'calc(100% - 50px)', overflow: 'auto' }}>
                                        {this.state.plannerTask.length > 0 ?
                                            this.state.plannerTask.map((task, i) => <>
                                                <div className='recentRowData' style={{ alignItems: 'center' }}>
                                                    <div>{task.description?.length > 26 ? task.description.substring(0, 26) : task.description}</div>
                                                    <div style={{ fontSize: '10px', fontWeight: '300' }}>{task.status}</div>
                                                </div>
                                                {i !== this.state.plannerTask.length - 1 ? <hr style={{ margin: '0px', background: '#c6c6c657', }} /> : ""}

                                            </>)
                                            : <div style={{ width: '100%', height: '100%', fontSize: '13px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No task available</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
            </div>
        </div >)
    }

}
export default ProjectComparison