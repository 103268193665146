import React, { Component } from 'react';
import * as API from '../../api.js';
import Loader from '../../icons/loader.svg';
import Chart from 'react-google-charts'
import dateFormat from 'dateformat';
import noData from '../../assets/Icons/noData.png';
import { addUserAnalytic, getLimitedName, updateOutTime } from '../../ReusableComponents/reusableFunctions';
import ButtonWithIcon from '../../ReusableComponents/ButtonWithIcon';

let colors = []
class TaskSummaryReport extends Component {
  state = {
    taskDetails: null,
    user: {},
    type: "",
    loader: true,
    showBackIcon: true,
    obj: [],
    data: [],
    report: [],
    report1: [],
    outId: "",
    cancel: false,
    totalObjectsDetected: 0,
    loading: false,
    task: {
      project_name: 'asd',
      plan_name: 'asd',
      task_name: 'asd',
      type: ['missing screws', 'rusting']
    },
  }

  getTask = async () => {
    await API.getAeroCaptureTask(this.state.task.task_id).then((data) => {
      this.setState((state) => ({
        ...state,
        task: data
      }))
    }, (e) => {
      console.log(e);
    })
  }
  getSlices() {
    let colorObj = {}
    for (let i = 0; i < this.state.obj.length; i++) {
      let ob = this.state.obj[i]
      colorObj = { ...colorObj, [i]: { color: ob?.color || "" } }
    }
    return colorObj
  }
  getReportDetails = async () => {
    API.getDetections(this.state.task.task_id, this.state.task.type).then((data) => {
      if (data.ret.length == 0) {
        this.setState((state) => ({
          ...state,
          loader: false
        }))
      } else {
        this.setState((state) => ({
          ...state,
          report: data.ret,
          report1: data.image_data,
          isDetectionDirty: true,
          totalObjectsDetected: 0,
        }), () => {
          if (this.state.report.length > 0) {
            let counter = 0;
            this.state.report.forEach((element) => {
              this.state.obj.forEach((ob,i) => {
                ob.count += element.result.detections[ob.type] ? element.result.detections[ob.type].count : 0;
                ob.color = ob.color ? ob.color : this.state.task.classes[i]?this.state.task.classes[i].color : ""; //if color is assigned to ob there is no need to check for each iteration of report arr for color

              });
              counter++
              if (counter == this.state.report.length) {
                let temp = [];
                let tempColor = {};
                let cntr = 0;
                let totalCount = 0;
                this.state.obj.forEach((el) => {
                  let dt = []
                  dt[0] = el.type.toLowerCase().replaceAll("_", ` `);
                  dt[1] = el.count;
                  totalCount += el.count
                  temp.push(dt);
                  cntr++;
                  if (cntr == this.state.obj.length) {
                    console.log("LOOP COMPLETED");
                    this.setState((state) => ({
                      ...state,
                      loader: false,
                      data: temp,
                      totalObjectsDetected: totalCount
                    }))
                  }
                });
              }
            });
          }
          else {
            this.setState({
              loader: false,
            })
          }
        })
      }
    }, (e) => {
      console.log(e);
      this.setState((state) => ({
        ...state,
        report: [],
        loader: false,
        loading: false
      }))
    })
  }

  componentWillMount() {
    document.title = "PicStork - Summary";
    if (this.props) {
      let { user, type, task, plan, project } = this.props;
      console.log(this.props);
      this.setState((state) => ({
        ...state,
        user,
        type,
        task,
        plan,
        project
      }), async () => {
        await this.getTask()
        if (this.state.task && this.state.task.type) {
          let ob = this.state.task.type;
          let temp = {};
          let tmp = [];
          let counter = 0;
          ob.forEach((type, key) => {
            // temp.selected = false;
            temp.type = type;
            temp.id = key;
            temp.count = 0;
            temp.color = colors[counter];

            tmp.push(temp);
            temp = {}
            counter++
            if (counter == ob.length) {
              this.setState((state) => ({
                ...state,
                obj: tmp
              }), () => {
                this.getReportDetails()
              })
            }
          });
        }
      })
    } else {
      API.getUserType().then(([blah, type, user]) => {
        this.setState((state) => ({
          ...state,
          user,
          type
        }), () => {
          this.getReportDetails();
        })
      }, (e) => {
        window.location.replace("/login");
      })
    }
  }
  render() {
    let { user, type, showBackIcon, task, obj, data, report, report1, taskDetails, plan, project } = this.state

    return (
      user.username ?
        <div className="wrapper" style={{ width: '100%', height: "85vh" }}>
          <div style={{ width: '100%', height: "100%", }}>
            {this.state.loader ? <div style={{ height: "100%", width: "100%", display: "flex", textAlign: "center" }}>
              <img src={Loader} style={{ height: "30vh", width: "100%", margin: "auto" }} />
            </div> :
              <div style={{ background: 'none', width: '100%', height: "100%", }} >
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "auto", marginBottom: '10px' }}>
                  <div className="services-content-title">
                    {"Summary Report"}
                  </div>
                  {/* <div style={{ justifyContent: 'space-between', display: 'flex', width: '335px' }}> */}
                  <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                    {/* <div className="task-button" onClick={() => this.props.changeView(2)} >Detail Report</div> */}
                    {/* <div className="task-button" onClick={() => this.props.changeView(1)} >Task Details</div> */}
                    <ButtonWithIcon
                    text={"Task Details"}
                    onClick={() => this.props.changeView(1)}
                    />
                  </div>
                  {/* </div> */}
                </div>
                <div className="topRow">
                  <div className="rowItem">
                    <div className="itemKey">Project Name</div>
                    <div className="itemValue">{getLimitedName(task.project_name, 30)}</div>
                  </div>
                  <div className="vl"></div>
                  <div className="rowItem">
                    <div className="itemKey">Imageset</div>
                    <div className="itemValue">{getLimitedName(task.collection_name, 30)}</div>
                  </div>
                  <div className="vl"></div>
                  <div className="rowItem">
                    <div className="itemKey">Date</div>
                    <div className="itemValue">{dateFormat(task.date_created, "dd-mm-yyyy")}</div>
                  </div>
                  <div className="vl"></div>
                  <div className="rowItem">
                    <div className="itemKey">Total Images</div>
                    <div className="itemValue">{report.length}</div>
                  </div>
                </div>
                {obj.length === 0 || this.state.totalObjectsDetected == 0 ? <div className="rmp-dashboard-empty-data-message" style={{ flexDirection: "column", height: "65%", marginTop: "5vh" }}>
                  <img src={noData} style={{ height: "230px" }} />
                  <div className="totalDetections" style={{ marginTop: "-3%" }}>No detections found</div>
                </div> : <div className="analysisSummary">
                  <div className="pieChart">
                    <Chart
                      width={'30vw'}
                      height={'50vh'}
                      chartType="PieChart"
                      loader={<div style={{ width: '30vw', height: '50vh', display: "flex", justifyContent: "center", alignItems: "center" }}>Loading Chart</div>}
                      data={[
                        ['defect', 'count'],
                        ...data
                      ]}
                      options={{
                        legend: 'none',
                        pieSliceText: "none",
                        backgroundColor: "transparent",
                        slices: this.getSlices()
                      }}

                      rootProps={{ 'data-testid': '5' }}
                    />
                    <div className="totalDetections">Total Detections <br /> <span style={{ fontWeight: 500 }}>{this.state.totalObjectsDetected}</span></div>
                  </div>
                  <div className="vl" style={{ height: "85%" }}></div>
                  <div className="summary">
                    <div className="header">Analysis Summary</div>
                    <div className="analysisTable" style={{ height: "70%" }}>
                      <table>
                        {obj.length === 0 || this.state.totalObjectsDetected == 0 ?
                          <tr>
                            <td>No Detections Available</td>
                          </tr> :
                          obj.filter((element) => {
                            if (element.count > 0) {
                              return element
                            }
                          }).map((ob) =>
                            <tr>
                              <td className="color" style={{ marginRight: '2px' }}><div className="eclipse" style={{ backgroundColor: ob.color }}></div></td>
                              <td className="object" style={{ margin: '0 2px' }}>{getLimitedName(ob.type, 12)}</td>
                              <td className="detected" style={{ margin: '0 2px' }}>{ob.count}</td>
                              <td className="percentage" style={{ marginLeft: '2px' }}>{((ob.count / this.state.totalObjectsDetected) * 100).toFixed(2)}%</td>
                            </tr>
                          )}
                      </table>
                    </div>
                  </div>
                </div>}
              </div>}
          </div>
        </div > : <div />
    );
  }
}
export default TaskSummaryReport;
