import React, { Component, useState } from 'react';
import { addProjectDetails } from "../../redux";
import dateFormat from 'dateformat'
import { BreadCrumbs } from '../../ReusableComponents/BreadCrumbs';
import AeroMeghLogo from "../../aeromeghLog.png";
import circleLeft from '../../icons/prev.png';
import circleRight from '../../icons/next.png';
import dashboardIcon from '../../assets/sideBarIcons/dashboard.png';
import { connect } from "react-redux";
import * as API from '../../api.js';
import trashIcon from '../../assets/Icons/trash.svg';
import closeButton from '../../close-button.png';
import closeBtn from '../../icons/closeButton.png';
import Popup from "reactjs-popup";
import Loader from '../../icons/loader.svg';
import { Link } from 'react-router-dom';
import service from "../../sidebaricon/Services.png";
import menuIcon from "../../assets/Icons/menu.png";
import Dropdown from 'react-bootstrap/Dropdown';
import LocationIcon from '../../res/locationwhite.png';
import ViewImagesOnMap from '../../ReusableComponents/inspectOnMapDroneNaksha';
import ViewImageInList from '../../ReusableComponents/viewImageInList';
import MultiFileUploader from "../../AeroCapture/Components/aeroCaptureMultiFileUploader";
import Noimageset from '../../assets/Images/Noimageset.png';
import refreshBtn from '../../assets/Icons/refresh.png'
import tickIcon from '../../icons/tick.png'
import projectIcon from '../../assets/sideBarIcons/ProjectActive.png';
import { ActivityPopup } from '../../ReusableComponents/ActivityPopup.js';
import Tiff from 'tiff.js'
import { checkURLExist } from "../../ReusableComponents/reusableFunctions";
import notificationIcon from '../../assets/Icons/notification.png'
import notificationActive from '../../assets/Icons/notificationActive.png'
import greenTick from '../../assets/Icons/greenTick.png';
import greyTick from '../../assets/Icons/greyTick.png';
import redCancel from '../../assets/Icons/redCancel.png';
import Slide from '@mui/material/Slide';
import Collapse from '@mui/material/Collapse';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Search from "../../Icon/Search.png";
import Help from "../../sidebaricon/Help.png";
import imageActive from "../../assets/sideBarIcons/Images.png"
import ImageSetInActive from '../../assets/sideBarIcons/ImageSetInactive.png';
import listViewIcon from '../../icons/listview.png'
import listViewActiveIcon from '../../icons/listViewActiveIcon.png'
import gridViewIcon from '../../icons/gridview.png'
import gridViewActiveIcon from '../../icons/gridViewActiveIcon.png'
import mapViewIcon from '../../assets/sideBarIcons/PlanInactive.png'
import mapViewActive from '../../assets/sideBarIcons/mapView.png'
import closeIcon from '../../icons/warningIcon.png'
import waitingIcon from '../../icons/waitingIcon.png'
import { AnimatedModal } from '../../ReusableComponents/AnimatedModal';
import { addUserAnalytic, updateOutTime } from '../../ReusableComponents/reusableFunctions.js';
import ButtonWithIcon from '../../ReusableComponents/ButtonWithIcon';
import Plus from "../../icons/addBlue.png";
import ImportOrtho from '../../ReusableComponents/ImportOrtho';
import Menu from "../../icons/MenuWhite.png";
import ImageSetThumb from "../../icons/ImageSetThumb.png"
import { ActiveRole, DRONENAKSHA_FEATURES, getFeaturesPermissions } from '../../Teams';
import AppConstants, { BLOB_URL } from '../../AppConstants';
import OrthoThumb from "../../icons/OrthoThumb.png";
import DSMThumb from "../../icons/DSMThumb.png";
import DTMThumb from "../../icons/DTMThumb.png";
import ImagesTypeSelection from '../../AeroCapture/Components/ReusableComp/ImagesTypeSelection';
import { StorageLimitExceed } from '../../subscription/UserSubscription';



const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
	<a
		href=""
		ref={ref}
		onClick={e => {
			e.preventDefault();
			onClick(e);
		}}
	>
		{children}
		<img src={menuIcon} alt="I am alt" />
	</a>
));

const CustomToggle1 = React.forwardRef(({ children, onClick }, ref) => (
	<a
		href=""
		ref={ref}
		onClick={e => {
			e.preventDefault();
			onClick(e);
		}}
	>
		{children}
		<img style={{ height: "12px", marginBottom: "5px", transform: "rotate(50%)" }} src={Menu} alt="I am alt" />
	</a>
));

class AeroCaptureImageCollectionImages extends Component {
	state = {
		isOwner: false,
		sharedBy: null,
		user: {},
		type: "",
		progress: 0,
		showCheckboxes: false,
		selectedImageCount: 0,
		Idx: 0,
		userperpage: 1000000,
		currentpage: 1,
		userdisplay: [],
		showDownloadPopup: false,
		loader: true,
		services: [],
		counts: 0,
		projects: [],
		orthoMosaicTaskStatus: 'No task',
		collection_name: '',
		tasks: undefined,
		images: [],
		reports: [
		],
		selecttedImages: [],
		showBackIcon: true,
		cancel: false,
		menuOptions: false,
		loading: true,
		sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
		imageset: '',
		scale: 0,
		mapView: false,
		listview: false,
		gridView: true,
		imageLoad: false,
		processAction: "",
		thumbURLs: [],
		imageURL: "",
		uploadPercent: 0,
		hasMoreItems: true,
		refresh: false,
		outId: "",
		interval: null,
		notificationPanel: false,
		taskStates: [],
		isExpanded: false,
		key: "",
		selectOrtho: true,
		select3d: false,
		selectElevation: false,
		accuracyParameter: 'low',
		taskParameters1: [],
		createTaskModal: false,
		deleteOrtho: false,
		sortType: 'Last Modified',
		isInvalidImages: false,
		processCheckMsg: { invalid_images: 0, usable_images: 0 },
		imagesStatistics: '',
		orthoAccuracy: 'low',
		elevationAccuracy: 'low',
		threeDAccuracy: 'low',
		selectedType: 'orthomosaic',
		isTaskPresent: false,
		orthoImage: undefined,
		orthoImagesData: [],
		viewMapShow: false,
		ThreeDAvailable: false,
		project: '',
		collection: '',
		importAssets: false,
		imageId: undefined,
		indThreeDAvailable: false,
		indPointCloudAvailable: false,
		showStorageExceedPopup: false,
		processingCreditExceed: false,
		activityPopupObj: undefined
	}

	parentSidebarSwitch = () => {
		this.setState((state) => ({
			...state,
			sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
		}))
	}
	closeActivityPopup = () => {
		this.setState((state) => ({
			...state,
			activityPopupObj: undefined
		}))
	}
	zoomIn = () => {
		if (this.state.scale < 15) {
			var myImg = document.getElementById("action-image")
			var currWidth = myImg.clientWidth;
			var currHeight = myImg.clientHeight;
			myImg.style.width = (currWidth + 100) + "px";
			this.setState((state) => ({
				...state,
				scale: this.state.scale + 1
			}))
			myImg.style.height = (currHeight + 100) + "px";
		}
	}

	// function to load tiff thumbnails
	loadThumbTiff = async (images) => {
		return new Promise(async (resolve, reject) => {
			for (let i = 0; i < images.length; i++) {
				if (images[i].file_name) {
					let name = images[i].file_name
					let turl = `${BLOB_URL}/${this.state.permissions.container}/FlightImages/collection-${this.state.collection.id}/thumb-${images[i].file_name}?${this.state.permissions.st}`
					images[i].thumbURL = turl
				}
				if (images.length - 1 == i) {
					console.log(i, "fdshfsfjfkbjkbggdskgds", images.length - 1);
					resolve(images)
				}
			}
		})
	}

	// to load tiff image 
	loadTiffImageSrc = (i) => {
		let { images, user } = this.state
		var imageURL = ""
		if (images[i].file_name) {
			let name = images[i].file_name.toLowerCase()
			if ((name.indexOf(".tif")) == -1) {
				let iurl = `${BLOB_URL}/${this.state.permissions.container}/FlightImages/collection-${this.state.collection.id}/${images[i].file_name}?${this.state.permissions.st}`
				imageURL = iurl
				this.setState((state) => ({
					...state,
					imageURL: imageURL,
				}))
			} else {
				// for image
				var xhr = new XMLHttpRequest();
				xhr.responseType = 'arraybuffer';
				let url = `${BLOB_URL}/${this.state.permissions.container}/FlightImages/collection-${this.state.collection.id}/${images[i].file_name}?${this.state.permissions.st}`
				xhr.open('GET', url);
				xhr.onload = function (e) {
					var arraybuffer = e.target.response
					Tiff.initialize({
						TOTAL_MEMORY: 16777216 * 10
					});
					var tiff = new Tiff({
						buffer: arraybuffer
					})
					var dataUrl = tiff.toDataURL();
					let url = e.target.responseURL
					var index = 0
					this.state.images.map((image, j) => {
						if (url.indexOf(encodeURIComponent(image.file_name)) != -1) {
							index = j
						}
					})
					imageURL = dataUrl   // to insert url at specific position
					this.setState((state) => ({
						...state,
						imageURL: imageURL,
					}))
				}.bind(this);
				xhr.send()
			}
		}
	}

	zoomOut = () => {
		if (this.state.scale != 0) {
			var myImg = document.getElementById("action-image")
			var currWidth = myImg.clientWidth;
			var currHeight = myImg.clientHeight;
			this.setState((state) => ({
				...state,
				scale: this.state.scale - 1
			}))
			myImg.style.width = (currWidth - 100) + "px";
			myImg.style.height = (currHeight - 100) + "px";
		}

	}

	getImages = async () => {
		let { user, type, userdisplay, currentpage, userperpage, key, counts, requestStatus } = this.state;
		const lastIndex = currentpage * userperpage;
		const firstpage = lastIndex - userperpage;
		const currentuser = userdisplay.slice(firstpage, lastIndex);
		const totalpages = userdisplay.length / userperpage;
		key = key == "" ? " " : key
		if (this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].READ) API.getImageCollectionImages(this.state.collection.id, key, userperpage, firstpage).then((data) => {
			if (this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].READ) API.getImageOrthomosaicImages(this.state.collection.plan_id).then(async (orthoData) => {
				let orthoImage = orthoData.images ? orthoData.images.find(ortho => ortho.collection_id == this.state.collection.id) : undefined
				// if (data.images.length > 0) {
				if (this.state.uploadPercent == 100 || this.state.uploadPercent == 0) {
					const tiffImg = data.images.length > 0 ? await this.loadThumbTiff(data.images) : []
					this.setState((state) => ({
						...state,
						images: tiffImg,
						sasToken: data.sasToken,
						counts: data.totalCount,
						showCheckboxes: false,
						selectedImageCount: 0,
						isTaskPresent: data.tasksCount > 0,
						orthomosaicImages: [...data.images],
						orthoImage: orthoImage,
						orthoImagesData: orthoData.images && orthoData.images.length > 0 ? orthoData.images.filter(obj => obj.collection_id == this.state.collection.id && (obj.image_type == "orthomosaic" || obj.image_type == "threeD" || obj.image_type == "pointCloud" || obj.image_type == "dem" || obj.image_type == "dtm")) : [],
						viewMapShow: orthoData.images.filter(obj => obj.collection_id == this.state.collection.id).length > 0 ? true : false,
					}), () => {
						if (this.state.sortType === 'A-Z') {
							this.sortByName()
						} else if (this.state.sortType === 'Z-A') {
							this.reverseByName()
						} else if (this.state.sortType === 'First Modified') {
							this.sortByDate()
						} else if (this.state.sortType === 'Last Modified') {
							this.reverseByDate()
						}
						if (this.state.mapView) {
							this.setState((state) => ({
								...state,
								mapView: false
							}), () => {
								this.setState((state) => ({
									...state,
									mapView: true
								}))
							})
						}

						this.checkImagesForProcessing1()
						API.getTaskDetails(this.state.collection.id, 3).then(async tasks => {
							if (tasks.tasks.length > 0) {

								let task = tasks.tasks[0]
								// && orthoImage === undefined
								task.status = task.status == "Created" ? "Pending" : task.status
								this.setState({
									loading: false,
									tasks: task,
									// && orthoImage === undefined
									orthoMosaicTaskStatus: task.status == "Created" ? "Pending" : task.status,
									ThreeDAvailable: await checkURLExist(`${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${task.task_id}/ept/ept.json?${this.state.permissions.st}`) ||
										await checkURLExist(`${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${task.task_id}/threeD/georeferencing_model_geo.txt?${this.state.permissions.st}`) &&
										await checkURLExist(`${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${task.task_id}/threeD/textured_model_geo.obj?${this.state.permissions.st}`) &&
										await checkURLExist(`${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${task.task_id}/threeD/textured_model_geo.mtl?${this.state.permissions.st}`) ?
										true : false,

									indThreeDAvailable: await checkURLExist(`${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${task.task_id}/threeD/georeferencing_model_geo.txt?${this.state.permissions.st}`) &&
										await checkURLExist(`${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${task.task_id}/threeD/textured_model_geo.obj?${this.state.permissions.st}`) &&
										await checkURLExist(`${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${task.task_id}/threeD/textured_model_geo.mtl?${this.state.permissions.st}`) ?
										true : false,

									indPointCloudAvailable: await checkURLExist(`${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${task.task_id}/ept/ept.json?${this.state.permissions.st}`) ? true : false
								})
								this.getUserTaskLogUpdate()
							} else {
								this.setState({
									loading: false,
									tasks: undefined,
								})
							}
						})
					})
				}
				// } 
				// else {
				// 	this.checkImagesForProcessing1()
				// 	this.setState((state) => ({
				// 		...state,
				// 		loading: false,
				// 		images: [],
				// 		// sasToken: '',
				// 		counts: 0,
				// 		// showCheckboxes: false,
				// 		selectedImageCount: 0,
				// 	}))
				// }
			})
		}, (e) => {
			console.log(e);
			alert('Something went wrong')
			this.setState((state) => ({
				...state,
				loading: false,
			}))
		})
		else {
			this.setState((state) => ({
				...state,
				loading: false,
			}))
		}
	}

	selectTaskParameters = (options) => {
		if (options == 'orthomosaic') {
			this.setState({
				selectOrtho: true
			})
		} else if (options == 'elevation') {
			this.setState({
				selectElevation: !this.state.selectElevation
			})
		} else if (options == '3d') {
			this.setState({
				select3d: !this.state.select3d
			})
		}
	}

	nextImg = () => {
		this.setState({
			...this.state,
			imageLoad: true,
			Idx: this.state.Idx + 1
		}, () => this.loadTiffImageSrc(this.state.Idx))
	}

	prevImg = () => {
		this.setState({
			...this.state,
			imageLoad: true,
			Idx: this.state.Idx - 1
		}, () => this.loadTiffImageSrc(this.state.Idx))
	}


	deleteImageById = async () => {
		console.log(" Delete Function Called ! \n this is image ID : ", this.state.imageId);
		await this.setState({
			...this.state,
			activityPopupObj: undefined
		})
	}

	deleteImages = async () => {
		this.setState((state) => ({
			...state,
			Idx: 0
		}))
		let temp = [];
		let counter = 0;
		await this.state.images.forEach(img => {
			if (img.checked == true) {
				temp.push(img.file_name);
			}
			counter++;
			if (counter == this.state.images.length) {
				return temp
			}
		});
		let images = temp
		this.setState((state) => ({
			...state,
			activityPopupObj: {
				open: true,
				item: "Images",
				icon: "WAIT",
				ACTION: "DELETE",
				msg: "Please wait.."
			}
		}))
		let collection_id = this.state.collection.id;
		if (this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].DELETE) API.deleteImages(collection_id, images, AppConstants.SERVICES.DRONENAKSHA).then((data) => {
			this.setState((state) => ({
				...state,
				showCheckboxes: false,
				activityPopupObj: {
					open: true,
					item: "Images",
					icon: "COMPLETE",
					ACTION: "DELETE",
					msg: "Images are deleted successfully."
				}
			}), () => {
				this.getImages()
			})
		}, (e) => {
			this.setState((state) => ({
				...state,
				activityPopupObj: {
					open: true,
					item: "Images",
					icon: "ERROR",
					action: "DELETE",
					msg: e
				}
			}))
		})

	}
	openDeleteConfirmationPopup = () => {
		this.setState((state) => ({
			...state,
			activityPopupObj: {
				open: true,
				item: "Images",
				icon: "WARNING",
				action: "DELETE",
				msg: "Are you sure you want to delete these Images ?"
			}
		}))
	}
	openImage = (idx) => {
		// window.alert(idx)
		let { images, user } = this.state
		this.setState((state) => ({
			...state,
			Idx: idx,
			imageLoad: true,
			scale: 0

		}), () => {
			this.loadTiffImageSrc(this.state.Idx)
			var style = document.createElement('style');
			style.type = 'text/css';
			style.innerHTML = '.popup-content {width: 800px !important; border-radius:15px;}';
			document.getElementsByTagName('head')[0].appendChild(style);
			document.getElementById("view-image-popup-button").click()
		})
	}

	checkImagesForProcessing = () => {
		this.setState((state) => ({
			...state,
			activityPopupObj: {
				open: true,
				item: "Task",
				icon: "WAIT",
				msg: 'Checking images, please wait...',
			}
		}))
		if (this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].EDIT) API.checkImagesForProcessing(this.props.location.state.collection.id).then(async (data) => {
			if (data.invalid_images != null) {
				this.setState((state) => ({
					...state,
					processPopupIcon: "ERROR",
					isInvalidImages: true,
					processCheckMsg: data,
					activityPopupObj: undefined
				}))
			} else {
				this.setState((state) => ({
					...state,
					isInvalidImages: false,
					createTaskModal: true,
					activityPopupObj: undefined

				}))
			}
		}, (e) => {
			this.setState((state) => ({
				...state,
				isInvalidImages: false,
				activityPopupObj: {
					open: true,
					item: "Task",
					icon: "ERROR",
					action: "ADD",
					msg: e.message
				}
			}))
		})
	}

	checkImagesForProcessing1 = () => {
		if (this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].EDIT) API.checkImagesForProcessing(this.props.location.state.collection.id).then(async (data) => {
			console.log('checkImagesForProcessing', data);
			this.setState((state) => ({
				...state,
				imagesStatistics: data
			}))
		}, (e) => {
			this.setState((state) => ({
				...state,
				isInvalidImages: false,
				activityPopupObj: {
					open: true,
					item: "Task",
					icon: "ERROR",
					action: "ADD",
					msg: e.message
				}
			}))
		})
	}

	cancelDelete = () => {
		this.state.images.forEach((image) => {
			image.checked = false
		})
		this.setState((state) => ({
			...state,
			showCheckboxes: false,
			selectedImageCount: 0,
		}))
	}

	submitTask = async () => {
		this.setState((state) => ({
			...state,
			activityPopupObj: {
				open: true,
				item: "Task",
				icon: "WAIT",
				action: "ADD",
				msg: "Please Wait..."
			}
		}))

		let taskParameters = {}

		if (this.state.selectOrtho === true) {
			taskParameters.orthomosaic = this.state.orthoAccuracy
		}
		if (this.state.selectElevation === true) {
			taskParameters.elevation = this.state.elevationAccuracy
		}
		if (this.state.select3d === true) {
			taskParameters["3dmodel"] = this.state.threeDAccuracy
		}

		if (this.state.tasks && this.state.tasks.tasktype === 3) {

			let task = {
				task_parameters: taskParameters,
				accuracy: this.state.accuracyParameter
			}
			if (this.state.permissions[DRONENAKSHA_FEATURES.MAPS].EDIT) API.updateTask(this.state.tasks.task_id, "Pending", "DroneNaksha", task).then(async (data) => {
				await this.getImages()
				this.setState((state) => ({
					...state,
					activityPopupObj: {
						open: true,
						item: "Task",
						icon: "COMPLETE",
						action: "UPDATE",
						msg: "Task updated."
					},
					taskParameters1: taskParameters
				}))
			}, (e) => {
				this.setState((state) => ({
					...state,
					activityPopupObj: {
						open: true,
						item: "Task",
						icon: "ERROR",
						action: "ADD",
						msg: e.message || e.errorMessage
					},
				}))
			})
		} else {

			let task = {
				task_name: this.props.location.state.collection.collection_name,
				project_id: this.props.location.state.collection.project_id,
				plan_id: this.props.location.state.collection.plan_id,
				type: [],
				images: this.state.counts,
				collection_id: this.props.location.state.collection.id,
				taskType: 'orthogen',
				task_parameters: taskParameters,
				accuracy: this.state.accuracyParameter
			}
			if (this.state.permissions[DRONENAKSHA_FEATURES.MAPS].CREATE)
				API.createTask(task).then(async (data) => {
					await this.getImages()
					this.setState((state) => ({
						...state,
						activityPopupObj: {
							open: true,
							item: "Task",
							icon: "COMPLETE",
							action: "ADD",
							msg: "Task created successfully."
						},
						taskParameters1: taskParameters
					}))
				}).catch(e => {
					console.log(e);
					this.setState((state) => ({
						...state,
						activityPopupObj: {
							open: !e?.creditExceeds,
							item: "Task",
							icon: "ERROR",
							action: "ADD",
							msg: e.message || e.errorMessage,
						},
						processingCreditExceed: e?.creditExceeds,
					}))
				})
		}
	}

	//Get user task log and assign them to state
	getUserTaskLogUpdate = () => {
		let { tasks } = this.state
		if (tasks && this.state.permissions[DRONENAKSHA_FEATURES.MAPS].EDIT) {
			API.userTaskLogUpdate(tasks.task_id).then(tasks_log => {
				this.setState({
					loading: false,
					taskStates: tasks_log.tasks
				})
			})
		}
	}

	isUserOwnerOfCollection = () => {
		const { id } = this.state.project
		API.isUserProjectOwner(id)
			.then((result) => {
				this.setState({
					isOwner: result.isShared ? false : true,
					isAllowToDownload: result.access_level == "DOWNLOAD" ? true : false,
					sharedBy: result.sharedBy
				})
			})
			.catch((err) => {
				this.setState((state) => ({
					...state,
					loading: false,
					isOwner: false,
					isAllowToDownload: false,
					activityPopupObj: {
						open: true,
						item: "ERROR",
						icon: "ERROR",
						action: "ADD",
						msg: "Something went wrong while getting owner information",
					},
				}))
			})
	}
	//For auto refresh
	componentDidMount() {
		const interval = setInterval(() => { this.getUserTaskLogUpdate(); }, 30000);
		this.setState((state) => ({
			...state,
			interval: interval
		}))
	}
	componentWillUnmount() {
		updateOutTime(this.state.outId)
		clearInterval(this.state.interval)
	}

	componentWillMount() {
		getFeaturesPermissions([DRONENAKSHA_FEATURES.IMAGECOLLECTIONS, DRONENAKSHA_FEATURES.PLANS, DRONENAKSHA_FEATURES.MAPS]) //PLANS MAPS IMAGECOLLECTIONS WAS USED
			.then(permissions => {
				addUserAnalytic(this.props.location.pathname)
					.then(id => {
						console.log("ID : ", id)
						this.setState((state) => ({
							outId: id
						}))
					})

				// var style = document.createElement('style');
				// style.type = 'text/css';
				// style.innerHTML = '.popup-content { width: 770px !important; }';
				// document.getElementsByTagName('head')[0].appendChild(style);
				document.title = "DroneNaksha - Images";
				if (this.props.location.state.user.avatar_url && this.props.location.state.user.avatar_url.length > 0) {
					this.setState((state) => ({
						...state,
						avatarURL: `${this.props.location.state.user.avatar_url}?${this.props.location.state.user.sas_token
							}&t${new Date().getTime()}`,
					}));
				} else {
					let avatarURL = `/images/${this.props.location.state.user.first_name[0].toLowerCase()}.PNG`;
					this.setState((state) => ({
						...state,
						avatarURL: avatarURL,
					}));
				}
				if (this.props.location.state) {
					let { user, type, task, project, plan, collection } = this.props.location.state;
					this.setState((state) => ({
						...state,
						user,
						type,
						task,
						project,
						permissions,
						plan,
						collection
					}), () => {
						this.getImages()
						this.isUserOwnerOfCollection()

						API.getUserSubbedServices().then(
							(response) => {
								let services = response[0];
								// console.log(services);
								if (services.length == 1 && services[0].title == 'AeroStream') {
									this.setState((state) => ({
										...state,
										showBackIcon: false
									}))
								} else {
									this.setState((state) => ({
										...state,
										showBackIcon: true
									}))
								}
							}
						)
					})
				} else {
					API.getUserType().then(([blah, type, user]) => {
						//console.log("ROLE ", type);
						//console.log("USER ", user)
						this.setState((state) => ({
							...state,
							user,
							type
						}), () => {
							this.getImages()
							//  console.log("ASKDJHASK", user);
						})
					}, (e) => {
						window.location.replace("/login");
					})
				}
			})
	}
	//Get SearchKey Function Start Here-----
	search(key) {
		if (key == '') {
			this.setState((state) => ({
				...state,
				hasMoreItems: true
			}))
		}
		this.setState((state) => ({
			...state,
			key: (key.length == 1 && key == '.') ? ` .` : key,
			currentpage: 1
		}),
			() => this.getImages());
	}
	//Get SearchKey Function Ends Here-----
	//Get on First page

	firstpage = () => {
		if (this.state.currentpage > 1) {
			this.setState((state) => ({
				...state,
				currentpage: 1
			}), () => {
				this.getImages();
			});
		} else {
			console.log("Reached first page")
		}
	}
	// Prev Page function
	prevpage = () => {
		if (this.state.currentpage > 1) {
			this.setState((state) => ({
				...state,
				currentpage: this.state.currentpage - 1
			})
				, () => {
					this.getImages();

				});
		}
	}

	capitalize = (s) => {
		return s[0].toUpperCase() + s.slice(1);
	}



	imageSelectDeleted = (key) => {
		this.state.images[key].checked = !this.state.images[key].checked
		if (this.state.images[key].checked == true) {
			let cnt = this.state.selectedImageCount + 1
			this.setState((state) => ({
				...state,
				selectedImageCount: cnt
			}))
		} else {
			let cnt = this.state.selectedImageCount - 1
			this.setState((state) => ({
				...state,
				selectedImageCount: cnt
			}))
		}
	}

	// Next page Function
	nextpage = () => {
		if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
			this.setState((state) => ({
				...state,
				currentpage: state.currentpage + 1
			}), () => {
				this.getImages();
			});
		} else {
			console.log("Reached next page")
		}
	}
	// Get Last Page Function Start here----------------------
	lastpage = () => {
		if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
			this.setState((state) => ({
				...state,
				currentpage: Math.ceil(this.state.counts / this.state.userperpage)
			}), () => {
				this.getImages();
			});
		} else {
			console.log("Reached last page")
		}
	}

	// Get Last Page Function End here ----------------------------
	// Get Changenumber Page Function Starts here ----------------------------
	changepage(num) {
		if (isNaN(num)) {

			return null;
		}
		else {
			this.setState((state) => ({
				...state,
				currentpage: num,
			}), () => this.getImages());
		}

	}

	onChangeSortEvent = (sortType) => {
		console.log(sortType);
		if (sortType === 'Last Modified') {
			this.reverseByDate()
		} else if (sortType === 'First Modified') {
			this.sortByDate()
		} else if (sortType === 'A-Z') {
			this.sortByName()
		} else if (sortType === 'Z-A') {
			this.reverseByName()
		}
	}




	sortByName = () => {
		this.setState((state) => ({
			...state,
			sortType: 'A-Z'
		}));
		let sort = this.state.images.sort((a, b) => a.file_name.localeCompare(b.file_name))
		this.setState((state) => ({
			...state,
			images: sort,
		}));

	}

	reverseByName = () => {
		this.setState((state) => ({
			...state,
			sortType: 'Z-A'
		}));
		let sort = this.state.images.sort((a, b) => b.file_name.localeCompare(a.file_name))
		this.setState((state) => ({
			...state,
			images: sort,
		}));
	}

	sortByDate = () => {
		this.setState((state) => ({
			...state,
			sortType: 'First Modified'
		}));
		let sort = this.state.images.sort((a, b) => a.created_on.localeCompare(b.created_on))
		this.setState((state) => ({
			...state,
			images: sort,
		}));
	}

	reverseByDate = () => {
		this.setState((state) => ({
			...state,
			sortType: 'Last Modified'
		}));
		let sort = this.state.images.sort((a, b) => b.created_on.localeCompare(a.created_on))
		this.setState((state) => ({
			...state,
			images: sort,
		}));
	}

	deleteOrthomosaic = () => {
		this.setState((state) => ({
			...state,
			tasks: undefined,
			activityPopupObj: {
				open: true,
				item: "Orthomosaic",
				icon: "WAIT",
				action: "DELETE",
				msg: "Are you sure?, want to delete orthomosaic?",
			}
		}))
		if (this.state.permissions[DRONENAKSHA_FEATURES.MAPS].DELETE) API.deleteOrthomosaicImage(this.state.orthoImage).then(() => {
			this.setState((state) => ({
				...state,
				activityPopupObj: {
					open: true,
					item: "Orthomosaic",
					icon: "COMPLETE",
					action: "DELETE",
					msg: "Orthomosaic deleted.",
				}
			}), () => {
				this.getImages()
			})
		}).catch(e => {
			this.setState((state) => ({
				...state,
				activityPopupObj: {
					open: true,
					item: "Orthomosaic",
					icon: "ERROR",
					action: "DELETE",
					msg: e?.message
				}
			}))
		})
	}
	onSelectTypeForUpload = (selectedFileType, importFrom) => {

		this.setState({
			selectedFileType: selectedFileType
		}, () => {

			if (importFrom === "GDRIVE") {

				this.setState({ startGoogleDriveSelection: true }, () => {
					console.log("startgoogledrive", this.state.startGoogleDriveSelection)
				})
			}
			else document.getElementById("hiddenFileInput").click()
		})
	}
	render() {

		let { user, type, images, sidebarMode, imageURLs, thumbURLs, collection, currentpage, userperpage, orthoImage } = this.state
		const listLoader = <div style={{ height: "10vh", width: "80vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
			<img src={Loader} style={{ height: "10vh", width: "50vw", margin: "auto" }} />
		</div>
		return (
			user.username ? <div className="wrapper">
				{/* <SideBar config={sidebarConfigurations} showMainDashboardIcons={this.state.showBackIcon} parentSidebarSwitch={this.parentSidebarSwitch} /> */}
				<div className="sidebar-expanded" style={{ paddingTop: "0px" }}>
					<div style={{ display: "flex", height: "40px" }}>
						<div style={{ height: "40px", transition: "0.3s", margin: 'auto' }}>
							<img
								src={AeroMeghLogo}
								style={{ width: "65px", height: "20px", marginTop: "10px" }}
							/>
						</div>
					</div>
					<ul className="sidebar-nav" id="sidebar-nav">
						<div className={"nonSelectBorder"} >
							<li>
								<Link
									to={{
										pathname: "/",
										state: {
											type,
											user,
										},
									}}
								>
									<div
										className="sidecontent"
										id="sidecontent"
										style={{ cursor: "pointer" }}
									>
										<div className="sidebar-icon">
											<img
												className="sidebar-icon"
												src={service}
												alt="serviceIcon"
											/>
										</div>
										<div
											name="service"
											className={"sidebaropt"}
										// style={{ color: "#2989CF" }}
										// onClick={this.changeColor}
										>
											{"Services"}
										</div>
									</div>
								</Link>
							</li>
						</div>


						<div className={"nonSelectBorder"} >
							<li>
								<Link
									to={{
										pathname: "/dronenaksha/dashboard",
										state: {
											type,
											user,
										},
									}}
								>
									<div
										className="sidecontent"
										id="sidecontent"
										style={{ cursor: "pointer" }}
									>
										<div className="sidebar-icon">
											<img
												className="sidebar-icon"
												src={dashboardIcon}
												alt="serviceIcon"
											/>
										</div>
										<div
											name="service"
											className={"sidebaropt"}
										// style={{ color: "#2989CF" }}
										// onClick={this.changeColor}
										>
											{"DroneNaksha"}
										</div>
									</div>
								</Link>
							</li>
						</div>
						<div className={"nonSelectBorder"} >
							<li>
								<Link
									to={{
										pathname: "/dronenaksha/plan",
										state: {
											type,
											user,
											plan: this.props.location.state.plan,
											project: this.props.location.state.project,
										},
									}}
								>
									<div
										className="sidecontent"
										id="sidecontent"
										style={{ cursor: "pointer" }}
									>
										<div className="sidebar-icon">
											<img
												className="sidebar-icon"
												src={projectIcon}
												alt="serviceIcon"
											/>
										</div>
										<div
											name="service"
											className={"sidebaropt"}
											// style={{ color: "#2989CF" }}
											onClick={this.changeColor}
										>
											{"Plan"}
										</div>
									</div>
								</Link>
							</li>
						</div>
						<div className={"nonSelectBorder"} >
							<li>
								<Link
									to={{
										pathname: "/dronenaksha/image_collections",
										state: {
											type,
											user,
											plan: this.props.location.state.plan,
											project: this.props.location.state.project,
										},
									}}
								>
									<div
										className="sidecontent"
										id="sidecontent"
										style={{ cursor: "pointer" }}
									>
										<div className="sidebar-icon">
											<img
												className="sidebar-icon"
												src={ImageSetInActive}
												alt="serviceIcon"
											/>
										</div>
										<div
											name="service"
											className={"sidebaropt"}
										// style={{ color: "#2989CF" }}
										// onClick={this.changeColor}
										>
											{"Imagesets"}
										</div>
									</div>
								</Link>
							</li>
						</div>
						<div className={"selectBorder"} >
							<li>
								<Link
									to={{
										pathname: `/dronenaksha/images`,
										state: {
											user, type, collection,
											plan: this.props.location.state.plan,
											project: this.props.location.state.project,
										}
									}}
								>
									<div
										className="sidecontent"
										id="sidecontent"
										style={{ cursor: "pointer" }}
									>
										<div className="sidebar-icon">
											<img
												className="sidebar-icon"
												src={imageActive}
												alt="serviceIcon"
											/>
										</div>
										<div
											name="service"
											className={"sidebaropt"}
											style={{ color: "#2989CF" }}
										>
											Images
										</div>
									</div>
								</Link>

							</li>
						</div>
						<div className={"nonSelectBorder"} >
							<li>
								<div
									className="onhoverext"
									onClick={
										() => {
											window.open("https://dronenaksha-docs.aeromegh.com/", "_blank")
										}
									}
								>
									<div
										className="sidecontent"
										id="sidecontent"
										style={{ cursor: "pointer" }}
									>
										<div className="sidebar-icon">
											<img
												className="sidebar-icon"
												src={Help}
												alt="serviceIcon"
											/>
										</div>
										<div
											name="service"
											className={"sidebaropt"}
										>
											{"Help"}
										</div>
									</div>
								</div>
							</li>
						</div>

					</ul>
				</div>
				<div id='tiffloaded' className="right-content" style={{}}
					onClick={
						() => {
							this.setState((state) => ({
								...state, sidebarMode: 'shrunk'
							}))
						}
					}
				>

					<Popup
						style={{ width: "min-content" }}

						trigger={<button id="view-image-popup-button" style={{
							display: "none",
						}}
						></button>}
						modal
					><img
							id="close-popup"
							src={closeButton} style={{
								width: "auto",
								height: "25px",
								position: "absolute",
								top: "-12px",
								right: "-12px",
								cursor: "pointer"

							}} onClick={() => {
								var style = document.createElement('style');
								style.type = 'text/css';
								style.innerHTML = '.popup-content { width: 800px !important; border-radius:15px;}';
								document.getElementsByTagName('head')[0].appendChild(style);
								document.getElementById("view-image-popup-button").click();
								this.setState((state) => ({
									...state,
									regions: [],
									scale: 0,
									imageLoad: false,
								}));

							}} />
						<div style={{ display: 'flex' }} >
							<div className='flex-center' style={{ alignItems: 'end', background: "#666666", width: "790px", height: '567px', borderRadius: '15px', overflow: "hidden" }}>
								{this.state.imageLoad ?
									<img className='image-popup' src={Loader} style={{ position: "absolute", zIndex: 5, height: "100%", width: "8vw", marginLeft: "10%" }} /> : ''
								}
								<TransformWrapper>
									{({ zoomIn, zoomOut, centerView, resetTransform, ...rest }) => (
										<TransformComponent>
											<img id="action-image" style={{ zIndex: 0, opacity: this.state.imageLoad ? 0 : 1 }} className='image-popup' loading='lazy'
												src={this.state.images && this.state.images.length ? this.state.imageURL : ''}
												onLoad={((load) => {
													this.setState((state) => ({
														...state,
														imageLoad: false,
													}),
														centerView(),
														resetTransform())

												})} />
										</TransformComponent>
									)}
								</TransformWrapper>

								{this.state.images && this.state.images.length ? <div className='imageinfo'>
									<div style={{ display: 'flex', justifyContent: 'space-between' }}>
										<div className="imagePopupTitle">{images[this.state.Idx].file_name.length > 50 ? images[this.state.Idx].file_name.substring(0, 50) : images[this.state.Idx].file_name}</div>
										<div className="imagePopupSubTitleTile">
											<div className="imagePopupSubTitle">{images[this.state.Idx].resolution} MP</div>
										</div>
									</div>
									<div style={{ display: 'flex', justifyContent: 'space-between' }}>
										<div className="imagePopupSubTitle">
											{images[this.state.Idx].latitude == null || images[this.state.Idx].latitude == "" ? "" : `${images[this.state.Idx].latitude.length > 11 ? `${images[this.state.Idx].latitude.substring(0, 11)}` : `${images[this.state.Idx].latitude}`}`} {images[this.state.Idx].longitude == null || images[this.state.Idx].longitude == "" ? `` : `${images[this.state.Idx].longitude.length > 11 ? `, ${images[this.state.Idx].longitude.substring(0, 11)}` : `, ${images[this.state.Idx].longitude}`}  ${images[this.state.Idx].altitude ? `, ${images[this.state.Idx].altitude}` : ``}`}
										</div>
										<div className="imagePopupSubTitleTile">
											<div className="imagePopupSubTitle">{images[this.state.Idx].file_size} MB</div>
										</div>
									</div>
								</div> : ''}
								{this.state.images && this.state.images.length > 1 ? < div className='nextprevfunction'>
									{
										this.state.Idx == 0 ?
											<div className='prevDiv'>
												<img className='prev' src={circleLeft} style={{ opacity: '0.5' }} />
											</div> :
											<div className='prevDiv' onClick={this.prevImg}>
												<img className='prev' src={circleLeft} style={{ cursor: 'pointer' }} />
											</div>

									}
									{
										this.state.Idx == this.state.images.length - 1 ?
											<div className='nextDiv'> <img className='next' src={circleRight} style={{ opacity: '0.5' }}
											/> </div> :
											<div className='nextDiv' onClick={this.nextImg} >
												<img className='next' src={circleRight} style={{ cursor: 'pointer' }} />
											</div>
									}

								</div> : ''
								}
							</div>
						</div>
					</Popup>

					{/* ----------------- Storage Exceed Popup ---------- */}
					{this.state.showStorageExceedPopup ?
						<StorageLimitExceed
							seletedDataSize={null} totalStorage={null}
							onClickOk={() => {
								this.setState({ importAssets: true, showStorageExceedPopup: false })
							}}
						/> : <></>}
					{/* ------------- End Storage Exceed Popup ---------- */}

					{/* -------------- Activity Popup ----------------------- */}

					<ActivityPopup
						item={this.state.activityPopupObj?.item}
						open={this.state.activityPopupObj?.open}
						icon={this.state.activityPopupObj?.icon}
						action={this.state.activityPopupObj?.action}
						msg={this.state.activityPopupObj?.msg}
						close={() => this.closeActivityPopup()}
						onClickOk={() => this.state.deleteOrtho ? this.deleteOrthomosaic() : this.state.imageId ? this.deleteImageById() : this.deleteImages()}
					/>

					<AnimatedModal
						isOpen={this.state.processingCreditExceed}
						height="150px"
						width="460px">
						<div style={{
							position: "absolute",
							padding: "30px",
							height: "100%",
							width: "100%",
						}}>
							<div style={{
								borderBottom: "1px solid #dee2e6",
								height: "calc(100% - 20px)",
							}}>
								<div style={{ fontSize: "15px", color: "#3c3c3c" }}>
									{this.state.activityPopupObj?.msg}
								</div>
							</div>
							<div style={{
								position: "absolute",
								right: "0px",
								margin: "10px 30px 0 0",
							}} >
								<ButtonWithIcon
									isBtnActive
									text={"Buy"}
									width={"70px"}
									onClick={() => { }}
									linkData={{
										pathname: "/buy-credit",
										state: {
											user: user,
											creditType: AppConstants.SUBSCRIPTOPTIONS.ITEMS.PROCESSING_CREDITS,
										}
									}}
								/>
							</div>
						</div>
					</AnimatedModal>

					{/* --------------------------------------------------------------- */}

					{/* Notification panel for task logs  */}
					<Slide direction="left" in={this.state.notificationPanel} timeout={500} mountOnEnter unmountOnExit>
						<div
							style={{ fontSize: "9px", zIndex: '2', backgroundColor: 'white', textAlign: 'left', width: '23vw', height: "calc(100%-40px)", right: "5px", top: "39px", bottom: "0px", position: 'fixed', boxShadow: '0px 6px 10px rgb(0 0 0 / 30%)', color: "white", fontWeight: "500" }}>
							<div style={{ height: "80px", backgroundColor: 'white', padding: '10px 20px 10px 20px', borderBottom: '1px solid #e5e5e5', display: "flex" }}>
								<img src={closeBtn} style={{ position: 'absolute', width: '22px', height: '22px', cursor: "pointer", top: "10px", right: "10px" }}
									onClick={
										() => {
											this.setState((state) => ({
												...state,
												notificationPanel: false,
											}))
										}
									}
								/>

								<div class="services-content-title" style={{ backgroundColor: 'white' }}>
									<div style={{ color: "#2989cf", fontSize: "16px" }}>
										Task status
									</div>
									<div style={{ color: "#666666", fontSize: "12px", fontWeight: "450" }}>Last updated : {dateFormat(this.state.tasks && this.state.tasks.status_update_timestamp, "dd-mm-yyyy h:MM TT")}
										<img style={{ width: '18px', marginLeft: '8px', marginBottom: '2px', cursor: 'pointer' }} src={this.state.refresh == false ? refreshBtn : tickIcon}
											onClick={() => {
												this.setState({ refresh: true })
												setTimeout(() => {
													this.setState({ refresh: false })
												}, 700);
												this.getImages()
												this.getUserTaskLogUpdate()
											}} />
									</div>
								</div>
							</div>
							<div style={{ backgroundColor: "white", width: '100%', padding: "15px", height: "90%", bottom: "20px", overflow: "auto" }}>
								<div class="services-content-title" style={{ backgroundColor: 'white', fontSize: "14px", fontWeight: "400" }}>

									{this.state.taskStates && this.state.taskStates.length == 0 ?
										<div class="content-text" style={{ color: "#666666", backgroundColor: "white", fontSize: "14px", fontWeight: "450", paddingLeft: "5px" }}> No task logs were found</div>
										: this.state.taskStates && this.state.taskStates.map((taskState, key) => {
											return (<div style={{ display: 'flex', marginBottom: "15px" }}>
												<div><img style={{ width: '22px', height: '22px', marginTop: "4px", marginLeft: '8px', marginBottom: '10px', cursor: 'pointer' }} src={this.state.tasks && taskState.display && taskState.type === "status" ? greenTick : taskState.type === 'error' ? redCancel : greyTick} /></div>
												<div style={{ marginLeft: "8px" }} >
													<div style={{ display: "flex" }}>
														<div style={{ color: "#3C3C3C" }}>
															{this.state.tasks && taskState.type === 'error' ?
																<div style={{ display: "flex" }}>
																	<div style={{ backgroundColor: "white" }}> Error</div>
																	<div>
																		<div style={{ marginLeft: "5px", color: "#2989cf", cursor: "pointer", fontSize: "11px" }} onClick={() => {
																			this.setState({
																				isExpanded: !this.state.isExpanded
																			})
																		}}>
																			View details

																		</div>
																		<Collapse style={{ marginLeft: '-33px' }} in={this.state.isExpanded} timeout="auto" unmountOnExit>
																			<div style={{ backgroundColor: "white", fontSize: "12px", lineHeight: '2', color: '#666666' }}>
																				{taskState.details.event_details}
																			</div>
																		</Collapse>
																	</div>
																</div>
																: taskState.details.event_details}
														</div>
													</div>
													<div style={{ display: "flex", alignItems: "center", height: "16px" }}>
														<div style={{ color: "#666666", fontSize: "10px", lineHeight: "14px" }}>Date : {this.state.tasks && taskState.display ? dateFormat(this.state.tasks && taskState.details.log_timestamp, "mmmm dS, yyyy, h:MM TT") : '-'}</div>
														<div>
															{(taskState.details.event_details == 'Created orthomosaic image') && taskState.display && orthoImage ?
																<div>
																	<Link to={{
																		pathname: "/dronenaksha/view_plan",
																		state: {
																			type,
																			user,
																			plan: this.props.location.state.plan,
																			project: this.props.location.state.project,
																			orthoImageCollectionId: this.state.collection ? this.state.collection.id : '',
																			layerShow: "RGBShow"
																		},
																	}}>
																		<div style={{ marginLeft: "5px", color: "#2989cf", cursor: "pointer", fontSize: "10px" }}>  View
																		</div>
																	</Link>
																</div> :
																taskState.details.event_details == 'Elevation stage completed' && taskState.display && orthoImage ?
																	<div>
																		<Link to={{
																			pathname: "/dronenaksha/view_plan",
																			state: {
																				type,
																				user,
																				plan: this.props.location.state.plan,
																				project: this.props.location.state.project,
																				orthoImageCollectionId: this.state.collection ? this.state.collection.id : '',
																				layerShow: "DEMShow"
																			},
																		}}>
																			<div style={{ marginLeft: "5px", color: "#2989cf", cursor: "pointer", fontSize: "10px" }}>  View
																			</div>
																		</Link> </div> :
																	taskState.details.event_details == 'Point Cloud generated' && taskState.display && orthoImage ?
																		<div>
																			<Link to={{
																				pathname: "/dronenaksha/view_plan",
																				state: {
																					type,
																					user,
																					plan: this.props.location.state.plan,
																					project: this.props.location.state.project,
																					orthoImageCollectionId: this.state.collection ? this.state.collection.id : '',
																					layerShow: "pointCloudShow"
																				},
																			}}>
																				<div style={{ marginLeft: "5px", color: "#2989cf", cursor: "pointer", fontSize: "10px" }}>  View
																				</div>
																			</Link> </div> :
																		taskState.details.event_details == '3D model generated' && taskState.display && orthoImage ?
																			<div>
																				<Link to={{
																					pathname: "/dronenaksha/view_plan",
																					state: {
																						type,
																						user,
																						plan: this.props.location.state.plan,
																						project: this.props.location.state.project,
																						orthoImageCollectionId: this.state.collection ? this.state.collection.id : '',
																						layerShow: "ThreeDShow"
																					},
																				}}>
																					<div style={{ marginLeft: "5px", color: "#2989cf", cursor: "pointer", fontSize: "10px" }}>  View
																					</div>
																				</Link> </div> : <></>
															}
														</div>
													</div>
												</div>
											</div>)
										})}
								</div>
							</div>
						</div>
					</Slide>



					<div className="top-bar">
						<div className="breadCrumbs">
							<BreadCrumbs data={[
								{
									displayName: "projects",
									pathname: "/dronenaksha/projects",
									state: {
										type,
										user,
									},
									style: {
										color: "black",
										fontSize: "12px",
									}
								},
								{
									displayName: this.props.location.state.plan.project_name,
									pathname: "/dronenaksha/project",
									state: {
										type,
										user,
										plan: this.props.location.state.plan,
										project: this.props.location.state.project,
									},
									style: {
										color: "black",
										fontSize: "12px"
									}
								},
								{
									displayName: this.props.location.state.plan.plan_name,
									pathname: "/dronenaksha/plan",
									state: {
										type,
										user,
										plan: this.props.location.state.plan,
										project: this.props.location.state.project
									},
									style: {
										color: "black",
										fontSize: "12px"
									}
								},
								{
									displayName: "Imagesets",
									pathname: "/dronenaksha/image_collections",
									state: {
										type,
										user,
										plan: this.props.location.state.plan,
										project: this.props.location.state.project
									},
									style: {
										color: "black",
										fontSize: "12px"
									}
								},
								{
									displayName: this.state.collection.collection_name,
								}
							]} />
							{this.state.sharedBy == null || !(this.state.sharedBy) ? <></> : <span className='dn-shared-by'>- Shared by {this.state.sharedBy.first_name} {this.state.sharedBy.last_name}</span>}
							<ActiveRole />

						</div>
					</div>

					{/*<TopBar user={user} sidebarMode={sidebarMode} type={type} pageTitle = {'SERVICES'}></TopBar>*/}
					{this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
						<img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
					</div> : <>

						<div className="main-content" style={{ background: 'none', }} onClick={
							() => {
								this.setState((state) => ({
									...state, sidebarMode: 'shrunk'
								}))
							}
						}>

							<AnimatedModal
								isOpen={this.state.createTaskModal}
								onRequestClose={() => {
									this.setState((state) => ({
										...state,
										createTaskModal: false,
										manageAccessList: [],
										notInAccessList: [],
										givePermissionToUser: "",
										givePermission: {},
										activePlan: "",
										searchUserKeyword: "",
										selectOrtho: true,
										selectElevation: false,
										select3d: false,
										orthoAccuracy: 'low',
										elevationAccuracy: 'low',
										threeDAccuracy: 'low'
									}))
								}}

								height="415x"
								width="600px"
							>
								<img src={closeBtn} style={{ position: 'absolute', width: '20px', height: '20px', cursor: "pointer", top: "15px", right: "15px" }}
									onClick={
										() => {
											this.setState((state) => ({
												...state,
												createTaskModal: false,
												selectOrtho: true,
												selectElevation: false,
												select3d: false,
												orthoAccuracy: 'low',
												elevationAccuracy: 'low',
												threeDAccuracy: 'low'
											}))
										}
									}
								/>
								<div className="services-content-title" style={{ margin: '20px 0px', diplay: "flex", justifyContent: "center", }}>
									Starting the task
								</div>
								<div className="services-content-title" style={{ fontWeight: 400, color: "#3c3c3c", backgroundColor: "#EAEAEA", padding: "20px 40px 20px 40px", textAlign: "left" }}>
									Please select the task execution parameters
									<div style={{ display: "flex", marginTop: "15px" }}>
										<div style={{ width: "60%" }}>
											<div style={{ display: "flex", width: "100%", justifyContent: "space-bertween" }}>
												<div style={{ fontSize: "12px", height: "12px", marginBottom: "15px", width: "70%" }}> Select outputs</div>
												<div style={{ fontSize: "12px", height: "10px", marginBottom: "15px", width: "35%" }}>Accuracy</div>
											</div>

											<div style={{ display: "flex", justifyContent: "space-between", padding: "5px" }}>
												<div style={{ alignItems: "center", display: "flex", fontSize: "12px", width: "60%", color: this.state.selectOrtho ? (this.state.selectedType === 'orthomosaic' ? "#2989cf" : "#3c3c3c") : "#666666", }}>
													<input type='checkbox' style={{ height: '15px', marginRight: "10px", width: '15px', cursor: 'pointer' }}
														checked={this.state.selectOrtho}
														onClick={() => {
															this.setState((state) => ({
																...state,
																selectedType: "orthomosaic"
															}))
															this.selectTaskParameters('orthomosaic')
														}}
													/> Orthomosaic
												</div>

												<select
													className="dropdown-item-style"
													value={this.state.orthoAccuracy}
													style={{ width: "100px", borderRadius: "3px", fontSize: "11px", color: "#666666", padding: "none", margin: "none" }}
													name='orthoDrop'
													defaultChecked='low'
													disabled={!this.state.selectOrtho}
													onChange={(e) => {
														this.setState({
															orthoAccuracy: e.target.value
														})
													}}
												>
													<option value={"low"}>Low</option>
													<option value={"moderate"}>Moderate</option>
													<option value={"high"}>High</option>
													<option value={"highest"}>Highest</option>
												</select>
											</div>

											<div style={{ display: "flex", justifyContent: "space-between", padding: "5px" }}>
												<div style={{ alignItems: "center", display: "flex", fontSize: "12px", width: "60%", color: this.state.selectElevation ? (this.state.selectedType === 'elevation' ? "#2989cf" : "#3c3c3c") : "#666666", }}>
													<input type='checkbox' style={{ height: '15px', marginRight: "10px", width: '15px', cursor: 'pointer' }}
														checked={this.state.selectElevation}
														onClick={() => {
															this.setState((state) => ({
																...state,
																selectedType: "elevation"
															}))
															this.selectTaskParameters('elevation')
														}}
													/> Elevation Model
												</div>
												<select
													className="dropdown-item-style"
													value={this.state.elevationAccuracy}
													style={{ width: "100px", borderRadius: "3px", fontSize: "11px", color: "#666666", padding: "none", margin: "none" }}
													name='elevDrop'
													disabled={!this.state.selectElevation}
													onChange={(e) => {
														this.setState({
															elevationAccuracy: e.target.value
														})
													}}
												>
													<option value={"low"}>Low</option>
													<option value={"moderate"}>Moderate</option>
													<option value={"high"}>High</option>
													<option value={"highest"}>Highest</option>
												</select>
											</div>

											<div style={{ display: "flex", justifyContent: "space-between", padding: "5px" }}>
												<div style={{ alignItems: "center", display: "flex", fontSize: "12px", width: "60%", color: this.state.select3d ? (this.state.selectedType === '3d' ? "#2989cf" : "#3c3c3c") : "#666666" }}>
													<input type='checkbox' style={{ height: '15px', marginRight: "10px", width: '15px', cursor: 'pointer' }}
														checked={this.state.select3d}
														onClick={() => {
															this.setState((state) => ({
																...state,
																selectedType: "3d",

															}))
															this.selectTaskParameters('3d')
														}}
													/> 3D Model
												</div>
												<select
													className="dropdown-item-style"
													value={this.state.threeDAccuracy}
													disabled={!this.state.select3d}
													style={{ width: "100px", borderRadius: "3px", fontSize: "11px", color: "#666666", padding: "none", margin: "none" }}
													name='3dDrop'
													onChange={(e) => {
														this.setState({
															threeDAccuracy: e.target.value
														})
													}}
												>
													<option value={"low"}>Low</option>
													<option value={"moderate"}>Moderate</option>
													<option value={"high"}>High</option>
													<option value={"highest"}>Highest</option>
												</select>
											</div>
										</div>
									</div>

									<div className="services-content-title" style={{ fontWeight: 400, color: "#666666", marginTop: "15px" }}>
										Task will be added to the execution queue.<br></br>
										<hr style={{ borderColor: "#fff", width: "520px", marginLeft: "auto" }} />
										<span style={{ color: "#3c3c3c" }}>Do you want to process these images and create the task ?</span>
									</div>
									<div className="services-content-title" style={{ fontWeight: 400, display: "flex", justifyContent: "flex-end", marginTop: "15px" }}>
										<div className="addcustomer-button-empty-dashboard btn-ok" style={{ paddingTop: "7px", paddingBottom: "7px", cursor: 'pointer', marginRight: "30px" }} onClick={() => {
											this.setState((state) => ({
												...state,
												createTaskModal: false,
												selectOrtho: true,
												selectElevation: false,
												select3d: false,
												orthoAccuracy: 'low',
												elevationAccuracy: 'low',
												threeDAccuracy: 'low'
											}));
										}}>
											No
										</div>

										<div className="add-user-form-submit-button btn-ok" style={{ paddingTop: "9px", paddingBottom: "7px", cursor: 'pointer' }} onClick={() => {
											this.setState((state) => ({
												...state,
												createTaskModal: false,
											}), () => {
												this.submitTask()
											})
										}}>
											Yes
										</div>
									</div>
								</div>
							</AnimatedModal>

							{/*------------------------------ Info Popup-----------------------------*/}
							<AnimatedModal
								height="305px"
								width="435px"
								onRequestClose={() => {
									this.setState((state) => ({
										...state,
										isInvalidImages: false
									}))
								}}
								isOpen={this.state.isInvalidImages}
							>
								<div>
									<div style={{ background: "#FAFAFA", padding: "2% 4%", display: "flex", justifyContent: "left", alignItems: "center", height: "60px" }}>
										<img src={this.state.processPopupIcon == "ERROR" ? closeIcon : waitingIcon} />
										<div className='content-text' style={{ fontSize: "15px", fontWeight: "500", marginLeft: "3%", color: "#2989CF" }}>
											{this.state.processPopupIcon == "ERROR" ? `Performing Precheck` : `Please Wait`}
										</div>
									</div>

									{this.state.processPopupIcon == "ERROR" ?
										<><div className='content-text' style={{ fontSize: "14px", fontWeight: "400", textAlign: "left", margin: "4% 7%" }}>
											<div>Total images : {this.state.counts}</div>
											<div>Usable images : {this.state.processCheckMsg.usable_images ? this.state.processCheckMsg.usable_images : 0}</div>
											<div>Unusable images : {this.state.processCheckMsg.invalid_images} (Invalid geotag)</div>

											<div style={{ marginTop: "10px" }}>This imageset contains {this.state.processCheckMsg.invalid_images} unusable {`${this.state.processCheckMsg.invalid_images == 1 ? 'image.' : 'images.'}`}</div>
											<div style={{ marginTop: "10px", color: 'brown' }}>Result <b>may not</b> be accurate or at accurate location, you still want to process this imageset ?</div>
										</div>
											<div style={{ display: "flex", position: "absolute", bottom: "8%", width: "88%", right: "6%" }}>
												<button
													style={{ paddingTop: "7px", paddingBottom: "7px" }}
													className="addcustomer-button-empty-dashboard popup-btn-left btn-ok"
													onClick={() => {
														this.setState((state) => ({
															...state,
															isInvalidImages: false,
														}))
													}}
												>
													No
												</button>
												<button
													style={{ paddingTop: "9px", paddingBottom: "7px" }}
													className="add-user-form-submit-button popup-btn-right btn-ok"
													onClick={() => {
														this.setState((state) => ({
															...state,
															isInvalidImages: false,
															createTaskModal: true
														}))
													}}
												>
													Yes
												</button>
											</div>
										</>
										:
										<div style={{ height: "100%", width: "100%", display: "flex", alignItems: "center" }}>
											<img src={Loader} style={{ height: "80px", width: "41vw", marginLeft: "6%", marginTop: "10%" }} />
										</div>
									}
								</div>
							</AnimatedModal>
							{/*-------------------------------------------------------------------------------------------- */}

							{/* <SnackBar user={user} sidebarMode={sidebarMode} type={type} /> */}
							<div className="slide" style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', width: this.state.notificationPanel ? '75%' : '100%', alignItems: 'center' }}>
								<div className="services-content-title">{`Images`}
									<span className='data-count'>({this.state.counts})</span>
								</div>
								<div style={{ display: 'flex', alignItems: 'center' }}>

									<div style={{ margin: '0px 5px' }}>
										<img src={Search} style={{ height: "20px", width: "20px", position: 'relative', left: '25px' }} />
										<input type="text" value={this.state.key} className="add-user-form-text2" placeholder='Search images' style={{ height: '30px', width: "230px", paddingLeft: '28px', border: '1px solid rgba(102, 102, 102, 0.3)' }}
											onChange={(event) => this.search(event.target.value)} />
									</div>

									{
										this.state.images.length > 0 ?
											<div className="add-user-form-text-wrapper" style={{ margin: '0px 5px' }} >
												<div className="imageViewButton" style={{ display: 'flex', height: '24px' }}  >
													<div className={this.state.listview ? 'imageViewButtonListActive' : 'imageViewButtonList'} style={{ cursor: 'pointer', width: "60px", borderRadius: '2px 0px 0px 2px', borderRight: 'solid 1px #2989cf' }}
														onClick={() => {
															this.setState((state) => ({
																...state,
																listview: true,
																gridView: false,
																mapView: false,
															}))
														}}>
														<div> <img src={this.state.listview ? listViewActiveIcon : listViewIcon} style={{ height: '18px', cursor: 'pointer', width: '18px', margin: '0px 5px' }} /></div>
													</div>

													<div className={this.state.gridView ? 'imageViewButtonListActive' : 'imageViewButtonList'} style={{ cursor: 'pointer', width: "60px", borderRight: 'solid 1px #2989cf', borderLeft: 'solid 1px #2989cf' }}
														onClick={() => {
															this.setState((state) => ({
																...state,
																listview: false,
																gridView: true,
																mapView: false,

															}))
														}}
													>
														<div>  <img src={this.state.gridView ? gridViewActiveIcon : gridViewIcon} style={{ cursor: 'pointer', height: '18px', width: '18px', margin: '0px 5px' }} /></div>
													</div>

													<div className={this.state.mapView ? 'imageViewButtonListActive' : 'imageViewButtonList'} style={{ width: "60px", borderRadius: '0px 2px 2px 0px', borderLeft: 'solid 1px #2989cf', filter: this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].READ ? "" : "grayscale(100%)", opacity: this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].READ ? "1" : "0.5" }}
														onClick={() => {
															this.cancelDelete()
															this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].READ && this.setState((state) => ({
																...state,
																listview: false,
																gridView: false,
																mapView: true,
															}))
														}}>
														<div><img src={this.state.mapView ? mapViewActive : mapViewIcon} style={{ height: '20px', width: '20px', cursor: 'pointer', margin: '0px 5px' }} /></div>
													</div>
												</div>
											</div> : <></>
									}


									<ButtonWithIcon
										text="Assets"
										width="95px"
										icon={Plus}
										isDisabled={!(this.state.permissions[DRONENAKSHA_FEATURES.MAPS].CREATE)
											|| (this.state.orthoMosaicTaskStatus == AppConstants.TASK.STATUS.IN_PROGRESS
												|| this.state.orthoMosaicTaskStatus == AppConstants.TASK.STATUS.PENDING)}
										onClick={() => {
											this.setState({ importAssets: true })
										}}
									/>
									{/* {this.state.viewMapShow || this.state.ThreeDAvailable ?
										<ButtonWithIcon text="View Map" width="95px" linkData={{
											pathname: "/dronenaksha/view_plan",
											state: {
												type,
												user,
												plan: this.props.location.state.plan,
												project: this.props.location.state.project,
												orthoImageCollectionId: this.state.collection?.id,
												layerShow: this.state.orthoImagesData[0].image_type == "dem" || this.state.orthoImagesData[0].image_type == "dtm" ? "DEMShow" : this.state.orthoImagesData[0].image_type == "pointCloud" ? 'pointCloudShow' : "RGBShow"
											},
										}} isBtnActive={true} isDisabled={!this.state.permissions[DRONENAKSHA_FEATURES.MAPS].READ} />
										: <> */}
									{this.state.orthoMosaicTaskStatus == "No task" || this.state.orthoMosaicTaskStatus == "Failed" ?
										<div style={{ display: 'flex', alignItems: 'center' }}>
											{this.state.showCheckboxes ? <></> :
												<>
													{this.state.counts == 0 ? <div /> :
														<div>
															<ButtonWithIcon
																isDisabled={!this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].CREATE}
																text="Process"
																width="95px"
																onClick={() => {
																	if (this.state.counts >= 10) {
																		this.checkImagesForProcessing()
																	} else {
																		this.setState((state) => ({
																			...state,
																			activityPopupObj: {
																				open: true,
																				item: "Task",
																				icon: "ERROR",
																				action: "ADD",
																				msg: "Minimun 10 images required to start the task."
																			}

																		}))
																	}
																}}
															/>
														</div>
													}
													{orthoImage || this.state.isTaskPresent ? <>{/* Notification panel */}

													</> : <div style={{ display: 'flex', height: '37px', justifyContent: 'center', alignItems: 'center', marginRight: '15px' }}>

														<MultiFileUploader
															uploadBtnNotRequired
															serviceName={AppConstants.SERVICES.DRONENAKSHA}
															acceptFileType={AppConstants.FILETYPES.PNGJPEGTIF}
															collection={collection}
															totalImageCount={this.state.counts}
															oldFiles={this.state.images}
															user={this.state.user.userid}
															callback={(n, files) => { this.setState((prevState) => ({ ...prevState, fileCount: n, images: files })) }}
															onImageUpload={(uploadPercent) => {
																this.setState((state) => ({
																	...state,
																	uploadPercent: uploadPercent,
																	activityPopupObj: undefined
																}), () => {
																	this.getImages()
																})
															}}
															openImportPopup={({ showProcessPopup, processPopupIcon, processMessage, keepOpenPopup }) => {
																this.setState({
																	activityPopupObj: {
																		open: showProcessPopup || keepOpenPopup,
																		icon: processPopupIcon,
																		item: processPopupIcon,
																		msg: processMessage
																	}
																})
															}}
															startGoogleDriveSelection={this.state.startGoogleDriveSelection}
															clearGoogleDriveStates={() => {
																this.setState({ startGoogleDriveSelection: false })
															}}
															isDisabled={!this.state.permissions?.[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].EDIT}
														/>

														<ImagesTypeSelection
															AnimatedModalStyle={{ width: "max-content" }}
															droneNakshaCards
															hideCards={[true, true, true]}
															uploadedImageCount={this.state.images.length}
															collectionId={this.props.collectionId || this.state.collection.id}
															permissions={this.state.permissions}
															disableOrthomosaic={true}
															disableApplicationType={false}
															uploadOk={() => { }}
															onSelectTypeForUpload={
																(fileType, importFrom) => {
																	this.onSelectTypeForUpload(fileType, importFrom)
																}
															}
															importSelectedOrtho={() => { }}
															user={this.state.user}
															isDisabled={!(this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS]?.EDIT)}
															normalImages={this.state.imageFileType === AppConstants.FILETYPES.PNGJPEG}
														/>
													</div>
													}
												</>}

											{/* Report popup */}
											{this.state.showCheckboxes ?
												<ButtonWithIcon
													text="cancel"
													width="100px"
													onClick={() => { this.cancelDelete() }}
												/>
												: <></>
											}
											{this.state.showCheckboxes && this.state.selectedImageCount > 0 ? <div className="add-user-form-text-wrapper" style={{ marginRight: '10px' }} >
												< img src={trashIcon} style={{ height: '24px', width: '24px', cursor: 'pointer' }}
													onClick={() => {
														this.openDeleteConfirmationPopup()
													}}
												/>

											</div> : <></>
											}
										</div> : <></>
									}
									{/* </>} */}

									{
										!(this.state.tasks) || this.state.tasks?.status === AppConstants.TASK.STATUS.MANUAL ?
											<></> : <div style={{ fontSize: "14px", color: '#3c3c3c', display: 'flex', height: '37px', justifyContent: 'center', alignItems: 'center', margin: '0px 10px 0px 5px', filter: this.state.permissions[DRONENAKSHA_FEATURES.MAPS].READ ? "" : "grayscale(100%)", opacity: this.state.permissions[DRONENAKSHA_FEATURES.MAPS].READ ? "1" : "0.5" }}
												onClick={() => {
													this.setState((state) => ({
														...state,
													}))
												}}
											>
												<img style={{ width: '25px', cursor: this.state.permissions[DRONENAKSHA_FEATURES.MAPS].READ ? "pointer" : "default" }} src={this.state.notificationPanel ? notificationActive : notificationIcon}
													onClick={
														() => {
															this.state.permissions[DRONENAKSHA_FEATURES.MAPS].READ && this.setState((state) => ({
																...state,
																notificationPanel: !this.state.notificationPanel,
															}))
														}
													}
												/>
											</div>}

									<div>

										<Dropdown style={{}}>
											<Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" >
											</Dropdown.Toggle>
											<Dropdown.Menu size="sm" title=""
												className={orthoImage ? 'custom-dropdown-settings-ortho' : this.state.images == 0 ? "custom-dropdown-settings1nodata" : "custom-dropdown-settings1"}
											>


												<Dropdown.Item
													style={{ padding: '0px !important' }}
													className={images.length == 0 ? "option-disabled" : "dropdown-set-padding"}
												>
													{images.length == 0 || !this.state.permissions[DRONENAKSHA_FEATURES.MAPS].EDIT ?
														<div style={{ fontSize: "12px", color: '#3c3c3c', opacity: '0.5', zIndex: 99 }}>Manage GCP</div>
														: <Link style={{ color: '#3c3c3c' }}
															to={{
																pathname: "/dronenaksha/create_GCP", state: {
																	type,
																	user,
																	plan: this.props.location.state.plan,
																	project: this.props.location.state.project,
																	collection,
																	completedTask: orthoImage || !(this.state.orthoMosaicTaskStatus == "No task" || this.state.orthoMosaicTaskStatus == "Failed")
																}
															}}
														><div style={{ fontSize: "12px", color: '#3c3c3c' }}
														>Manage GCP</div></Link>}
												</Dropdown.Item>
												{!this.state.mapView && !this.state.isTaskPresent ? <Dropdown.Item
													style={{ padding: '0px !important' }}
													className={images.length == 0 ? "option-disabled" : "dropdown-set-padding"}
												>
													{!this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].DELETE || this.state.showCheckboxes || images.length == 0 || orthoImage || !(this.state.orthoMosaicTaskStatus == "No task" || this.state.orthoMosaicTaskStatus == "Failed") ? <>
														<div style={{ fontSize: "12px", color: '#3c3c3c', opacity: '0.5', cursor: 'auto' }}>Delete Images</div>
													</> : <div style={{ fontSize: "12px", color: '#3c3c3c' }}
														onClick={
															() => {

																this.setState((state) => ({
																	...state,
																	showCheckboxes: true,
																	deleteOrtho: false
																}))
															}
														}>Delete Images</div>}
												</Dropdown.Item> : <></>}
												{/* {orthoImage ?
													<Dropdown.Item
														style={{ padding: '0px !important' }}
														className={images.length == 0 && this.state.orthoImagesData.length == 0 ? "option-disabled" : "dropdown-set-padding"}
													>
														{!this.state.permissions[DRONENAKSHA_FEATURES.MAPS].DELETE ? <div style={{ fontSize: "12px", color: '#3c3c3c', opacity: '0.5', cursor: 'auto' }}>Delete orthomosaic</div> :
															<div style={{ fontSize: "12px", color: '#3c3c3c' }}
																onClick={
																	() => {
																		this.setState((state) => ({
																			...state,
																			deleteOrtho: true,
																			itemName: "Orthomosaic",
																			processPopupIcon: "WARNING",
																			processAction: "DELETE",
																			processMessage: "Are you sure you want to delete this Orthomosaic ?",
																			showProcessPopup: true,
																		}))
																	}
																}>Delete orthomosaic</div>
														}
													</Dropdown.Item>
													: <></>} */}
											</Dropdown.Menu>
										</Dropdown>
									</div>
								</div >

							</div >

							{
								this.state.imagesStatistics ? <div style={{ display: 'flex', marginTop: '-5px', alignItems: "center" }}>
									<span style={{ color: "#3c3c3c", fontSize: '12px', }}>Usable images :</span>&nbsp;<span style={{ color: '#3c3c3c', fontSize: '12px', fontWeight: '600' }}>{this.state.imagesStatistics.usable_images ? this.state.imagesStatistics.usable_images : 0}</span>&nbsp;&nbsp;
									<span style={{ color: "#3c3c3c", fontSize: '12px', }}>|&nbsp;&nbsp;Images with invalid geotag :</span>&nbsp;<span style={{ color: '#3c3c3c', fontSize: '12px', fontWeight: '600' }}>{this.state.imagesStatistics.invalid_images ? this.state.imagesStatistics.invalid_images : 0}</span>
								</div> : <></>
							}

							{
								!(this.state.tasks) || this.state.tasks?.status === AppConstants.TASK.STATUS.MANUAL ?
									<></>
									:
									<div className='slide' style={{ display: "flex", justifyContent: "space-between", backgroundColor: "#EDF1F4", width: this.state.notificationPanel ? "75%" : "100%", height: "60px", padding: "10px 20px", margin: "10px 0px" }}>
										{this.state.tasks ? <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
											<div style={{ fontSize: "13px", width: "40%" }}>
												<div style={{ color: "#3c3c3c" }}>Process status :<span style={{ color: '#3c3c3c', fontWeight: "600" }}> {this.state.tasks.status}</span></div>
												<div style={{ fontSize: "13px" }}>
													<span style={{ color: "#3c3c3c" }}>Date : </span> <span style={{ fontWeight: "600", color: "#3c3c3c" }}>{dateFormat(this.state.tasks.status_update_timestamp, "mmmm dS, yyyy, h:MM TT")}</span>
													<img style={{ width: '18px', marginLeft: '8px', marginBottom: '5px', cursor: 'pointer' }} src={this.state.refresh == false ? refreshBtn : tickIcon}
														onClick={() => {
															this.setState({ refresh: true })
															setTimeout(() => {
																this.setState({ refresh: false })
															}, 700);
															this.getImages()
															this.getUserTaskLogUpdate();
														}} />
												</div>
											</div>
											{/* {this.state.tasks.status != 'Failed ' ?
											<div style={{ display: "flex", color: "#3c3c3c", marginLeft: "10px" }}> Outputs : */}

											{this.state.tasks && this.state.tasks.task_parameters && this.state.tasks.task_parameters.orthomosaic ?
												<div style={{ color: "#3c3c3c", fontSize: "13px", display: "flex", flexDirection: "column", alignItems: "right" }}>
													<div style={{ display: "flex", color: "#3c3c3c", marginLeft: "10px" }}> Outputs :
														{Object.keys(this.state.tasks.task_parameters).map((params, key) => {
															return (
																<div>
																	{params != '3dmodel' ?
																		<div style={{ color: "#3c3c3c", marginLeft: "5px" }}>
																			{this.capitalize(params)}
																			<span style={{ fontWeight: "600" }}>
																				({this.capitalize(this.state.tasks.task_parameters[params])})
																			</span>
																		</div>
																		: <></>
																	}
																</div>
															)
														})
														}
													</div>
													{this.state.tasks.task_parameters['3dmodel'] ?
														<div style={{ textAlign: "right", width: "100%", marginTop: "2px" }}> 3D Model
															<span style={{ fontWeight: "600", }}>
																({this.capitalize(this.state.tasks.task_parameters['3dmodel'])})
															</span>
														</div>
														:
														<></>
													}

												</div>
												: <></>
											}
										</div> : <></>}

									</div>
							}

							{
								this.state.importAssets ?
									<ImportOrtho
										threeD={this.state.indThreeDAvailable}
										laslaz={this.state.indPointCloudAvailable}
										pointCloud={this.state.indPointCloudAvailable}
										orthoData={this.state.orthoImagesData ? this.state.orthoImagesData : undefined}
										userId={this.state.user.userid}
										planId={this.state.plan.id}
										projectId={this.state.project.id}
										taskId={this.state.tasks ? this.state.tasks.task_id : undefined}
										collectionId={this.state.collection.id}
										taskName={this.state.collection.collection_name}
										showUploadErrWarning={() => {
											this.setState({ importAssets: false, showStorageExceedPopup: true })
										}}
										onClick={() => {
											this.getImages();
										}}
										onClickOkButton={() => {
											this.setState({ importAssets: false });
										}}
									/> : <></>
							}


							{this.state.orthoImagesData ? <div style={{ display: "flex" }}>
								{
									this.state.orthoImagesData.map(e => {
										return <div>
											<Link to={{
												pathname: "/dronenaksha/view_plan",
												state: {
													type,
													user,
													plan: this.props.location.state.plan,
													project: this.props.location.state.project,
													orthoImageCollectionId: this.state.collection ? this.state.collection.id : undefined,
													layerShow: e.image_type == "dem" || e.image_type == "dtm" ? "DEMShow" : e.image_type == "pointCloud" ? 'pointCloudShow' : e.image_type == "threeD" ? 'ThreeDShow' : "RGBShow"
												},
											}} style={{ color: "#3c3c3c" }}
											>
												<div style={{ padding: "8px" }}>
													<div className="customer-cards-dashboard card" style={{ boxShadow: '0px 6px 10px rgb(0 0 0 / 30%)', }}>
														<div className="card-header customer-cards-dashboard-logo project-card-header" >
															{/* <img src={e.image_type == "dem" ? DSM : e.image_type == "dtm" ? DTM : ortho} style={{ width: "25px", height: "25px", marginTop: "25px" }} alter="thumb" /> */}
															{/* <img id='ProjectImage' src={ImageSetThumb} alter="Thumb Img" /> */}
															<img src={e.image_type == "dem" ? DSMThumb : e.image_type == "dtm" ? DTMThumb : OrthoThumb} style={{ width: "150px", height: "100px" }} alter="thumb" />
														</div>
														{/* ----- Delete Component---- */}
														{/* <div style={{ cursor: "pointer", position: "absolute", right: "5px", top: "5px", height: "20px", width: "20px", borderRadius: "10px", background: "#00000099" }} alt="I am alt" >
																			<Dropdown style={{}}>
																				<Dropdown.Toggle as={CustomToggle1} id="dropdown-custom-components" >
																				</Dropdown.Toggle>
																				<Dropdown.Menu size="sm" title=""
																					style={{ height: "31px", minWidth: "0px", position: "absolute" }}
																				>
																					<Dropdown.Item
																						style={{}}
																					>
																						<div style={{ fontSize: "12px", color: '#3c3c3c', margin: "-6px" }}
																							onClick={
																								() => {
																									console.log("clicked option dlt");
																									this.setState({
																										...this.state,
																										imageId: e.id,
																										itemName: e.image_type,
																										processPopupIcon: "WARNING",
																										processAction: "DELETE",
																										processMessage: `Are you sure you want to delete this ${e.image_type} ?`,
																										showProcessPopup: true,
																									})
																								}
																							}>Delete</div>
																					</Dropdown.Item>
																				</Dropdown.Menu>
																			</Dropdown>
																		</div> */}
														<div className="card-body change-color customer-cards-dashboard-text project-card-body" >
															<div style={{ height: "24px", overflow: "hidden", display: "flex", justifyContent: "space-between" }}>
																<div>{e.image_type ? e.image_type == "dem" ? "DSM" : e.image_type == "dtm" ? "DTM" : e.image_type == "orthomosaic" ? "Orthomosaic" : e.image_type : e.image_type}</div>
																{e.img_size ? <div style={{ fontSize: "11px" }}>{`${Number(e.img_size).toFixed(2)} MB`}</div> : <></>}
															</div>
															<div style={{ fontSize: '10px', display: 'flex', justifyContent: 'space-between' }}>
																<div>
																	{dateFormat(e.date_created, "h:MM TT")}
																</div>
																<div> {dateFormat(e.date_created, "dd-mmm-yyyy")}</div>
															</div>
														</div>
													</div>
												</div>

											</Link>
										</div>
									})
								}
							</div> : <></>}



							{
								this.state.mapView ?
									<div>
										<ViewImagesOnMap
											service={AppConstants.SERVICES.DRONENAKSHA}
											height={this.state.tasks ? "71vh" : '82vh'}
											width={this.state.notificationPanel ? "75%" : '100%'}
											inspect={this.state.images}
											collection_id={this.state.collection.id}
											plan_id={this.state.plan.id}
											project_id={this.state.project.id}
											showCheckboxes={this.state.showCheckboxes}
											getImages={() => this.getImages()}
											onClickOnPoint={idx => this.openImage(idx)}
											cannotDelete={orthoImage || this.state.isTaskPresent || !(this.state.orthoMosaicTaskStatus == "No task" || this.state.orthoMosaicTaskStatus == "Failed")}
											permissions={this.state.permissions}
										/>
									</div>
									: <>{this.state.listview && images.length > 0 ?
										<div className="slide" style={{ height: this.state.tasks ? "71vh" : '82vh', width: this.state.notificationPanel ? '76%' : '100%', overflow: 'auto' }}>
											<ViewImageInList user={this.state.user} showCheckboxes={this.state.showCheckboxes} collection_id={this.state.collection.id} inspect={this.state.images} sortType={this.state.sortType}
												onClickOnCheckBox={key => this.imageSelectDeleted(key)} onClickOnPoint={idx => this.openImage(idx)}
												onClickSort={sortType => this.onChangeSortEvent(sortType)} />
										</div>

										: <>   {images.length ?
											<div style={{ height: this.state.tasks ? "71vh" : '82vh', overflow: 'auto' }}>
												<div style={{ display: "flex", marginTop: '25px', justifyContent: "space-between", }}>
													<div className="services-content-title">Images
														<span className='data-count'>({images?.length})</span>
													</div>
												</div>
												<div style={{ width: this.state.notificationPanel ? '78%' : '100%' }} className="aerocapture-cards-tray-images1">
													{
														images.map((image, key) => {
															// let name = image.file_name.toLowerCase()
															return (
																<div>
																	<div>
																		{< div key={key} className="customer-cards-dashboard card" style={{ boxShadow: '0px 6px 10px rgb(0 0 0 / 30%)', }}>
																			<div className="card-header customer-cards-dashboard-logo project-card-header">
																				{/* {name.indexOf(".tif") == -1 ? */}
																				<img className="image-cards-dashboard"
																					src={image.thumbURL}
																					loading='lazy'
																					onError={(e) => { e.currentTarget.src = ImageSetThumb }}
																					onClick={
																						() => {
																							if (this.state.showCheckboxes) {
																								this.imageSelectDeleted(key)
																							} else {
																								this.openImage(key)
																							}
																						}
																					} id={image.file_name} style={{ objectFit: 'cover', cursor: 'pointer' }} />
																				{/* :
																			<img className="image-cards-dashboard"
																				src={image.thumbURL}
																				loading='eager'
																				onClick={
																					() => {dronenaksha/images
																						if (this.state.showCheckboxes) {
																							this.imageSelectDeleted(key)
																						} else {
																							this.openImage(key)
																						}
																					}
																				} id={image.file_name} style={{ objectFit: 'cover', cursor: 'pointer' }} />
																			} */}
																				{this.state.showCheckboxes ? <input type='checkbox' id={image.file_name} checked={image.checked} style={{ height: '15px', width: '15px', marginLeft: 'auto', marginRight: "-145px", marginTop: '-40px', position: 'absolute' }}
																					onClick={() => { this.imageSelectDeleted(key) }}
																				/> : <> </>
																				}
																			</div>
																			<div style={{ paddingTop: "3px !important" }}
																				className="card-body change-color customer-cards-dashboard-text project-card-body2"
																			>
																				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
																					<div style={{ fontSize: '11px' }}>{
																						image.file_name.length > 15 ?
																							`${image.file_name.substring(0, 15)}...` :
																							`${image.file_name}`
																					}</div>
																					<div style={{ height: '12px', }}>
																						{image.latitude == "" || image.longitude == "" || image.latitude == null || image.longitude == null || image.latitude == '0' || image.longitude == '0' ? <></> : <img style={{ marginTop: '-2px', height: '12px', width: '12px', color: '#3c3c3c' }} src={LocationIcon} />}
																					</div>
																				</div>
																				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
																					<div style={{ height: "11px", overflow: "hidden", fontSize: '10px' }}>{image.file_size} MB</div>
																					<div style={{ height: "11px", overflow: "hidden", fontSize: '10px' }}>{image.resolution} MP</div>
																				</div>
																			</div>
																		</div>}
																	</div>
																</div>
															)
														})
													}
												</div>
											</div>
											:
											images.length == 0 && this.state.orthoImagesData.length == 0 ?
												<div className="rmp-dashboard-empty-data-message" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
													<img style={{ height: '180px', width: '180px' }} src={Noimageset} />
													<p style={{ marginTop: '10px' }}>No images available</p>
												</div>
												: <></>
										}
										</>}</>}
						</div >
					</>}
				</div >
			</div > : <div />
		);
	}
}
const mapStateToProps = state => {
	return {
		projectName: state.project.projectName,
		planName: state.project.planName,
		activeTeamId: state.profile.activeTeamId,
		activeTeam: state.profile.activeTeam,
		isLoggedInUserOwner: state.profile.isLoggedInUserOwner,
		SubscriptionData: state.subscription.subscriptionData,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		addProjectDetails: (payload) => dispatch(addProjectDetails(payload))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AeroCaptureImageCollectionImages)
