import React, { useEffect, useState } from 'react';
import DropDownWithButton from './DropDownWithButton';
import * as API from '../api.js';
import closeButton from '../close-button.png';
import ButtonWithIcon from './ButtonWithIcon';
import { AnimatedModal } from './AnimatedModal';
import { getLimitedName } from './reusableFunctions';
import AppConstants, { SERVICES } from '../AppConstants';


const ImportClassesFromTask = ({ open, importedClasses, dropDownHeight, dropDownWidth, onClose }) => {
  const [projects, setProjects] = useState([])
  const [classes, setClasses] = useState([])
  const [dropDownListForClasses, setDropDownListForClasses] = useState([]);
  const [selectedTaskName, setSelectedTaskName] = useState("Tasks");
  const [selectedProject, setSelectedProject] = useState(undefined)
  const [projectDataMessage, setProjectDataMessage] = useState("Loding Project")

  const getAllProjectsData = async () => {
    API.getAllProjects(" ", 10000, 0, undefined, false, AppConstants.SERVICES.PICSTORK) //if user is not owner of team then fetch projects of active team which user has MANAGER ROLE.
      .then(async (data) => {
        const projects = data?.projects?.filter(p => !(p.sharedData?.isShared))
          .map(p => {
            return ({
              id: p.id,
              name: p.project_name,
              onClick: () => {
                setSelectedProject({
                  id: p.id,
                  name: p.project_name,
                })
              }
            })
          }) || []
        setProjects(projects)
        setProjectDataMessage(projects.length > 0 ? false : "No projects found.")
        //filtered shared Projects
      }, (e) => {
        console.log(e);
      })
  }

  useEffect(() => {
    getAllProjectsData()
  }, [])

  useEffect(() => {
    setSelectedTaskName("Select Task")
    setClasses([])
    if (selectedProject) API.getAllTask(selectedProject.id, " ", " ", 0, 100000, SERVICES.PICSTORK).then(res => {
      setDropDownListForClasses(res.tasks?.length > 0 ? (res.tasks.map(task => {
        const newClasses = task?.classes?.map(cls => {
          return cls.name
        })
        return {
          id
            : task.task_id, name: task.task_name, classes: newClasses, onClick: () => {
              setSelectedTaskName(task.task_name);
              setClasses(newClasses)
            }
        }
      }).filter(task => task?.classes?.length > 0)) : [])
    })
  }, [selectedProject])

  return (
    <>

      <AnimatedModal
        height="350px"
        width="600px"
        zIndex={5}
        onRequestClose={() => onClose()}
        isOpen={open}
      >
        <div style={{ height: '100%', width: '100%', textAlign: 'left' }}>
          <div className="services-content-title" style={{ marginTop: "10px", padding: '0px 20px' }}>Import Classes</div>
          <hr style={{ margin: '5px 0px' }} />
          <div style={{ width: "100%", marginTop: "3px", display: 'flex', justifyContent: 'space-between', padding: '10px 20px', textAlign: 'left' }}>
            <div style={{ width: "48%" }}>
              <div className="itemValue">Select Project</div>
              <div style={{ border: "1px solid #b4b4bb", borderRadius: "5px", height: "34px", padding: '2px' }}>
                <DropDownWithButton
                  // isButton
                  isDisabled={projects.length === 0}
                  selectedItem={projectDataMessage || (selectedProject ? getLimitedName(selectedProject.name, 40) : "select project")}
                  list={projects}
                  textFontSize="13px"
                  textFontWeight="400"
                  width={dropDownWidth}
                  maxHeight={dropDownHeight}
                />
              </div>
            </div>

            <div style={{ width: '48%' }}>
              <div className="itemValue" >Select Task</div>
              <div style={{ border: "1px solid #b4b4bb", borderRadius: "5px", height: "34px", padding: '2px' }}>
                <DropDownWithButton
                  // isButton
                  isDisabled={dropDownListForClasses.length === 0}
                  selectedItem={Array.from(selectedTaskName).length > 15 ? `${selectedTaskName.substring(0, 12)}...` : selectedTaskName}
                  list={dropDownListForClasses}
                  textFontSize="13px"
                  textFontWeight="400"
                  width={dropDownWidth}
                  maxHeight={dropDownHeight}
                />
              </div>
            </div>
          </div >

          <div style={{ height: "calc(100% - 210px)", padding: "0 20px", lineHeight: '22px', color: '#3c3c3c', overflow: "auto" }}>
            {classes && classes.length > 0 ?
              <>
                {/* <div style={{ display: "flex", marginTop: '10px', overflow: 'scroll', height: '100%', }}> */}
                {/* <div className="rpas-model-info-primary-details-value1"> */}
                {classes.map((type, i) => {
                  return (
                    <>
                      {type && type.length > 0 ? <button
                        className="aerocapture-detection-type-list"
                        style={{ fontSize: "12px", margin: "4px" }}
                      >
                        {type}
                        {/* {isTaskEditAccess() ? */}
                        {/* <img onClick={() => {
                            let data = classes.filter((cl => cl != type))
                            setClasses(data)
                            // props.onChangeDetails({ type: data })
                          }} style={{ height: '16px', cursor: 'pointer', marginLeft: '14px' }} src={closeButton} /> */}
                        {/* : <></>} */}
                      </button> : <></>}
                    </>
                  );
                })}
                {/* </div> */}
                {/* </div> */}
              </>
              : <></>
            }
          </div>
          <hr style={{ marginBottom: "5px", zIndex: "5" }} />
          <div style={{ position: "absolute", bottom: "10px", width: "100%" }}>

            <div style={{ display: "flex", width: "100%", padding: "0 20px" }}>
              <div style={{ marginLeft: "auto", display: "flex", }}>
                <div style={{ display: "flex", justifyContent: "center", height: "28px", margin: "5px 0 0 3px" }}>
                  <ButtonWithIcon
                    width="80px"
                    height="25px"
                    text="close" onClick={() => { onClose(false) }}
                  />
                </div>
                <div style={{ display: "flex", justifyContent: "center", height: "28px", margin: "5px 0 0 3px" }}>
                  <ButtonWithIcon
                    text="Import"
                    isDisabled={!(classes && classes.length > 0)}
                    height="25px"
                    width="80px"
                    onClick={() => {
                      importedClasses(classes)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </AnimatedModal >
    </>
  )
}

export default ImportClassesFromTask;