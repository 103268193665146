
import React from 'react'
import { StepperModal } from './StepperModal'
import Loader from '../icons/loader.svg'
import createPlan from '../Icon/createProjectPlanIcons/Project.png'
import createProject from '../Icon/createProjectPlanIcons/Plan.png'
import createImageSet from '../Icon/createProjectPlanIcons/ImageSet.png'
import uploadImages from '..//Icon/createProjectPlanIcons/uploadImages.png'
import { useState, useEffect } from 'react'
import * as API from '../api.js';
import { StaticRouter, Redirect } from "react-router-dom";
import ButtonWithIcon from './ButtonWithIcon'
import SingleMap from "../ReusableComponents/map";
import Autocomplete from "react-google-autocomplete";
import L from "leaflet";
import MarkerIcon from '../assets/marker.png'
import { Map, GoogleApiWrapper } from "google-maps-react";
import AppConstants from '../AppConstants.js'



// styles

const nextButton = {
    position: "absolute",
    bottom: "25px",
    right: "40px"
}

const mainContent = {
    textAlign: "initial",
    margin: "35px 0 0 15%"
}

let mapInState = undefined
let mapMarker = undefined

export const CreateProjectPlanModal = (props) => {

    //states
    const [currentStep, setCurrentStep] = useState(0);
    const [project_name, setProjectName] = useState("");
    const [client_name, setClientName] = useState("");
    const [project_id, setProjectId] = useState(undefined);
    const [allProjects, setAllProjects] = useState(undefined);
    const [allPlans, setAllPlans] = useState(undefined);
    const [plan_name, setPlanName] = useState("");
    const [plan_id, setPlanId] = useState(undefined);
    const [description, setDescription] = useState(undefined)
    const [loader, setLoader] = useState(false);
    const [selectProject, setSelectProject] = useState(false);
    const [selectPlan, setSelectPlan] = useState(false);
    const [imageSetName, setImageSetName] = useState("");
    const [redirectToImages, setRedirectToImages] = useState(false)
    const [collection, setCollection] = useState(undefined)
    const [errorMessage, setError] = useState(undefined)
    const [newItem, setNewItem] = useState(undefined)
    const [center, setCenter] = useState({ lat: 19.993982, lng: 73.790416 })
    const [locationName, setLocationName] = useState('')

    useEffect(() => {
        if (props.createProjectPlanModal) {
            if (props.project) {
                setProjectName(props.project.project_name)
                setProjectId(props.project.id)
            } else {
                getAllProjects()
            }
            if (props.createType != 'plan')
                setProjectName("")
            else
                setProjectName(props.project.project_name)
            setPlanName("")
            setImageSetName("")
            setNewItem(null)
            getAllPlans()
        }
    }, [props.createProjectPlanModal])

    //plan creation

    const checkPlan = () => {

        API.checkPlan(project_name, plan_name, project_id).then(async (res) => {
            if (res.message == "Plan doesn't already exist") {
                let Plan = {};
                let planConfiguration = {
                    projectDetails: [
                        {
                            clientName: 'asd',
                            'dateTime': new Date().toISOString(),
                            flightCompletedCount: 0,
                            projectName: project_name,
                            projectId: project_id,
                            planName: plan_name,
                            planCompletedStatus: 0,
                            softwareVerison: "1.1",
                            linkedMap: null
                        }
                    ]
                }
                Plan.planConfiguration = await planConfiguration;
                Plan.planAddress = {};
                Plan.missionStatistics = {
                    "missionStatistics": [{
                        "missionType": 'notGCSPlan',
                    }]
                };
                Plan.DGCAfence = [{}];
                Plan.RallyPointData = [];
                Plan.corridor = [];
                Plan.Mission =
                {
                    waypoints: [{

                    }]
                }
                Plan.Geofence =
                {
                    geofence: [{
                        "lat": 0,
                        "lon": 0,
                        "alt": 0
                    }]
                }
                Plan.Assets = {
                    "planMap.png": ""
                }
                API.createPlan(
                    Plan
                ).then(
                    async (data) => {
                        console.log("INSIDE DATA", data);
                        if (data.message == "OK") {
                            setLoader(false)
                            setCurrentStep(currentStep + 1)
                            setNewItem(newItem ? newItem + "PLAN" : "PLAN") // to refresh the list only when new plan is created
                        } else if (data.message == "Plan already exists") {
                            setLoader(false)
                            setError("Plan already exist with this name.");
                        }
                        else if (data.error) {
                            setLoader(false)
                            setError(data.error);
                        }
                    },
                    (e) => {
                        setLoader(false)
                        setError("Something went wrong");
                    }
                );
            }
            else {
                setLoader(false)
                setError("Plan name is already exist.");
            }
        })
    }
    const planNameHandler = (e) => {
        let format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;

        if (format.test(e.target.value)) {
            setError("Spacial characters are not allowed.")
        } else if (e.target.value.includes(' ')) {
            setError("Spaces are not allowed.")
        }
        else {
            setError(undefined)
            setPlanName(e.target.value)
        }
    }
    const descriptionHandler = (e) => {
        setDescription(e.target.value)
    }

    const savePlan = (e) => {
        if (plan_name != "" && plan_name) {
            setError(undefined)
            setLoader(true)
            if (selectPlan) {
                setLoader(false)
                setCurrentStep(currentStep + 1)
            } else {
                checkPlan()
            }
        }
        else {
            setError('Plan name is necessary.')
        }
    }

    const getAllProjects = async () => {
        let key = ' '
        API.getAllProjects(key, 10000, 0, undefined, undefined, props.serviceName).then(async (data) => {
            console.log("project data in create project data", data);
            let projectData = data.projects.length > 0 ? data.projects.filter((data) => { return !data.sharedData.isShared }) : []
            setAllProjects(projectData)
        }, (e) => {
            console.log(e);
        })
    }

    const getAllPlans = () => {
        let key = ' '
        let projectName = props.project ? props.project.project_name : project_name
        API.getPlans(projectName, key, 10000, 0, props.serviceName === "DroneNaksha" ? 3 : 0, props?.project ? props?.project?.id : project_id).then(async (data) => {
            console.log("plan data in create plan data", data);
            setAllPlans(data.plans)
        }, (e) => {
            console.log(e);
        })
    }


    //project 
    const getProject = () => {
        let dt = new Date();
        let date = dt.getDate().toString().padStart(2, "0");
        let month = (dt.getMonth() + 1).toString().padStart(2, "0");
        let year = dt.getFullYear();
        let hour = dt.getHours();
        let minute = dt.getMinutes();
        let seconds = dt.getSeconds();
        let dateTime = `${year}-${month}-${date} ${hour}:${minute}:${seconds}`;
        let project = {
            service: props.serviceName,
            projectDetails: {
                clientName: client_name,
                "dateTime": new Date().toISOString(),
                flightCompletedCount: 0,
                projectName: project_name,
                softwareVerison: "1.0",
                locationName: locationName,
                locationLatLng: center
            }
        }
        API.createNewProject(project).then((data) => {
            if (data.message == "OK") {
                setLoader(false);
                setProjectId(data.project_id)
                setCurrentStep(currentStep + 1)
                setNewItem(newItem ? newItem + "PROJECT" : "PROJECT") //to refresh the list only when new project is created
            }
            else if (data.error) {
                setLoader(false);
                setError(data.error)
            }
            else {
                setLoader(false);
                setError("Something went wrong.")
            }
        })
    }

    const projectNameHandler = (e) => {

        let format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;

        if (format.test(e.target.value)) {
            setError("Special characters are not allowed.")
        } else if (e.target.value.includes(' ')) {
            setError("Spaces are not allowed.")
        } else {
            setError(undefined)
            setProjectName(e.target.value)
        }
    }

    const saveProject = (e) => {
        // e.preventDefault();
        if (project_name != "" && project_name) {
            setLoader(true)
            setError(undefined)
            if (selectProject) {
                setLoader(false);
                setCurrentStep(currentStep + 1)
                getAllPlans()
            }
            else getProject();
        }
        else {
            setError("Project name is necessary.")
        }
    }

    const onChangeListener = (e) => {
        e.preventDefault();
        let key = e.target.value
        console.log(key);
        if (e.target.value === 'select') {
            setProjectName('')
            setProjectId(undefined)
            setSelectProject(false)
        } else {
            setProjectName(allProjects[key].project_name)
            setProjectId(allProjects[key].id)
            setSelectProject(true)
        }
    }


    const onChangeListenerplan = (e) => {
        e.preventDefault();
        let key = e.target.value
        console.log(key);
        if (e.target.value === 'select') {
            setPlanName('')
            setPlanId(undefined)
            setSelectPlan(false)
        } else {
            setPlanName(allPlans[key].plan_name)
            setPlanId(allPlans[key].id)
            setSelectPlan(true)
        }
    }

    //imageSet 
    const imageSetNameHandler = (e) => {
        let format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;

        if (format.test(e.target.value)) {
            setError("Spacial characters are not allowed.")
        } else if (e.target.value.includes(' ')) {
            setError("Spaces are not allowed.")
        } else {
            setError(undefined)
            setImageSetName(e.target.value)
        }
    }

    const getPlanId = () => {
        return new Promise((resolve, reject) => {
            API.getPlans(project_name, plan_name, 1, 0, 3, project_id).then(async (data) => {
                console.log("data", data)
                const plan = data.plans[0]
                setPlanId(plan.id)
                resolve(plan.id)
            }, (e) => {
                setLoader(false);
                setError("Something went wrong.")
            })

        })
    }
    const successRedirectComponent = () => {
        if (redirectToImages) {
            return <Redirect to={{
                pathname: props.serviceName == "DroneNaksha" ? "/dronenaksha/images" : "/picstork/images",
                state: {
                    user: props.user,
                    type: props.type,
                    collection: collection,
                    project: {
                        project_name: project_name,
                        id: project_id,
                    },
                    plan: {
                        plan_name: plan_name,
                        id: plan_id,
                        project_name: project_name
                    },
                    openUploader: true
                }
            }} />
        }
    }

    const setCollectionData = async (collection) => {
        setCollection(collection)
    }

    const saveImageSet = async (e) => {
        if (imageSetName != "" && imageSetName != " " && imageSetName) {
            setError(undefined)
            setLoader(true)
            let plan_id = await getPlanId()
            API.createImageCollection(project_id, plan_id, imageSetName).then(async (data) => {
                console.log(data);
                if (data.collection) {
                    await setCollectionData(data.collection)
                    setLoader(false);
                    setCurrentStep(currentStep + 1)
                    setNewItem(newItem ? newItem + "IMAGESET" : "IMAGESET") //to refresh the list only when new imageset is created
                    // setRedirectToImages(true)
                } else if (data.status == 409) {
                    setError("Imageset already exist with same name.")
                    setLoader(false);
                } else {
                    setError("Something went wrong.")
                    setLoader(false)
                }
            }, (e) => {
                setError("Something went wrong.")
                setLoader(false)
            })
        } else {
            setError("Imageset name is necessary.")
        }
    }


    let getSteps = () => {
        switch (props.createType) {
            case "project": return (
                [{
                    title: 'Create project',
                    icon: createProject,
                }, {
                    title: 'Create plan',
                    icon: createPlan

                }, {
                    title: 'Create imageset',
                    icon: createImageSet

                }, {
                    title: 'Upload images',
                    icon: uploadImages

                }]
            )

            case "plan": return (
                [{
                    title: 'Create plan',
                    icon: createPlan

                }, {
                    title: 'Create imageset',
                    icon: createImageSet

                }, {
                    title: 'Upload images',
                    icon: uploadImages

                }]
            )
        }
    }
    const ErrorMessageComponent = () => {
        return (<div style={{
            color: "red",
            fontSize: "12px",
            padding: "5px 0 0 3px"
        }}>{errorMessage}</div>)
    }

    const changeMarkerPosition = (mark) => {
        mapInState.setView(mark);
        mapMarker.setLatLng(mark);
    }

    const onchangePlace = (e) => {
        console.log("Place changed : ", e.target.value);
        let val = e.target.value.trim()
        let seperator = val.indexOf(",") != -1 ? ',' : val.indexOf(" ") != -1 ? " " : " "
        let latlng = val.trim().split(seperator)
        if (latlng.length == 2) {
            setCenter({ lat: latlng[0], lng: latlng[1] })
        }
    }

    const handleMapClick = (e) => {
        console.log(e, mapMarker)
        let center = { lat: e.latlng.lat, lng: e.latlng.lng }
        mapMarker.setLatLng(center);
        mapInState.setView(center);
        setCenter(center)
    }

    const initMap = (map) => {
        var markerOptions = {
            title: "MyLocation",
            clickable: true,
            draggable: false,
            icon: new L.Icon({ iconUrl: MarkerIcon, iconSize: [20, 28], iconAnchor: [10, 28] })
        }
        let marker1 = new L.Marker(center, markerOptions).addTo(map)
        console.log('marker', marker1);
        mapMarker = marker1
        map.setView(center, 17);
    };

    const handleKeyDown = (event) => {
        console.log(event);
        if (event.key === 'Enter') {
            console.log('Enter key pressed !')
            mapMarker.setLatLng(center);
        }
    }

    const LoaderComponent = () => {
        return (<div style={{ height: "65%", width: "100%", display: "flex", textAlign: "center" }}>
            <img src={Loader} style={{ width: "20%", margin: "-32px 0 0 3px" }} />
        </div>)
    }
    return (
        <>
            {/*------------------------------ Stepper Modal-----------------------------*/}
            <GoogleApiWrapper apiKey='AIzaSyBuxfUtRZSEUT2QrbZA8zUsG58lZUYYmwI' libraries={["drawing", "places"]} />
            {successRedirectComponent()}
            <StepperModal
                steps={getSteps()}
                closeStepper={() => {
                    setCurrentStep(0);
                    setPlanName(undefined);
                    setDescription(undefined);
                    props.closeCreateProjectPlanModal(newItem);
                    setError(undefined)
                    setPlanId(undefined)
                    setDescription(undefined)
                    setLoader(false)
                    setImageSetName(undefined)
                    setCollection(undefined)
                    setError(undefined)
                    setSelectPlan(false)
                    setSelectProject(false)
                }}
                stepperModal={props.createProjectPlanModal}
                heading={props.createType == "project" ? "Create project" : "Create plan"}
                currentStep={currentStep}
                height={"550px"}
                width={"700px"}
            >
                {props.createType == "project" ?
                    <>
                        {currentStep == 0 ? <>
                            <form onSubmit={saveProject}>
                                <div style={{ margin: '10px 30px', textAlign: 'left' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ width: '50%' }}>
                                            <div className="add-user-form-label" style={{ textTransform: "none" }}>
                                                Enter project name
                                            </div>
                                            <div className="add-user-form-text-wrapper">
                                                {selectProject ? <input
                                                    type="text"
                                                    maxLength={50}
                                                    className="add-user-form-text textbox-shadow"
                                                    onChange={projectNameHandler}
                                                    name="project_name"
                                                    value={project_name}
                                                    style={{ backgroundColor: 'rgba(102, 102, 102, 0.1)', width: '250px' }}
                                                    disabled
                                                /> :
                                                    <input
                                                        type="text"
                                                        maxLength={50}
                                                        style={{ width: '250px' }}
                                                        className="add-user-form-text textbox-shadow"
                                                        onChange={projectNameHandler}
                                                        name="project_name"
                                                        value={project_name}
                                                    />}
                                            </div>
                                        </div>
                                        <div style={{ width: '50%' }}>
                                            {allProjects && allProjects.length > 0 ? <>
                                                <div className="add-user-form-label" style={{ textTransform: "none", }}>Select from existing Projects</div>
                                                <select
                                                    name="plan_id"
                                                    onChange={onChangeListener}
                                                    style={{ width: '250px' }}
                                                    className="add-user-form-text textbox-shadow"
                                                ><option value={'select'}>Select project</option>
                                                    {allProjects ?
                                                        allProjects.map((project, key) => {
                                                            return <option value={key}>{project.project_name}</option>
                                                        }) : null}
                                                </select>
                                            </> : <></>}
                                        </div>
                                    </div>


                                    <div style={{ height: '250px', width: '640px', margin: '20px 0px' }}>
                                        <div style={{ position: 'absolute', zIndex: '1', background: '#3c3c3c99', padding: '5px' }}>
                                            <Autocomplete
                                                style={{ width: '270px', opacity: selectProject && "0.5", cursor: selectProject && "not-allowed", pointerEvents: selectProject && "none" }}
                                                className="input-transparent1"
                                                placeholder='Search location'
                                                apiKey="AIzaSyBuxfUtRZSEUT2QrbZA8zUsG58lZUYYmwI"
                                                onChange={(e) => { onchangePlace(e) }}
                                                onKeyDown={(e) => handleKeyDown(e)}
                                                onPlaceSelected={(place) => {
                                                    try {
                                                        console.log('place', place);
                                                        let lat = place.geometry.location.lat();
                                                        let lng = place.geometry.location.lng();
                                                        let cntr = {};
                                                        cntr.lat = parseFloat(lat);
                                                        cntr.lng = parseFloat(lng);
                                                        setCenter(cntr)
                                                        setLocationName(place.formatted_address)
                                                        changeMarkerPosition(cntr)
                                                    } catch { }
                                                }}
                                                types={[]}
                                            />
                                        </div>
                                        <SingleMap
                                            initCenter={[center.lat, center.lng]}
                                            initZoom={22}
                                            handleMapClick={handleMapClick}
                                            maxZoom={23}
                                            setMap={(map) => {
                                                mapInState = map;
                                                initMap(map)
                                            }}
                                            style={{ opacity: selectProject && "0.5", pointerEvents: selectProject && "none" }}
                                        />
                                    </div>
                                    {errorMessage ? <ErrorMessageComponent /> : <></>}
                                    {loader ? <LoaderComponent /> : <></>}
                                    <div style={{ position: 'absolute', bottom: '15px', right: '20px' }}>
                                        <ButtonWithIcon text="Next" width="90px" onClick={() => saveProject()} isBtnActive /></div>
                                </div>
                            </form>
                        </> : <></>}
                        {currentStep == 1 ? <>
                            <form onSubmit={savePlan}>
                                <div style={{
                                    textAlign: "initial",
                                    margin: "50px 0 0 15%"
                                }
                                }>
                                    <div className="add-user-form-label">
                                        Plan Name
                                    </div>
                                    <div className="add-user-form-text-wrapper">
                                        {selectPlan ? <input
                                            type="text"
                                            maxLength={50}
                                            className="add-user-form-text textbox-shadow"
                                            // placeholder="Enter Project Name"
                                            onChange={planNameHandler}
                                            name="plan_name"
                                            value={plan_name}
                                            style={{ backgroundColor: 'rgba(102, 102, 102, 0.1)' }}
                                            disabled
                                        />
                                            :
                                            <input
                                                type="text"
                                                maxLength={50}
                                                className="add-user-form-text textbox-shadow"
                                                // placeholder="Enter Project Name"
                                                onChange={planNameHandler}
                                                name="plan_name"
                                                value={plan_name}
                                            />
                                        }
                                    </div>
                                    {/* <div className="add-user-form-label" style={{ marginTop: "5px" }}>
                                        Description ( Optional )
                                    </div>
                                    <div className="add-user-form-text-wrapper">
                                        <input
                                            className="add-user-form-text textbox-shadow"
                                            type="text"
                                            maxLength={200}
                                            name="description"
                                            onChange={descriptionHandler}
                                            value={description}
                                        />
                                    </div> */}
                                    {allPlans ? allPlans.length > 0 ?
                                        <>
                                            <div className="add-user-form-label" style={{ textTransform: "none", margin: '20px 0px 10px 0px' }}>Select from existing Plans</div>
                                            <select
                                                name="plan_id"
                                                onChange={onChangeListenerplan}
                                                // value={plan_id}
                                                className="add-user-form-text textbox-shadow"
                                            // className="sub-add-user-form-text"
                                            >
                                                <option value={'select'}>Select plan</option>
                                                {
                                                    allPlans ?
                                                        allPlans.map((plan, key) => {
                                                            return <option value={key}>{plan.plan_name}</option>
                                                        })
                                                        : null}
                                            </select>
                                        </> : <></> : <></>}

                                    {errorMessage ? <ErrorMessageComponent /> : <></>}
                                    {loader ? <LoaderComponent /> : <></>}
                                    <div style={nextButton}>
                                        <ButtonWithIcon text="Next" width="90px" onClick={() => savePlan()} type="submit" isBtnActive />
                                    </div>
                                </div>

                            </form>
                        </> : <></>}
                        {currentStep == 2 ? <>
                            <form onSubmit={saveImageSet}>
                                <div style={{
                                    textAlign: "initial",
                                    margin: "50px 0 0 15%"
                                }}>
                                    <div className="add-user-form-label">
                                        Imageset Name
                                    </div>
                                    <div className="add-user-form-text-wrapper">
                                        <input
                                            type="text"
                                            maxLength={AppConstants.IMAGESET_NAME_LIMIT}
                                            className="add-user-form-text textbox-shadow"
                                            // placeholder="Enter Project Name"
                                            onChange={imageSetNameHandler}
                                            name="plan_name"
                                            value={imageSetName}
                                        />
                                    </div>
                                    {errorMessage ? <ErrorMessageComponent /> : <></>}
                                    {loader ? <LoaderComponent /> : <></>}
                                    <div style={nextButton}>
                                        <ButtonWithIcon text="Next" width="90px" onClick={() => saveImageSet()} isBtnActive />
                                    </div>
                                </div>
                            </form>
                        </> : <></>}
                        {currentStep == 3 ?
                            <div style={{ display: "flex", margin: "50px 0 0 15%" }}>
                                <span style={{ marginRight: "10px", color: "#666666" }}> Click finish and start uploading images.</span>
                                <div style={nextButton}>
                                    <ButtonWithIcon text="Finish" width="90px" onClick={() => setRedirectToImages(true)} isBtnActive />
                                </div>
                            </div> : <></>}
                    </> : <>
                        {currentStep == 0 ? <>
                            <form onSubmit={savePlan}>
                                <div style={{
                                    textAlign: "initial",
                                    margin: "50px 0 0 15%"
                                }
                                }>
                                    <div className="add-user-form-label">
                                        Plan Name
                                    </div>
                                    <div className="add-user-form-text-wrapper">
                                        {selectPlan ? <input
                                            type="text"
                                            maxLength={50}
                                            className="add-user-form-text textbox-shadow"
                                            // placeholder="Enter Project Name"
                                            onChange={planNameHandler}
                                            name="plan_name"
                                            value={plan_name}
                                            style={{ backgroundColor: 'rgba(102, 102, 102, 0.1)' }}
                                            disabled
                                        />
                                            :
                                            <input
                                                type="text"
                                                maxLength={50}
                                                className="add-user-form-text textbox-shadow"
                                                // placeholder="Enter Project Name"
                                                onChange={planNameHandler}
                                                name="plan_name"
                                                value={plan_name}
                                            />
                                        }
                                    </div>
                                    {/* <div className="add-user-form-label" style={{ marginTop: "5px" }}>
                                        Description ( Optional )
                                    </div>
                                    <div className="add-user-form-text-wrapper">
                                        <input
                                            className="add-user-form-text textbox-shadow"
                                            type="text"
                                            maxLength={200}
                                            name="description"
                                            onChange={descriptionHandler}
                                            value={description}
                                        />
                                    </div> */}

                                    {allPlans ? allPlans.length > 0 ?
                                        <>
                                            <div className="add-user-form-label" style={{ textTransform: "none", margin: '20px 0px 10px 0px' }}>Select from existing Plans</div>
                                            <select
                                                name="plan_id"
                                                onChange={onChangeListenerplan}
                                                // value={plan_id}
                                                className="add-user-form-text textbox-shadow"
                                            // className="sub-add-user-form-text"
                                            >
                                                <option value={'select'}>Select plan</option>
                                                {
                                                    allPlans ?
                                                        allPlans.map((plan, key) => {
                                                            return <option value={key}>{plan.plan_name}</option>
                                                        })
                                                        : null}
                                            </select>
                                        </> : <></> : <></>}
                                    {errorMessage ? <ErrorMessageComponent /> : <></>}
                                    {loader ? <LoaderComponent /> : <></>}
                                    <div style={nextButton}>
                                        <ButtonWithIcon
                                            text="Next"
                                            isBtnActive
                                            onClick={() => savePlan()}
                                            width="84px"
                                        />
                                    </div>
                                </div>

                            </form>
                        </> : <></>}
                        {currentStep == 1 ? <>
                            <form onSubmit={saveImageSet}>
                                <div style={{
                                    textAlign: "initial",
                                    margin: "50px 0 0 15%"
                                }}>
                                    <div className="add-user-form-label">
                                        Imageset Name
                                    </div>
                                    <div className="add-user-form-text-wrapper">
                                        <input
                                            type="text"
                                            maxLength={AppConstants.IMAGESET_NAME_LIMIT}
                                            className="add-user-form-text textbox-shadow"
                                            // placeholder="Enter Project Name"
                                            onChange={imageSetNameHandler}
                                            name="plan_name"
                                            value={imageSetName}
                                        />
                                    </div>
                                    {errorMessage ? <ErrorMessageComponent /> : <></>}
                                    {loader ? <LoaderComponent /> : <></>}
                                    <div style={nextButton}>
                                        <ButtonWithIcon
                                            text="Next"
                                            isBtnActive
                                            onClick={() => saveImageSet()}
                                            width="84px"
                                        />
                                    </div>
                                </div>
                            </form>
                        </> : <></>}
                        {currentStep == 2 ?
                            <div style={{ display: "flex", margin: "50px 0 0 15%" }}>
                                <span style={{ marginRight: "10px", color: "#666666" }}> Click finish and start uploading images.</span>
                                <div style={nextButton}>
                                    <ButtonWithIcon
                                        text="Finish"
                                        isBtnActive
                                        width="84px"
                                        onClick={() => setRedirectToImages(true)} style={{ marginLeft: 'auto', outline: 'none' }}
                                    />
                                </div>
                            </div> : <></>}
                    </>}
            </StepperModal>
            {/*-------------------------------------------------------------------------------------------- */}
        </>
    )
}