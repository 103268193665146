import * as API from "../../api.js";
import React, { Component } from "react";

import { Modal } from "react-bootstrap";
import { Link, StaticRouter, Redirect } from "react-router-dom";
import Loader from "../../icons/loader.svg";
import BoxAnnotation from '../../icons/boxAnnotation.png';
import BoxAnnotationActive from '../../icons/boxAnnotationActive.png';
import PolygonToolActive from "../../../src/icons/polygonActive.png"
import PolygonToolInActive from "../../../src/icons/polygonInactive.png"
import circleToolActive from '../../../src/icons/CircleActive.png';
import circleToolInActive from "../../../src/icons/CircleWhite.png"
import cursorActive from '../../icons/cursorActive.png';
import cursorInactive from "../../icons/cursorWhite.png"
import SliderIcon from "../../assets/Icons/gallery.png";
import closeButton from '../../assets/Icons/cancel.png';
import { ActivityPopup } from '../../ReusableComponents/ActivityPopup.js';
import { addUserAnalytic, getLimitedName, skipTrainingTaskTour, updateOutTime, isTaskEditable, calculateCirclePoints } from "../../ReusableComponents/reusableFunctions.js";
import { v4 as uuid } from 'uuid';
import Annotation, { AUTOSEGMENTATIONTOOL, BOX, CIRCLE, LINE, POINT, POINTER, POLYGON } from './Annotation/index'
import locateWhiteIcon from "../../assets/Icons/locateMeWhite.png"
import Tiff from 'tiff.js'
import AppConstants, { BLOB_URL } from "../../AppConstants.js"
import ClassListWithColor from "./ReusableComp/ClassListWithColor"
import ReactJoyride, { BeaconRenderProps, TooltipRenderProps, ACTIONS, EVENTS, STATUS } from 'react-joyride';
import ImportExportImgRegion from "./ReusableComp/ImportExportImgRegion.js";
import ButtonWithIcon from "../../ReusableComponents/ButtonWithIcon.js";
import { PICSTORK_FEATURES } from "../../Teams/index.js";
import ReusableImageSlider from "../../ReusableComponents/ReusableImageSlider.js";
import AutoSegmentationTool from "../../assets/Icons/magic-wand.png"
import AutoSegmentationToolActive from "../../assets/Icons/magic-wand-active.png"
export default class ImagesAnnotation extends Component {
    constructor(props) {
        super(props);
        this.Annotator = React.createRef();
        this.state = {
            // toggleInspection:false,
            panning: true,
            user: {},
            type: "",
            key: " ",
            allImages: [],
            annotationSet: {},
            currentpage: 1,
            userperpage: 10,
            image: {},
            idx: 0,
            fileName: 'testing',
            redirect: false,
            displayNext: false,
            displayPrev: false,
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            emptyImage: false,
            outId: "",
            show: false,
            showPopup: false,
            objects: this.props.location.state.task.type || [],
            selectedObjects: [],
            fullScale: false,
            showSlider: true,
            imageLoad: true,
            annotations: [],
            annotation: {},
            dragImage: false,
            isOpen: true,
            itemName: "",
            showProcessPopup: false,
            processPopupIcon: "",
            processAction: "",
            processMessage: "",
            successParams: {
                title: "ADD USER",
                message1: "User has been added successfully",
                message2: "To go back to users, click",
                hereLink: "/admin/users",
                buttonText: "Add new user",
                buttonLink: "/admin/add_npnt_user",
            },
            sidebarMode: this.props.location.state
                ? this.props.location.state.sidebarMode
                : "shrunk",
            regions: undefined,
            progress: 0,
            loading: true,
            savingLoader: false,
            _progressInfos: [],
            toShowProgressOf: {},
            showErrorPopup: false,
            showSelectBox: false,
            imageURL: '',
            regionToCopy: null,
            classes: this.props.location.state.task.classes ? this.props.location.state.task.classes : [],
            showImageSlider: true,
            guideSteps: [
                {
                    disableBeacon: true,
                    target: '#semi-step1',
                    content: (<div style={AppConstants.TEXTSTYLING}><p>Start adding class labels which will be used for annotations</p></div>),
                    title: (<div style={AppConstants.TITLESTYLING}>Add Class Label</div>),
                    placement: 'left',
                    styles: {
                        options: {
                            width: 250,
                        },
                    },
                },
                {
                    disableBeacon: true,
                    target: '#tourStep',
                    content: (<div style={AppConstants.TEXTSTYLING}><p>Start annotations using drawing tool</p></div>),
                    title: (<div style={AppConstants.TITLESTYLING}>Start Annotations</div>),
                    placement: 'left',
                    styles: {
                        options: {
                            width: 250,
                        },
                    },
                },
                {
                    disableBeacon: true,
                    target: '#done-button',
                    content: (<div style={AppConstants.TEXTSTYLING}><p>Click here to save annotations</p></div>),
                    title: (<div style={AppConstants.TITLESTYLING}>Save Annotations</div>),
                    placement: 'bottom',
                    styles: {
                        options: {
                            width: 250,
                        },
                    },
                },
            ],
            joyrideTrigger: false,
        };
    }

    isAnnotationsEditable = () => {
        return (this.props.permissions?.[PICSTORK_FEATURES.ANNOTATIONS].EDIT && isTaskEditable(this.state.task.status, this.state.task.sharedBy))
    }

    loadTiffImageSrc = () => {
        let { image, user, task } = this.state
        var imageURL = ""
        if (image) {
            let name = image.file_name.toLowerCase()
            if ((name.indexOf(".tif")) == -1) {
                let iurl = `${BLOB_URL}/${this.props.permissions?.container}/FlightImages/collection-${image.collection_id}/${image.file_name}?${this.props.permissions?.st}`
                imageURL = iurl
                this.setState((state) => ({
                    ...state,
                    imageURL: imageURL,
                }))
            } else {
                // for image
                var xhr = new XMLHttpRequest();
                xhr.responseType = 'arraybuffer';
                let url = `${BLOB_URL}/${this.props.permissions?.container}/FlightImages/collection-${image.collection_id}/${image.file_name}?${this.props.permissions?.st}`
                xhr.open('GET', url);
                xhr.onload = function (e) {
                    var arraybuffer = e.target.response
                    Tiff.initialize({
                        TOTAL_MEMORY: 16777216 * 10
                    });
                    var tiff = new Tiff({
                        buffer: arraybuffer
                    })
                    var dataUrl = tiff.toDataURL();
                    let url = e.target.responseURL
                    imageURL = dataUrl   // to insert url at specific position
                    this.setState((state) => ({
                        ...state,
                        imageURL: imageURL,
                    }))
                }.bind(this);
                xhr.send()
            }
        }
    }

    // getImages1 = () => {
    //   const {
    //     user,
    //     type,
    //     currentpage,
    //     userperpage,
    //     key,
    //     counts,
    //     taskName,
    //   } = this.state;
    //   const lastIndex = currentpage * userperpage;
    //   const firstpage = lastIndex - userperpage;

    //   API.getAnnotationSetImages(
    //     this.props.location.state.annotationSet.image_collection_id,
    //     this.props.location.state.annotationSet.id,
    //     0,
    //     0,
    //   )
    //     .then((result) => {
    //       if (result.images) {
    //         result.images.forEach((image) => {
    //           if (image.annotation_status) {
    //             API.getAnnotation(image.file_name, this.props.location.state.annotationSet.image_collection_id, this.props.location.state.annotationSet.id).then((data) => {
    //               let lines = data.data ? data.data.annotationData : [];
    //               let reg = [];
    //               for (let i = 0; i < lines.length; i++) {
    //                 let str = lines[i];
    //                 let element = {};
    //                 let x = str.x_points[0];
    //                 let y = str.y_points[0];
    //                 let width = str.x_points[1] - str.x_points[0];
    //                 let height = str.y_points[1] - str.y_points[0];
    //                 let regularRegion = {};
    //                 regularRegion.data = { dataType: str.className };
    //                 element.data = regularRegion.data;

    //                 element.height = height / image.height * 100;
    //                 element.width = width / image.width * 100;

    //                 element.x = x / image.width * 100;
    //                 element.y = y / image.height * 100;
    //                 reg.push(element);
    //               }
    //               image.regions = reg
    //             })
    //           }
    //         })
    //       }
    //       let allImages = result.images
    //       // console.log("IMAGES : ", allImages)
    //       // if (this.props.location.state.image) {
    //       //   const { image } = this.props.location.state
    //       //   let idx = allImages.findIndex(img => img.id === image.id)
    //       //   this.setState(
    //       //     (state) => ({
    //       //       ...state,
    //       //       idx
    //       //     }))
    //       // }
    //       this.setState(
    //         (state) => ({
    //           ...state,
    //           allImages,
    //           loading: false
    //         }),
    //         () => {
    //         }
    //       );
    //     })
    //     .catch((e) => {
    //       this.setState((state) => ({
    //         ...state,
    //         loading: false
    //       }))
    //       console.log(JSON.stringify(e));
    //     });
    // };


    handleJoyrideCallback = data => {
        const { action, index, status, type } = data;
        if ([STATUS.FINISHED].includes(status)) {
            localStorage.setItem('annotationTour', 2)
        } else if ([STATUS.SKIPPED].includes(status)) {
            skipTrainingTaskTour()
        }
    };

    getImages = () => {
        const {
            user,
            type,
            currentpage,
            userperpage,
            key,
            counts,
            taskName,
        } = this.state;
        const lastIndex = currentpage * userperpage;
        const firstpage = lastIndex - userperpage;

        API.getAnnotationSetImages(
            this.props.location.state.annotationSet.image_collection_id,
            this.props.location.state.annotationSet.id,
            0,
            0,
        )
            .then((result) => {
                let validImagesForTraining = this.props.location.state.images && this.props.location.state.images.length > 0 ? this.props.location.state.images : result
                if (validImagesForTraining) {
                    validImagesForTraining.forEach((image) => {
                        if (image.annotation_status) {
                            API.getAnnotation(image.file_name, this.props.location.state.annotationSet.image_collection_id, this.props.location.state.annotationSet.id).then((data) => {
                                let regions = data.data.annotationData.map((region, key) => {
                                    if (region.shape === "rectangle") {
                                        const existingClass = this.state.classes.find(classs => classs.name === region.className)
                                        if (!existingClass) this.setState({
                                            classes: [...this.state.classes, {
                                                name: region.className,
                                                color: AppConstants.classColors[this.state.classes.length]
                                            }]
                                        })
                                        return {
                                            id: uuid(),
                                            className: region.className,
                                            x: (region.x_points[0] / image.width * 100) / 100,
                                            y: (region.y_points[0] / image.height * 100) / 100,
                                            h: ((region.y_points[1] - region.y_points[0]) / image.height * 100) / 100,
                                            w: ((region.x_points[1] - region.x_points[0]) / image.width * 100) / 100,
                                            type: "box",
                                            color: existingClass ? existingClass.color : AppConstants.classColors[key]
                                        }
                                    } else if (region.shape === "polygon") {

                                        const existingClass = this.state.classes.find(classs => classs.name === region.className)
                                        if (!existingClass) this.setState({
                                            classes: [...this.state.classes, {
                                                name: region.className,
                                                color: AppConstants.classColors[this.state.classes.length]
                                            }]
                                        })
                                        return region.type === 'circle' ? {
                                            id: uuid(),
                                            className: region.className,
                                            x: (region.x / image.width * 100) / 100,
                                            y: (region.y / image.height * 100) / 100,
                                            radius: (region.radius / image.width * 100) / 100,
                                            type: "circle",
                                            color: existingClass ? existingClass.color : AppConstants.classColors[key]
                                        } : {
                                            id: uuid(),
                                            className: region.className,
                                            points: region.points.map(p => [(p[0] / image.width * 100) / 100, (p[1] / image.height * 100) / 100]),
                                            type: "polygon",
                                            color: existingClass ? existingClass.color : AppConstants.classColors[key]
                                        }
                                    }
                                })
                                image.regions = regions
                                if (image.id == this.props.location.state.image.id) {
                                    this.setState(
                                        (state) => ({
                                            ...state,
                                            regions
                                        }))
                                }
                            })
                        } else {
                            if (image.id == this.props.location.state.image.id) {
                                this.setState(
                                    (state) => ({
                                        ...state,
                                        regions: []
                                    }))
                            }
                            image.regions = []
                        }
                    })
                }
                let allImages = validImagesForTraining
                if (this.props.location.state.image) {
                    const { image } = this.props.location.state
                    let idx = allImages.findIndex(img => img.id === image.id)
                    this.setState(
                        (state) => ({
                            ...state,
                            idx
                        }))
                }
                this.setState(
                    (state) => ({
                        ...state,
                        allImages,
                        loading: false
                    }), () => {
                        this.loadTiffImageSrc()
                        if (document.getElementById('container'))
                            document.getElementById('container').scrollTop = this.state.idx * 68;
                    });
            })
            .catch((e) => {
                this.setState((state) => ({
                    ...state,
                    loading: false
                }))
                console.log(JSON.stringify(e));
            });
    };

    exportFile = () => {
        let annotationObjs = {
            "fileName": this.state.image.file_name,
            "annotationSetId": this.props.location.state.annotationSet.id,
            "collectionId": this.props.location.state.annotationSet.image_collection_id,
        }

        API.exportAnnotation(annotationObjs).then(
            (message) => {
                this.setState((state) => ({
                    ...state,
                    //showPopup: false,
                    savingLoader: false
                }));
                // this.getImages1()
            },
            (e) => {
                console.log(e);
            }
        );
    }

    saveFile = () => {
        let annotationData = this.state.regions.map((region) => {
            if (region.type === "box") {
                return {
                    "className": region.className,
                    "shape": "rectangle",
                    "x_points": [
                        Math.round((region.x * 100) * this.state.image.width / 100),
                        Math.round(((region.x + region.w) * 100) * this.state.image.width / 100)
                    ],
                    "y_points": [
                        Math.round((region.y * 100) * this.state.image.height / 100),
                        Math.round(((region.y + region.h) * 100) * this.state.image.height / 100)
                    ]
                }
            } else if (region.type === "polygon") {
                return {
                    "className": region.className,
                    "shape": "polygon",
                    "points": region.points.map(p => [(p[0] * 100) * this.state.image.width / 100, (p[1] * 100) * this.state.image.height / 100])
                }
            } else if (region.type === "circle") {
                const x = Math.round((region.x * 100) * this.state.image.width / 100)
                const y = Math.round((region.y * 100) * this.state.image.height / 100)
                const radius = Math.round((region.radius * 100) * this.state.image.width / 100)
                return {
                    "className": region.className,
                    "shape": "polygon",
                    "type": "circle",
                    "x": x,
                    "y": y,
                    "radius": radius,
                    "points": (calculateCirclePoints(x, y, radius, 36))
                        .map(point => [
                            point[0] > Number(this.state.image.width) ? Number(this.state.image.width) : (point[0] < 0 ? 0 : Math.round(point[0])),
                            point[1] > Number(this.state.image.height) ? Number(this.state.image.height) : (point[1] < 0 ? 0 : Math.round(point[1]))
                        ])
                }
            }
        })
        let annotationObjs = {
            "fileName": this.state.image.file_name,
            "annotation_set_id": this.props.location.state.annotationSet.id,
            "image_collection_id": this.props.location.state.annotationSet.image_collection_id,
            "image_data": {
                "imageName": this.state.image.file_name,
                "height": this.state.image.height,
                "width": this.state.image.width,
                "annotationData": annotationData
            }
        };
        let _progressInfos = [{ percentage: 0, fileName: this.state.image.file_name }];
        // this.setState((state) => ({
        //   ...state,
        //   savingLoader: true
        // }));
        API.saveAnnotation(annotationObjs, (event) => {
            _progressInfos[0].percentage = Math.round(
                (100 * event.loaded) / event.total
            );
            this.setState({
                progress: Math.round((event.total / event.loaded) * 100),
                _progressInfos,
            });
        }).then(
            (message,) => {
                this.setState((state) => ({
                    ...state,
                    //showPopup: false,
                    savingLoader: false
                }));
                // this.getImages1()
            },
            (e) => {
                console.log(e);
            }
        );
    }
    clearAnnotations = () => {
        this.showSeverity()
        this.onCopyRegion()
        this.setState({
            regions: undefined, allImages: this.state.allImages.map((item) => {
                if (this.state.image.id === item.id) {
                    return {
                        ...item,
                        regions: []
                    }
                } else {
                    return item
                }
            })
        }, () => {
            this.setState({ regions: [], panning: true })
        }) //to refresh annotator 
        this.closeActivityPopup()
        API.clearAllAnnotations(this.props.location.state.annotationSet.image_collection_id, this.props.location.state.annotationSet.id, this.state.image.file_name).then(
            (e) => {
                console.log(e);
                this.props.updateTaskDetails(3)
            }
        );
    }

    fullScreen = () => {
        var element = document.querySelector("#container-full-screen");
        // make the element go to full-screen mode
        element.requestFullscreen()
            .then(function () {
                // element has entered fullscreen mode successfully
            })
            .catch(function (error) {
                // element could not enter fullscreen mode
                console.log(error);
            });
    }

    nextImage = () => {
        this.setState((state) => ({
            ...state,
            regions: [],
        }));
        let id = this.state.idx + 1
        this.setState((state) => ({
            ...state,
            idx: id,
            image: this.state.allImages[id],
            regions: this.state.allImages[id].regions,
            imageLoad: true
        }), () => {
            if (!this.state.regionToCopy)
                this.onCopyRegion()
            else
                this.onCopyRegion(this.state.regionToCopy)
            this.loadTiffImageSrc()
        });

        document.getElementById('container').scrollTop = id * 68;
    }

    updateAllImageList = () => {
        this.setState({
            allImages: this.state.allImages.map((item) => {
                if (this.state.image.id === item.id) {
                    return {
                        ...item,
                        regions: this.state.regions
                    }
                } else {
                    return item
                }
            })
        })
    }

    prevImage = () => {
        this.setState((state) => ({
            ...state,
            regions: [],
        }));
        let id = this.state.idx - 1
        this.setState((state) => ({
            ...state,
            idx: id,
            image: this.state.allImages[id],
            regions: this.state.allImages[id].regions,
            imageLoad: true
        }), () => {
            if (!this.state.regionToCopy)
                this.onCopyRegion()
            else
                this.onCopyRegion(this.state.regionToCopy)
            this.loadTiffImageSrc()
        });
        document.getElementById('container').scrollTop = id * 68;
    }


    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null
        }))
    }

    relocateImage = (e) => {
        // this is used to relocate image
        this.setState({
            emptyImage: true
        }, () => {
            this.setState({ emptyImage: false })
        })
    }

    showSeverity = (id) => {
        if (id) this.onCopyRegion();
        else this.Annotator.current.deSelectRegion()
        if (this.state.regions && this.state.regions.length > 0) {
            this.setState({
                selectedRegion: id ? this.state.selectedRegion : undefined,
                regions: this.state.regions.map((region) => {
                    if (id === region.id) {
                        return { ...region, showSeverity: true }
                    } else {
                        return { ...region, showSeverity: false, editable: false, highlight: false }
                    }
                })
            })
        }
    }

    togglePanning = async (value) => {
        this.setState({ panning: value === false ? false : (value === true ? true : !this.state.panning), selectedRegion: undefined })
        await this.onCopyRegion()
        this.showSeverity()
        this.Annotator.current.flushCopyingLayer()
    }

    editClassName = (oldName, newName) => {
        if (this.isAnnotationsEditable()) {
            let objects = this.state.task.type
            let currentObjectIndex = objects.findIndex(o => o == oldName)
            objects[currentObjectIndex] = newName
            let classes = this.state.classes
            let currentClassIndex = classes.findIndex(c => c.name == oldName)
            classes[currentClassIndex].name = newName

            this.setState({
                regions: this.state.regions.map((r) => {
                    if (r.className == oldName) {
                        r.className = newName
                    }
                    return r
                }),
                objects: objects,
                classes: classes,
                selectedRegion: this.state.selectedRegion && this.state.selectedRegion.className === oldName ? { ...this.state.selectedRegion, className: newName } : this.state.selectedRegion
            }, () => {
                API.updateTaskDetails(this.state.task.task_id, { classes }).then((result) => {
                    this.props.updateTaskDetails(3, result.task)
                    this.setState({ task: result.task })
                })
                this.saveFile()
            })
        }
    }

    deleteClass = (className) => {
        if (this.isAnnotationsEditable()) {
            this.setState({
                classDelete: true,
                itemName: "Class",
                processAction: "DELETE",
                processPopupIcon: "WARNING",
                processMessage: "",
                showProcessPopup: true,
                classToDelete: className
            })
        }
    }

    deleteClassData = () => {
        this.setState({
            objects: this.state.objects.filter((o) => o != this.state.classToDelete),
            classes: this.state.classes.filter((c) => c.name != this.state.classToDelete),
            regions: this.state.regions.filter((r) => r.className != this.state.classToDelete),
            selectedRegion: this.state.selectedRegion && this.state.selectedRegion.className === this.state.classToDelete ? "" : this.state.selectedRegion
        }, () => {
            API.updateTaskDetails(this.state.task.task_id, { classes: this.state.classes }).then((result) => {
                this.props.updateTaskDetails(3, result.task)
                this.setState({ task: result.task })
            })
            this.saveFile()
            this.setState({
                showProcessPopup: false,
                classToDelete: ""
            })
        })
    }

    randomColor = () => {
        let newColor = false
        do {
            let clr = "#" + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0').toUpperCase()
            if (!(this.state.classes.find(cls => cls.color === clr))) {
                newColor = clr
                return clr
            }
        }
        while (newColor)
    }

    addNewObject = (className) => {
        if (this.isAnnotationsEditable()) this.setState({
            classes: [...this.state.classes, {
                name: className,
                color: this.randomColor()
            }],
            objects: [...this.state.objects, className],
            newClassName: "",
            error: undefined
        }, () => {
            if (this.state.activeClass) this.Annotator.current.setDrawingRegion(className)
            API.updateTaskDetails(this.state.task.task_id, { classes: this.state.classes }).then((result) => {
                this.props.updateTaskDetails(3, result.task)
                this.setState({ task: result.task })
            })
        });
        else this.setError(`Cannot add new class as your task is ${this.state.task.status == AppConstants.TASK.STATUS.COMPLETED ? 'completed.' : 'in process.'}`)
    }

    onRegionAdded = (region) => {
        this.setState({
            selectedRegion: this.state.selectedRegion ? region : undefined,
            regions: [
                ...this.state.regions,
                region
            ]
        }, () => {
            this.updateAllImageList()
            this.saveFile()
        })
    }
    onCopyRegion = async (region) => {
        if (this.isAnnotationsEditable()) this.setState({
            regionToCopy: region,
            regions: this.state.regions.map((r) => region && (r.id == region.id) ?
                { ...r, color: AppConstants.COLORS.COPYANNOTATIONCOLOR, copy: true }
                : { ...r, color: this.state.classes.find(cls => cls.name === r.className).color, copy: false }
            )
        });
        else if (region) this.setError()

    }

    onClickRegion = (region) => {
        this.showSeverity(region.id);
        this.setState({
            selectedRegion: region
        })
        if (region.id && region.id !== this.state.clickedRegion?.id) {

            this.setState(state => {
                const index = state.regions.findIndex(reg => reg.id === region.id);
                const clickedRegion = state.regions[index]
                const updatedPolygons = [
                    clickedRegion,
                    ...state.regions.slice(0, index),
                    ...state.regions.slice(index + 1),
                ];

                return {
                    ...state, clickedRegion, regions: updatedPolygons
                }
            })
        }

    }
    onDeleteRegion = (id) => {

        if (this.isAnnotationsEditable()) this.setState({ selectedRegion: undefined, activeClass: undefined, regions: this.state.regions.filter(item => item.id !== id) }, () => {
            this.updateAllImageList()
            if (this.state.regions.length == 0)
                this.clearAnnotations()
            else {
                this.showSeverity()
                this.onCopyRegion()
                this.saveFile()
            }
        });
        else if (id) this.setError()
    }

    onClassUpdate = (id, updatedClass) => {
        this.setState({
            selectedRegion: { ...this.state.selectedRegion, className: updatedClass },
            regions: this.state.regions.map(item => {
                if (item.id === id) {
                    return { ...item, className: updatedClass, color: this.state.classes.find(cls => cls.name === updatedClass).color }
                } else {
                    return item
                }
            }),
        }, () => {
            this.updateAllImageList()
            this.saveFile()
        })
    }
    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: this.state.sidebarMode == "shrunk" ? "expanded" : "shrunk",
        }));
    };
    componentWillMount() {
        addUserAnalytic(this.props.location.pathname)
            .then(id => {
                this.setState((state) => ({
                    outId: id
                }))
            })

        let temp = localStorage.getItem('annotationTour')
        document.title = "Tasks - Annotation Tool";
        if (this.props.location.state) {
            let { user, type, image, project, plan, classes, task } = this.props.location.state;
            let tempObject = [{ value: classes[0] }]
            this.setState(
                (state) => ({
                    ...state,
                    user,
                    task,
                    type,
                    image,
                    project,
                    plan,
                    selectedObjects: tempObject,
                }),
                () => {

                    setTimeout(() => {
                        this.setState({ joyrideTrigger: temp == 1 ? true : false })
                    }, AppConstants.TRIGGER_DELAY)
                    this.getImages();
                }
            );
        } else {
            API.getUserType().then(
                ([blah, type, user]) => {
                    this.setState(
                        (state) => ({
                            ...state,
                            user,
                            type,
                            image: this.props.location.state.image,
                            regions: this.props.location.state.image.regions
                        }),
                        () => {
                            this.getImages();
                        }
                    );
                },
                (e) => {
                    window.location.replace("/login");
                }
            );
        }
    }
    onClickPointer = () => {
        this.setState({ panning: true })
        this.onCopyRegion()
    }
    close() {
        this.setState((state) => ({
            ...state,
            showPopup: false,
            showErrorPopup: false
            // idx: this.state.idx,
            // image:this.state.image
        }));
    }
    successRedirectComponent = () => {
        if (this.state.redirect) {
            return (
                <Redirect
                    to={{
                        pathname: "/success",
                        state: {
                            ...this.state.successParams,
                            user: this.state.user,
                            type: this.state.type,
                            sidebarMode: this.state.sidebarMode,
                        },
                    }}
                />
            );
        }
    };
    componentDidMount() {
        document.addEventListener("fullscreenchange", this.onChangeFullScreen, false)
    }

    componentWillUnmount() {
        updateOutTime(this.state.outId)
        document.removeEventListener("fullscreenchange", this.onChangeFullScreen, false)
    }
    onChangeFullScreen = (e) => {
        if (!document.fullscreenElement) {
            this.setState({ fullScale: false })
        }
    }
    onEditClassColor = async (className, color) => {
        this.setState({
            selectedRegion: this.state.selectedRegion ? (this.state.selectedRegion.className === className ? { ...this.state.selectedRegion, color } : this.state.selectedRegion) : undefined,
            classes: this.state.classes.map(cls => cls.name === className ? { ...cls, color } : cls),
            regions: this.state.regions.map(r => r.className === className ? { ...r, color } : r)
        }, () => {
            API.updateTaskDetails(this.state.task.task_id, { classes: this.state.classes }).then((result) => {
                this.props.updateTaskDetails(3, result.task)
                this.setState({ task: result.task })
            })
        })
    }
    // pass id for highlight one region only
    highlightClassRegions = (cls, id) => {
        // this.Annotator.current.deSelectRegion()
        this.setState({
            activeClass: cls,
            regions: this.state.regions.map(region => id && region.id === id ? { ...region, highlight: true } : (region.className === cls ? { ...region, highlight: true } : { ...region, highlight: false }))
        })
    }
    setError = (e, title, showErrorOnly) => {
        !showErrorOnly && this.showSeverity()
        this.setState((state) => ({
            ...state,
            showProcessPopup: true,
            processPopupIcon: "ERROR",
            title: title || "Modification restricted",
            processMessage: !this.props.permissions?.[PICSTORK_FEATURES.ANNOTATIONS].EDIT ? "You are not allowed to edit." : this.state.task.sharedBy?.isShared ? `Cannot modify existing annotations as your task is shared by ${this.state.task.sharedBy.sharedBy.first_name}` : (e || `Cannot modify existing annotations as your task is ${this.state.task.status == AppConstants.TASK.STATUS.COMPLETED ? 'completed' : 'in process'}.`)
        }))
    }

    setRegionEditable = (id) => {
        if (this.isAnnotationsEditable()) this.setState({ regions: this.state.regions.map(r => r.id === id ? { ...r, editable: true } : { ...r, editable: false }), selectedRegion: { ...this.state.selectedRegion, editable: id ? true : false } });
        else if (id) this.setError()
        if (id) {
            this.setState(state => {
                const index = state.regions.findIndex(reg => reg.id === id);
                const clickedRegion = state.regions[index]
                const updatedPolygons = [
                    ...state.regions.slice(0, index),
                    ...state.regions.slice(index + 1),
                    clickedRegion,
                ];

                return {
                    ...state, clickedRegion, regions: updatedPolygons
                }
            })
        }
    }

    onChangeAnnotation = (annotation) => {
        let { idx, allImages } = this.state
        let x_co = allImages[idx].regions[0].width
        let y_co = allImages[idx].regions[0].height
        let x1 = allImages[idx].regions[0].x
        let y1 = allImages[idx].regions[0].y
        annotation["x"] = x1 / 10
        annotation["y"] = y1 / 10
        annotation["h"] = y_co / 10
        annotation["w"] = x_co / 10
        this.setState({
            annotations: [
                {
                    ...annotation,
                    "type": "box",
                    "highlighted": false,
                    "editingLabels": true,
                    "color": "#2989cf",
                    "id": "1",
                }
            ],
            annotation: annotation
        })
    };

    importRegions = (regions, newClasses) => {
        if (this.isAnnotationsEditable()) {
            const newImages = this.state.allImages.map((image, i) => {
                if (i === this.state.idx) return { ...image, regions: [...image.regions, ...regions] }
                else return image
            })
            this.setState({
                classes: [...this.state.classes, ...newClasses],
                objects: [...this.state.objects, ...(newClasses.map(cls => cls.name))],
                regions: [...this.state.regions, ...regions],
                allImages: newImages
            }, () => {
                API.updateTaskDetails(this.state.task.task_id, { classes: this.state.classes }).then((result) => {
                    this.props.updateTaskDetails(3, result.task)
                    this.setState({ task: result.task })
                });
                this.saveFile();
            })
        } else {
            this.setError()
        }
    }
    changeTool = (tool) => {
        this.setState({ pointToolActive: false, lineToolActive: false, polygonToolActive: false, autoSegmentationToolActive: false, }, () => {

            switch (tool) {
                case POINTER:
                    this.togglePanning(true)
                    break;
                case BOX:
                    this.togglePanning(false)
                    break;
                case CIRCLE:
                    break;
                case AUTOSEGMENTATIONTOOL:
                    this.togglePanning(false)
                    this.setState({ autoSegmentationToolActive: true })
                    break;
                case POLYGON:
                    this.togglePanning(false);
                    this.setState({ polygonToolActive: true })
                    break;
                case POINT:
                    this.togglePanning(false)
                    this.setState({ pointToolActive: true })
                    break;
                case LINE:
                    this.togglePanning(false)
                    this.setState({ lineToolActive: true })
                    break;
                default:
                    break;
            }
        })
    }
    render() {
        const regionStyle = {
            background: "rgba(255, 0, 0, 0.3)",
        };
        let { user, type } = this.props.location.state;
        return (
            <React.Fragment>
                <Modal
                    show={this.state.showPopup}
                    onHide={() => this.close()}
                    centered
                >
                    <Modal.Body>
                        {
                            <div style={{ height: "150px", width: "150px", display: "flex", justifyItems: "center" }}>
                                <img src={Loader} style={{ height: "150px", width: "150px", margin: "auto" }} />
                            </div>
                        }
                    </Modal.Body>
                </Modal>


                {/* <Modal
          show={this.state.showClearPopup}
          onHide={() => this.close()}
          className="modal-dialog-centered"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div>Are you sure you want to clear?</div>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button
                className="addcustomer-button-empty-dashboard popup-btn-left btn-ok"
                onClick={() => {
                  this.setState((state) => ({
                    ...state,
                    showClearPopup: false,
                  }));
                }}
              >
                No
              </button>
            </div>
            <div>
              <button
                className="add-user-form-submit-button popup-btn-right btn-ok"
                onClick={() => {
                  this.setState((state) => ({
                    ...state,
                    showClearPopup: false,
                  }), () => {
                    this.clearAllAnnotations()

                  })
                }}
              >
                Yes
              </button>
            </div>
          </Modal.Footer>
        </Modal> */}

                {this.state.joyrideTrigger ?
                    <ReactJoyride
                        continuous={true}
                        run={true}
                        hideBackButton={true}
                        hideCloseButton={true}
                        showSkipButton={true}
                        steps={this.state.guideSteps}
                        disableOverlayClose={true}
                        floaterProps={AppConstants.FLOATER_PROPS}
                        locale={AppConstants.LOCALE_BUTTONS}
                        callback={(e) => this.handleJoyrideCallback(e)}
                        styles={{
                            buttonNext: AppConstants.NEXT_BUTTON,
                            buttonSkip: AppConstants.SKIP_BUTTON,
                            spotlight: AppConstants.SPOTLIGHT,
                            options: AppConstants.MAIN_OPTIONS
                        }}
                    />
                    : <></>}

                {/* -------------- Activity Popup ----------------------- */}
                <ActivityPopup
                    item={this.state.itemName}
                    open={this.state.showProcessPopup}
                    icon={this.state.processPopupIcon}
                    action={this.state.processAction}
                    title={this.state.title}
                    msg={this.state.processMessage}
                    close={() => this.closeActivityPopup()}
                    onClickOk={() => this.state.classDelete ? this.deleteClassData() : this.clearAnnotations()}
                />
                {/* --------------------------------------------------------------- */}
                {/*----------------- Show popup for assigning classes --------------------- */}
                {/* <Modal
          show={this.state.showErrorPopup}
          onHide={() => this.close()}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div>Please select object for all the annotations. </div>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button
                className="add-user-form-submit-button popup-btn-right btn-ok"
                onClick={() => {
                  this.setState((state) => ({
                    ...state,
                    showErrorPopup: false,
                  }))
                }}
              >
                Ok
              </button>
            </div>
          </Modal.Footer>
        </Modal> */}
                <div style={{
                    width: "100%",
                    height: "100%",
                    padding: "5px 10px"
                }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div
                            className="services-content-title"
                            style={{ color: "#3c3c3c" }}
                        >
                            <span style={{ color: "#2989cf" }}>{getLimitedName(this.state.image.file_name, 18)}</span>
                            <span
                                className="content-text"
                                style={{ color: "#3c3c3c", fontWeight: 500, marginLeft: "20px" }}
                            >
                                Annotations : <span style={{ color: "#2989cf", fontSize: "12px", marginRight: "20px" }}>{this.state.regions ? this.state.regions.length : 0}</span>
                            </span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ color: this.state.error ? "red" : "black", fontSize: "11px", display: 'flex', alignItems: "center" }}>
                                {this.state.error ? this.state.error : (this.isAnnotationsEditable() && this.state.polygonToolActive ? "(Use right click to save polygon)" : <></>)}
                            </div>
                            <ImportExportImgRegion
                                imageData={
                                    {
                                        imageSize: this.state.image.file_size,
                                        height: this.state.image.height,
                                        width: this.state.image.width,
                                        imageName: this.state.image.file_name
                                    }
                                }
                                regions={
                                    this.state.regions
                                }
                                classes={this.state.classes}
                                importRegions={this.importRegions}
                                importDisable={!(this.isAnnotationsEditable())}
                                exportDisable={!(this.props.permissions[PICSTORK_FEATURES.ANNOTATIONS].DOWNLOAD && this.state.regions && this.state.regions.length)}
                            />


                            <ButtonWithIcon
                                width="94px"
                                isDisabled={!this.isAnnotationsEditable()}
                                onClick={(event) => {
                                    if (this.isAnnotationsEditable()) this.setState((state) => ({
                                        ...state,
                                        itemName: "Clear Annotations",
                                        processPopupIcon: "WARNING",
                                        processAction: "DELETE",
                                        processMessage: 'Are you sure you want to clear all annotations ?',
                                        showProcessPopup: true
                                    }))
                                    else this.setError(`Cannot clear annotations as your task is ${this.state.task.status == AppConstants.TASK.STATUS.COMPLETED ? 'completed.' : 'in process.'}`)
                                }}
                                text="Clear"
                            />
                            <ButtonWithIcon
                                text="Done"
                                width="94px"
                                onClick={this.props.setAnnotationDone}
                            />

                        </div>
                    </div>
                    <div id='container-full-screen' style={{ display: "flex", justifyContent: 'space-between', marginTop: "8px", width: "100%", height: "94%", background: 'white', padding: '10px' }}>
                        {this.state.imageLoad ?
                            <img src={Loader} style={{ position: "absolute", zIndex: 5, height: "20%", width: "60%", marginTop: "15%" }} />
                            : ''
                        }
                        <div style={{ width: '80%', height: '100%', backgroundColor: "#3C3C3C" }} >
                            {this.state.regions && !this.state.emptyImage ? <><div style={{ display: 'flex', height: '100%' }}>
                                <Annotation
                                    ref={this.Annotator}
                                    disabledUpdate={!(this.isAnnotationsEditable())}
                                    setRegionEditable={this.setRegionEditable}
                                    setActiveClass={(cls) => this.setState({ activeClass: cls })}
                                    showSeverity={this.showSeverity}
                                    highlightClassRegions={this.highlightClassRegions}
                                    onCopyRegion={this.onCopyRegion}
                                    regionToCopy={this.state.regionToCopy}
                                    onClickRegion={this.onClickRegion}
                                    panning={this.state.panning}
                                    togglePanning={this.togglePanning}
                                    regionAdded={this.onRegionAdded}
                                    // onClassUpdate={this.onClassUpdate}
                                    classList={this.state.objects}
                                    onDeleteRegion={this.onDeleteRegion}
                                    onRecenter={this.relocateImage}
                                    regions={
                                        this.state.regions
                                    }
                                    removeRegion={(regionId) => this.setState({ regions: this.state.regions.filter(region => region.id !== regionId) })}
                                    classesWithColor={this.state.classes}
                                    imageSrc={this.state.emptyImage || this.state.imageURL}
                                    onImageOrVideoLoaded={(e) => this.setState({ imageLoad: false })}
                                    setError={this.setError}
                                    imgWidth={this.state.image.width}
                                    imgHeight={this.state.image.height}
                                    toggleInspection={this.state.toggleInspection}
                                    circleToolActive={this.state.circleToolActive}
                                    polygonToolActive={this.state.polygonToolActive}
                                    autoSegmentationToolActive={this.state.autoSegmentationToolActive}
                                    changeTool={this.changeTool}
                                    setActivityPopup={(activity) => {
                                        this.setState({ ...activity })
                                    }}
                                />

                                {this.state.allImages.length > 0 ?
                                    <div style={{ display: "flex", justifyContent: 'space-between', flexDirection: 'column', padding: "2px", alignItems: 'end' }}>
                                        <div style={{ display: "flex", height: '180px', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: "column", cursor: 'pointer', width: "35px", margin: '3px', borderRadius: "10px", background: 'rgba(0,0,0,0.5)' }}>



                                            {/* <img src={this.state.toggleInspection?cursorInactive: this.state.panning ? cursorActive : cursorInactive} style={{
                                                pointerEvents: 'all',
                                                width: this.state.panning ? '24px' : '23px',
                                            }}
                                                onClick={() => {this.togglePanning(true)
                                                this.setState({toggleInspection:false})
                                            }} />



                                            <img src={this.state.toggleInspection? InspectToolActive:InspectTool } style={{
                                                pointerEvents: 'all',
                                                width: this.state.panning ? '24px' : '23px',
                                            }}
                                                onClick={() => {
                                                    this.togglePanning(true)
                                                    this.setState({toggleInspection:true})
                                                }
                                            }
                                            />



                                            <img id="tourStep" src={this.state.toggleInspection?BoxAnnotation: this.state.panning? BoxAnnotation : BoxAnnotationActive} style={{
                                                pointerEvents: 'all',
                                                cursor: this.isAnnotationsEditable() ? "pointer" : "not-allowed",
                                                opacity: this.isAnnotationsEditable() ? "1" : "0.5",
                                                width: "18px",
                                            }}
                                                onClick={() => {
                                                    this.setState({toggleInspection:false})
                                                    if (this.isAnnotationsEditable()) this.togglePanning(false);
                                                    else this.setError();
                                                }} />



                                            <img src={locateWhiteIcon} style={{
                                                pointerEvents: 'all',
                                                width: "22px",
                                            }}
                                                onClick={()=>{this.relocateImage();
                                                    this.setState({toggleInspection:false})
                                            }} />
*/}
                                            {/* cursor */}
                                            <img src={this.state.polygonToolActive ? cursorInactive : this.state.panning ? cursorActive : cursorInactive} style={{
                                                pointerEvents: 'all',
                                                width: this.state.panning ? '23px' : '23px',
                                            }}
                                                onClick={() => {
                                                    this.setState({ pointToolActive: false, lineToolActive: false, polygonToolActive: false, circleToolActive: false, autoSegmentationToolActive: false })
                                                    this.togglePanning(true)
                                                }} />

                                            {/* box */}
                                            <img src={this.state.polygonToolActive || this.state.circleToolActive || this.state.autoSegmentationToolActive ? BoxAnnotation : this.state.panning ? BoxAnnotation : BoxAnnotationActive} style={{
                                                pointerEvents: 'all',
                                                cursor: this.isAnnotationsEditable() ? "pointer" : "not-allowed",
                                                opacity: this.isAnnotationsEditable() ? "1" : "0.5",
                                                width: "18px",
                                                marginBottom: "4px 0px"
                                            }}
                                                onClick={() => {
                                                    if (this.isAnnotationsEditable()) {
                                                        this.togglePanning(false);
                                                        this.setState({ polygonToolActive: false, circleToolActive: false, autoSegmentationToolActive: false })
                                                    }
                                                    else this.setError();
                                                }} />
                                            {/* polygon */}
                                            {<img src={this.state.polygonToolActive ? PolygonToolActive : PolygonToolInActive} style={{
                                                pointerEvents: "all",
                                                cursor: this.isAnnotationsEditable() ? "pointer" : "not-allowed",
                                                opacity: this.isAnnotationsEditable() ? "1" : "0.5",
                                                width: "20px",
                                                marginBottom: "4px 0px"
                                            }}
                                                onClick={() => {
                                                    if (this.isAnnotationsEditable()) {

                                                        this.togglePanning(false);
                                                        this.setState({ polygonToolActive: true, circleToolActive: false, autoSegmentationToolActive: false })
                                                    }
                                                    else this.setError();

                                                }} />}
                                            {/* auto segmentation tool */}
                                            {/* <img src={this.state.autoSegmentationToolActive ? AutoSegmentationToolActive : AutoSegmentationTool} style={{
                                                pointerEvents: "all",
                                                cursor: this.isAnnotationsEditable() ? "pointer" : "not-allowed",
                                                opacity: this.isAnnotationsEditable() ? "1" : "0.5",
                                                width: "20px",
                                                marginBottom: "4px 0px"
                                            }}
                                                onClick={() => {
                                                    if (this.isAnnotationsEditable()) {
                                                        this.togglePanning(false);
                                                        this.setState({ pointToolActive: false, lineToolActive: false, polygonToolActive: false, autoSegmentationToolActive: true })
                                                    } else this.setError();
                                                }} /> */}
                                            {/* circle */}
                                            {<img src={this.state.circleToolActive ? circleToolActive : circleToolInActive} style={{
                                                pointerEvents: "all",
                                                cursor: this.isAnnotationsEditable() ? "pointer" : "not-allowed",
                                                opacity: this.isAnnotationsEditable() ? "1" : "0.5",
                                                width: "20px",
                                                marginBottom: "4px 0px"
                                            }}
                                                onClick={() => {
                                                    if (this.isAnnotationsEditable()) {

                                                        this.togglePanning(false);
                                                        this.setState({ circleToolActive: true, polygonToolActive: false, autoSegmentationToolActive: false })
                                                    }
                                                    else this.setError();

                                                }} />}
                                            {/* recenter tool */}
                                            <img src={locateWhiteIcon} style={{
                                                pointerEvents: 'all',
                                                width: "22px",
                                            }}
                                                onClick={this.relocateImage} />
                                        </div>

                                        <div id="myAnimation" className="slide" style={{ pointerEvents: "all", cursor: 'pointer', height: this.state.showImageSlider ? "152px" : "32px", zIndex: 2 }} >
                                            <img src={this.state.showImageSlider ? closeButton : SliderIcon} style={{
                                                width: "30px",
                                                marginRight: "3px",
                                                borderRadius: "30px",
                                                backgroundColor: 'rgba(0,0,0,0.8)',
                                            }}
                                                onClick={() => {
                                                    this.setState((state) => ({
                                                        ...state,
                                                        showImageSlider: !this.state.showImageSlider
                                                    }
                                                    ), () => {
                                                        // if (this.state.showImageSlider)
                                                        //     document.getElementById('container').scrollLeft += (this.state.selected * 150);
                                                    })
                                                }
                                                } />
                                        </div>
                                    </div>
                                    :
                                    <></>}
                            </div></> : <></>}
                            {
                                this.state.regions && !this.state.emptyImage ?
                                    <>
                                        {this.state.showImageSlider ?
                                            <>
                                                <ReusableImageSlider
                                                    imageList={this.state.allImages}
                                                    permissions={this.props.permissions}
                                                    selectedImageIndex={this.state.idx}
                                                    onClickImage={(image, idx) => {
                                                        this.setState((state) => ({
                                                            ...state,
                                                            imageLoad: true,
                                                            idx: idx,
                                                            image: image,
                                                        }), () => {
                                                            if (!this.state.regionToCopy)
                                                                this.onCopyRegion()
                                                            else
                                                                this.onCopyRegion(this.state.regionToCopy)
                                                            this.loadTiffImageSrc();
                                                        });
                                                        this.setState((prevSate) => ({
                                                            ...prevSate,
                                                            regions: [],
                                                        }));
                                                        this.setState((state) => ({
                                                            ...state,
                                                            regions: image.regions
                                                        }));

                                                    }}
                                                    onClickNext={
                                                        () => {
                                                            if (this.state.idx != this.state.allImages.length - 1) this.nextImage()
                                                        }
                                                    }
                                                    onClickPrev={
                                                        () => { if (this.state.idx != 0) this.prevImage() }
                                                    }
                                                    requiredData={[this.props.location.state.annotationSet.image_collection_id,]}
                                                />
                                            </>
                                            :
                                            <></>
                                        }
                                    </>
                                    : <></>

                            }
                        </div>

                        <div style={{ display: "flex", width: '20%', height: '100%', justifyContent: 'space-between', cursor: 'pointer', right: '0%', pointerEvents: "none", zIndex: 2 }}>
                            {this.state.showSlider ?
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        zIndex: 10,
                                        pointerEvents: "all",
                                        height: '100%',
                                        width: "100%",
                                        bottom: '0px',
                                        color: '#3c3c3c'
                                    }}>

                                    {/* classes list */}
                                    <ClassListWithColor
                                        disabledUpdate={this.state.regionToCopy || !this.isAnnotationsEditable()}
                                        onEditClassColor={this.onEditClassColor}
                                        // onRegionUpdate={(uRegion)=>this.setState({regions:this.state.regions.map(region=>uRegion.id===region.id ? uRegion : region),selectedRegion:uRegion})}
                                        onClassUpdate={this.onClassUpdate}
                                        classListWithColor={this.state.classes}
                                        // onEditClass={(old, newClass) => this.editClassName(old, newClass)}
                                        // onDeleteClass={(className) => this.deleteClass(className)}
                                        addNewObject={this.addNewObject}
                                        onClickClass={(cls) => {
                                            if (this.state.panning) {
                                                this.highlightClassRegions(cls.name)
                                            } else {
                                                this.Annotator.current.setDrawingRegion(cls.name)
                                            }
                                        }}
                                        imageHeight={Number(this.state.image.height)}
                                        imageWidth={Number(this.state.image.width)}
                                        regions={this.state.regions}
                                        activeClass={this.state.activeClass}
                                        selectedRegion={this.state.selectedRegion}
                                        taskIsInCreating={() => { return this.isAnnotationsEditable() }}
                                        permissions={this.props.permissions}
                                    />
                                    {/* classesLisr */}
                                </div>
                                : ''}
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}