
import React, { useEffect, useState } from 'react';
import { AnimatedModal } from '../ReusableComponents/AnimatedModal';
import DropDownWithButton from '../ReusableComponents/DropDownWithButton';
import { ENTITIES, PLANNER_TASKS_TYPES, TYPES, PRIORITIES } from './Constants';
import { assignTaskToTM, getAllProjects, getAllTask, getLatestImageSets, getPlans } from '../api'
import AppConstants, { TASK } from '../AppConstants';
import ButtonWithIcon from '../ReusableComponents/ButtonWithIcon';
import { getActiveTeam, getActiveTeamActiveMembers, ROLES } from '.';
import Close from "../icons/blackClose.png"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as API from "../api"
import { getLimitedName } from '../ReusableComponents/reusableFunctions';
import { setHours, setMinutes } from 'date-fns';
const AssignTask = ({ assignedTask, closeTaskAssigner, date, setAssignedTasks, selectedProject, service }) => {

    const [task, setTask] = useState({
        title: "complete annotation",
        description: "complete annotation on each image."
    })
    const [activeTeam, setActiveTeam] = useState({})

    const [teamMembers, setTeamMembers] = useState([])
    const [selectedAssignee, setSelectedAssignee] = useState(assignedTask?.assignedTo ? { id: assignedTask.assignedTo.userid, name: `${assignedTask.assignedTo.first_name} ${assignedTask.assignedTo.last_name}` } : null)
    const [selectedPriority, setSelectedPriority] = useState(assignedTask?.priority || PRIORITIES.LOW)
    const [selectedDate, setSelectedDate] = useState(assignedTask?.due_date ? new Date(assignedTask.due_date) : (date ? new Date(date) : new Date()))
    const [projects, setProjects] = useState([])
    const [plans, setPlans] = useState([])
    const [tasks, setTasks] = useState([])
    const [description, setDescription] = useState(assignedTask?.description || "")
    const [imageCollections, setImageCollections] = useState([])
    const [isEverythingSelected, setIsEverythingSelected] = useState(false)
    const [selectedData, setSelectedData] = useState({
        plan: assignedTask?.plan ? {
            id: assignedTask.plan.id,
            name: assignedTask.plan.plan_name
        } : undefined,
        project: assignedTask?.project ? {
            id: assignedTask.project.id,
            name: assignedTask.project.project_name
        } : undefined,
        collection: assignedTask?.collection ? {
            id: assignedTask.collection.id,
            name: assignedTask.collection.collection_name
        } : undefined,
        task: assignedTask?.task ? {
            id: assignedTask.task.task_id,
            name: assignedTask.task.task_name
        } : undefined

    })

    const [selectedType, setSelectedType] = useState(assignedTask?.type ? PLANNER_TASKS_TYPES.find(t => t.name === assignedTask.type) : undefined)
    useEffect(() => {

        if (selectedPriority && selectedAssignee && description.length > 0) {
            setIsEverythingSelected(true)
        } else setIsEverythingSelected(false)
    }, [selectedData, selectedType, selectedPriority, selectedAssignee, selectedDate, description, selectedDate])
    useEffect(() => {
        initData()

    }, [])

    const initData = async () => {
        const AT = await getActiveTeam()
        setActiveTeam(AT)
        // getAllProjectsData()
        getPlansData()
        getImageCollectionData({ planId: assignedTask.plan_id, projectId: assignedTask.project_id })
        getTasksData({ planId: assignedTask.plan_id, projectId: assignedTask.project_id })
        getTeamMembers()
    }
    const getTeamMembers = () => {
        getActiveTeamActiveMembers().then(m => {
            setTeamMembers(m.map(tm => {
                return ({
                    name: tm.userFullName,
                    onClick: () => {
                        setSelectedAssignee({
                            name: tm.userFullName,
                            id: tm.id
                        })
                    }
                })
            }))
        })
            .catch(e => {
                console.log("something went wrong while getting team members.")
            })
    }

    // ---------------- User interActions Functions ------------ //
    const clearData = async () => {
        setPlans([])
        setProjects([])
        setImageCollections([])
        setTasks([])
        setSelectedData({})
        return
    }

    const [pageLoaded, setPageLoaded] = useState(false)

    useEffect(() => {
        if (pageLoaded) {
            onChangeTypeCallBack()
        } else {
            setPageLoaded(true)
        }

    }, [selectedType])

    const onChangeTypeCallBack = async () => {
        await clearData()
        getAllProjectsData()
    }

    useEffect(() => {
        if (selectedData?.project) {
            onChangeProjectListner()
        }
    }, [selectedData.project])


    const onChangeProjectListner = () => {
        getPlansData(selectedData.project.id)
        getTasksData({ projectId: selectedData.project.id })
        getImageCollectionData({ projectId: selectedData.project.id })
    }

    useEffect(() => {
        if (selectedData?.plan) {
            onChangePlanListner()
        }
    }, [selectedData.plan])

    const onChangePlanListner = () => {
        getTasksData({ projectId: selectedData.project.id, planId: selectedData.plan.id })
        getImageCollectionData({ planId: selectedData.plan.id })
    }


    const onChangeEntityData = async (entityType, data) => {
        if (entityType === ENTITIES.PROJECT) {
            setSelectedData({
                project: data
            })
        } else if (entityType === ENTITIES.PLAN) {
            setSelectedData({
                ...selectedData,
                plan: data,
                task: undefined,
                collection: undefined
            })
        } else if (entityType === ENTITIES.TASK) {
            setSelectedData({
                ...selectedData,
                task: data,
                collection: undefined
            })
        } else if (entityType === ENTITIES.IMAGESET) {
            setSelectedData({
                ...selectedData,
                collection: data
            })
        }
    }

    const onChangeType = (type) => {
        setSelectedType(type)
    }

    const getSelectedDataOfEntity = (entity) => {
        const SELECTDATA = `Select ${entity}`
        return (
            ENTITIES.PROJECT === entity ? selectedData.project?.name || SELECTDATA :
                (
                    ENTITIES.TASK === entity ? selectedData.task?.name || SELECTDATA :
                        (
                            ENTITIES.IMAGESET === entity ? selectedData.collection?.name || SELECTDATA :
                                (
                                    ENTITIES.PLAN === entity ? selectedData.plan?.name || SELECTDATA
                                        : SELECTDATA
                                )
                        )
                )
        )
    }

    const getEntityData = (entity) => {
        return (
            ENTITIES.PROJECT === entity ? projects :
                (
                    ENTITIES.TASK === entity ? tasks :
                        (
                            ENTITIES.IMAGESET === entity ? imageCollections :
                                (
                                    ENTITIES.PLAN === entity ? plans
                                        : []
                                )
                        )
                )
        )
    }

    const getEntityDataforDropdown = (entity) => {
        const data = getEntityData(entity)
        return data.map(data => {
            return ({
                name: data.name,
                onClick: () => onChangeEntityData(entity, data)
            })
        })
    }
    const getSelectEntityList = () => {
        return PLANNER_TASKS_TYPES.filter(t => service ? t.service === service : true).map(type => {
            return ({
                name: type.name,
                onClick: () => onChangeType(type)
            })
        })
    }

    const getPrioritiesList = () => {
        return [
            {
                name: PRIORITIES.LOW,
                onClick: () => setSelectedPriority(PRIORITIES.LOW)
            },
            {
                name: PRIORITIES.MEDIUM,
                onClick: () => setSelectedPriority(PRIORITIES.MEDIUM)
            },
            {
                name: PRIORITIES.HIGH,
                onClick: () => setSelectedPriority(PRIORITIES.HIGH)
            },
        ]
    }

    const getTaskDetails = async () => {
        return {
            plan_id: selectedData?.plan?.id,
            task_id: selectedData?.task?.id,
            project_id: selectedData?.project?.id,
            collection_id: selectedData?.collection?.id,
            description: description,
            due_date: selectedDate,
            assignee: selectedAssignee.id,
            team_id: activeTeam.teamId,
            type: selectedType?.name || "",
            priority: selectedPriority
        }
    }

    const updateTask = async () => {
        const taskToBeUpdate = {
            ...assignedTask,
            plan: undefined,
            project: undefined,
            task: undefined,
            collection: undefined,
            assignedBy: undefined,
            assignedTo: undefined,
            team: undefined,
            linkData: undefined,
            ...(await getTaskDetails())
        }
        API.updateAssignedTask(taskToBeUpdate).then(res => setAssignedTasks(prev => prev.map(task => {
            if (task.id === res.updatedTask.id) {
                return res.updatedTask
            } else return task
        })))
    }

    const deleteTask = async () => {
        API.deleteAssignedTask(assignedTask.id).then(res => setAssignedTasks(prev => prev.filter(task => task.id !== res.deletedTask.id)))
    }

    const assignTask = async () => {
        assignTaskToTM(await getTaskDetails()).then(data => {
            setAssignedTasks(prev => [...prev, data.addedTask])
        }).catch(e => {
            console.log("something went wrong")
        })
    }

    // ---------------- User interActions Functions End------------ //

    //------------------------get data functions end ----------------------------//

    const getAllProjectsData = async () => {
        if (!(selectedProject)) getAllProjects(" ", 0, 10000, undefined, activeTeam.isOwner ? false : ROLES.MANAGER, selectedType.service) //if user is not owner of team then fetch projects of active team which user has MANAGER ROLE.
            .then(async (data) => {
                setProjects(
                    data?.projects?.filter(p => !(p.sharedData?.isShared))
                        .map(p => {
                            return ({
                                id: p.id,
                                name: p.project_name
                            })
                        }) || [])
                //filtered shared Projects
            }, (e) => {
                console.log(e);
            })
        else if (selectedProject) {
            setProjects([{
                id: selectedProject.id,
                name: selectedProject.project_name
            }])
        }
    }


    const getPlansData = () => {
                if (selectedData?.project && selectedType?.requiredEntities?.includes(ENTITIES.PLAN)) {
            getPlans(selectedData.project.name, " ", 0, 1000, false, selectedData.project.id)
                .then(async (data) => {
                    setPlans(data?.plans?.map(p => {
                        return (
                            {
                                id: p.id,
                                name: p.plan_name
                            }
                        )
                    }) || [])
                }, (e) => {
                    console.log(e);
                })
        } else {
            setPlans([])
        }
    }

    // filter tasks basis on type
    const getFilteredTask = (data) => {
        if (data?.tasks) {
            const tasks = selectedType.name === TYPES.DNMAP ?
                data.tasks.filter(t => t.status === TASK.STATUS.COMPLETED) : // filtered DN completed task.
                (selectedType.name === TYPES.ANNOTATION ? data.tasks.filter(t => t.status === TASK.STATUS.CREATING && t.tasktype === 1) : // filtered tasks which is available for annotation in training.
                    data.tasks)

            return tasks.map(t => {
                return ({
                    id: t.task_id,
                    name: t.task_name
                })
            })
        }
        else return []
    }

    const getTasksData = ({ planId, projectId }) => {
        if (selectedType?.requiredEntities?.includes(ENTITIES.PLAN) ?
            (selectedData?.plan && selectedType?.requiredEntities?.includes(ENTITIES.TASK)) :
            selectedType?.requiredEntities?.includes(ENTITIES.TASK)
        ) {
            getAllTask(projectId, planId || 1, " ", 0, 100000, selectedType.service).then(async (data) => {
                try {
                    setTasks(getFilteredTask(data))
                } catch (e) {
                    console.log(e);
                }
            }, (e) => {
                //console.log(e);
            })
        } else {
            setTasks([])
        }
    }

    const getImageCollectionData = ({ planId, projectId }) => {
        if (selectedType?.requiredEntities?.includes(ENTITIES.PLAN) ?
            (selectedData?.plan && selectedType?.requiredEntities?.includes(ENTITIES.IMAGESET)) :
            selectedType?.requiredEntities?.includes(ENTITIES.IMAGESET)
        ) {
            getLatestImageSets(planId ? "plan" : "project", 1000000, planId || projectId, 0).then(async (data) => {
                try {
                    setImageCollections(data?.imageCollections ? data.imageCollections.map(IC => {
                        return ({
                            id: IC.id,
                            name: IC.collection_name
                        })
                    }) : [])
                } catch (e) {
                    console.log(e);
                }
            }, (e) => {
                //console.log(e);
            })
        } else {
            setImageCollections([])
        }
    }

    //------------------------get data functions end ----------------------------//

    return <>
        <AnimatedModal
            width="600px"
            height={assignTask ? "537px" : "520px"}
            overflow="hidden"
            isOpen={true}
            onRequestClose={closeTaskAssigner}
        >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <div style={{ backgroundColor: "white", outline: "1px solid #c6c6c6", padding: "10px", fontSize: "23px", color: "black", borderBottom: "1px solid #dbdbdb", textAlign: "center" }}>{assignedTask ? "Update Task" : "Assign Task"}
                    <div style={{ position: "absolute", right: "17px", top: "17px", width: "20px", height: "20px", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }} onClick={closeTaskAssigner}><img style={{ width: "100%", height: "100%" }} src={Close} /></div>
                </div>
                <div style={{ height: "100%", padding: "0px 44px", display: "flex", flexDirection: "column", paddingBottom: "20px", backgroundColor: "rgb(243 243 243)" }}>
                    <div style={{ padding: "10px", display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>


                            <div style={{ margin: "0px 10px", width: "100%" }}>
                                <div style={{ fontSize: "13px", textAlign: "left" }}>Date</div>
                                <div style={{ backgroundColor: "white", outline: "1px solid #c6c6c6", borderRadius: "5px", textAlign: "left", padding: "5px" }}>
                                    <DatePicker selected={selectedDate} minDate={new Date()} dateFormat="dd/MM/yyyy" onChange={(date) => setSelectedDate(new Date(date))} customInput={<input style={{ backgroundColor: "transparent", border: "none", width: "100%", outline: "none" }} />} />
                                </div>
                            </div>
                            <div style={{ margin: "0px 10px", width: "100%" }}>
                                <div style={{ fontSize: "13px", textAlign: "left" }}>Time</div>
                                <div style={{ backgroundColor: "white", outline: "1px solid #c6c6c6", borderRadius: "5px", textAlign: "left", padding: "5px" }}>
                                    <DatePicker selected={selectedDate}
                                        minTime={new Date(selectedDate).getDate() <= new Date().getDate() && new Date(selectedDate).getMonth() <= new Date().getMonth() && new Date(selectedDate).getFullYear() <= new Date().getFullYear() ? new Date() : ""}
                                        maxTime={new Date(selectedDate).getDate() <= new Date().getDate() && new Date(selectedDate).getMonth() <= new Date().getMonth() && new Date(selectedDate).getFullYear() <= new Date().getFullYear() ? setHours(setMinutes(new Date(), 59), 23) : ""}
                                        dateFormat="h:mm aa"
                                        onChange={(time) => {
                                            let minTime = new Date(selectedDate).getDate() <= new Date().getDate() && new Date(selectedDate).getMonth() <= new Date().getMonth() && new Date(selectedDate).getFullYear() <= new Date().getFullYear() ? new Date() : ""
                                            setSelectedDate((minTime > time) ? minTime : time)
                                        }}
                                        showTimeSelect showTimeSelectOnly
                                        customInput={<input style={{ backgroundColor: "transparent", border: "none", width: "100%", outline: "none" }} />}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{ margin: "15px 10px 0px 10px" }}>
                            <div style={{ fontSize: "13px", textAlign: "left" }}>Description</div>
                            <textarea style={{ backgroundColor: "white", outline: "1px solid #c6c6c6", borderRadius: "", height: "75px", textAlign: "left", padding: "8px", width: "100%", border: "none", resize: "none" }} value={description} onChange={e => setDescription(e.target.value)}></textarea>
                        </div>


                    </div>

                    <div style={{ padding: "0px 10px", display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                        <div style={{ display: "flex", flexDirection: "column", margin: "0px 10px" }}>
                            <span style={{ fontSize: "13px", textAlign: "left" }}>Prioirity</span>
                            <div style={{ backgroundColor: "white", outline: "1px solid #c6c6c6", borderRadius: "4px" }}><DropDownWithButton
                                textAlign={"left"}
                                width={"100%"}
                                list={getPrioritiesList()}
                                selectedItem={selectedPriority}
                            /></div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", margin: "0px 10px" }}>
                            <span style={{ fontSize: "13px", textAlign: "left" }}>Assignee</span>
                            <div style={{ backgroundColor: "white", outline: "1px solid #c6c6c6", borderRadius: "4px", }}> <DropDownWithButton
                                textAlign={"left"}
                                width={"100%"}
                                maxHeight={"20vh"}
                                selectedItem={getLimitedName(selectedAssignee?.name || "Select Assignee", 15)}
                                list={teamMembers}
                            />
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", margin: "15px 10px 0px 10px" }}>
                            <span style={{ fontSize: "13px", textAlign: "left" }}>Type</span>
                            <div style={{ backgroundColor: "white", outline: "1px solid #c6c6c6", borderRadius: "4px" }}>
                                <DropDownWithButton
                                    textAlign={"left"}
                                    width={"100%"}
                                    isDisabled={!(selectedAssignee)}
                                    selectedItem={getLimitedName(selectedType?.name || "Select Type", 15)}
                                    list={getSelectEntityList()}
                                />
                            </div>
                        </div>
                        {selectedType?.requiredEntities?.map((entity, key) => {
                            return <div style={{ display: "flex", flexDirection: "column", margin: "15px 10px 0px 10px" }}>
                                <span style={{ fontSize: "13px", textAlign: "left" }}>{entity}</span>
                                <div style={{ backgroundColor: "white", outline: "1px solid #c6c6c6", borderRadius: "4px" }}>
                                    <DropDownWithButton
                                        textAlign={"left"}
                                        width={"100%"}
                                        key={key}
                                        selectedItem={getLimitedName(getSelectedDataOfEntity(entity), 15)}
                                        maxHeight={"90px"}
                                        isDisabled={(getEntityData(entity)).length === 0}
                                        list={
                                            getEntityDataforDropdown(entity)
                                        }
                                    />
                                </div>
                            </div>
                        })}

                    </div>
                    <div style={{ marginTop: "auto", display: "flex", justifyContent: assignedTask ? "space-between" : "end", padding: "0px 15px" }}>
                        {assignedTask && <ButtonWithIcon
                            fontSize="15px"
                            text={"Delete"}
                            isBtnActive={false}
                            onClick={() => {
                                deleteTask()
                                closeTaskAssigner()
                            }}
                        />}
                        <ButtonWithIcon
                            fontSize="15px"
                            text={assignedTask ? "Update" : "Assign task"}
                            isBtnActive={true}
                            onClick={() => {
                                if (isEverythingSelected) {
                                    assignedTask ? updateTask() : assignTask()
                                    closeTaskAssigner()
                                }
                            }
                            }
                            isDisabled={!isEverythingSelected}
                        />
                    </div>
                </div>
            </div>
        </AnimatedModal>
    </>
}

export default AssignTask;