import { Link } from 'react-router-dom';
import React from 'react';
import { Component } from 'react';
import service from "./sidebaricon/Services.png";
import allServices from "./allServices"
import { AnimatedModal } from './ReusableComponents/AnimatedModal'
import reqServiceIcon from './assets/reqService.png'
import * as API from "./api.js";
import { getActiveTeam } from './Teams';
import { ActivityPopup } from './ReusableComponents/ActivityPopup.js';
import { Popup } from './ReusableComponents/reusableFunctions.js';


export default class ServicesTileTray extends Component {
    state = {
        tiles: [],
        type: '',
        inactiveTiles: [],
        requestServiceModal: false,
        reqMessage: "Thank you for your interest, we will activate selected service in your account at the earliest once requested.",
        reqSent: {
            0: false,
            1: false,
            2: false
        },
        reqSentMessage: "The confirmation email will be sent to your account",
        mainServices: "false"
        // sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk")
    }

    componentWillMount() {
        getActiveTeam().then(activeTeam => {
            this.setState({
                activeTeam: activeTeam
            }, () => {

                let { user, type, sidebarMode, tiles } = this.props;
                this.setState((state) => ({
                    ...state,
                    user,
                    type,
                    sidebarMode,
                    tiles
                }), () => {
                    const { tiles, mainServices } = this.props
                    if (mainServices == "true") {
                        let inactiveTiles = allServices.services.filter(({ title: id1 }) => !tiles.some(({ title: id2 }) => id2 === id1));
                        this.setState((state) => ({
                            ...state,
                            inactiveTiles,
                            mainServices
                        }))
                    }
                })
            })
        })

    }

    sendServiceRequest = (serviceTitle) => {
        API.request_service(serviceTitle).then(response => {
            let activeKey = this.state.key
            this.setState((state) => ({
                ...state,
                reqSent: { ...state.reqSent, [activeKey]: true }
            }))
        })
    }

    render() {
        let { user, type, sidebarMode, tiles, inactiveTiles, requestServiceModal, clickedInactiveService } = this.state;
        console.log("tiles:", tiles, "openRemovePopup:", this.state.openRemovePopup)
        return <>
            {clickedInactiveService ? <AnimatedModal
                isOpen={requestServiceModal}
                onRequestClose={() => {
                    this.setState((state) => ({
                        ...state,
                        requestServiceModal: false,
                    }))
                }}
                height="310px"
                width="550px"
            >
                <div style={{ display: "flex", height: "100%" }}>
                    <div style={{ width: "35%", backgroundColor: "#EAEAEA", display: "flex", flexDirection: "column", height: "100%", justifyContent: "center" }}>
                        <img src={reqServiceIcon} style={{ margin: "8%" }} />
                    </div>
                    <div style={{ width: "65%", display: "flex", flexDirection: "column", height: "100%" }}>

                        <div
                            style={{ textAlign: "center", margin: "0px 50px 0px 50px" }}
                        >
                            <div
                                style={{
                                    textAlign: "left",
                                    margin: "15% 0 12% 0",
                                    fontSize: "25px",
                                    fontWeight: "500",
                                    color: "#3C3C3C",
                                }}
                            >
                                {clickedInactiveService.title}
                                <div style={{
                                    textAlign: "left",
                                    fontSize: "12px",
                                    color: "#666666"
                                }}>{clickedInactiveService.description}</div>
                            </div>
                            <div
                                style={{
                                    textAlign: "left",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    color: "#666666",
                                    height: "45%"
                                }}
                            >
                                {this.state.reqSent[this.state.key] ? this.state.reqSentMessage : this.state.reqMessage}
                            </div>
                            {this.state.reqSent[this.state.key] ? <div style={{ display: "flex" }}>
                                <div style={{ textAlign: "left" }}>
                                    <button
                                        onClick={() => this.setState((state) => ({
                                            ...state,
                                            requestServiceModal: false
                                        }))}
                                        className="defaultButtonSuccess"
                                    >Ok</button>
                                </div>
                            </div> : <div style={{ display: "flex" }}>
                                <div style={{ textAlign: "left" }}>
                                    <button
                                        onClick={() => this.sendServiceRequest(clickedInactiveService.title)}
                                        className="defaultButtonSuccess"
                                    >Request</button>
                                </div>
                            </div>}
                        </div>


                    </div>
                </div>
            </AnimatedModal> : <></>}
            {this.state.mainServices == "true" ? <div style={{ display: "flex" }}>
                <div className="services-content-title">{"Subscribed Services"}</div>
            </div> : <></>}
            <div className="servicesCardstray" id="cardstray">
                {tiles.map((tile) => {
                    return (
                        tile.title === "AeroGCS Enterprise" ?
                            <div onClick={() => {
                                Popup.alert("CUSTOM", <div>AeroGCS Enterprise has been migrated to new home <a href='https://enterprise.aerogcs.com' target='_blank'>https://enterprise.aerogcs.com</a>, click ok to visit, you will have to login one more time</div>, () => {
                                    const redirectURL = 'https://enterprise.aerogcs.com';
                                    if (redirectURL) {
                                        window.open(redirectURL, '_blank');
                                    }
                                }, () => {}, "200px", "Cancel", "Ok",)
                            }} className="servicesCardscon" style={{ border: 'none', background: 'none' }}>
                                <div>
                                    <img className="servicesCardimage" src={tile.image} alt="imageIcon" />
                                    <div className="services-title">{tile.title}</div><br />
                                    <div className="servicesPara">{tile.description.length <= 50 ? tile.description : tile.description.substr(0, 47) + "..."}</div>
                                </div>
                            </div>
                            :
                            <Link to={{
                                pathname: tile.redirectURL || tile.redirecturl,
                                state: {
                                    user,
                                    type,
                                    tile,
                                    sidebarMode
                                }
                            }} className="servicesCardscon" style={{ border: 'none', background: 'none' }}>
                                <div>
                                    <img className="servicesCardimage" src={tile.image} alt="imageIcon" />
                                    <div className="services-title">{tile.title}</div><br />
                                    <div className="servicesPara">{tile.description.length <= 50 ? tile.description : tile.description.substr(0, 47) + "..."}</div>
                                </div>
                            </Link>
                    )
                })}
            </div>
            <div style={{ display: "flex" }}>
                {inactiveTiles.length > 0 ? <div className="services-content-title">{"Available Services"}</div> : <></>}
                {/* <div style={{marginLeft: "auto", width: "fit-content", height: "0"}}>
                                          <Link to={{ pathname: "/more_services", state: { user, type, sidebarMode: this.state.sidebarMode } }}>
                                              <div className="addcustomer-button-empty-dashboard" >More Services</div>
                                          </Link>
                                      </div> */}
            </div>
            {inactiveTiles.length > 0 ? <div className="servicesCardstray" id="cardstray">
                {inactiveTiles.map((inactiveTile, key) => {
                    return <div onClick={() =>
                        this.setState((state) => ({
                            ...state,
                            clickedInactiveService: inactiveTile,
                            requestServiceModal: true,
                            key: key
                        }))
                    }
                        className="servicesCardscon" style={{ border: 'none', background: 'none' }}>
                        <div>
                            <img className="servicesCardimage" src={inactiveTile.image} alt="imageIcon" />
                            <div className="services-title">{inactiveTile.title}</div><br />
                            <div className="servicesPara">{inactiveTile.description.length <= 50 ? inactiveTile.description : inactiveTile.description.substr(0, 47) + "..."}</div>
                        </div>
                    </div>
                })
                }
            </div> : <></>}
        </>
    }
}
