import React, { Component } from 'react';
import closeButton from './close-button.png';
import CustomersInactive from './customers.png';
import Users from './usersActive.png';
import UsersStats from './res/stats.png'
import closeIcon from "../src/icons/blackClose.png"
import editIcon from './edit.svg';
import { Modal } from "react-bootstrap";
import waiting from './res/waiting.png';
import success from './assets/check_circle_24px.png';
import fail from './assets/error.png';
import Popup from "reactjs-popup";
import { Link, Redirect } from 'react-router-dom';
import SideBar from './SideBar';
import * as API from './api.js';

import service from "./sidebaricon/Services.png";
import install from './icons/AeroGCSInactive.png'
import TaskIcon from './icons/servers.png'
import SupportIcon from './icons/support.png'
import logList from './icons/logList.png'
import detectors from './icons/detector.png'
import ButtonWithIcon from './ReusableComponents/ButtonWithIcon';
import { ActivityPopup } from './ReusableComponents/ActivityPopup';

const INVOICE_PAGE_SIZE = 5

export default class AdminUserDetails extends Component {
    state = {
        userCredits: [],
        service: {
            servs: [],
            allservs: []
        },
        npnt_config: {
            vm_ip: '-',
            vm_domain: '-'
        },
        showDeletePopup: false,
        npntConfig: false,
        services: [],
        allServices: [],
        serviceStatus: [],
        user: {},
        models: [],
        invoicesPageNumber: 1,
        invoices: [],
        type: "",
        user_info: {
            first_name: "",
            last_name: "",
            email: "",
            address: "",
            password: "",
            phone: "",
            userid: ""
        },
        successParams: {
            title: "DELETE USER",
            message1: "User has been deleted successfully",
            message2: "To go back to dashboard, click",
            hereLink: "/",
            buttonText: "View All Users",
            buttonLink: "/admin/users",
        },
        config: {},
        redirect: false,
        deleteRedirect: false,
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        confirmCancelSub: false,
        cancelSubscription: false,
        popupText: "",
        subId: '',
        subName: '',
        today: new Date().toISOString(),
        userAnalytic: false,
        userStorageDetails: undefined,
    }
    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode === "shrunk" ? "expanded" : "shrunk")
        }))
    }

    sendReminderEMail = (invoiceID) => {
        API.adminSendInvoiceReminder(invoiceID).then(res => {
            alert("Email sent to user")
        }).catch(e => {
            alert(e)
        })
    }

    getUsersInvoices = (cb) => {
        API.adminGetUsersInvoices(this.state.user_info.userid, INVOICE_PAGE_SIZE, this.state.invoicesPageNumber).then(res => {
            let { invoices, totalCount } = res
            this.setState({
                invoices,
                totalCount
            }, () => {
                cb(null)
            })
        }).catch(e => {
            cb("Failed to get your invoices. Try again later. Error: " + e)
        })
    }
    getUser = () => {
        API.getUser(this.state.user_info.userid, true).then(res => {
            this.setState({
                user_info: res.users[0],
            })
        }).catch(e => {
            console.log(e)
        })
    }

    componentWillMount() {
        if (this.props.location.state) {
            document.title = " Users Details - Admin";
            let { user, type, user_info } = this.props.location.state;

            this.setState((state) => ({
                ...state,
                user,
                type,
                user_info,
                config:
                {
                    options:
                        [
                            {
                                icon: service,
                                text: "Services",
                                path: "/"
                            },
                            {
                                icon: UsersStats,
                                text: "Statistics",
                                path: "/admin/users_stats",
                            },

                            {
                                icon: CustomersInactive,
                                text: "Add NPNT Customer",
                                path: "/admin/add_npnt_customer"
                            },

                            {
                                icon: Users,
                                text: "Users",
                                path: "/admin/users"
                            },
                            {
                                icon: install,
                                text: "Installations",
                                path: "/admin/gcs-installation",
                            },
                        ],
                    user,
                    type,
                    mode: this.state.sidebarMode
                },
            }), () => {
                this.getSubbedServices()
                this.getSubbedServices1()
                this.getUserCreditsDetails()
                this.getUserStorage();
                this.getUser();
                this.getUsersInvoices((e) => {
                    if (e) {
                        alert(e)
                    }
                })

            })
        } else {
            window.location.replace(process.env.REACT_APP_CLIENT_PROTOCOL + "://" + process.env.REACT_APP_CLIENT_HOST + (process.env.REACT_APP_CLIENT_PORT ? process.env.REACT_APP_CLIENT_PORT : "") + "/admin/dashboard");
            return;
        }
    }
    deleteUserButtonClickHandler = (event) => {
        event.preventDefault();
        if (window.confirm("This user will be deleted. Click \"OK\" to continue.")) {
            API.deleteUser(this.state.user_info).then((result) => {
                this.setState((state) => ({
                    ...state,
                    deleteRedirect: true
                }))
            })
        }
    }
    editUserButtonClickHandler = (event) => {
        event.preventDefault();
        this.setState((state) => ({
            ...state,
            redirect: true
        }))
    }
    editUserRedirect = () => {
        if (this.state.redirect) {
            let { user, type, user_info, sidebarMode } = this.state
            return <Redirect to={{
                pathname: "/admin/edituser",
                state: {
                    user,
                    type,
                    sidebarMode,
                    first_name: user_info.first_name,
                    last_name: user_info.last_name,
                    email: user_info.username,
                    phone: user_info.phone,
                    address: user_info.address,
                    userid: user_info.userid
                }
            }} />
        }
    }
    deleteUserRedirect = () => {
        if (this.state.deleteRedirect) {
            let { user, type, user_info, sidebarMode, config } = this.state
            return <Redirect to={{
                pathname: "/success",
                state: {
                    ...this.state.successParams,
                    user,
                    type,
                    user_info,
                    sidebarMode,
                    config
                }
            }} />
        }
    }

    cancelUserSubscription = () => {
        API.cancelUserSubscriptionPlan(this.state.subId).then(async (data) => {
            let res = await data.json()
            if (data.status == 200) {
                this.setState((state) => ({
                    ...state,
                    popupText: "Subscription Cancelled Successfully !",
                    createIcon: success
                }), () => {
                    this.getUser()
                })
            }
            else {
                this.setState((state) => ({
                    ...state,
                    popupText: res.message,
                    createIcon: fail
                }), () => {
                    this.getUser()
                })
            }
        })
    }

    getSubbedServices = () => {
        API.getUserSubbedServices(this.state.user_info.username).then((response) => {
            let serviceStatus = response[1]
            serviceStatus.forEach((service, i) => {
                if (response[0].find((element) => {
                    return element.id === service.id
                })) {
                    service.assigned = true;
                } else {
                    service.assigned = false;
                }
            });
            this.setState((state) => ({
                ...state,
                services: response[0],
                allServices: response[1],
                serviceStatus
            }), () => {
            })
        })
    }

    getSubbedServices1 = () => {
        API.getUserSubbedServices().then(
            (services) => {

                let names = services[0].map((service) => { return service.title })
                if (names.findIndex(data => data == "Analytic Tool") != -1) {
                    this.setState((state) => ({
                        ...state,
                        userAnalytic: true
                    }));
                }
            },
            (e) => {
                this.setState({
                    userAnalytic: false
                });
            }
        );
    };

    getUserCreditsDetails = () => {
        API.getUserCreditDetailsByAdmin(this.state.user_info.userid).then(userCreditDetails => {
            this.setState({ userCredits: userCreditDetails.credits })
        }).catch(err => console.log(err))
    }

    getUserStorage = async () => {
        await API.getUserStorage(this.state.user_info.userid).then(res => {
            this.setState({
                userStorageDetails: res
            })
        }).catch(err => {
            this.setState({
                showProcessPopup: true,
                itemName: "ERROR",
                processPopupIcon: 'ERROR',
                processMessage: `${err.error}`,
            })
        })
    }

    handleUserCreditLimitChange = (id, value) => {

        this.setState(state => {
            const newlimit = state.userCredits.map(credit => {
                if (credit.id === id) {
                    return { ...credit, credits_limit: value }
                } else return credit
            })
            return { ...state, userCredits: newlimit }

        })
    }

    handleEditUserCredit = (editType, id) => {
        this.setState(state => {
            const newCredits = state.userCredits.map(credit => {
                if (credit.id === id) {
                    if (editType === "usage") return { ...credit, editUsage: !credit.editUsage }
                    if (editType === "limit") return { ...credit, editLimit: !credit.editLimit }

                } else return credit
            })
            return { ...state, userCredits: newCredits }
        })
    }
    handleSaveCreditDetails = () => {
        API.updateUserCreditDetailsByAdmin(this.state.user_info.userid, { userCredits: this.state.userCredits }).then(result => {
            this.setState({ userCredits: this.state.userCredits.map(credit => ({ ...credit, editLimit: false, editUsage: false, })), processMessage: "Credits updated succesfully", showProcessPopup: true, processPopupIcon: "COMPLETE" })
        }).catch(err => {
            console.log(err)
            this.setState({ processMessage: "Error occured while updating credits", showProcessPopup: true, processPopupIcon: "ERROR" })
        })
    }
    closeActivityPopup = () => {
        this.setState({
            showProcessPopup: false,
            processMessage: "",
            processPopupIcon: ""
        })
    }
    render() {
        let { user, type, user_info, npnt_config } = this.state
        let sidebarConfigurations = {
            options: [
                {
                    icon: service,
                    text: "Services",
                    path: "/"
                },

                {
                    icon: UsersStats,
                    text: "Statistics",
                    path: "/admin/users_stats",
                },
                {
                    icon: CustomersInactive,
                    text: "Add NPNT Customer",
                    path: "/admin/add_npnt_customer"
                },
                {
                    icon: Users,
                    text: "Users",
                    path: "/admin/users",
                    selected: true,
                },
                {
                    icon: install,
                    text: "Installations",
                    path: "/admin/gcs-installation",
                },
                {
                    icon: TaskIcon,
                    text: "Tasks",
                    path: "/admin/tasks",
                },
                {
                    icon: logList,
                    text: "Task Logs",
                    path: "/admin/taskLogs",
                },
                {
                    icon: detectors,
                    text: "Detectors",
                    path: "/admin/detectors"
                },
                {
                    icon: SupportIcon,
                    text: "Support",
                    path: "/admin/supportEmails",
                },
            ],
            user,
            type,
            mode: this.state.sidebarMode
        }
        return (
            user && user.username ? <div className="wrapper">
                <ActivityPopup
                    item={this.state.itemName}
                    open={this.state.showProcessPopup}
                    icon={this.state.processPopupIcon}
                    action={this.state.processAction}
                    msg={this.state.processMessage}
                    close={() => this.closeActivityPopup()}
                    onClickOk={() => this.closeActivityPopup()}
                />
                <SideBar config={sidebarConfigurations} parentSidebarSwitch={this.parentSidebarSwitch} />

                <div className="right-content"
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >
                    {/* --------- Cancel subscription popup--------- */}
                    <Modal
                        show={this.state.confirmCancelSub}
                        onHide={() => {
                            this.setState((state) => ({
                                ...state,
                                updateSubPopup: false,

                            }));
                        }}
                        className="modal-dialog-centered"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Body >
                            <p> Are you sure you want to cancel this subscription for '{this.state.subName}' ? </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <div>
                                <button
                                    className="addcustomer-button-empty-dashboard popup-btn-left btn-ok"
                                    onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            confirmCancelSub: false,
                                        }));
                                    }}
                                >
                                    No
                                </button>
                            </div>
                            <div>
                                <button
                                    className="add-user-form-submit-button popup-btn-right btn-ok"
                                    onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            confirmCancelSub: false,
                                            createIcon: waiting,
                                            popupText: "Cancelling Subscription, Please Wait...",
                                            cancelSubscription: true
                                        }), () => {
                                            this.cancelUserSubscription()
                                        })
                                    }}
                                >
                                    Yes
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        // style={{ marginTop: '150px' }}
                        show={this.state.cancelSubscription}
                        onHide={() => {
                            this.setState((state) => ({
                                ...state,
                                cancelSubscription: false,
                            }));
                        }}
                        className="modal-dialog-centered"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Body >
                            <img
                                id="close-popup"
                                src={closeButton} style={{
                                    width: "auto",
                                    height: "26px",
                                    position: "absolute",
                                    top: "-10px",
                                    right: "-13px",
                                    cursor: "pointer"
                                }} onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        cancelSubscription: false,
                                    }))
                                }}></img>
                            <div >
                                <div style={{ width: "100%", display: "flex" }}>
                                    <img src={this.state.createIcon} style={{
                                        width: "auto",
                                        height: "64px",
                                        margin: "30px auto",
                                    }} />
                                </div>
                                <>
                                    <div style={{ width: "100%", textAlign: "center", marginBottom: '50px' }}>{
                                        this.state.popupText
                                    }</div>
                                </>
                            </div>
                        </Modal.Body>
                    </Modal>
                    {/* -------------------------------------------- */}
                    <Popup
                        trigger={<button id="send-plan-popup-button" style={{
                            display: "none",
                            // width:"30% !important"
                        }}></button>}
                        modal
                    >
                        <img
                            id="close-popup"
                            src={closeButton} style={{
                                width: "auto",
                                height: "26px",
                                position: "absolute",
                                top: "-13px",
                                right: "-13px",
                                cursor: "pointer"

                            }} onClick={() => {

                                document.getElementById("send-plan-popup-button").click();
                                this.setState((state) => ({
                                    ...state,
                                    cteateIcon: waiting,
                                    creatingAerostackText1: 'Deleting Configuration Please Wait...',
                                    creatingAerostackText2: ''
                                }))
                            }}></img>
                        <div >
                            <div style={{ width: "100%", display: "flex" }}>
                                <img src={this.state.cteateIcon} style={{
                                    width: "auto",
                                    height: "64px",
                                    margin: "30px auto"
                                }} />
                            </div>

                            <>
                                <div style={{ width: "100%", textAlign: "center" }}>{
                                    this.state.creatingAerostackText1
                                }</div>
                                <div style={{ width: "100%", textAlign: "center", marginBottom: "20px" }}>{
                                    this.state.creatingAerostackText2
                                }</div>
                            </>

                        </div>
                    </Popup>
                    <div className="top-bar"><div className="top-bar-text">USER DETAILS</div></div>
                    <div className="main-content">
                        <div className="page" style={{ padding: "0px 0px 20px 0px", height: "auto", minHeight: "815px" }}>
                            {this.editUserRedirect()}
                            {this.deleteUserRedirect()}

                            {this.state.userAnalytic ?

                                <div className="customer-details-top-section">
                                    <img className="customer-details-logo" style={{ borderRadius: "50%" }} src={`/images/${user_info.first_name[0].toLowerCase()}.PNG`} />
                                    <div className="services-content-title customer-details-company-name">{user_info.first_name + " " + user_info.last_name}</div>
                                    <div className="customer-details-actions-row" style={{ width: "fit-content", marginLeft: "auto", marginTop: "5px", marginRight: "40px" }}>
                                        <Link
                                            style={{ display: 'flex', marginRight: '16px' }}
                                            to={{
                                                pathname: "/admin/user_tracking_logs",
                                                state: {
                                                    user, type, user_info: user_info,
                                                    sidebarMode: this.state.sidebarMode,
                                                    serviceStatus: this.state.serviceStatus,
                                                    services: this.state.services
                                                }
                                            }}
                                        >
                                            <button style={{ padding: "0", outline: "none", border: "none", backgroundColor: "white" }}>
                                                <div className="addcustomer-button-empty-dashboard" style={{ width: "6rem", display: 'flex', justifyContent: 'center' }} >
                                                    Analytics
                                                </div>
                                            </button>
                                        </Link>
                                        <button style={{ padding: "0", outline: "none", border: "none", backgroundColor: "white" }} onClick={this.editUserButtonClickHandler}>
                                            <div className="addcustomer-button-empty-dashboard" style={{ width: "5rem" }} >
                                                Edit
                                            </div>
                                        </button>
                                        <button style={{ padding: "0", outline: "none", border: "none", marginLeft: "15px", backgroundColor: "white" }} onClick={this.deleteUserButtonClickHandler}>
                                            <div className="addcustomer-button-empty-dashboard" style={{ width: "5rem" }} >
                                                Delete
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                :

                                <div className="customer-details-top-section">
                                    <img className="customer-details-logo" style={{ borderRadius: "50%" }} src={`/images/${user_info.first_name[0].toLowerCase()}.PNG`} />
                                    <div className="services-content-title customer-details-company-name">{user_info.first_name + " " + user_info.last_name}</div>
                                    <div className="customer-details-actions-row" style={{ width: "fit-content", marginLeft: "auto", marginTop: "5px", marginRight: "40px" }}>
                                        {/* <Link
                                        style={{ display: 'flex', marginRight: '16px' }}
                                        to={{
                                            pathname: "/admin/user_tracking_logs",
                                            state: {
                                                user, type, user_info: user_info,
                                                sidebarMode: this.state.sidebarMode,
                                                serviceStatus: this.state.serviceStatus,
                                                services: this.state.services
                                            }
                                        }}
                                    >
                                        <button style={{ padding: "0", outline: "none", border: "none", backgroundColor: "white" }}>
                                            <div className="addcustomer-button-empty-dashboard" style={{ width: "6rem", display: 'flex', justifyContent: 'center' }} >
                                                Analytics
                                            </div>
                                        </button>
                                    </Link> */}
                                        <button style={{ padding: "0", outline: "none", border: "none", backgroundColor: "white" }} onClick={this.editUserButtonClickHandler}>
                                            <div className="addcustomer-button-empty-dashboard" style={{ width: "5rem" }} >
                                                Edit
                                            </div>
                                        </button>
                                        <button style={{ padding: "0", outline: "none", border: "none", marginLeft: "15px", backgroundColor: "white" }} onClick={this.deleteUserButtonClickHandler}>
                                            <div className="addcustomer-button-empty-dashboard" style={{ width: "5rem" }} >
                                                Delete
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            }

                            <div className="customer-details-middle-section">
                                <div className="customer-details-primary-row">
                                    <div className="customer-details-primary-row-left">
                                        <div className="customer-details-primary-row-left-title">First Name:</div>
                                        <div className="customer-details-primary-row-left-value">{`${user_info.first_name}`}</div>
                                    </div>
                                    <div className="customer-details-primary-row-right">
                                        <div className="customer-details-primary-row-left-title">Last Name:</div>
                                        <div className="customer-details-primary-row-left-value">
                                            <div>{`${user_info.last_name}`}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="customer-details-primary-row">
                                    <div className="customer-details-primary-row-left">
                                        <div className="customer-details-primary-row-left-title">Email:</div>
                                        <div className="customer-details-primary-row-left-value">{`${user_info.username}`}</div>
                                    </div>
                                    <div className="customer-details-primary-row-right">
                                        <div className="customer-details-primary-row-left-title">Phone Number:</div>
                                        <div className="customer-details-primary-row-left-value">
                                            <div>{`${user_info.phone}`}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="customer-details-primary-row">
                                    <div className="customer-details-primary-row-left">
                                        <div className="customer-details-primary-row-left-title">Drones:</div>
                                        <div className="customer-details-primary-row-left-value">{`${user_info.droneCount}`}</div>
                                    </div>
                                    <div className="customer-details-primary-row-right">
                                        <div className="customer-details-primary-row-left-title">Flights:</div>
                                        <div className="customer-details-primary-row-left-value">
                                            <div>{`${user_info.flightCount}`}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="customer-details-primary-row">
                                    <div className="customer-details-primary-row-left">
                                        <div className="customer-details-primary-row-left-title">Address:</div>
                                        <div className="customer-details-primary-row-left-value">{user_info.address}</div>
                                    </div>
                                </div>
                            </div>

                            {this.state.reloadThePageViaRedirect && <Redirect to={{
                                pathname: "/admin/userdetails",
                                state: {
                                    user: this.props.location.state.user,
                                    type: this.props.location.state.type,
                                    user_info: this.state.user_info,
                                    sidebarMode: this.state.sidebarMode
                                }
                            }} />}

                            <div className="customer-details-bottom-section">
                                <div style={{ marginBottom: "19px", display: "flex" }}>
                                    <div className="customer-details-primary-row-left-title" style={{ paddingTop: "10px" }}>User Subscriptions</div>
                                    <div style={{ marginLeft: "auto", width: "fit-content" }}>
                                        <Link to={{ pathname: "/admin/user_manage_plan", state: { user, type, user_info: user_info, sidebarMode: this.state.sidebarMode, serviceStatus: this.state.serviceStatus, services: this.state.services } }}>
                                            <div className="addcustomer-button-empty-dashboard" style={{ padding: "10px 23px 10px 23px", }}>Add</div>
                                        </Link>
                                    </div>
                                </div>
                                <table className="customers-table" >
                                    <tr className="customers-table-header-row" style={{ position: "inherit" }}>
                                        <th style={{
                                            width: "25%", position: "inherit"
                                        }}>Subscription Tier</th>
                                        <th style={{
                                            width: "20%", position: "inherit"
                                        }}>Service</th>
                                        <th style={{
                                            width: "15%", position: "inherit"
                                        }}>Status</th>
                                        <th style={{
                                            width: "25%", position: "inherit"
                                        }}>Expires on</th>
                                        <th style={{
                                            width: "15%", position: "inherit"
                                        }}>Actions</th>
                                    </tr>
                                    {
                                        user_info.subs && user_info.subs.length > 0 ? user_info.subs.map((sub) => (
                                            <tr className="customers-table-data-row">
                                                <td>{sub.plan_name}</td>
                                                <td>{sub.service}</td>
                                                <td>{sub.expiry_date > this.state.today ? <div style={{ color: "green" }}>Active</div> : <div style={{ color: "red" }}>Expired</div>}</td>
                                                <td>{sub.expiry_date ?
                                                    (() => {
                                                        let d = (new Date(sub.expiry_date)).toString().split(" GMT")[0]
                                                        d = d.substr(0, 3) + "," + d.substr(3, d.length)
                                                        let t = d.substr(d.length - 8, d.length)
                                                        let amPm = "am"
                                                        if (parseInt(t.split(':')[0]) > 12 || (parseInt(t.split(':')[0]) === 12 && parseInt(t.split(':')[1]) > 0)) {
                                                            amPm = "pm"
                                                        }
                                                        let h = parseInt(t.split(":")[0])
                                                        if (h > 12) {
                                                            h -= 12
                                                        }
                                                        t = h + ":" + t.split(":")[1] + " " + amPm
                                                        d = d.substr(0, d.length - 8) + t

                                                        return d
                                                    })() : "-"}</td>
                                                {sub.expiry_date > this.state.today ? <td>
                                                    <button className="addcustomer-button-empty-dashboard" style={{ padding: "10px 15px 10px 15px", color: "#f97272", borderColor: "#f97272" }}
                                                        onClick={() => {
                                                            this.setState((state) => ({
                                                                ...state,
                                                                confirmCancelSub: true,
                                                                subId: sub.id,
                                                                subName: sub.plan_name
                                                            }))
                                                        }}
                                                    >Cancel</button>
                                                </td> : <td align='center'> - </td>}
                                            </tr>
                                        )) : (
                                            <tr className="customers-table-data-row">
                                                <td colspan="5" style={{ textAlign: "center" }}>-- No Subscriptions --</td>
                                            </tr>
                                        )
                                    }
                                </table>


                                {/* User credits */}
                                <div style={{ marginBottom: "19px", display: "flex", alignItems: "center", marginTop: "40px" }}>
                                    <div className="customer-details-primary-row-left-title" style={{ paddingTop: "10px" }}>User Credits</div>
                                    <div style={{ marginLeft: "auto" }}><ButtonWithIcon text={"Save"} onClick={this.handleSaveCreditDetails} /></div>
                                </div>
                                <table className="customers-table" >
                                    <tr className="customers-table-header-row" style={{ position: "inherit" }}>
                                        {/* <th style={{
                                            width: "25%", position: "inherit"
                                        }}>Service</th> */}
                                        <th style={{
                                            width: "25%", position: "inherit"
                                        }}>Type</th>
                                        <th style={{
                                            width: "20%", position: "inherit"
                                        }}>Usage</th>
                                        <th style={{
                                            width: "15%", position: "inherit"
                                        }}>Limits</th>

                                    </tr>
                                    {
                                        this.state.userCredits.length > 0 ? this.state.userCredits.map((credit) => (
                                            <tr className="customers-table-data-row">
                                                {/* <td>{credit.type === "analytics" ? "Picstork" : "DroneNaksha"}</td> */}
                                                <td>{credit.type} </td>
                                                <td><div style={{ display: "flex", alignItems: "center" }}><div>{credit.credits_usage}</div> <div>{`  (${(Number(credit.credits_usage) / 1024).toFixed(2)} GB)`}</div></div></td>
                                                <td><div style={{ display: "flex", alignItems: "center" }}>{credit.editLimit ? <input style={{ width: "100px" }} value={credit.credits_limit} onChange={e => this.handleUserCreditLimitChange(credit.id, e.target.value)} /> : credit.credits_limit} <div>{`  (${(Number(credit.credits_limit) / 1024).toFixed(2)} GB)`}</div> <div>{<img src={credit.editLimit ? closeIcon : editIcon} style={{ cursor: "pointer", width: "15px" }} onClick={() => this.handleEditUserCredit("limit", credit.id)} />}</div></div></td>
                                            </tr>
                                        )) : (
                                            <tr className="customers-table-data-row">
                                                <td colspan="5" style={{ textAlign: "center" }}>-- No Credits --</td>
                                            </tr>
                                        )
                                    }
                                </table>

                                {/* User Storage */}
                                <div style={{ marginBottom: "19px", display: "flex", alignItems: "center", marginTop: "40px" }}>
                                    <div className="customer-details-primary-row-left-title" style={{ paddingTop: "10px" }}>User Storage</div>
                                    {/* <div style={{ marginLeft: "auto" }}><ButtonWithIcon text={"Save"} onClick={this.handleSaveCreditDetails} /></div> */}
                                </div>
                                <table className="customers-table" >
                                    <tr className="customers-table-header-row" style={{ position: "inherit" }}>
                                        {/* <th style={{
                                            width: "25%", position: "inherit"
                                        }}>Service</th> */}
                                        <th style={{
                                            width: "25%", position: "inherit"
                                        }}>Type</th>
                                        <th style={{
                                            width: "20%", position: "inherit"
                                        }}>Usage (GB)</th>
                                        <th style={{
                                            width: "15%", position: "inherit"
                                        }}>Limits (GB)</th>

                                    </tr>
                                    {
                                        this.state?.userStorageDetails ?
                                            this.state?.userStorageDetails?.storageDetails.map((sd) => (
                                                <tr className="customers-table-data-row">
                                                    <td>{sd.service} </td>
                                                    <td><div style={{ display: "flex", alignItems: "center" }}><div>{(Number(sd.used) / 1024).toFixed(2)}</div> </div></td>
                                                    <td><div style={{ display: "flex", alignItems: "center" }}><div>{(Number(sd.limit) / 1024).toFixed(2)}</div>  </div></td>
                                                </tr>
                                            )) :
                                            <tr className="customers-table-data-row">
                                                <td colspan="3" style={{ textAlign: "center" }}>--No Data Available--</td>
                                            </tr>
                                    }
                                </table>

                                {/* <div style={{ marginBottom: "19px", display: "flex" }}>
                                    <div className="customer-details-primary-row-left-title" style={{ marginTop: "40px" }}>NPNT Management Server Configuration</div>
                                    <div style={{marginLeft: "auto", width: "fit-content"}}>
                                        <Link to={{ pathname: "/admin/user_manage_plan", state: { user, type, user_info:user_info, sidebarMode: this.state.sidebarMode, serviceStatus: this.state.serviceStatus,services:this.state.services } }}>
                                            <div className="addcustomer-button-empty-dashboard" >Add</div>
                                        </Link>
                                    </div>
                                </div> */}
                                {/* <table className="customers-table">
                                    <tr className="customers-table-header-row" style={{ position: "inherit" }}>
                                        <th style={{
                                            width: "25%", position: "inherit"
                                        }}>VM IP</th>
                                        <th style={{
                                            width: "25%", position: "inherit"
                                        }}>Domain Name</th>
                                        <th style={{
                                            width: "40%", position: "inherit"
                                        }}>Actions</th>
                                    </tr>
                                    {

                                        <tr className="customers-table-data-row">
                                            <td>{npnt_config.vm_ip}</td>
                                            <td>{npnt_config.vm_domain}</td>
                                            <td>
                                                <div style={{ display: 'flex' }}>
                                                    {this.state.npntConfig == true ?
                                                        <a id='license-download-button' >Download License</a> : ''}
                                                    <Link to={{ pathname: "/admin/edit_npnt_configuration", state: { user, type, sidebarMode: this.state.sidebarMode, npnt_config, user_info } }}>
                                                        <img src={editIcon} style={{ height: '20px', width: '20px', marginLeft: '30px', marginRight: '30px' }}
                                                        />
                                                    </Link>
                                                    {this.state.npntConfig == true ?

                                                        <img src={deleteIcon} onClick={() => {
                                                            this.setState((state => ({
                                                                ...state,
                                                                showDeletePopup: true
                                                            })))
                                                        }} style={{ height: '20px', width: '20px', cursor: 'pointer' }} /> : ''}
                                                </div>
                                            </td>
                                        </tr>

                                    }
                                </table> */}


                                <div style={{ marginBottom: "19px", display: "flex" }}>
                                    <div className="customer-details-primary-row-left-title" style={{ marginTop: "40px" }}>User's Invoices</div>
                                </div>
                                <table className="customers-table">
                                    <tr className="customers-table-header-row" style={{ position: "inherit" }}>
                                        <th style={{ position: "inherit" }}>
                                            Invoice Number
                                        </th>
                                        <th style={{ position: "inherit" }}>
                                            Product
                                        </th>
                                        <th style={{ position: "inherit" }}>
                                            Price
                                        </th>
                                        <th style={{ position: "inherit" }}>
                                            Created Time
                                        </th>
                                        <th style={{ position: "inherit" }}>
                                            Status
                                        </th>
                                        <th style={{ position: "inherit" }}>
                                            Action
                                        </th>
                                    </tr>
                                    {this.state.invoices.length ? this.state.invoices.map((invoice, i) => <tr className="customers-table-data-row">
                                        <td>
                                            <Link to={{
                                                pathname: "/invoice/" + invoice.id,
                                                state: this.state
                                            }}>
                                                {invoice.id}
                                            </Link>
                                        </td>
                                        <td>
                                            {invoice.product}
                                        </td>
                                        <td>
                                            {"$"}{invoice.price - (invoice.price * invoice.discount / 100)}
                                        </td>
                                        <td>
                                            {
                                                (() => {
                                                    let d = new Date(invoice.created_time).toString().split(" GMT")[0]
                                                    d = d.substr(0, 3) + "," + d.substr(3, d.length)
                                                    let t = d.substr(d.length - 8, d.length)
                                                    let amPm = "am"
                                                    if (parseInt(t.split(':')[0]) > 12 || (parseInt(t.split(':')[0]) === 12 && parseInt(t.split(':')[1]) > 0)) {
                                                        amPm = "pm"
                                                    }
                                                    let h = parseInt(t.split(":")[0])
                                                    if (h > 12) {
                                                        h -= 12
                                                    }
                                                    t = h + ":" + t.split(":")[1] + " " + amPm
                                                    d = d.substr(0, d.length - 8) + t

                                                    return d
                                                })()
                                            }
                                        </td>
                                        <td>
                                            {invoice.payment.payment_status == "paid" ? <div style={{ color: "green" }}>Paid</div> : <div style={{ color: "red" }}>Pending</div>}
                                        </td>
                                        <td style={{ width: "190px" }}>
                                            {!invoice.payment.date_time ? <button className="addcustomer-button-empty-dashboard" onClick={() => {
                                                this.sendReminderEMail(invoice.id)
                                            }}>Remind</button> : <div>--</div>}
                                        </td>
                                    </tr>) : <tr className="customers-table-data-row">
                                        <td colspan="6" style={{ textAlign: "center" }}>--No invoices--</td>
                                    </tr>}
                                </table>
                                <div id="invoice-table-actions">
                                    {this.state.invoices.length > 5 ? <div id="invoice-table-actions-content">
                                        <button className="addcustomer-button-empty-dashboard" onClick={() => {
                                            this.setState({ invoicesPageNumber: 1 }, () => {
                                                this.getUsersInvoices((e) => {
                                                    if (e)
                                                        alert(e)
                                                })
                                            })
                                        }}>
                                            First
                                        </button>
                                        <button className="addcustomer-button-empty-dashboard" onClick={() => {
                                            this.setState((prevState) => ({
                                                ...prevState,
                                                invoicesPageNumber: Math.max(prevState.invoicesPageNumber - 1, 1)
                                            }), () => {
                                                this.getUsersInvoices((e) => {
                                                    if (e)
                                                        alert(e)
                                                })
                                            })
                                        }}>
                                            Prev
                                        </button>
                                        <span id="invoices-page-number">{this.state.invoicesPageNumber}</span>
                                        <button className="addcustomer-button-empty-dashboard" onClick={() => {
                                            this.setState((prevState) => ({
                                                ...prevState,
                                                invoicesPageNumber: Math.min(prevState.invoicesPageNumber + 1, Math.ceil(this.state.totalCount / INVOICE_PAGE_SIZE))
                                            }), () => {
                                                this.getUsersInvoices((e) => {
                                                    if (e)
                                                        alert(e)
                                                })
                                            })
                                        }}>
                                            Next
                                        </button>
                                        <button className="addcustomer-button-empty-dashboard" onClick={() => {
                                            this.setState((prevState) => ({
                                                ...prevState,
                                                invoicesPageNumber: Math.ceil(this.state.totalCount / INVOICE_PAGE_SIZE)
                                            }), () => {
                                                this.getUsersInvoices((e) => {
                                                    if (e)
                                                        alert(e)
                                                })
                                            })
                                        }}>
                                            Last
                                        </button>
                                    </div> : <div />}
                                </div>

                                <div style={{ marginBottom: "19px", marginTop: "80px", display: "flex" }}>
                                    <div className="customer-details-primary-row-left-title" style={{ paddingTop: "10px" }}>Services Assigned</div>
                                    <div style={{ marginLeft: "auto", width: "fit-content" }}>
                                        <Link to={{ pathname: "/admin/assign_services", state: { user, type, user_info: user_info, sidebarMode: this.state.sidebarMode, serviceStatus: this.state.serviceStatus, services: this.state.services } }}>
                                            <div className="addcustomer-button-empty-dashboard" >Assign Services</div>
                                        </Link>
                                    </div>
                                </div>
                                <table className="customers-table">
                                    <tr className="customers-table-header-row" style={{ position: "inherit" }}>
                                        <th style={{ position: "inherit" }}>Service Name</th>
                                        <th style={{ position: "inherit" }}>Subscribed</th>
                                    </tr>
                                    {
                                        this.state.serviceStatus.filter(service => (!(this.state.user_info.role_id == '8b28f377-c02f-4f3c-8bd7-f4794c066546') || !(service.title == "NPNT Services")) && (!(this.state.user_info.organization == "31e5a4fd-150f-4600-a233-c1ed88bb59b3") || !(service.title == "NPNT Services"))).map((service) => (
                                            <tr className="customers-table-data-row">
                                                <td>{service.title}</td>
                                                <td>{service.assigned ? "yes" : "no"}</td>
                                            </tr>
                                        ))
                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                </div >
            </div > : <div />
        );
    }
}
