import React from 'react';
import styled from "styled-components/macro";
import * as API from '../../../api'
import Loader from './assets/loader.svg'
import * as THREE from 'three'
import { OBJLoader } from './loaders/OBJLoader.js'
import { MTLLoader } from './loaders/MTLLoader.js'
import { DRONENAKSHA_FEATURES, getFeaturesPermissions } from '../../../Teams';
import { checkURLExist } from '../../../ReusableComponents/reusableFunctions';
import { ReactCompareSlider, ReactCompareSliderHandle } from 'react-compare-slider';
import dateFormat from 'dateformat'
import Prev from '../../../icons/back1.png';
import Next from '../../../icons/next1.png';
import compareSlider from '../../../icons/compareSlider.png'
import { ClipTask, ClipTaskIcons } from './Constants';
import Tip from '../../../ReusableComponents/Tip';
import { BLOB_URL } from '../../../AppConstants.js';


const POLYLINE = "Polyline"
const POLYGON = "Polygon"
const MARKER = "Marker"
const VOLUMEBOX = "VolumeBox"
const hoveredShapeColor = "#FFFFFF"

var utmObj = require('utm-latlng');
var utm = new utmObj();
//components end
export default class PotreeViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            measurements: [],
            loading: true,
            user: {},
            taskId: '',
            areaCode: this.props.utmDetails?.ZoneLetter,
            areaNumber: this.props.utmDetails?.ZoneNumber,
            cursor: "default",
            drawPolyline: false,
            drawPolygon: false,
            showPointer: true,
            drawMarker: false,
            loadingMeasurements: true,
            modelAvailableData: [],
            selectedModel: '',
            selectedModel1: '',
            activeModel: '',
            activeModel1: '',
            selectedClipTask: "Highlight",
            drawnClipVolumes: [],
            onHandleEnter: false,
            onHandleDown: false,
            showTip: false,
        };
        this.potreeContainerDiv = React.createRef();
        this.potreeContainerDiv1 = React.createRef();
    }

    loadGeoreferencingOffset = async(cb) => {
        const geoFile = `./tempfiles/odm_georeferencing/coords.txt`;
        const legacyGeoFile = `${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${this.props.compare3DMode ? this.state.selectedModel.task_id : this.taskId}/threeD/georeferencing_model_geo.txt?${this.state.permissions.st}`;
        if (await checkURLExist(legacyGeoFile)) {
            const getGeoOffsetFromUrl = (url) => {
                this.$.ajax({
                    url: url,
                    type: 'GET',
                    error: () => {
                        console.warn(`Cannot find ${url} (not georeferenced?)`);
                        cb({ x: 0, y: 0 });
                    },
                    success: (data) => {
                        const lines = data.split("\n");
                        if (lines.length >= 2) {
                            const [x, y] = lines[1].split(" ").map(parseFloat);
                            cb({ x, y });
                        } else {
                            console.warn(`Malformed georeferencing file: ${data}`);
                            cb({ x: 0, y: 0 });
                        }
                    }
                });
            };

            this.$.ajax({
                type: "HEAD",
                url: legacyGeoFile
            }).done(() => {
                getGeoOffsetFromUrl(legacyGeoFile);
            }).fail(() => {
                getGeoOffsetFromUrl(geoFile);
            });
        } else {
            cb({ x: 0, y: 0 })
        }

    }

    loadGeoreferencingOffset1 = async(cb) => {
        const geoFile = `./tempfiles/odm_georeferencing/coords.txt`;
        const legacyGeoFile = `${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${this.state.selectedModel1.task_id}/threeD/georeferencing_model_geo.txt?${this.state.permissions.st}`;
        if (await checkURLExist(legacyGeoFile)) {
            const getGeoOffsetFromUrl = (url) => {
                this.$.ajax({
                    url: url,
                    type: 'GET',
                    error: () => {
                        console.warn(`Cannot find ${url} (not georeferenced?)`);
                        cb({ x: 0, y: 0 });
                    },
                    success: (data) => {
                        const lines = data.split("\n");
                        if (lines.length >= 2) {
                            const [x, y] = lines[1].split(" ").map(parseFloat);
                            cb({ x, y });
                        } else {
                            console.warn(`Malformed georeferencing file: ${data}`);
                            cb({ x: 0, y: 0 });
                        }
                    }
                });
            };

            this.$.ajax({
                type: "HEAD",
                url: legacyGeoFile
            }).done(() => {
                getGeoOffsetFromUrl(legacyGeoFile);
            }).fail(() => {
                getGeoOffsetFromUrl(geoFile);
            });
        } else {
            cb({ x: 0, y: 0 })
        }

    }

    indicateMarkerInScene = (id) => {
        this.state.measurements.map(m => {
            if (m.id === id) m.drawnMeasurement.selectedMarker = true
            else m.drawnMeasurement.selectedMarker = false
        })
    }

    getThreedMeasurements = async (coordinates, activeMeasurement) => {
        return await Promise.all(coordinates.map(async (coord, key) => {
            return ({ lat: coord.lat, lng: coord.lng, x: activeMeasurement.points[key].position.x, y: activeMeasurement.points[key].position.y, elevation: activeMeasurement.points[key].position.z })
        }))
    }

    saveMeasurement = (measurement) => {
        const activeMeasurement = this.activeMeasurement
        return new Promise(async (resolve, reject) => {
            const coordinates = measurement.type === MARKER ?
                { lat: measurement.coordinates.lat, lng: measurement.coordinates.lng, x: activeMeasurement.points[0].position.x, y: activeMeasurement.points[0].position.y, elevation: activeMeasurement.points[0].position.z }
                : await this.getThreedMeasurements(measurement.coordinates, activeMeasurement)
            this.props.drawIn2dAndSave({ ...measurement, coordinates, is3d: true })
                .then(async (savedMeasurement) => {
                    let newMeasurement = {
                        ...savedMeasurement,
                        coordinates,
                        show: true
                    }
                    this.drawMeasurements([newMeasurement])
                    activeMeasurement.visible = false
                })
            resolve()
        })

    }

    activePointer = () => {
        this.activeMeasurement = undefined
        this.setState({
            cursor: "default",
            drawMarker: false,
            drawPolyline: false,
            drawPolygon: false,
            showPointer: true
        })
    }

    startDrawing = async (type) => {
        if (this.activeMeasurement) {
            this.activeMeasurement.showSphere = false
            if (this.activeMeasurement.name !== type) this.activeMeasurement.visible = false;
        }
        if (this.state.selectedMeasurement) this.setMeasurementEditible(this.state.selectedMeasurement.id, false)
        this.viewer.scene.removeEventListeners("stop_inserting_measurement")
        this.setState({
            cursor: "crosshair",
            drawMarker: type === MARKER ? true : false,
            drawPolyline: type === POLYLINE ? true : false,
            drawPolygon: type === POLYGON ? true : false,
            showPointer: false
        })
        if (type === VOLUMEBOX) {
            this.drawVolumeBox()
        }
        else {
            this.activeMeasurement = await this.viewer.measuringTool.startInsertion({
                showDistances: true,
                showAngles: false,
                showCoordinates: MARKER ? true : false,
                showArea: type === POLYGON ? true : false,
                closed: type === POLYGON ? true : false,
                maxMarkers: type === MARKER ? 1 : undefined,
                name: type,
                unit: this.props.unit
            });
            if (type === MARKER) {
                this.activeMeasurement.addEventListener("marker_dropped", (e) => {
                    console.log(utm.convertUtmToLatLng(e.measurement.points[0].position.x, e.measurement.points[0].position.y, this.state.areaNumber, this.state.areaCode), "A")
                    this.saveMeasurement({
                        name: type,
                        type: type,
                        coordinates: utm.convertUtmToLatLng(e.measurement.points[0].position.x, e.measurement.points[0].position.y, this.state.areaNumber, this.state.areaCode),
                        description: null,
                        taskId: this.taskId,
                        color: "red"
                    })
                        .then(() => {
                            if (this.state.drawMarker) this.startDrawing(MARKER);
                            else this.activePointer();
                        })

                })
            } else {
                this.viewer.scene.addEventListener("stop_inserting_measurement", (e) => {
                    this.viewer.scene.removeEventListeners("stop_inserting_measurement")
                    let count = 0
                    let measurement = {
                        name: type,
                        type: type,
                        coordinates: [],
                        description: null,
                        taskId: this.taskId,
                        color: "red"
                    }
                    if (((!e.stoppedFromUI && this.activeMeasurement.points.length > 2 || e.stoppedFromUI && this.activeMeasurement.points.length > 1) && type === POLYLINE) || ((!e.stoppedFromUI && this.activeMeasurement.points.length > 3 || e.stoppedFromUI && this.activeMeasurement.points.length > 2) && type === POLYGON)) {
                        e.measure.points.forEach((point) => {
                            measurement.coordinates.push(utm.convertUtmToLatLng(point.position.x, point.position.y, this.state.areaNumber, this.state.areaCode))
                            if (++count === e.measure.points.length) {
                                if (!e.stoppedFromUI) measurement.coordinates.pop()
                                this.saveMeasurement(measurement).then(() => {
                                    if (this.state.drawPolygon) { this.startDrawing(POLYGON) }
                                    else if (this.state.drawPolyline) { this.startDrawing(POLYLINE) }
                                    else { this.activePointer() }
                                })
                            }
                        });
                    }
                    else {
                        this.activeMeasurement.visible = false; //for remove partially drawn measurements
                        if (this.state.drawPolygon && e.continuosDrawing) { this.startDrawing(POLYGON) }
                        else if (this.state.drawPolyline && e.continuosDrawing) { this.startDrawing(POLYLINE) }
                        else { this.activePointer() }
                    };

                })
            }
        }


    }

    clickOnMiddleOfCanvas = () => {
        document.querySelector("canvas").dispatchEvent(new MouseEvent("mousedown", {
            clientX: 50,
            clientY: 50
        }));
        document.querySelector("canvas").dispatchEvent(new MouseEvent("mouseup", {
            clientX: 50,
            clientY: 50
        }));
    }

    stopDrawing = async () => {
        return new Promise(async (resolve) => {
            if (this.state.selectedMeasurement) this.setMeasurementEditible(this.state.selectedMeasurement.id, false)
            if (this.activeMeasurement) {
                await this.viewer.dispatchEvent({
                    type: "cancel_insertions"
                });
                if (this.state.drawPolygon || this.state.drawPolyline) {
                    this.setState({
                        drawPolygon: false,
                        drawPolyline: false
                    }, () => {
                        this.viewer.scene.dispatchEvent({
                            type: 'stop_inserting_measurement',
                            measure: this.activeMeasurement,
                            stoppedFromUI: true
                        });
                        resolve()
                    })

                } else {
                    // this is for the close marker
                    this.setState({
                        cursor: "default",
                        drawMarker: false,
                        drawPolyline: true,
                        drawPolygon: false,
                        showPointer: false
                    }, () => {
                        this.startDrawing(POLYLINE).then(async () => {
                            await this.stopDrawing()
                            resolve()
                        })
                    })
                }
                if (!(this.props.compare3DMode)) this.clickOnMiddleOfCanvas()
                // }
            }
            else resolve()
        })
    }

    togglePointsTo = (type) => {
        //type must be elevation or rgba
        this.viewer.scene.pointclouds.forEach(pc => pc.material.activeAttributeName = type);
    }

    setMeasurementEditible = (id, value) => {
        this.state.measurements.forEach((measure) => {
            if (measure.id === id && measure.drawnMeasurement) {
                measure.drawnMeasurement.showSphere = value
            }
        })
    }

    selectMeasurement = (selectById) => {
        const { selectedMeasurement } = this.state
        if (selectById || this.hoveredMeasurement) {
            if (selectedMeasurement) this.setMeasurementEditible(selectedMeasurement.id, false); // disable edit  which is enabled by setMeasurementEditible
            this.setState(
                {
                    // if id pass to this function it wiil select measurement from id otherwise it takes hoverd measurement id if there is any after cicking on the scene.
                    selectedMeasurement: selectById ? this.state.measurements.find(measurement => measurement.id === selectById) : this.hoveredMeasurement
                },
                () => {
                    if (this.state.selectedMeasurement) {
                        this.indicateMarkerInScene(null) //indication of all marker will be gone.
                        this.setMeasurementColor(this.state.selectedMeasurement.id, this.state.selectedMeasurement.color)
                        this.props.measurementClickedOnThreed(this.state.selectedMeasurement.id)
                        this.state.permissions[DRONENAKSHA_FEATURES.MAPS].EDIT && this.setMeasurementEditible(this.state.selectedMeasurement.id, true)
                    }
                    // else {
                    //     this.props.measurementClickedOnThreed(null)
                    // }
                })
        }

    }

    updateMeasurement = async (measurement, updatedCoords, redraw) => {
        this.threeJsMeshScene.remove(this.threeJsMeshScene.getObjectByName(measurement.id))
        let measurementObject = {
            ...measurement,
            cut_volume: null,
            fill_volume: null,
            show: true,
            volume: null,
            calc_method: null,
            measurements: undefined,
            is3d: true,
            coordinates: measurement.type === MARKER ? { x: updatedCoords[0].position.x, y: updatedCoords[0].position.y, elevation: updatedCoords[0].position.z, ...(this.state.areaCode ? { ...utm.convertUtmToLatLng(updatedCoords[0].position.x, updatedCoords[0].position.y, this.state.areaNumber, this.state.areaCode) } : {}) } :
                updatedCoords.map((coord) => { return { x: coord.position.x, y: coord.position.y, elevation: coord.position.z, ...(this.state.areaCode ? { ...utm.convertUtmToLatLng(updatedCoords[0].position.x, updatedCoords[0].position.y, this.state.areaNumber, this.state.areaCode) } : {}) } })
        }
        if (measurement.type !== MARKER && !redraw) measurementObject = await this.addThreeJsMeshScene(measurementObject)
        const lengthOrArea = measurementObject.type === POLYGON ? await this.getVolumeAreaString(measurementObject) : measurementObject.type === POLYLINE ? await this.props.getLength(measurementObject.coordinates, this.props.unit, true) : 0
        this.setState({
            measurements: this.state.measurements.map((measure) => {
                if (measure.id === measurementObject.id) {
                    if (redraw) this.drawMeasurements([measurementObject], measure.id)
                    if (measurementObject.type === POLYGON) measure.drawnMeasurement.area = lengthOrArea
                    if (measurementObject.type === POLYLINE) measure.drawnMeasurement.lineLength = lengthOrArea
                    this.setState({
                        selectedMeasurement: { ...measure, ...measurementObject, color: measure.color }
                    })
                    this.props.updateMeasurement({ ...measure, ...measurementObject, color: measure.color, drawnMeasurement: undefined, threeJsMeasurement: undefined })
                    return ({ ...measure, ...measurementObject, drawnMeasurement: measure.drawnMeasurement, color: measure.color })
                }
                else return measure
            })
        })
    }

    addThreeJsMeshScene = async (measurement) => {
        if (measurement.type === POLYLINE) {
            const lineGeometry = new THREE.BufferGeometry().setFromPoints(measurement.coordinates.map((coord) => new THREE.Vector3(coord.x, coord.y, coord.elevation)))
            const line = new THREE.Line(lineGeometry, new THREE.LineBasicMaterial({ color: 0x0000ff, visible: false }))
            if (measurement.show) this.threeJsMeshScene.add(line);
            line.name = measurement.id
            measurement.threeJsMeasurement = line //saved for delete/update etc
            return Promise.resolve(measurement)
        } else if (measurement.type === POLYGON) {
            let polyShape = new THREE.Shape(measurement.coordinates.map((coord) => new THREE.Vector3(coord.x, coord.y, coord.elevation)))
            const polyGeometry = new THREE.ShapeGeometry(polyShape);
            polyGeometry.setAttribute("position", new THREE.Float32BufferAttribute(measurement.coordinates.map(coord => [coord.x, coord.y, coord.elevation]).flat(), 3))
            let polygon = new THREE.Mesh(polyGeometry, new THREE.MeshBasicMaterial({ color: measurement.color, side: THREE.DoubleSide, visible: false }))
            if (measurement.show) this.threeJsMeshScene.add(polygon);
            polygon.name = measurement.id
            measurement.threeJsMeasurement = polygon //saved for delete/update etc
            return Promise.resolve(measurement)
        }
    }

    getVolumeAreaString = async (measurement) => {
        const area = await this.props.getArea(measurement.coordinates, this.props.unit, true, true)
        return `${area}\u00B2${measurement.volume ?
            (this.props.unit === 'm' ? `, ${Number(measurement.volume).toFixed(2)} m\u00B3` : `, ${(Number(measurement.volume) * 1.308).toFixed(2)} yd\u00B3`)
            : ""}`
    }

    updateVolume = (id, volume) => {
        this.state.measurements.forEach(async (measurement) => {
            if (measurement.id === id) {
                measurement.volume = volume
                measurement.drawnMeasurement.area = await this.getVolumeAreaString(measurement)
            }
        })
    }

    drawMeasurements = (measurements, redraw) => {
        measurements.forEach(async (measurement) => {
            let measure = new this.Potree.Measure(measurement.color, false, measurement.type === POLYGON ? await this.getVolumeAreaString(measurement) : undefined, measurement.type === POLYLINE ? await this.props.getLength(measurement.coordinates, this.props.unit, true) : undefined, this.props.unit); //false for disable drawing 
            // measure.color = measurement.color;
            measure.name = measurement.name;
            measure.closed = measurement.type === POLYGON ? true : false;
            measure.showArea = measurement.type === POLYGON ? true : false;
            measure.showAngles = true
            measure.showEdgeLabels = true
            measure.showCoordinates = measurement.type === MARKER ? true : false;
            measure.visible = measurement.show;
            measure.showSphere = redraw ? true : false
            measure.scene = this.viewer.scene
            if (measurement.type === MARKER) {
                measure.maxMarkers = 1
                if (measurement.coordinates.elevation > 0) measure.addMarker(new THREE.Vector3(measurement.coordinates.x, measurement.coordinates.y, measurement.coordinates.elevation));
                else {
                    measure.visible = false
                }
            }
            else {
                // three js mesh/line for intersect object which is invisible to the UI
                measurement = await this.addThreeJsMeshScene(measurement)

                // potree actual measurement
                measurement.coordinates.forEach((coord) => {
                    if (coord.elevation > 0) measure.addMarker(new THREE.Vector3(coord.x, coord.y, coord.elevation));
                    else measure.visible = false
                });
            }
            //---------- this will fire only when user drop marker ----------//
            measure.addEventListener("marker_dropped", (e) => {
                if (this.state.selectedMeasurement) this.setMeasurementEditible(this.state.selectedMeasurement.id, false);
                this.setState({ selectedMeasurement: { ...measurement, drawnMeasurement: measure } }, () => {
                    if (measurement.type !== MARKER) {
                        //polygon vertex clicked, delete vertex
                        if (this.mouseDown && ((e.target.closed && e.target.points.length > 3) || (!e.target.closed && e.target.points.length > 2))) {
                            const points = [...e.target.points]
                            points.splice(e.index, 1)
                            this.updateMeasurement(this.state.measurements.find(m => measurement.id === m.id), points, true)
                        }
                        //polygon vertex dragged update vertex
                        else this.updateMeasurement(this.state.measurements.find(m => measurement.id === m.id), e.target.points)
                        this.setMeasurementEditible(this.state.selectedMeasurement.id, true)
                    } else {
                        this.indicateMarkerInScene(measurement.id)
                        this.updateMeasurement(measurement, e.target.points)
                        if (this.mouseDown) this.props.measurementClickedOnThreed(this.state.selectedMeasurement.id)
                    }
                })

                // if user clicked on middle point - add new point 
                if (e.measurement.newPoint) {
                    const points = [...e.target.points]
                    points.splice(e.measurement.newPoint.index + 1, 0, { position: e.measurement.newPoint.position })
                    this.updateMeasurement(measurement, points, true)
                }
            })

            //---------------------------------------------------------------//
            this.setState({
                measurements: redraw ? this.state.measurements.map((m) => {
                    if (m.id === redraw) {
                        m.drawnMeasurement.visible = false
                        return ({ ...measurement, drawnMeasurement: measure })

                    } else {
                        return m
                    }
                }) : [...this.state.measurements, { ...measurement, drawnMeasurement: measure }]
            })
            this.viewer.scene.addMeasurement(measure);
        })
    }

    getMeasurementsInThreed = () => {
        if (this.props.measurements && this.props.measurements.length > 0) {
            const elevationNotAvailableMeasurements = this.props.measurements.filter(m => (m.type === MARKER && !m.coordinates.elevation) || (m.type !== MARKER && !m.coordinates[0].elevation))
            if (elevationNotAvailableMeasurements.length > 0) {
                if (this.state.permissions[DRONENAKSHA_FEATURES.MAPS].READ) API.getMeasurementsInThreed(this.taskId, this.state.permissions, elevationNotAvailableMeasurements).then((data) => {
                    this.setState({
                        // areaCode: data.areaCode,
                        // areaNumber: data.areaNumber,
                        loadingMeasurements: false

                    })
                    let measurements = [...data.measurements, ...this.props.measurements.filter(m => (m.type === MARKER && m.coordinates.elevation) || (m.type !== MARKER && m.coordinates[0].elevation))]
                    this.drawMeasurements(measurements)
                });
            } else {
                const { areaCode, areaNumber } = this.props.measurements[0].type !== "Marker" ? this.props.measurements[0].coordinates[0] : this.props.measurements[0].coordinates
                this.setState({
                    // areaCode: areaCode,
                    // areaNumber: areaNumber,
                    loadingMeasurements: false

                })
                let measurements = this.props.measurements

                this.drawMeasurements(measurements)
            }
        }
        else this.setState({
            loadingMeasurements: false
        })
    }


    // ----------- Volume clipping functions --------------//

    isVolumeBoxDrawn = () => {
        return this.state.drawnClipVolumes.length > 0
    }

    clearPartiallyVolume = async () => {
        if (this.drawingClipVolume) {
            this.viewer.scene.removeVolume(this.drawingClipVolume)
            this.drawingClipVolume.cancel.callback()
            this.drawingClipVolume = undefined
            this.clickOnMiddleOfCanvas()
            return
        }
        else return
    }

    exitPointCloudSlicing = async () => {
        if (this.viewer?.scene) {
            await this.clearPartiallyVolume()
            this.viewer.scene.removeAllClipVolumes()
            this.setState({ drawnClipVolumes: [] })
            return
        } else return

    }

    drawVolumeBox = async () => {
        if (this.props.pointCloudShow) {
            this.changeClipTask("Highlight")
            await this.stopDrawing()
            this.drawingClipVolume = await this.viewer.volumeTool.startInsertion({ clip: true })
            if (this.drawingClipVolume) {
                this.drawingClipVolume.addEventListener("drop", () => {
                    const drawingClipVolume = this.drawingClipVolume
                    this.drawingClipVolume = undefined
                    if (drawingClipVolume) {
                        this.viewer.inputHandler.toggleSelection(drawingClipVolume)
                        this.setState({ activeClipVolume: drawingClipVolume })
                        drawingClipVolume.addEventListener("select", (e) => {
                            this.setState({ activeClipVolume: e.target })
                        })
                        drawingClipVolume.addEventListener("deselect", (e) => {
                            this.setState({ activeClipVolume: null })
                        })
                        const newVolume = this.drawingClipVolume
                        if (this.state.drawnClipVolumes) {
                            const drawnClipVolumes = this.state.drawnClipVolumes
                            drawnClipVolumes.push(newVolume)
                            this.setState({ drawnClipVolumes })
                        }
                        else this.setState({ drawnClipVolumes: [newVolume] })
                    }
                })
            }

        }

    }

    removeClipVolume = async (volume) => {
        if (volume && volume instanceof this.Potree.BoxVolume) {
            this.viewer.scene.removeVolume(volume)
            this.setState({ drawnClipVolumes: this.state.drawnClipVolumes.filter(CV => CV.uuid !== volume.uuid) })
        }
        return
    }

    changeClipTask = (type) => {
        this.viewer.setClipTask(ClipTask[type])
        this.setState({ selectedClipTask: type })
    }

    // -------- Volume clipping functions end ------------//
    loadPointCloud = async () => {
        console.log('loadPointCloud called.');
        let eptURL = `${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${this.props.compare3DMode ? this.state.selectedModel.task_id : this.taskId}/ept/ept.json?${this.state.permissions.st}`
        let cloudUrl = `${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${this.props.compare3DMode ? this.state.selectedModel.task_id : this.taskId}/cloud/cloud.js`
        const url = await checkURLExist(eptURL) ? eptURL : cloudUrl

        if (await checkURLExist(eptURL)) {
            let response = await fetch(eptURL);
            let data = await response.json();
            if (!(data?.srs?.wkt)) {
                this.setState({ showTip: true })
            }
        }

        this.Potree.loadPointCloud(url, this.state.permissions.st).then(e => {
            let pointcloud = e.pointcloud;
            let material = pointcloud.material;
            material.activeAttributeName = "rgba";
            // material.activeAttributeName = "elevation";
            // pointcloud.material.elevationRange = [60, 120];
            material.minSize = 2;
            material.pointSizeType = this.Potree.PointSizeType.FIXED
            this.viewer.scene.addPointCloud(pointcloud);
            this.viewer.fitToScreen();
            if (this.view === "pointCloudShow" && !(this.view === "ThreeDShow")) {
                this.setState({ loading: false }, () => {
                    // if (!(this.props.compare3DMode)) { console.log("callback if called"); this.getMeasurementsInThreed() }
                })
            }
        }, e => console.err("ERROR: ", e));

    }

    loadPointCloud1 = async () => {
        console.log('loadPointCloud1 called.');
        let eptURL = `${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${this.state.selectedModel1.task_id}/ept/ept.json?${this.state.permissions.st}`
        let cloudUrl = `${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${this.state.selectedModel1.task_id}/cloud/cloud.js`
        const url = await checkURLExist(eptURL) ? eptURL : cloudUrl

        if (await checkURLExist(eptURL)) {
            let response = await fetch(eptURL);
            let data = await response.json();
            if (!(data?.srs?.wkt)) {
                this.setState({ showTip: true })
            }
        }

        this.Potree.loadPointCloud(url, this.state.permissions.st).then(e => {
            let pointcloud = e.pointcloud;
            let material = pointcloud.material;
            material.activeAttributeName = "rgba";
            material.minSize = 2;
            material.pointSizeType = this.Potree.PointSizeType.FIXED
            this.viewer1.scene.addPointCloud(pointcloud)
            this.viewer1.fitToScreen()
            if (this.view1 === "pointCloudShow" && !(this.view1 === "ThreeDShow")) {
                this.setState({ loading: false })
            }
        }, e => console.err("ERROR: ", e));
    }

    loadTexture = () => {
        if (this.view === "ThreeDShow" || !(this.view)) {
            this.setState((state) => ({
                ...state,
                loading: true,
            }))
            let manager = new THREE.LoadingManager();
            manager.onLoad = function () {
                console.log('Loading complete!');
                this.setState((state) => ({
                    ...state,
                    loading: false,
                }))
            }.bind(this);
            manager.onError = function () {
                window.alert("Failed to load 3D model");
            }.bind(this);
            manager.onProgress = function (url, itemsLoaded, itemsTotal) {
                let percentComplete = itemsLoaded / itemsTotal * 100
                let loaded = Math.round(percentComplete, 2)
                console.log(loaded + '%')
                this.setState({ loaded: loaded })
            }.bind(this);
            let objloader = new OBJLoader(manager);
            let mtlloader = new MTLLoader();
            mtlloader.load(`${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${this.props.compare3DMode ? this.state.selectedModel.task_id : this.taskId}/threeD/textured_model_geo.mtl?${this.state.permissions.st}`,
                (materials) => {
                    let changeMapKd = async () => {
                        Object.keys(materials.materialsInfo).map((material, key) => {
                            if (materials.materialsInfo[material].map_kd) materials.materialsInfo[material].map_kd = `${materials.materialsInfo[material].map_kd}?${this.state.permissions.st}`
                        })
                    }
                    changeMapKd().then(() => {
                        materials.preload()
                        objloader.setMaterials(materials)
                        objloader.load(`${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${this.props.compare3DMode ? this.state.selectedModel.task_id : this.taskId}/threeD/textured_model_geo.obj?${this.state.permissions.st}`, (object) => {
                            this.loadGeoreferencingOffset((offset) => {
                                object.translateX(offset.x);
                                object.translateY(offset.y);
                                object.name = "3dModel"
                                this.viewer.scene.scene.add(object);
                                this.modelReference = object;
                                if (this.view == "ThreeDShow") {
                                    this.viewer.setEDLEnabled(true);
                                    this.viewer.setEDLOpacity(0);
                                }
                            });
                        })
                    })
                })

        }
    }

    loadTexture1 = () => {
        if (this.view1 === "ThreeDShow" || !(this.view1)) { // Load Textured bunny from obj
            this.setState((state) => ({
                ...state,
                loading: true,
            }))
            let manager = new THREE.LoadingManager();
            manager.onLoad = function () {
                console.log('Loading complete!');
                this.setState((state) => ({
                    ...state,
                    loading: false,
                }))
            }.bind(this);
            manager.onError = function () {
                window.alert("Failed to load 3D model");
            }.bind(this);
            manager.onProgress = function (url, itemsLoaded, itemsTotal) {
                let percentComplete = itemsLoaded / itemsTotal * 100
                let loaded = Math.round(percentComplete, 2)
                console.log(loaded + '%')
                this.setState({ loaded: loaded })
            }.bind(this);
            let objloader = new OBJLoader(manager);
            let mtlloader = new MTLLoader();
            mtlloader.load(`${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${this.state.selectedModel1.task_id}/threeD/textured_model_geo.mtl?${this.state.permissions.st}`,
                (materials) => {
                    let changeMapKd = async () => {
                        Object.keys(materials.materialsInfo).map((material, key) => {
                            if (materials.materialsInfo[material].map_kd) materials.materialsInfo[material].map_kd = `${materials.materialsInfo[material].map_kd}?${this.state.permissions.st}`
                        })
                    }
                    changeMapKd().then(() => {
                        materials.preload()
                        objloader.setMaterials(materials)
                        objloader.load(`${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${this.state.selectedModel1.task_id}/threeD/textured_model_geo.obj?${this.state.permissions.st}`, (object) => {
                            this.loadGeoreferencingOffset1((offset) => {
                                object.translateX(offset.x);
                                object.translateY(offset.y);
                                object.name = "3dModel"
                                this.viewer1.scene.scene.add(object);
                                this.modelReference1 = object;
                                if (this.view1 == "ThreeDShow") {
                                    this.viewer1.setEDLEnabled(true);
                                    this.viewer1.setEDLOpacity(0);
                                }
                            });
                        })
                    })
                })

        }
    }

    setMeasureShow = (measure, visible) => {
        if (measure && measure.drawnMeasurement) {
            measure.show = visible
            measure.drawnMeasurement.showSphere = false
            let mesh = this.threeJsMeshScene.getObjectByName(measure.id)
            if (visible & !mesh) {
                this.addThreeJsMeshScene(measure)
            }
            else if (mesh) {
                this.threeJsMeshScene.remove(mesh)
            }
            measure.drawnMeasurement.visible = visible
        }
    }

    toggleAllMeasurementsVisibility = (visible) => {
        this.state.measurements.forEach((measure) => {
            this.setMeasureShow(measure, visible)
        });
    }

    setMeasurementVisibility = (id, visible) => {
        this.setMeasureShow(this.state.measurements.find((measure) => measure.id === id && measure.drawnMeasurement), visible)
    }

    setMeasurementColor = (id, color, temp) => {
        this.state.measurements.forEach((measure) => {
            if (measure.id === id && measure.drawnMeasurement) {
                if (!temp) measure.color = color.hex ? color.hex : color
                measure.drawnMeasurement.color = new this.Potree.Color(color.hex ? color.hex : color)
            } else if (measure.drawnMeasurement) {
                measure.drawnMeasurement.color = new this.Potree.Color(measure.color)
            }
        })
    }

    hoverMeasurement = () => {
        if (this.hoveredMeasurement) this.setMeasurementColor(this.hoveredMeasurement.id, this.hoveredMeasurement.color)
        let intersects = this.raycaster.intersectObjects(this.threeJsMeshScene.children, true)
        let measurements = this.state.measurements.filter((measurement) => intersects.map((intersect) => intersect.object.name).includes(measurement.id))
        const lines = measurements.filter(measurement => measurement.type === POLYLINE && measurement.drawnMeasurement && measurement.drawnMeasurement.visible)
        if (lines.length) {
            let line = lines.reduce((prev, curr) => prev.length < curr.length ? prev : curr)
            this.hoveredMeasurement = line
            if (!this.state.selectedMeasurement || this.state.selectedMeasurement.id !== line.id) {
                this.setMeasurementColor(line.id, hoveredShapeColor, true)
                this.setState({ cursor: "pointer" })
            }
        }
        else {
            const polygons = measurements.filter(measurement => measurement.type === POLYGON && measurement.drawnMeasurement && measurement.drawnMeasurement.visible)
            if (polygons.length > 0) {
                let polygon = polygons.reduce((prev, curr) => prev.area < curr.area ? prev : curr)
                this.hoveredMeasurement = polygon
                if (!this.state.selectedMeasurement || this.state.selectedMeasurement.id !== polygon.id) {
                    this.setMeasurementColor(polygon.id, hoveredShapeColor, true)
                    this.setState({ cursor: "pointer" })
                }
            }
            else {
                this.setState({ cursor: "default" })
                this.setMeasurementColor() //default color to all measurements
                this.hoveredMeasurement = undefined;
            }
        }
    }

    initPotree = () => {
        return new Promise(resolve => {
            this.Potree = window.Potree
            var width = window.innerWidth;
            var height = window.innerHeight;
            this.$ = window.$
            const viewerElem = this.potreeContainerDiv.current
            this.viewer = new this.Potree.Viewer(viewerElem);
            this.domElement = this.viewer.renderer.domElement;
            this.camera = new THREE.PerspectiveCamera(45, width / height, 1, 2000);
            this.viewer.scene.scene.add(this.camera);
            this.viewer.scene.scene.add(new THREE.AmbientLight(0x404040, 2.0)); // soft white light );
            this.viewer.scene.scene.add(new THREE.DirectionalLight(0xcccccc, 0.5));
            const directional = new THREE.DirectionalLight(0xcccccc, 0.5);
            directional.position.z = 99999999999;
            this.viewer.scene.scene.add(directional);
            this.viewer.setBackground("#000000");
            this.viewer.setEDLEnabled(true);
            this.viewer.setFOV(60);
            this.viewer.setPointBudget(1 * 1000 * 1000);
            this.viewer.loadSettingsFromURL();
            this.viewer.setControls(this.viewer.orbitControls)
            this.threeJsMeshScene = new THREE.Object3D()
            this.viewer.scene.scene.add(this.threeJsMeshScene)
            this.viewer.renderer.domElement.addEventListener('mousedown', () => this.mouseDown = true, false)
            this.viewer.renderer.domElement.addEventListener('pointermove', () => {
                this.mouseDown = false
                const ray = this.Potree.Utils.mouseToRay(this.viewer.inputHandler.mouse, this.viewer.scene.getActiveCamera(), this.viewer.renderer.domElement.clientWidth, this.viewer.renderer.domElement.clientHeight);
                this.raycaster = new THREE.Raycaster(ray.origin, ray.direction);
                this.raycaster.params.Line.threshold = 2
                this.raycaster.params.Mesh.threshold = 2

                if (this.viewer && this.raycaster && !this.activeMeasurement) this.hoverMeasurement()
            });
            this.viewer.renderer.domElement.addEventListener('mouseup', () => { if (this.mouseDown) this.selectMeasurement() }, false)
            resolve()
        })
    }

    initPotree1 = () => {
        return new Promise(resolve => {
            const viewerElem1 = this.potreeContainerDiv1.current
            this.viewer1 = new this.Potree.Viewer(viewerElem1);
            this.domElement = this.viewer1.renderer.domElement;
            this.viewer1.scene.scene.add(this.camera);
            this.viewer1.scene.scene.add(new THREE.AmbientLight(0x404040, 2.0)); // soft white light );
            this.viewer1.scene.scene.add(new THREE.DirectionalLight(0xcccccc, 0.5));
            const directional = new THREE.DirectionalLight(0xcccccc, 0.5);
            directional.position.z = 99999999999;
            this.viewer1.scene.scene.add(directional);
            this.viewer1.scene.camera = this.viewer.scene.camera
            this.viewer1.scene.cameraO = this.viewer.scene.cameraO
            this.viewer1.scene.cameraP = this.viewer.scene.cameraP
            this.viewer1.scene.cameraScreenSpace = this.viewer.scene.cameraScreenSpace
            this.viewer1.scene.cameraVR = this.viewer.scene.cameraVR
            this.viewer1.scene.directionalLight = this.viewer.scene.directionalLight
            this.viewer1.scene.referenceFrame = this.viewer.scene.referenceFrame
            this.viewer1.scene.view = this.viewer.scene.view
            this.viewer1.setBackground("#000000");
            this.viewer1.setEDLEnabled(true);
            this.viewer1.setFOV(60);
            this.viewer1.setPointBudget(1 * 1000 * 1000);

            this.threeJsMeshScene1 = new THREE.Object3D()
            this.viewer1.scene.scene.add(this.threeJsMeshScene1)
            resolve()
        })
    }

    isMapChanged = async (prevProps) => {
        if (this.props.taskId !== prevProps.taskId) {
            this.viewer.scene.removeAllMeasurements()
            this.viewer.scene.scenePointCloud.remove(this.viewer.scene.pointclouds[0]);
            this.viewer.scene.scene.remove(this.threeJsMeshScene)
            this.threeJsMeshScene = new THREE.Object3D()
            await this.exitPointCloudSlicing()
            this.setState({
                measurements: [],
            }, () => {
                this.initData()
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        this.isMapChanged(prevProps)
        if (this.props.bothShowPT !== prevProps.bothShowPT || this.props.ThreeDShow !== prevProps.ThreeDShow) {
            console.log('component did updated called');
            this.view = this.props.bothShowPT ? undefined : (this.props.ThreeDShow ? "ThreeDShow" : "pointCloudShow")
            if (this.modelReference) {
                const inSceneAlready = this.viewer.scene.scene.getObjectByName(this.modelReference.name);
                if (inSceneAlready && this.props.pointCloudShow) {
                    //remove 3d model if it is in scene when user wants to show only pointcloud
                    this.viewer.scene.scene.remove(this.modelReference)
                } else if (!inSceneAlready) {
                    // if user again switch to 3d model and it is already loaded but not in scene, add it to scene
                    this.exitPointCloudSlicing()
                    this.viewer.scene.scene.add(this.modelReference)
                }
            } else if (!this.props.pointCloudShow) {
                this.loadTexture()
                this.exitPointCloudSlicing()
            }

            // remove pointCloud if it is in scene when user wants to show only 3d model
            if (this.props.ThreeDShow) {
                this.viewer.setEDLEnabled(true);
                this.viewer.setEDLOpacity(0);
            } else {
                this.viewer.setEDLOpacity(1.0);
            }
        }

        if (this.props.compare3DMode && prevState.loading != this.state.loading) this.props.loadingComponent(this.state.loading)
        if (prevProps.showMeasurements != this.props.showMeasurements) {
            this.toggleAllMeasurementsVisibility(this.props.showMeasurements)
        }
    }

    loadthreeDDataTask = () => {
        return new Promise(async (resolve, reject) => {
            let modelAvailableData = []
            for (let i = 0; i < this.props.threeDModelData.length; i++) {
                let data = this.props.threeDModelData[i]
                let indThreeDAvailable = await checkURLExist(`${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${data.task_id}/threeD/textured_model_geo.obj?${this.state.permissions.st}`) &&
                    await checkURLExist(`${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${data.task_id}/threeD/textured_model_geo.mtl?${this.state.permissions.st}`) ? true : false

                let indPointCloudAvailable = (await checkURLExist(`${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${data.task_id}/ept/ept.json?${this.state.permissions.st}`)
                    || await checkURLExist(`${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${data.task_id}/cloud/cloud.js?${this.state.permissions.st}`)) ? true : false

                if (indThreeDAvailable || indPointCloudAvailable) {
                    data['models'] = {
                        threeD: indThreeDAvailable,
                        pointsCloud: indPointCloudAvailable
                    }
                    modelAvailableData.push(data)
                }
            }
            let selectedModel = modelAvailableData.find((data) => { return data.task_id == this.taskId })
            let index = modelAvailableData.indexOf(selectedModel)
            let selectedModel1 = modelAvailableData[modelAvailableData.length < 2 || index == modelAvailableData.length - 1 ? index : index + 1]
            let index1 = modelAvailableData.indexOf(selectedModel1)
            this.setState({
                modelAvailableData: modelAvailableData,
                selectedModel: selectedModel,
                selectedModel1: selectedModel1,
                activeModel: index,
                activeModel1: index1,
                activeView: !(selectedModel?.models?.pointsCloud) && (selectedModel?.models?.threeD) ? 'ThreeDShow' : 'pointCloudShow',
                activeView1: !(selectedModel1?.models?.pointsCloud) && (selectedModel1?.models?.threeD) ? 'ThreeDShow' : 'pointCloudShow',
            }, () => {
                resolve()
            })
        })

    }

    removeData = () => {
        return new Promise((resolve, reject) => {
            if (this.modelReference) {
                this.viewer.scene.scene.remove(this.modelReference)
                this.modelReference = undefined
            }
            if (this.modelReference1) {
                this.viewer1.scene.scene.remove(this.modelReference1)
                this.modelReference1 = undefined
            }
            this.viewer.scene.scenePointCloud.remove(this.viewer.scene.pointclouds[0]);
            this.viewer.scene.pointclouds.pop();
            this.viewer1.scene.scenePointCloud.remove(this.viewer1.scene.pointclouds[0]);
            this.viewer1.scene.pointclouds.pop();
            this.setState({
                onHandleEnter: false,
                onHandleDown: false,
            })
            resolve()
        })
    }

    toggleView = () => {
        this.setState({ loading: true, }, () => {
            setTimeout(async () => {
                if (this.props.compare3DMode) {
                    await this.loadthreeDDataTask()
                } else {
                    await this.removeData()
                    console.log('models remove from comapre');
                }
                this.initData()
            }, 300)
        })
    }

    initData = () => {
        console.log('init data called');
        setTimeout(async () => {
            if (window.Potree) {
                this.user = this.props.user
                this.view = this.props.compare3DMode ? (this.state.activeView == 'both' ? undefined : this.state.activeView)
                    : (this.props.bothShowPT ? undefined : (this.props.ThreeDShow ? "ThreeDShow" : "pointCloudShow"))
                this.view1 = this.props.compare3DMode ? (this.state.activeView1 == 'both' ? undefined : this.state.activeModel1) : undefined
                this.taskId = this.props.taskId
                await this.initPotree()
                if (this.props.compare3DMode) {
                    await this.initPotree1()
                    this.loadPointCloud1()
                    this.loadTexture1()
                }
                if (!(this.props.compare3DMode)) this.getMeasurementsInThreed()
                this.loadPointCloud()
                this.loadTexture()
            } else {
                console.log("potree not initialized")
                this.initData()
            }
        }, 500);
    }

    AddLibrary = async (urlOfTheLibrary) => {
        const script = document.createElement('script');
        script.src = urlOfTheLibrary;
        script.async = true;
        document.body.appendChild(script);
    }

    componentDidMount = async () => {
        await this.AddLibrary("/potree/jquery/jquery-3.1.1.min.js")
        await this.AddLibrary("/potree/other/BinaryHeap.js")
        await this.AddLibrary("/potree/tween/tween.min.js")
        await this.AddLibrary("/potree/proj4/proj4.js")
        await this.AddLibrary("/potree/openlayers3/ol.js")
        await this.AddLibrary("/potree/plasio/js/laslaz.js")
        await this.AddLibrary("/potree/potree/potree.js")
        this.initData()
        getFeaturesPermissions([DRONENAKSHA_FEATURES.MAPS,])
            .then(permissions => {
                this.setState(state => ({ ...state, permissions }))
            })
    }

    prevModel = () => {
        let activeModelKey = this.state.activeModel - 1
        this.setState({
            selectedModel: this.state.modelAvailableData[activeModelKey],
            activeModel: activeModelKey,
            activeView: !(this.state.modelAvailableData[activeModelKey]?.models?.pointsCloud) && (this.state.modelAvailableData[activeModelKey]?.models?.threeD) ? 'ThreeDShow' : 'pointCloudShow',
            loading: true
        }, () => {
            if (this.modelReference) {
                this.viewer.scene.scene.remove(this.modelReference)
                this.modelReference = undefined
                this.viewer.setEDLOpacity(1.0);
            }
            this.view = !(this.state.modelAvailableData[activeModelKey]?.models?.pointsCloud) && (this.state.modelAvailableData[activeModelKey]?.models?.threeD) ? 'ThreeDShow' : 'pointCloudShow'
            this.viewer.scene.scenePointCloud.remove(this.viewer.scene.pointclouds[0]);
            this.viewer.scene.pointclouds.pop();
            this.loadPointCloud()
            this.loadTexture()
        })
    }

    nextModel = () => {
        let activeModelKey = this.state.activeModel + 1
        this.setState({
            selectedModel: this.state.modelAvailableData[activeModelKey],
            activeModel: activeModelKey,
            activeView: !(this.state.modelAvailableData[activeModelKey]?.models?.pointsCloud) && (this.state.modelAvailableData[activeModelKey]?.models?.threeD) ? 'ThreeDShow' : 'pointCloudShow',
            loading: true
        }, () => {
            if (this.modelReference) {
                this.viewer.scene.scene.remove(this.modelReference)
                this.modelReference = undefined
                this.viewer.setEDLOpacity(1.0);
            }
            this.view = !(this.state.modelAvailableData[activeModelKey]?.models?.pointsCloud) && (this.state.modelAvailableData[activeModelKey]?.models?.threeD) ? 'ThreeDShow' : 'pointCloudShow'
            this.viewer.scene.scenePointCloud.remove(this.viewer.scene.pointclouds[0]);
            this.viewer.scene.pointclouds.pop();
            this.loadPointCloud()
            this.loadTexture()
        })
    }

    prevModel1 = () => {
        let activeModelKey = this.state.activeModel1 - 1
        this.setState({
            selectedModel1: this.state.modelAvailableData[activeModelKey],
            activeModel1: activeModelKey,
            activeView1: !(this.state.modelAvailableData[activeModelKey]?.models?.pointsCloud) && (this.state.modelAvailableData[activeModelKey]?.models?.threeD) ? 'ThreeDShow' : 'pointCloudShow',
            loading: true
        }, () => {
            if (this.modelReference1) {
                this.viewer1.scene.scene.remove(this.modelReference1)
                this.modelReference1 = undefined
                this.viewer1.setEDLOpacity(1.0);
            }
            this.view1 = !(this.state.modelAvailableData[activeModelKey]?.models?.pointsCloud) && (this.state.modelAvailableData[activeModelKey]?.models?.threeD) ? 'ThreeDShow' : 'pointCloudShow'
            this.viewer1.scene.scenePointCloud.remove(this.viewer1.scene.pointclouds[0]);
            this.viewer1.scene.pointclouds.pop();
            this.loadPointCloud1()
            this.loadTexture1()
        })
    }

    nextModel1 = () => {
        let activeModelKey = this.state.activeModel1 + 1
        this.setState({
            selectedModel1: this.state.modelAvailableData[activeModelKey],
            activeModel1: activeModelKey,
            activeView1: !(this.state.modelAvailableData[activeModelKey]?.models?.pointsCloud) && (this.state.modelAvailableData[activeModelKey]?.models?.threeD) ? 'ThreeDShow' : 'pointCloudShow',
            loading: true
        }, () => {
            if (this.modelReference1) {
                this.viewer1.scene.scene.remove(this.modelReference1)
                this.modelReference1 = undefined
                this.viewer1.setEDLOpacity(1.0);
            }
            this.view1 = !(this.state.modelAvailableData[activeModelKey]?.models?.pointsCloud) && (this.state.modelAvailableData[activeModelKey]?.models?.threeD) ? 'ThreeDShow' : 'pointCloudShow'
            this.viewer1.scene.scenePointCloud.remove(this.viewer1.scene.pointclouds[0]);
            this.viewer1.scene.pointclouds.pop();
            this.loadPointCloud1()
            this.loadTexture1()
        })
    }

    changeActiveView = () => {
        this.view = this.props.compare3DMode ? (this.state.activeView == 'both' ? undefined : this.state.activeView)
            : (this.props.bothShowPT ? undefined : (this.props.ThreeDShow ? "ThreeDShow" : "pointCloudShow"))
        if (this.modelReference) {
            const inSceneAlready = this.viewer.scene.scene.getObjectByName(this.modelReference.name);
            if (inSceneAlready && this.state.activeView == 'pointCloudShow') {
                //remove 3d model if it is in scene when user wants to show only pointcloud
                this.viewer.scene.scene.remove(this.modelReference)
            } else if (!inSceneAlready) {
                // if user again switch to 3d model and it is already loaded but not in scene, add it to scene
                this.viewer.scene.scene.add(this.modelReference)
            }
        } else if (!(this.state.activeView == 'pointCloudShow')) {
            this.loadTexture()
        }

        // remove pointCloud if it is in scene when user wants to show only 3d model
        if (this.state.activeView == 'ThreeDShow') {
            this.viewer.setEDLEnabled(true);
            this.viewer.setEDLOpacity(0);
        } else {
            this.viewer.setEDLOpacity(1.0);
        }
    }

    changeActiveView1 = () => {
        this.view1 = this.props.compare3DMode ? (this.state.activeView1 == 'both' ? undefined : this.state.activeView1)
            : (this.props.bothShowPT ? undefined : (this.props.ThreeDShow ? "ThreeDShow" : "pointCloudShow"))
        if (this.modelReference1) {
            const inSceneAlready = this.viewer1.scene.scene.getObjectByName(this.modelReference1.name);
            if (inSceneAlready && this.state.activeView1 == 'pointCloudShow') {
                //remove 3d model if it is in scene when user wants to show only pointcloud
                this.viewer1.scene.scene.remove(this.modelReference1)
            } else if (!inSceneAlready) {
                // if user again switch to 3d model and it is already loaded but not in scene, add it to scene
                this.viewer1.scene.scene.add(this.modelReference1)
            }
        } else if (!(this.state.activeView1 == 'pointCloudShow')) {
            this.loadTexture1()
        }

        // remove pointCloud if it is in scene when user wants to show only 3d model
        if (this.state.activeView1 == 'ThreeDShow') {
            this.viewer1.setEDLEnabled(true);
            this.viewer1.setEDLOpacity(0);
        } else {
            this.viewer1.setEDLOpacity(1.0);
        }
    }

    LoaderComponent = () => {
        return (this.state.loading || this.state.loadingMeasurements) ? <div style={{ height: "100%", width: "100%", display: "flex", textAlign: "center", position: "absolute", zIndex: "1", backgroundColor: "#000000" }}>
            <img src={Loader} style={{ height: "30vh", width: "100vw", paddingLeft: "7.5%", margin: "auto" }} />
        </div>
            :
            <></>

    }

    handleEvents = () => {
        console.log(this.state.onHandleDown && this.state.onHandleDown ? 'handle in Active mode' : 'handle Inactive mode');
        this.handleMove = this.state.onHandleDown && this.state.onHandleDown ? true : false
    }

    render() {
        const clipTaskKeys = Object.keys(ClipTask)
        return (
            <>
                {this.state.showTip && <div style={{ zIndex: "100", transition: "0.5s all", width: this.props.sidebarPanel ? "73vw" : "96vw", display: "flex", justifyContent: "center", alignItems: "center", position: "fixed", top: "46px" }}>
                    <Tip
                        text={`As the uploaded laz file is not georeferenced, measurements will not be displayed at the appropriate locations.`}
                        style={{ left: "", position: "" }}
                        closeTip={() => { this.setState({ showTip: false }) }}
                    />
                </div>}

                <this.LoaderComponent />

                {this.props.compare3DMode ? <>
                    {this.state.loading ? <></> : <div style={{ zIndex: '11', marginTop: '45px', position: 'fixed' }}>
                        <div style={{ borderRadius: '10px', backgroundColor: 'rgba(60, 60,60,0.6)', color: 'white', textAlign: 'center', width: '25%', marginLeft: '15%', marginRight: '70%', position: 'fixed', padding: '1px 5px' }}>
                            <div style={{ width: "100%", padding: '0px 30px' }}>
                                <div style={{ fontSize: "13px", overflow: 'hidden', height: '24px' }}>{this.state.selectedModel.task_name}</div>
                                <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', height: '22px' }}>
                                    <div style={{ fontSize: '11px', marginLeft: '5px' }}>{dateFormat(this.state.selectedModel.date_created, "dd/mm/yyyy, h:MM TT")} </div>
                                    <div style={{}}>
                                        <select
                                            style={{ width: "100px", height: "32px", border: "none", outline: "none", color: "white", fontSize: "12px", backgroundColor: 'transparent', }}
                                            value={this.state.activeView}
                                            onChange={(event) => this.setState({
                                                activeView: event.target.value
                                            }, () => {
                                                this.changeActiveView()
                                            })}
                                        >
                                            {this.state.selectedModel?.models?.pointsCloud ? <option style={{ backgroundColor: 'rgba(60, 60,60,0.6)' }} value={"pointCloudShow"}>Points Cloud</option> : <></>}
                                            {this.state.selectedModel?.models?.threeD ? <option style={{ backgroundColor: 'rgba(60, 60,60,0.6)' }} value={"ThreeDShow"}>Texture View</option> : <></>}
                                            {this.state.selectedModel?.models?.pointsCloud && this.state.selectedModel?.models?.threeD ? <option style={{ backgroundColor: 'rgba(60, 60,60,0.6)' }} value={"both"}>Both</option> : <></>}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', backgroundColor: 'transparent' }}>
                                <div style={{ marginTop: '-35px' }}>
                                    {this.state.activeModel == 0 ?
                                        <img src={Prev} style={{ height: '15px', opacity: '0.5', cursor: "default" }} /> :
                                        <img src={Prev} style={{ height: '15px', cursor: 'pointer' }}
                                            onClick={() => {
                                                this.prevModel()
                                            }}
                                        />}
                                </div>
                                <div style={{ marginLeft: 'auto', marginTop: '-35px' }}>
                                    {this.state.activeModel == this.state.modelAvailableData.length - 1 ?
                                        <img src={Next} style={{ height: '15px', opacity: '0.5', cursor: "default" }}
                                        /> :
                                        <img src={Next} style={{ height: '15px', cursor: 'pointer' }}
                                            onClick={() => {
                                                this.nextModel()
                                            }}
                                        />
                                    }
                                </div>
                            </div>
                        </div>

                        <div style={{ borderRadius: '10px', color: 'white', backgroundColor: 'rgba(60, 60,60,0.6)', textAlign: 'center', width: '25%', marginLeft: '62%', marginRight: '15%', position: 'fixed', padding: '1px 5px' }}>
                            <div style={{ width: "100%", padding: '0px 30px' }}>
                                <div style={{ fontSize: "13px", overflow: 'hidden', height: '24px' }}>{this.state.selectedModel1.task_name}</div>
                                <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', height: '22px' }}>
                                    <div style={{ fontSize: '11px', marginLeft: '5px' }}>{dateFormat(this.state.selectedModel1.date_created, "dd/mm/yyyy, h:MM TT")} </div>
                                    <div style={{}}>
                                        <select
                                            style={{ width: "100px", height: "32px", border: "none", outline: "none", color: "white", fontSize: "12px", backgroundColor: 'transparent', }}
                                            value={this.state.activeView1}
                                            onChange={(event) => this.setState({
                                                activeView1: event.target.value
                                            }, () => {
                                                this.changeActiveView1()
                                            })}
                                        >
                                            {this.state.selectedModel1?.models?.pointsCloud ? <option style={{ backgroundColor: 'rgba(60, 60,60,0.6)' }} value={"pointCloudShow"}>Points Cloud</option> : <></>}
                                            {this.state.selectedModel1?.models?.threeD ? <option style={{ backgroundColor: 'rgba(60, 60,60,0.6)' }} value={"ThreeDShow"}>Texture View</option> : <></>}
                                            {this.state.selectedModel1?.models?.pointsCloud && this.state.selectedModel1?.models?.threeD ? <option style={{ backgroundColor: 'rgba(60, 60,60,0.6)' }} value={"both"}>Both</option> : <></>}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', backgroundColor: 'transparent' }}>
                                <div style={{ marginTop: '-35px' }}>
                                    {this.state.activeModel1 == 0 ?
                                        <img src={Prev} style={{ height: '15px', opacity: '0.5', cursor: "default" }} /> :
                                        <img src={Prev} style={{ height: '15px', cursor: 'pointer' }}
                                            onClick={() => {
                                                this.prevModel1()
                                            }}
                                        />
                                    }
                                </div>
                                <div style={{ marginLeft: 'auto', marginTop: '-35px' }}>
                                    {this.state.activeModel1 == this.state.modelAvailableData.length - 1 ?
                                        <img src={Next} style={{ height: '15px', opacity: '0.5', cursor: "default" }}
                                        /> :
                                        <img src={Next} style={{ height: '15px', cursor: 'pointer' }}
                                            onClick={() => {
                                                this.nextModel1()
                                            }}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>}
                    <ReactCompareSlider
                        itemOne={<div ref={this.potreeContainerDiv} style={{ height: "100vh", cursor: this.state.cursor, background: 'black', pointerEvents: this.handleMove ? 'none' : 'all' }} className={"potree_container "}> </div>}
                        itemTwo={<div ref={this.potreeContainerDiv1} style={{ height: "100vh", cursor: this.state.cursor, pointerEvents: this.handleMove ? 'none' : 'all' }} className={"potree_container "}> </div>}
                        onlyHandleDraggable
                        style={{ height: '100%', width: '100%' }}
                        handle={
                            // <ReactCompareSliderHandle buttonStyle={{ height: '45px', width: '45px', gridGap: '6px', zIndex: '10000' }} />
                            <div style={{ display: 'grid', placeContent: 'center', cursor: 'pointer', backgroundColor: 'white', width: '3px', height: '100%', zIndex: '100' }}
                                onMouseEnter={() => { this.setState({ onHandleEnter: true }, () => { this.handleEvents() }) }}
                                onMouseDown={() => { this.setState({ onHandleDown: true }, () => { this.handleEvents() }) }}
                                onMouseLeave={() => { this.setState({ onHandleEnter: false }, () => { this.handleEvents() }) }}
                                onMouseUp={() => { this.setState({ onHandleDown: false }, () => { this.handleEvents() }) }}>
                                <img src={compareSlider} style={{ width: '40px', zIndex: '100' }} />
                            </div>
                        }
                    />
                </> : <><div ref={this.potreeContainerDiv} style={{ height: "100vh", cursor: this.state.cursor }} className={"potree_container "}></div>
                    {this.props.drawVolumeBox ? <div
                        className={"hoverPointer AeroFarm"}
                        style={{
                            position: 'absolute',
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            bottom: '15px',
                            color: "#3C3C3C",
                            fontWeight: "500"
                        }}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                borderRadius: "10px",
                                width: clipTaskKeys.length * 70 + "px"
                            }}
                        >
                            {clipTaskKeys.map((ct, key) => {
                                return <div
                                    style={{
                                        width: "70px",
                                        borderRadius: key === 0 ? "5px 0px 0px 5px" : (
                                            key == clipTaskKeys.length - 1 ? "0px 5px 5px 0px" :
                                                "0px 0px 0px 0px"
                                        ),
                                        textAlign: "center",
                                        margin: 'auto',
                                        borderWidth: "1px 0.5px 1px 1px",
                                        borderStyle: "solid",
                                        borderColor: 'rgb(102, 102, 102, 0.3)',
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                    data-tip data-for={ct}
                                    onClick={() => {
                                        this.changeClipTask(ct)
                                    }}
                                    className={this.state.selectedClipTask === ct ? "toggle-layer-active-buttons" : "toggle-layer-inactive-buttons"}
                                >
                                    <span style={{ color: this.state.selectedClipTask === ct ? "#FFFFFF" : "#3C3C3C" }}>{ct}</span>
                                    {/* <img width='17px' height="17px" src={icon} /> */}
                                    {/* <ReactTooltip border='true' borderColor="rgb(102, 102, 102, 0.3)" className="tooltipclass" offset={{ top: 0 }} id={clipTaskName} place="top" type="light" effect="solid"><span style={{ fontSize: '14px', padding: '0px', margin: '0px', zIndex: '3', position: 'relative' }}>{clipTaskName.replace("_", " ")}</span></ReactTooltip> */}
                                    {/* <div style={{ marginLeft: "5px" }}></div> */}
                                </div>
                            })}

                        </div>
                    </div> : <></>}
                </>
                }
            </>
        )
    }
}