module.exports = {
  SOCKET_IO_MESSAGES: {
    DRONENAKSHA: {
      MEASUREMENT_UPDATED: "measurementUpdated",
      MEASUREMENT_ADDED: "measurementAdded",
    MEASUREMENT_DELETED: "measurementDeleted",
    },
    CONNECTED : "connected",
    JOIN_GROUP : 'joinGroup',
    DISCONNECT : "disconnect",
    CONNECTION : 'connection'
  },
  EXTENDBOUNDS: 0.00075,
  ORTHODETECTORTYPE: "ortho",
  tilesServer: process.env.REACT_APP_TILES_SERVER_URL,
  // tilesServer: "http://20.119.35.116:5008",
  specialCharReg: /[!@#$%^&*()+\-`=\[\]{};':"\\|,.<>\/?]+/,
  SERVICES: {
    AEROGCS: 'AeroGCS Enterprise',
    PICSTORK: 'PicStork',
    DRONENAKSHA: 'DroneNaksha',
    DRONE_CONTROL_CENTER: "Drone Control Center"
  },
  COLORS: {
    PRIMARYCOLOR: "#2989CF",
    ANNOTATIONCOLOR: "#FF0000",
    COPYANNOTATIONCOLOR: "#fce514",
    STEPPERCOLOR: "#3c3c3c",
    STEPPERTEXTCOLOR: "white",
    CLASSSELECTEDCOLOR: "#2989CF26"
  },
  TASK: {
    STATUS: {
      CREATING: 'Creating',
      PREPARING: 'Preparing',
      PENDING: "Pending",
      IN_PROGRESS: "In progress",
      CREATED: "Created",
      FAILED: "Failed",
      COMPLETED: "Completed",
      MANUAL: "Manual",
    },
    TYPE: {
      TRAINING: 'Training',
      DETECTION: 'Detection',
      ANNREPORT: 'Annotation Report'
    }
  },

  EMPTY_IMAGESET_POPUP_MESSAGE: "( Empty or already processed imagesets will not appear in this list.)",
  EMPTY_PROJECT_POPUP_MESSAGE: "(You will only see projects that contain unprocessed imagesets.)",
  EMPTY_PLAN_POPUP_MESSAGE: "(You will only see plans that contain unprocessed imagesets.)",
  EMPTY_PROJECT_POPUP_MESSAGE_TRAINING: "(You will only see projects that contain unprocessed annotationsets.)",
  EMPTY_PLAN_POPUP_MESSAGE_TRAINING: "(You will only see plans that contain unprocessed annotationsets.)",

  POPUP_INPUT_TEXT_LIMIT: 50,
  IMAGESET_NAME_LIMIT:30,
  OBJECT_NAME_LIMIT: 50,
  TASKPURPOSE: {
    COUNTING: "Counting",
    DEFECT: "Defect",
    SEGMENTATION: "Segmentation",
    BOTH: "Both"
  },

  PAGES: [
    { DISPLAY_NAME: "Main dashboard", PAGE_NAME: "MAIN_DASHBOARD", ROUTE: "/" },
    { DISPLAY_NAME: "AeroGCS-E dashboard", PAGE_NAME: "AEROGCSE_DASHBOARD", ROUTE: "/aerogcsenterprise/dashboard" },
    { DISPLAY_NAME: "PicStork dashboard", PAGE_NAME: "PICSTORK_DASHBOARD", ROUTE: "/picstork/dashboard" },
    { DISPLAY_NAME: "DroneNaksha dashboard", PAGE_NAME: "DRONENAKSHA_DASHBOARD", ROUTE: "/dronenaksha/dashboard" },

    //AEROGCS-ENTERPRISE PAGES
    { DISPLAY_NAME: "AeroGCS-E Projects", PAGE_NAME: "AEROGCSE_PROJECTS", ROUTE: "/projects" },
    { DISPLAY_NAME: "AeroGCS-E Plans", PAGE_NAME: "AEROGCSE_PLANS", ROUTE: "/plans" },
    { DISPLAY_NAME: "AeroGCS-E Plan view", PAGE_NAME: "AEROGCSE_PLANVIEW", ROUTE: "/remote_mission/assignDroneToPlan" },
    { DISPLAY_NAME: "Plan flight logs", PAGE_NAME: "PLAN_FLIGHT_LOGS", ROUTE: "/remote_mission/flightLogs" },
    { DISPLAY_NAME: "AeroGCS-E Plan videos", PAGE_NAME: "AEROGCSE_VIDEOS", ROUTE: "/remote_mission/my_videos" },
    { DISPLAY_NAME: "AeroGCS-E Image Sets", PAGE_NAME: "AEROGCSE_IMAGE_SETS", ROUTE: "/remote_mission/image_collection" },
    { DISPLAY_NAME: "AeroGCS-E Images", PAGE_NAME: "AEROGCSE_IMAGES", ROUTE: "/remote_mission/my_images" },
    { DISPLAY_NAME: "Flight statistics", PAGE_NAME: "FLIGHT_STATISTICS", ROUTE: "/remote_mission/flights_statistics" },
    { DISPLAY_NAME: "All Flight Logs", PAGE_NAME: "ALL_FLIGHT_LOGS", ROUTE: "/remote_mission/all_flights_logs" },
    { DISPLAY_NAME: "Drone Control Center", PAGE_NAME: "DRONE_CONTROL_CENTER", ROUTE: "/drone_control_center/all_drones" },
    { DISPLAY_NAME: "Drone Performance", PAGE_NAME: "DRONE_PERFORMANCE", ROUTE: "/remote_mission/flights_completed_hours" },
    { DISPLAY_NAME: "Live stream videos", PAGE_NAME: "STREAM_VIDEOS", ROUTE: "/request_a_drone/all_videos" },

    //DRONE NAKSHA PAGES
    { DISPLAY_NAME: "DroneNaksha Projects", PAGE_NAME: "DRONENAKSHA_ALL_PROJECTS", ROUTE: "/dronenaksha/projects" },
    { DISPLAY_NAME: "DroneNaksha Project", PAGE_NAME: "DRONENAKSHA_PROJECT", ROUTE: "/dronenaksha/project" },
    { DISPLAY_NAME: "DroneNaksha Plan", PAGE_NAME: "DRONENAKSHA_PLAN", ROUTE: "/dronenaksha/plan" },
    { DISPLAY_NAME: "DroneNaksha Image Sets", PAGE_NAME: "DRONENAKSHA_IMAGE_SETS", ROUTE: "/dronenaksha/image_collections" },
    { DISPLAY_NAME: "DroneNaksha Images", PAGE_NAME: "DRONENAKSHA_IMAGES", ROUTE: "/dronenaksha/images" },
    { DISPLAY_NAME: "DroneNaksha Tasks", PAGE_NAME: "DRONENAKSHA_TASKS", ROUTE: "/dronenaksha/tasks" },
    { DISPLAY_NAME: "DroneNaksha Plan view", PAGE_NAME: "DRONENAKSHA_PLAN_VIEW", ROUTE: "/dronenaksha/view_plan" },
    { DISPLAY_NAME: "All Maps", PAGE_NAME: "ALL_MAPS", ROUTE: "/dronenaksha/myMaps" },
    { DISPLAY_NAME: "Main Report", PAGE_NAME: "MAIN_REPORT", ROUTE: "/dronenaksha/report" },
    { DISPLAY_NAME: "Plant Health Report", PAGE_NAME: "PLANT_HEALTH_REPORT", ROUTE: "/dronenaksha/plantHealth_report" },
    { DISPLAY_NAME: "Measurement Report", PAGE_NAME: "MEASUREMENT_REPORT", ROUTE: "/dronenaksha/measurement_report" },
    { DISPLAY_NAME: "Create GCP", PAGE_NAME: "CREATE_GCP", ROUTE: "/dronenaksha/create_GCP" },
    { DISPLAY_NAME: "Image Sets on maps", PAGE_NAME: "IMAGE_SETS_ON_MAP", ROUTE: "/dronenaksha/imagesets-on-map" },

    //PICSTORK PAGES
    { DISPLAY_NAME: "PicStork Projects", PAGE_NAME: "PICSTORK_ALL_PROJECTS", ROUTE: "/picstork/projects" },
    { DISPLAY_NAME: "PicStork Project", PAGE_NAME: "PICSTORK_PROJECT", ROUTE: "/picstork/project" },
    { DISPLAY_NAME: "PicStork Images", PAGE_NAME: "PICSTORK_IMAGES", ROUTE: "/picstork/images" },
    { DISPLAY_NAME: "PicStork Task", PAGE_NAME: "PICSTORK_TASK", ROUTE: "/picstork/task" },

    //User 
    { DISPLAY_NAME: "Usage statistics", PAGE_NAME: "USAGE_STATISTICS", ROUTE: "/usage_statistics" },
    { DISPLAY_NAME: "User Profile", PAGE_NAME: "USER_PROFILE", ROUTE: "/profile-settings" },
    { DISPLAY_NAME: "User Login", PAGE_NAME: "USER_LOGIN", ROUTE: "/login" },
    { DISPLAY_NAME: "User Logout", PAGE_NAME: "USER_LOGOUT", ROUTE: "/logout" }
  ],
  classColors: ["#B366CC", "#FF1A66", "#00FFFF", "#FF0000", "#800000", "#808000", "#FFFF00", "#000000",
    "#0000FF", "#FF00FF", "#808080", "#FF8C00", "#FFFACD", "#A0522D", "#BC8F8F", "#F5DEB3", "#4B0082",
    "#D8BFD8", "#E6E6FA", "#1E90FF", "#FAEBD7", "#FFFAFA", "#B0C4DE", "#00FF00", "#D3D3D3", "#778899",
    "#B0E0E6", "#FFF0F5", "#AFEEEE", "#FF00FF", "#8FBC8F", "#F0F8FF", "#A52A2A", "#FF7F50", "#00CED1",
    "#FFA500", "#FF69B4", "#FFDAB9", "#FFD700", "#BDB76B", "#A52A2A", "#BC8F8F", "#FFC0CB", "#9400D3",
    "#F0F8FF", "#808000", "#D2691E", "#F5FFFA", "#9ACD32", "#191970", "#F5F5DC"],
  GRAPH_COLORS: {
    "0": { "color": "#CEB976" },
    "1": { "color": "#72810E" },
    "2": { "color": "#B04E75" },
    "3": { "color": "#3996F8" },
    "4": { "color": "#AE9723" },
    "5": { "color": "#6F1D77" },
    "6": { "color": "#CF24A7" },
    "7": { "color": "#BA154F" },
    "8": { "color": "#51B4CB" },
    "9": { "color": "#5AB14F" },
    "10": { "color": "#4CCEE8" },
    "11": { "color": "#A1FA48" },
    "12": { "color": "#4CC040" },
    "13": { "color": "#2A5AAD" },
    "14": { "color": "#BF842C" },
    "15": { "color": "#35A7D1" },
    "16": { "color": "#6478D1" },
    "17": { "color": "#E78CDA" },
    "18": { "color": "#6C7057" },
    "19": { "color": "#52D0B9" },
    "20": { "color": "#26EF00" },
    "21": { "color": "#D40FC6" },
    "22": { "color": "#CEA87D" },
    "23": { "color": "#8DBEA8" },
    "24": { "color": "#E58300" },
    "25": { "color": "#AAC586" },
    "26": { "color": "#89AA6D" },
    "27": { "color": "#046006" },
    "28": { "color": "#0C70D4" },
    "29": { "color": "#C314D8" },
    "30": { "color": "#563C02" },
    "31": { "color": "#61D3BF" },
    "32": { "color": "#3BE559" },
    "33": { "color": "#766607" },
    "34": { "color": "#DCA1EE" },
    "35": { "color": "#0C549F" },
    "36": { "color": "#9EE2F7" },
    "37": { "color": "#0D9A97" },
    "38": { "color": "#C2EA69" },
    "39": { "color": "#4824E6" },
    "40": { "color": "#58F093" },
    "41": { "color": "#A8E77C" },
    "42": { "color": "#CEB976" },
    "43": { "color": "#72810E" },
    "44": { "color": "#B04E75" },
    "45": { "color": "#3996F8" },
    "46": { "color": "#AE9723" },
    "47": { "color": "#6F1D77" },
    "48": { "color": "#CF24A7" },
    "49": { "color": "#BA154F" },
    "50": { "color": "#51B4CB" },
    "51": { "color": "#5AB14F" },
    "52": { "color": "#4CCEE8" },
    "53": { "color": "#A1FA48" },
    "54": { "color": "#4CC040" },
    "55": { "color": "#2A5AAD" },
    "56": { "color": "#BF842C" },
    "57": { "color": "#35A7D1" },
    "58": { "color": "#6478D1" },
    "59": { "color": "#E78CDA" },
    "60": { "color": "#6C7057" },
    "61": { "color": "#52D0B9" },
    "62": { "color": "#26EF00" },
    "63": { "color": "#D40FC6" },
    "64": { "color": "#CEA87D" },
    "65": { "color": "#8DBEA8" },
    "66": { "color": "#E58300" },
    "67": { "color": "#AAC586" },
    "68": { "color": "#89AA6D" },
    "69": { "color": "#046006" },
    "70": { "color": "#0C70D4" },
    "71": { "color": "#C314D8" },
    "72": { "color": "#563C02" },
    "73": { "color": "#61D3BF" },
    "74": { "color": "#3BE559" },
    "75": { "color": "#766607" },
    "76": { "color": "#DCA1EE" },
  },
  BLOB_URL: process.env.REACT_APP_AZURE_BLOB_URL,
  TASKONIMAGES: "imageSet",
  TASKONDNMAP: "droneNakshaMap",
  MAP_COLORS: ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
    '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
    '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
    '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
    '#66994D', '#B366CC', '#6666FF', '#B33300', '#CC80CC',
    '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
    '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
    '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
    '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
    '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#4D8000'],
  PICSTORK_PLAN_NAME: 'PICSTORK_PLAN',

  //React joyride constants 
  TRIGGER_DELAY: 1000,
  LOCALE_BUTTONS: { back: 'Back', close: 'Close', last: 'OK', next: 'Next', skip: 'Stop tour' },
  FLOATER_PROPS: { disableAnimation: true },
  NEXT_BUTTON: {
    fontSize: '14px',
    content: 'Step',
    background: 'none',
    color: '#2989cf',
  },
  SKIP_BUTTON: {
    fontSize: '14px',
    content: 'Step',
    background: 'none',
    color: '#2989cf',
  },
  SPOTLIGHT: { border: "2px solid #05426f", borderRadius: '5px' },
  MAIN_OPTIONS: {
    arrowColor: '#fff',
    primaryColor: '#2989cf',
    textColor: '#3c3c3c',
    font: "popins",
    width: "100%",
    zIndex: 1000,
    beaconSize: 35,
    overlayColor: 'rgba(0, 0, 0, 0.7)',
  },
  FILETYPES: {
    PNGJPEGTIF: ".tif , .tiff,.jpeg,.jpg,.png",
    TIF: ".tif , .tiff",
    PNG: ".png",
    JPEG: ".jpeg,.jpg",
    PNGJPEG: ".jpeg,.jpg,.png",
    ZIP: ".zip",
  },
  MIMETYPES: {
    PNGJPEG: "image/jpeg,image/png"
  },
  TEXTSTYLING: { textAlign: 'left', fontSize: '12px', height: '20px', color: '#3c3c3c' },
  TITLESTYLING: { textAlign: 'left', paddingLeft: '10px', fontSize: '16px' },
  UPLOAD_IMAGE_SIZE_LIMIT: 4294967296,
  accessLevels: {
    DOWNLOAD: "DOWNLOAD",
    READ: "READ"
  },
  IMAGE_SET_RENAME_VARIABLE: "_$PDRL",
  STEPPER: {
    STEPPERWIDTH: "260px"
  },
  CLASSLABELS: {
    DARKCLASSBG: "white",
    DARKCLASSTEXT: "#3c3c3c",
  },
  BTNINACTIVE: {
    BTNTEXT: "white",
    BTNBG: "#2989cf",
    BTNBORDER: "1px solid #2989cf"
  },
  BTNACTIVE: {
    BTNTEXT: "#2989cf",
    BTNBG: "transparent",
    BTNBORDER: "1px solid #2989cf"
  },
  BOXSHADOW: '0px 0px 2px #00000040',
  DETECTOR_TYPES: [
    {
      name: "FASTER_RCNN",
      accepeted_file_type: '.zip'
    },
    {
      name: "DETECTRON",
      accepeted_file_type: '.pth'
    },
    {
      name: "YOLOV8",
      accepeted_file_type: '.pt'
    }
  ],
  PLANNER: {
    STATUS: {
      TO_DO: "#FF6161",
      IN_PROGRESS: "#FFE24B",
      COMPLETED: "#43CAC8",
    }
  },
  PICSTORK_APPLICATION_TYPES: {
    WINDMILL: {
      NAME: "Windmill",
      BLADE_SIDES: {
        LE: "LE",
        SS: "SS",
        PS: "PS",
        TE: "TE"
      }
    }
  },
  PICSTORK_WINDMILL_MARKER: {
    COLOR: "red"
  },
  THERMAL: {
    COLOR: {
      POINT: "white",
      BOX: "white",
      LINE: "white",
      SELECTED: "yellow",
      SELECTED_TEMP: "yellow"
    }
  },
  MAP_URLS: {
    ROAD: 'https://mt0.google.com/vt/lyrs=h&hl=en&x={x}&y={y}&z={z}',
    ROAD_MAP: 'https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}',
    TERRAIN: 'https://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}',
    ALTER_ROAD: 'https://mt0.google.com/vt/lyrs=r&hl=en&x={x}&y={y}&z={z}',
    SATELITE: 'https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}',
    TERREN_ONLY: 'https://mt0.google.com/vt/lyrs=t&hl=en&x={x}&y={y}&z={z}',
    HYBRID: 'https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}',
  },
  SEVIRITY: {
    _1: "Normal",
    _2: "Medium",
    _3: "High",
    _4: "Higher",
    _5: "Very High",
    NONE: "None"
  },
  ASSETS: {
    SHP: "SHP",
    KML: "KML",
    CAD: "CAD",
    DWG: "dwg",
    DXF: "dxf",
    DETECTION: "DETECTION",
    GEOJSON: "GEOJSON",
    CONTOUR: 'CONTOUR'
  },
  FLOORIMGTYPE: {
    FLOORIMAGES: "floorImages",
    FLOORIMAGES360: "floorThreeSixtyImages"
  },
  SUBSCRIPTOPTIONS: {
    ITEMS: {
      STORAGE: "storage",
      ANALYTICS_CREDITS: "analytics",
      PROCESSING_CREDITS: "processing"
    },
    PRODUCT_TYPES: {
      SERVICES_PLAN: "services_plan",
      TASKS_CREDITS: "tasks_credits"
    }
  }
}


