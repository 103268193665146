import React, { Component } from "react";
import * as API from "../../api.js";
import SliderIcon from "../../assets/Icons/gallery.png";
import Image2 from "../../assets/Images/image2.jpg";
import Loader from "../../icons/loader.svg";
import cursorActive from '../../icons/cursorActive.png';
import LE from '../../Windmill/LE.png';
import TE from '../../Windmill/TE.png';
import HP from '../../Windmill/HP.png';
import LP from '../../Windmill/LP.png';
import cursorInactive from "../../icons/cursorWhite.png"
import LineToolActive from "../../assets/Icons/lineToolActive.png"
import LineToolInactive from "../../assets/Icons/lineToolInactive.png"
import PointToolActive from "../../assets/Icons/pointToolActive.png"
import PointToolInactive from "../../assets/Icons/pointToolInactive.png"
import PolygonToolActive from "../../../src/icons/polygonActive.png"
import PolygonToolInActive from "../../../src/icons/polygonInactive.png"
import closeButton from '../../assets/Icons/cancel.png';
import { Modal } from "react-bootstrap";
import { ActivityPopup } from '../../ReusableComponents/ActivityPopup.js';
import BoxAnnotation from '../../icons/boxAnnotation.png';
import BoxAnnotationActive from '../../icons/boxAnnotationActive.png';
import CLOSE from '../../icons/closeButton.png'
import Tiff from 'tiff.js'
import { addUserAnalytic, getSevirityNumber, isTaskEditable, updateOutTime } from '../../ReusableComponents/reusableFunctions'
import Annotation, { AUTOSEGMENTATIONTOOL, BOX, CIRCLE, LINE, POINT, POINTER, POLYGON } from "./Annotation"
import { v4 as uuid } from 'uuid';
import { AnimatedModal } from "../../ReusableComponents/AnimatedModal";
import whitePenIcon from "../assets/icons/whitePen.svg"
import whiteFilterIcon from "../assets/icons/whiteFilterIcon.svg"
import whiteTickIcon from "../../assets/Icons/whiteTickIcon.png"
import locateWhiteIcon from "../../assets/Icons/locateMeWhite.png"
import AutoSegmentationTool from "../../assets/Icons/magic-wand.png"
import AutoSegmentationToolActive from "../../assets/Icons/magic-wand-active.png"
import AppConstants, { BLOB_URL } from "../../AppConstants";
import ClassListWithColor from "./ReusableComp/ClassListWithColor";
import ImportExportImgRegion from "./ReusableComp/ImportExportImgRegion";
import { PICSTORK_FEATURES } from "../../Teams";
import DropDownWithButton from "../../ReusableComponents/DropDownWithButton";
import ButtonWithIcon from "../../ReusableComponents/ButtonWithIcon";
import ReusableImageSlider from "../../ReusableComponents/ReusableImageSlider";
class TaskReportImageSlider extends Component {
  constructor(props) {
    super(props);
    this.Annotator = React.createRef();
    this.WindMillAnnotator = React.createRef();
    // const { optionList } = this.state;
    this.onChange = this.onChange.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.state = {
      lineToolActive: false,
      pointToolActive: false,
      currentImgVal: 0,
      user: {},
      key: 0,
      type: "",
      progress: 0,
      showDownloadPopup: false,
      loader: false,
      services: [],
      objects: [],
      filter:[],
      showBackIcon: true,
      cancel: false,
      imageListindex: 0,
      panning: true,
      selected: 0,
      zoomcheck: 0,
      loading: false,
      indexLength: 10,
      image: Image2,
      outId: "",
      regions: undefined,
      setzoom: 0,
      editAnnotations: false,
      sidebarMode: this.props
        ? this.props.sidebarMode
        : "shrunk",
      showPopup: false,
      message: "",
      scale: 0,
      showDiv: false,
      selectedObjects: [],
      addObjectPopup: false,
      newObject: "",
      regionIndex: 0,
      popupIcon: "",
      popupMessage: "",
      imageURL: '',
      thumbURLs: [],
      image_data: [],
      itemName: "",
      showProcessPopup: false,
      processPopupIcon: "",
      processAction: "",
      processMessage: "",
      imageLoad: true,
      showFilterPopup: false,
      severityLevels: [{ value: '1' }, { value: '2' }, { value: '3' }, { value: '4' }, { value: '5' }, { value: "All" }],
      severity: 'All',
      note: '',
      objCount: [],
      selectAll: true,
      obj: [],
      regionToCopy: null,
      classes: this.props.data.task.classes ? this.props.data.task.classes : [],
      isThermalImage: false,
      boxTemperature: "",
      thermalMeta: "",
      isThermalDataLoaded: false,
      isWindMillData: false,
      selectedWindMillImage: undefined,
      bladeSide: undefined,
      windMillRegions: undefined,
      selecteWindMillRegion: undefined,
      clickFromList: false,
      clearAnnotationPopup: false,
      // currTempCalculated: true
    };
  }
  changeTempOfRegions = () => {
    this.setState({ regions: this.state.regions.map(reg => ({ ...reg, disableTemp: this.state.disableTemp })) })
  }
  toggleTemperature = (showTemp) => {
    this.setState({ disableTemp: showTemp ? false : true, }, () => {
      this.changeTempOfRegions()

    })
  }
  toggleClickedFromList = (value) => {
    this.setState({ clickFromList: value })
  }
  getBladeSide() {
    if (this.state.isWindMillData) {

      this.setState({
        bladeSide:
          (this.state.selectedWindMillImage.windmillData.side === AppConstants.PICSTORK_APPLICATION_TYPES.WINDMILL.BLADE_SIDES.LE) ? LE :
            (this.state.selectedWindMillImage.windmillData.side === AppConstants.PICSTORK_APPLICATION_TYPES.WINDMILL.BLADE_SIDES.TE) ? TE :
              (this.state.selectedWindMillImage.windmillData.side === AppConstants.PICSTORK_APPLICATION_TYPES.WINDMILL.BLADE_SIDES.SS) ? LP :
                (this.state.selectedWindMillImage.windmillData.side === AppConstants.PICSTORK_APPLICATION_TYPES.WINDMILL.BLADE_SIDES.PS) ? HP : undefined
      })
    }
  }
  showFilterPopup = (i) => {
    this.showSeverity()
    this.setState(
      (state) => ({
        ...state,
        showFilterPopup: true,
        severity: this.state.selectedSeverity || "All"
      }));
  }

  addAnnotationInfo = () => {
    let { severity, regionIndex, note, regions } = this.state
    if (severity)
      regions[regionIndex].data.severity = severity
    if (note)
      regions[regionIndex].data.note = note
    this.setState({
      regions,
      showFilterPopup: false
    })
  }

  addNewObject = (className) => {
    if (this.taskIsInCreating()) {
      let newType = this.state.type;
      if (this.state.type.filter(name => name === className).length > 0) {

      } else {
        newType = [...this.state.type, className]
      }
      this.setState({
        objects: [...this.state.type, className],
        type: newType,
        newClassName: "",
        error: undefined
      }, () => {
        this.props.onClassListUpdated(this.state.type)
        if (this.state.activeClass) this.Annotator.current.setDrawingRegion(className)
        API.updateAeroCaptureTask(this.state.task.task_id, className).then(
          (result) => {
            this.setState((state) => ({
              ...state,
              result: result.data,
              imageList: result.data,
              detection: result.data[this.state.key],
              task: { ...this.state.task, type: this.state.type, classes: result.classes },
              classes: result.classes
            }), async () => {
              await this.initObjects()
              this.saveFile(false)
            })
          })
      });
    }
    else this.setError("Please select 'Edit' mode to add new class label.")
  }

  parentSidebarSwitch = () => {
    this.setState((state) => ({
      ...state,
      sidebarMode: state.sidebarMode == "shrunk" ? "expanded" : "shrunk",
    }));
  };

  convertRegion = (line, type) => {
    if (type === "polygon") {
      return {
        points: line.map(p => [parseFloat(p[0]), parseFloat(p[1])])
      }
    }
    else if (type === "line") {
      return {
        x1: parseFloat(line[0]),
        y1: parseFloat(line[1]),
        x2: parseFloat(line[2]),
        y2: parseFloat(line[3]),
      }
    } else return {
      y: parseFloat(line[0]),
      x: parseFloat(line[1]),
      h: (parseFloat(line[2]) - parseFloat(line[0])),
      w: (parseFloat(line[3]) - parseFloat(line[1])),
    };
  }

  onKeyPress(e) {
    if (e.keyCode === 187 && e.shiftKey)
      this.zoomIn()
    if (e.keyCode === 189 && e.shiftKey)
      this.zoomOut()
  }

  // to load tiff image 
  loadTiffImageSrc = () => {
    let { detection, user, task } = this.state
    var imageURL = ""
    if (detection?.image) {
      let name = detection.image.toLowerCase()
      if ((name.indexOf(".tif")) == -1) {
        let iurl = `${BLOB_URL}/${this.props.permissions?.container}/FlightImages/collection-${task.image_collection_id}/${detection.image}?${this.props.permissions?.st}`
        imageURL = iurl
        this.setState((state) => ({
          ...state,
          imageURL: imageURL,
        }))
      } else {
        // for image
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'arraybuffer';
        let url = `${BLOB_URL}/${this.props.permissions?.container}/FlightImages/collection-${task.image_collection_id}/${detection.image}?${this.props.permissions?.st}`
        xhr.open('GET', url);
        xhr.onload = function (e) {
          var arraybuffer = e.target.response
          Tiff.initialize({
            TOTAL_MEMORY: 16777216 * 10
          });
          var tiff = new Tiff({
            buffer: arraybuffer
          })
          var dataUrl = tiff.toDataURL();
          let url = e.target.responseURL
          imageURL = dataUrl   // to insert url at specific position
          this.setState((state) => ({
            ...state,
            imageURL: imageURL,
          }))
        }.bind(this);
        xhr.send()
      }
    }
  }
  makeAllDetectionObjects = () => {
    return new Promise(async (resolve, reject) => {
      let detections = {}
      let objects = []
      await this.state.regions.forEach(async (region, key) => {
        if (detections[region.className]) {
          detections[region.className] = {
            count: detections[region.className].count + 1,
            data: [...detections[region.className].data, this.convertToPolyData(region)]
          }
        } else {
          objects.push(region.className)
          detections[region.className] = {
            count: 1,
            data: [this.convertToPolyData(region)]
          }
        }
        if (this.state.regions.length == key + 1) resolve({ detections, objects });
      })
    })
  }
  //toggle select
  toggleSelecetAll = () => {
    let objects = []
    this.state.obj.map((el, i) => {
      if (this.state.selectAll) {
        el.selected = true
        objects.push(el)
      } else {
        el.selected = false
        objects.push(el)
      }
    });
    this.setState((state) => ({
      ...state,
      obj: objects,
      filter:objects,
      showAllImages: this.state.selectAll
    }));
  }
  updateAllReportsData = async () => {
    await this.setState({
      detection: {
        ...this.state.detection,
        "result": await this.makeAllDetectionObjects()
      }
    }, () => {
      this.setState({
        imageList: this.state.imageList.map((data) => {
          if (data._id === this.state.detection._id) return this.state.detection;
          else return data
        }),
        report: this.state.report.map((data) => {
          if (data._id === this.state.detection._id) return this.state.detection;
          else return data
        })
      }, () => {
      })
    })
  }
  getImageDataOnImageChange = async () => {
    this.setState({ showFilterPopup: false })
    await API.getDetections(this.state.task.task_id, this.state.task.type).then(
      (data) => {
        if (data.ret.length > 0) {
          let temp1 = [];
          temp1 = data.ret
          let key = temp1.findIndex((data) => { return data.image == this.state.selectedImage })
          const images = data.image_data.filter(img => img.id)
          const windMillRegions = images.find(img => (img.file_name === this.state.selectedImage))

          if (windMillRegions.windmillData) {
            this.setState((state) => ({
              ...state,
              report: temp1,
              detection: temp1[key],
              imageList: temp1,
              image_data: images,
              isWindMillData: images.find(img => img.file_name === this.state.selectedImage).windmillData ? true : false,
              selectedWindMillImage: images.find(img => img.file_name === this.state.selectedImage && img.windmillData),
              loading: false,
              windMillRegions: windMillRegions.windmillData.annotations ? windMillRegions.windmillData.annotations.map(reg => {
                return {
                  data: { datatype: "", id: 1, note: undefined, percentage: "100", severity: undefined }, className: "",
                  type: "box",
                  open: undefined,
                  color: AppConstants.PICSTORK_WINDMILL_MARKER.COLOR,
                  className: "",
                  ...(this.convertRegion(reg))
                }
              }) : [],
              key
            }), () => {
              this.getBladeSide()
            })
          }
        }
        else {
          this.setState((state) => ({
            ...state,
            loading: false
          }))

        }
      },
      (e) => {
        console.log(e);
        this.setState((state) => ({
          ...state,
          report: [],
          loading: false,
        }));
      }
    );
  };

  getReportDetails = async () => {
    this.setState({ showFilterPopup: false })
    await API.getDetections(this.state.task.task_id, this.state.task.type).then(
      (data) => {
        if (data.ret.length > 0) {

          let temp1 = [];
          temp1 = data.ret
          let key = temp1.findIndex((data) => { return data.image == this.state.selectedImage })
          const images = data.image_data.filter(img => img.id)
          const windMillRegions = images.find(img => (img.file_name === this.state.selectedImage))

          if (windMillRegions.windmillData) {
            this.setState((state) => ({
              ...state,
              report: temp1,
              detection: temp1[key],
              imageList: temp1,
              image_data: images,
              isWindMillData: images.find(img => img.file_name === this.state.selectedImage).windmillData ? true : false,
              selectedWindMillImage: images.find(img => img.file_name === this.state.selectedImage && img.windmillData),
              loading: false,
              windMillRegions: windMillRegions.windmillData.annotations ? windMillRegions.windmillData.annotations.map(reg => {
                return {
                  data: { datatype: "", id: 1, note: undefined, percentage: "100", severity: undefined }, className: "",
                  type: "box",
                  open: undefined,
                  color: AppConstants.PICSTORK_WINDMILL_MARKER.COLOR,
                  className: "",
                  ...(this.convertRegion(reg))
                }
              }) : [],
              key
            }), () => {
              this.changeImage(key)
              this.getBladeSide()
            })
          } else {

            this.setState((state) => ({
              ...state,
              report: temp1,
              detection: temp1[key],
              imageList: temp1,
              image_data: images,
              loading: false,
              key
            }), () => {
              this.changeImage(key)
              this.getBladeSide()
            })
          }
        }
        else {
          this.setState((state) => ({
            ...state,
            loading: false
          }))

        }
      },
      (e) => {
        console.log(e);
        this.setState((state) => ({
          ...state,
          report: [],
          loading: false,
        }));
      }
    );
  };

  convertToPolyData = (region, isPolygon, isLine) => {
    let element = [];
    if (region.type === "polygon") {
      return region.points
    } else if (isLine) {
      element[0] = region.x1;
      element[1] = region.y1;
      element[2] = region.x2;
      element[3] = region.y2;
      return element;
    }
    else {
      element[0] = region.y //ymin
      element[1] = region.x  //xmin
      element[2] = (region.y + region.h); //ymax
      element[3] = (region.x + region.w); //xmax
      return element;
    }
  }

  checkForTemperature = async () => {
    const newRegions = await Promise.all(this.state.regions.map(async reg => {
      if (reg.type === "box") {

        let Xmin = Math.round((reg?.x * 100) * this.state.imageWidth / 100);
        let Xmax = Math.round(((reg?.x + reg.w) * 100) * this.state.imageWidth / 100);
        let Ymin = Math.round((reg?.y * 100) * this.state.imageHeight / 100);
        let Ymax = Math.round(((reg?.y + reg.h) * 100) * this.state.imageHeight / 100);
        let XYval = `${Xmin}/${Xmax}/${Ymin}/${Ymax}`;

        if (reg.type === "box" && !reg.boxTemperature) {
          const rectTemp = await API.getRectTemperature(
            XYval,
            this.props.permissions?.container,
            this.props.permissions?.st,
            this.state.task.image_collection_id,
            this.state.detection.image
          )
          return {
            ...reg,
            boxTemperature: rectTemp,
            calculated: true
          }


        }
        else return { ...reg, calculated: true }
      }
      else if (reg.type === "point") {
        let x = Math.round((reg?.x * 100) * this.state.imageWidth / 100);
        let y = Math.round((reg?.y * 100) * this.state.imageHeight / 100);
        let XYval = `${x}/${y}`

        if (reg.type === "point" && !reg.pointTemperature) {
          const pointTemp = await API.getSinglePixleTemperature(
            XYval,
            this.props.permissions?.container,
            this.props.permissions?.st,
            this.state.task.image_collection_id,
            this.state.detection.image
          )

          return {
            ...reg,
            pointTemperature: pointTemp,
            calculated: true
          }
        }
        else return { ...reg, calculated: true }
      }
      else if (reg.type === "line") {
        let x1 = Math.round((reg?.x1 * 100) * this.state.imageWidth / 100);
        let y1 = Math.round((reg?.y1 * 100) * this.state.imageHeight / 100);
        let x2 = Math.round((reg?.x2 * 100) * this.state.imageWidth / 100);
        let y2 = Math.round((reg?.y2 * 100) * this.state.imageHeight / 100);
        let XYval = `${x1}/${y1}/${x2}/${y2}`
        if (reg.type === "line" && !reg.lineTemperature) {
          const lineTemp = await API.getLineTemperature(
            XYval,
            this.props.permissions?.container,
            this.props.permissions?.st,
            this.state.task.image_collection_id,
            this.state.detection.image
          )

          return {
            ...reg,
            lineTemperature: lineTemp,
            calculated: true
          }
        }
        else return { ...reg, calculated: true }
      }
      else return reg
    }))
    this.setState({
      isThermalDataLoaded: true,
      regions: newRegions
    }, () => {
      this.changeTempOfRegions()
    })
  }
  getRegions = async (dontUpdateRegions = false) => {
    let boxCount = 1;
    let lineCount = 1;
    let pointCount = 1;
    this.setState({ showFilterPopup: false })
    this.loadTiffImageSrc();
    let detection = this.state.detection;
    if (detection && detection.result && detection.result.objects && detection.result.objects.length) {
      let type = this.state.obj ? this.state.obj.filter((data) => data.selected && data.type) : this.state.type.length > 0 ? this.state.type : detection.result.objects;
      let objects = type
      let tmp = {};
      var regions = []
      var selected = []
      for (let k = 0; k < objects.length; k++) {
        let obj = objects[k].type
        let data = this.state.detection.result.detections[obj] ? this.state.detection.result.detections[obj].data : []
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            let region = data[i]
            const existingClass = this.state.classes.find(classs => classs.name === obj)
            if (!existingClass) this.setState({
              classes: [...this.state.classes, {
                name: region.className,
                color: AppConstants.classColors[this.state.classes.length]
              }]
            })
            selected.push(obj)
            tmp = region.type === "polygon" ? this.convertRegion(region.poly, region.type) : this.state.isThermalImage ? (region.type === "line" ? this.convertRegion(region.poly, region.type) : region.poly[2] ? this.state.detection.isPolygon ? { points: region.poly } : this.convertRegion(region.poly) : this.convertRegion(region.poly)) : this.convertRegion(region.poly)
            if (region?.boxTemperature && this.state.isThermalImage) {
              tmp.boxTemperature = region?.boxTemperature;
            } else if (region?.pointTemperature && this.state.isThermalImage) {
              tmp.pointTemperature = region?.pointTemperature;
            } else if (region?.lineTemperature && this.state.isThermalImage) {
              tmp.lineTemperature = region?.lineTemperature;
            }
            tmp.data = { severity: region.severity, note: region.note, datatype: obj, percentage: region.percentage, id: i + 1 }
            tmp.id = uuid()
            tmp.type = region.type ? region.type : this.state.detection.isPolygon ? "polygon" : "box"
            tmp.color = this.state.isThermalImage ? (tmp.type === "point" ? AppConstants.THERMAL.COLOR.POINT : tmp.type === "box" ? AppConstants.THERMAL.COLOR.BOX : tmp.type === "line" ? AppConstants.THERMAL.COLOR.LINE : existingClass ? existingClass.color : AppConstants.classColors[i]) : existingClass ? existingClass.color : AppConstants.classColors[i]
            tmp.className = obj
            tmp.open = region.poly[2] ? this.state.detection.isPolygon ? false : undefined : undefined
            tmp.name = region.name ? region.name : (tmp.type === "point" ? `Point ${pointCount++}` : tmp.type === "box" ? `Rect ${boxCount++}` : tmp.type === "line" ? `Line ${lineCount++}` : "")
            // tmp.calculated=true;
            if (!this.state.selectedSeverity || this.state.selectedSeverity === "All" || tmp.data.severity == this.state.selectedSeverity)
              if (this.state.isThermalImage) {
                regions.push(tmp);
              } else {
                if (tmp.type === "point" || tmp.type === "line") {

                } else {
                  regions.push(tmp);
                }
              }
          }
        }
      }
      var objCount = []
      for (let i = 0; i < objects.length; i++) {
        let obj = objects[i].type
        let count = (regions.filter((d) => { return d.data.datatype == obj })).length
        if (count && count > 0) {
          objCount.push({ obj, count })
        }
      }

      this.setState({
        objCount,
        selectedObjects: [...new Set(selected)],
        isRegions: true,
        regionLoaded: true,
        regions: dontUpdateRegions ? this.state.regions : regions
      }, () => {

        this.state.isThermalImage && this.checkForTemperature()
        // this.state.isThermalImage && this.fetchThermalMeta()
      })
    } else {
      this.setState((state) => ({
        ...state,
        isRegions: false,
        regionLoaded: true,
        regions: []
      }))
    }
  }

  getTask = async () => {
    return new Promise((resolve, reject) => {
      console.log("In getting task file...");
      API.getAeroCaptureTask(this.state.task.task_id).then((data) => {
        this.setState((state) => ({
          ...state,
          task: data
        }), () => {
          this.initObjects()
          resolve(0)
        })
      }, (e) => {
        console.log(e);
        resolve(0)
      })
    })
  }
  // Remove single annotation
  removeAnnotation = (i) => {
    let arr = this.state.regions
    arr.splice(i, 1)
    this.setState(
      (state) => ({
        ...state,
        regions: arr,
        showSelectBox: false
      }));
  }

  onChangeListener = (e) => {
    let t = e.target;
    let format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;

    if (format.test(e.target.value)) {
    } else if (e.target.value.includes(' ')) {
    }
    else {
      this.setState((state) => ({
        ...state,
        [t.name]: t.value
      }))
    }
  }

  nextImage = () => {
    let id = this.state.selected + 1 >= this.state.imageList?.length ? this.state.selected : this.state.selected + 1
    this.setState((state) => ({
      ...state,
      showDiv: true,
      imageLoad: id === this.state.imageList?.length - 1 ? false : true
    }), () => this.changeImage(id));
    // if (document.getElementById('container'))
    //   document.getElementById('container').scrollLeft = (id * 130);
  }

  prevImage = () => {
    let id = this.state.selected - 1 < 0 ? this.state.selected : this.state.selected - 1
    this.setState((state) => ({
      ...state,
      showDiv: true,
      imageLoad: id === 0 ? false : true
    }), () => this.changeImage(id));
    // if (document.getElementById('container'))
    //   document.getElementById('container').scrollLeft -= 120;
  }

  onDeleteRegion = (id, forWindMill) => {
    if (forWindMill) {
      this.setState({ windMillRegions: this.state.windMillRegions.slice(0, -1) }, () => {
        let afterUNDOConvertedRegions = this.state.windMillRegions.map(reg => this.convertToPolyData(reg, false))
        API.saveWindMillData({ annotations: afterUNDOConvertedRegions }, this.state.selectedWindMillImage.id).then(res => console.log("save windmill data api res", res)).catch(e => console.log(e)).then(() => {
        })
      })
    }
    else {

      this.showSeverity()
      this.setState({ regions: this.state.regions.filter(item => item.id !== id) }, async () => {
        this.saveFile(true)
      })
    }
  }

  showSeverity = (id) => {
    if (id) this.onCopyRegion();
    else this.Annotator.current.deSelectRegion()
    if (this.state.regions && this.state.regions.length > 0) {
      this.setState((state) => ({
        ...state,
        selectedRegion: id ? this.state.selectedRegion : undefined,
        regions: state.regions.map((region) => {
          if (id === region.id) {
            return { ...region, color: this.state.isThermalImage ? AppConstants.THERMAL.COLOR.SELECTED : region.color, tempColor: AppConstants.THERMAL.COLOR.SELECTED_TEMP, showSeverity: true }
          } else {
            return { ...region, tempColor: "white", color: this.state.isThermalImage ? (region.type === "point" ? AppConstants.THERMAL.COLOR.POINT : region.type === "box" ? AppConstants.THERMAL.COLOR.BOX : region.type === "line" ? AppConstants.THERMAL.COLOR.LINE : "") : region.color, showSeverity: false, editable: false, highlight: false }
          }
        })
      }), () => {
      })
    }
  }

  fetchThermalMeta = async () => {
    await API.getThermalMeta(
      this.props.permissions?.container,
      this.props.permissions?.st,
      this.state.task.image_collection_id,
      this.state.detection.image
    ).then((res) => {
      this.setState((state) => ({
        ...state,
        thermalMeta: res,
        isThermalImage: res.ImageInfo ? true : undefined
      })
      )
    },
      () => {
        // this.saveFile(true)
      })
  }



  getTemperatureOfLine = async (region) => {
    // const updateRegion = this.state.regions.find(r => r.id === region.id)
    // if (updateRegion && updateRegion.type === "line") {
    //   let x1 = Math.round((updateRegion?.x1 * 100) * this.state.imageWidth / 100);
    //   let y1 = Math.round((updateRegion?.y1 * 100) * this.state.imageHeight / 100);
    //   let x2 = Math.round((updateRegion?.x2 * 100) * this.state.imageWidth / 100);
    //   let y2 = Math.round((updateRegion?.y2 * 100) * this.state.imageHeight / 100);
    //   let XYval = `${x1}/${y1}/${x2}/${y2}`
    //   const lineTemp = await API.getLineTemperature(
    //     XYval,
    //     this.props.permissions?.container,
    //     this.props.permissions?.st,
    //     this.state.task.image_collection_id,
    //     this.state.detection.image
    //   )
    //   updateRegion.lineTemperature = lineTemp
    //   updateRegion.calculated = true
    //   console.log("ran till here", updateRegion.calculated)
    //   this.saveFile(true)


    // }

    //  if(this.state.isThermalImage)  this.Annotator.current.toggleCurrTempCalculated(false)
    this.setState({
      regions: await Promise.all(this.state.regions.map(async (reg) => {
        return new Promise(async (resolve, reject) => {

          if (reg.id === region.id) {
            let x1 = Math.round((reg?.x1 * 100) * this.state.imageWidth / 100);
            let y1 = Math.round((reg?.y1 * 100) * this.state.imageHeight / 100);
            let x2 = Math.round((reg?.x2 * 100) * this.state.imageWidth / 100);
            let y2 = Math.round((reg?.y2 * 100) * this.state.imageHeight / 100);
            let XYval = `${x1}/${y1}/${x2}/${y2}`
            const lineTemp = await API.getLineTemperature(
              XYval,
              this.props.permissions?.container,
              this.props.permissions?.st,
              this.state.task.image_collection_id,
              this.state.detection.image
            )
            resolve({ ...reg, lineTemperature: lineTemp, calculated: true })
          } else resolve(reg)
        })
      }))
    }, async () => {
      await this.saveFile(true)
      if (this.state.isThermalImage) this.Annotator.current.toggleCurrTempCalculated(true)
    })
  }

  getTempratureOfPoint = async (region) => {

    // const updateRegion = this.state.regions.find(r => r.id === region.id)
    // if (updateRegion && updateRegion.type === "point") {
    //   let x = Math.round((updateRegion?.x * 100) * this.state.imageWidth / 100);
    //   let y = Math.round((updateRegion?.y * 100) * this.state.imageHeight / 100);
    //   let XYval = `${x}/${y}`
    //   const pointTemp = await API.getSinglePixleTemperature(
    //     XYval,
    //     this.props.permissions?.container,
    //     this.props.permissions?.st,
    //     this.state.task.image_collection_id,
    //     this.state.detection.image
    //   )
    //   updateRegion.pointTemperature = pointTemp
    //   updateRegion.calculated = true
    //   this.saveFile(true)
    // }
    //  if(this.state.isThermalImage)  this.Annotator.current.toggleCurrTempCalculated(false)
    this.setState({
      regions: await Promise.all(this.state.regions.map(async (reg) => {
        return new Promise(async (resolve, reject) => {

          if (reg.id === region.id) {
            let x = Math.round((reg?.x * 100) * this.state.imageWidth / 100);
            let y = Math.round((reg?.y * 100) * this.state.imageHeight / 100);
            let XYval = `${x}/${y}`
            const pointTemp = await API.getSinglePixleTemperature(
              XYval,
              this.props.permissions?.container,
              this.props.permissions?.st,
              this.state.task.image_collection_id,
              this.state.detection.image
            )
            resolve({ ...reg, pointTemperature: pointTemp, calculated: true })
          } else resolve(reg)
        })
      }))
    }, async () => {
      await this.saveFile(true)
      if (this.state.isThermalImage) this.Annotator.current.toggleCurrTempCalculated(true)
    })
  }

  getTemperatureOfBox = async (region) => {
    // const updateRegion = this.state.regions.find(r => r.id === region.id)
    // if (updateRegion && updateRegion.type === "box") {
    //   let Xmin = Math.round((updateRegion?.x * 100) * this.state.imageWidth / 100);
    //   let Xmax = Math.round(((updateRegion?.x + updateRegion.w) * 100) * this.state.imageWidth / 100);
    //   let Ymin = Math.round((updateRegion?.y * 100) * this.state.imageHeight / 100);
    //   let Ymax = Math.round(((updateRegion?.y + updateRegion.h) * 100) * this.state.imageHeight / 100);
    //   let XYval = `${Xmin}/${Xmax}/${Ymin}/${Ymax}`;
    //   const rectTemp = await API.getRectTemperature(
    //     XYval,
    //     this.props.permissions?.container,
    //     this.props.permissions?.st,
    //     this.state.task.image_collection_id,
    //     this.state.detection.image
    //   )
    //   updateRegion.boxTemperature = rectTemp
    //   updateRegion.calculated = true
    //   this.saveFile(true)
    // }
    // if(this.state.isThermalImage)  this.Annotator.current.toggleCurrTempCalculated(false)
    this.setState({
      regions: await Promise.all(this.state.regions.map(async (reg) => {
        return new Promise(async (resolve, reject) => {

          if (reg.id === region.id) {
            let Xmin = Math.round((reg?.x * 100) * this.state.imageWidth / 100);
            let Xmax = Math.round(((reg?.x + reg.w) * 100) * this.state.imageWidth / 100);
            let Ymin = Math.round((reg?.y * 100) * this.state.imageHeight / 100);
            let Ymax = Math.round(((reg?.y + reg.h) * 100) * this.state.imageHeight / 100);
            let XYval = `${Xmin}/${Xmax}/${Ymin}/${Ymax}`;
            const rectTemp = await API.getRectTemperature(
              XYval,
              this.props.permissions?.container,
              this.props.permissions?.st,
              this.state.task.image_collection_id,
              this.state.detection.image
            )
            resolve({ ...reg, boxTemperature: rectTemp, calculated: true })
          } else resolve(reg)
        })
      }))
    }, async () => {
      await this.saveFile(true)
      if (this.state.isThermalImage) this.Annotator.current.toggleCurrTempCalculated(true)
    })
  }

  onRegionAdded = (region, isregionCopied, forWindMill) => {
    region = { ...region, disableTemp: this.state.disableTemp }
    if (forWindMill) {
      this.setState({
        selecteWindMillRegion: isregionCopied ? this.state.selecteWindMillRegion : this.state.selecteWindMillRegion ? region : undefined,
        windMillRegions: [
          ...this.state.windMillRegions,
          {
            data: { datatype: region.className, percentage: "100", severity: region.severity, note: region.note },
            className: region.className,
            ...region,

          }
        ]
      }, async () => {
        const newWindMillRegions = this.state.windMillRegions.map(reg => this.convertToPolyData(reg, false))
        API.saveWindMillData({ annotations: [...newWindMillRegions] }, this.state.selectedWindMillImage.id).then(res => console.log("save windmill data api res", res)).catch(e => console.log(e))
      })
    }
    // x and y values converted percentage to pixel
    else if (region.type === "box") {
      // get temperature on thermal image for rectangle

      this.setState({
        addedFromAnnotation: true,
        // isThermalImage: true,
        selectedRegion: isregionCopied ? this.state.selectedRegion : this.state.selectedRegion ? region : undefined,
        regions: [
          ...this.state.regions,
          {
            data: { datatype: region.className, percentage: "100", severity: region.severity, note: region.note },
            className: region.className,
            ...region,
            // color: this.state.isThermalImage?(region.type === "point" ? AppConstants.THERMAL.COLOR.POINT : region.type === "box" ? AppConstants.THERMAL.COLOR.BOX : region.type === "line" ? AppConstants.THERMAL.COLOR.LINE : ""):region.color,
            // tempColor: region.type === "point" ? AppConstants.THERMAL.COLOR.POINT : region.type === "box" ? AppConstants.THERMAL.COLOR.BOX : region.type === "line" ? AppConstants.THERMAL.COLOR.LINE : ""

          }
        ]
      }
        ,
        () => {
          this.state.isThermalImage && this.getTemperatureOfBox(region)
          !this.state.isThermalImage && this.saveFile(true)
        }
      )
    } else if (region.type === "polygon") {
      this.setState({
        addedFromAnnotation: true,
        selectedRegion: isregionCopied ? this.state.selectedRegion : this.state.selectedRegion ? region : undefined,
        regions: [
          ...this.state.regions,
          {
            data: { datatype: region.className, percentage: "100", severity: region.severity, note: region.note },
            className: region.className,
            ...region,
          }
        ]
      }
        ,
        () => {
          console.log("auto segementated image sucesfully!")
          !this.state.isThermalImage && this.saveFile(true)
        }
      )
    }
    if (this.state.isThermalImage) { //this willl happen only if image is thermal image

      if (region.type === "point") {
        this.setState({
          addedFromAnnotation: true,
          // isThermalImage: true,
          selectedRegion: isregionCopied ? this.state.selectedRegion : this.state.selectedRegion ? region : undefined,
          regions: [
            ...this.state.regions,
            {
              data: { datatype: region.className, percentage: "100", severity: region.severity, note: region.note },
              className: region.className,
              ...region,
              // color: this.state.isThermalImage?(region.type === "point" ? AppConstants.THERMAL.COLOR.POINT : region.type === "box" ? AppConstants.THERMAL.COLOR.BOX : region.type === "line" ? AppConstants.THERMAL.COLOR.LINE : ""):region.color,
              // tempColor: region.type === "point" ? AppConstants.THERMAL.COLOR.POINT : region.type === "box" ? AppConstants.THERMAL.COLOR.BOX : region.type === "line" ? AppConstants.THERMAL.COLOR.LINE : ""

            }
          ]
        }
          ,
          () => {
            this.state.isThermalImage && this.getTempratureOfPoint(region)
          }
        )
      }
      if (region.type === "line") {
        this.setState({
          addedFromAnnotation: true,
          // isThermalImage: true,
          selectedRegion: isregionCopied ? this.state.selectedRegion : this.state.selectedRegion ? region : undefined,
          regions: [
            ...this.state.regions,
            {
              data: { datatype: region.className, percentage: "100", severity: region.severity, note: region.note },
              className: region.className,
              ...region,
              // color: this.state.isThermalImage?(region.type === "point" ? AppConstants.THERMAL.COLOR.POINT : region.type === "box" ? AppConstants.THERMAL.COLOR.BOX : region.type === "line" ? AppConstants.THERMAL.COLOR.LINE : ""):region.color,
              // tempColor: region.type === "point" ? AppConstants.THERMAL.COLOR.POINT : region.type === "box" ? AppConstants.THERMAL.COLOR.BOX : region.type === "line" ? AppConstants.THERMAL.COLOR.LINE : ""

            }
          ]
        }
          ,
          () => {
            this.state.isThermalImage && this.getTemperatureOfLine(region)
          }
        )
      }
    }
  }

  onEditClassColor = async (className, color) => {
    this.setState({
      selectedRegion: this.state.selectedRegion ? (this.state.selectedRegion.className === className ? { ...this.state.selectedRegion, color } : this.state.selectedRegion) : undefined,
      classes: this.state.classes.map(cls => cls.name === className ? { ...cls, color } : cls),
      regions: this.state.regions.map(r => r.className === className ? { ...r, color } : r)
    }, () => {
      API.updateTaskDetails(this.state.task.task_id, { classes: this.state.classes }).then((result) => {
        this.setState({ task: result.task })
      })
    })
  }

  onClassUpdate = (id, updatedClass) => {
    this.setState({
      selectedRegion: { ...this.state.selectedRegion, className: updatedClass, data: { ...this.state.selectedRegion.data, datatype: updatedClass } },
      regions: this.state.regions.map(item => {
        if (item.id === id) {
          return { ...item, className: updatedClass, color: this.state.classes.find(cls => cls.name === updatedClass).color, data: { ...this.state.selectedRegion.data, datatype: updatedClass } }
        } else {
          return item
        }
      }),
    }, () => {
      this.saveFile(true)
    });
  }

  onRegionDataUpdate = (id) => {
    let pathNm = this.props.data?.task?.tasktype == 4 ? '/picstork/annotationReportTask' : this.props.data?.task?.tasktype == 2 ? '/picstork/detectionTask' : this.props.data?.task?.tasktype == 1 && '/picstork/trainingTask';
    if (this.state.mentionedUser) {
      let props = {
        pathname: pathNm,
        state: {
          user: this.state.user,
          type: '',
          task: { task_id: this.state.task.task_id },
          collection: { id: this.state.task.image_collection_id },
          project: { project_name: this.state.task.project_name, id: this.state.task.project_id },
          plan: { plan_name: this.state.task.plan_name, id: this.state.task.plan_id, project_name: this.state.task.project_name },
        }
      }
      API.addNotification(this.state.mentionedUser, this.state.selectedRegion.data.note, props, 1).then(result => {

      })
    }

    this.setState({
      selectedRegion: { ...this.state.selectedRegion, data: { ...this.state.selectedRegion.data } },
      regions: this.state.regions.map(item => {
        if (item.id === id) {
          return { ...item, data: { ...this.state.selectedRegion.data } }
        } else {
          return item
        }
      }),
    }, () => {
      this.setRegionEditable()
      this.saveFile(true)
    });
  }

  saveFile = async (dontUpdateRegions) => {
    // this.setState({ isThermalDataLoaded: false })
    console.log("In save file...");
    let reg = this.state.regions;
    let result = this.state.detection.result
    await Promise.all(result.objects.map(async (obj) => {
      if (this.state.task.type.indexOf(obj) != -1) {
        result.detections[obj] = {
          data: [],
          count: 0
        }
      }
    }))
    for (let i = 0; i < reg.length; i++) {
      let region = reg[i];
      let poly = this.convertToPolyData(region, this.state.detection.isPolygon, region.type === "line")
      let polyData = { percentage: region.data.percentage, poly: poly, severity: region.data.severity, note: region.data.note, type: region.type, ...region, points: undefined }
      if (result.detections[region.data.datatype]) {
        result.detections[region.data.datatype].data.push(polyData);
        result.detections[region.data.datatype].count = result.detections[region.data.datatype].data.length
      } else {
        result.objects.push(region.data.datatype)
        result.detections[region.data.datatype] = {
          data: [polyData],
          count: 1
        }
      }
    }
    let { task, detection } = this.state
    API.saveDetectionResult(task.task_id, detection.image, result).then(
      (res) => {
        console.log("Saved detections...", res);
        this.setState((state) => ({
          ...state,
          report: res.result,
        }),
          () => this.getRegions(dontUpdateRegions)
        )
      }

    ).catch((e) => {

      this.setState((prevState) => ({
        ...prevState,
        editAnnotations: false,
        showDiv: false,
        itemName: "Detection Result",
        processPopupIcon: "ERROR",
        processAction: "UPDATE",
        processMessage: "Something Went Wrong while saving Detections",
        showProcessPopup: true,
        isThermalDataLoaded: true
      }));
    })
  }

  isFilterEnabled = () => {
    return (this.state.task.tasktype == 4 && (this.state.selectedSeverity != "All" || !this.state.selectAll || this.state.task.type.length != this.state.type.length))
  }
  changeTool = (tool) => {
    this.setState({ pointToolActive: false, lineToolActive: false, polygonToolActive: false, autoSegmentationToolActive: false, }, () => {

      switch (tool) {
        case POINTER:
          this.togglePanning(true)
          break;
        case BOX:
          this.togglePanning(false)
          break;
        case CIRCLE:
          break;
        case AUTOSEGMENTATIONTOOL:
          this.togglePanning(false)
          this.setState({ autoSegmentationToolActive: true })
          break;
        case POLYGON:
          this.togglePanning(false);
          this.setState({ polygonToolActive: true })
          break;
        case POINT:
          this.togglePanning(false)
          this.setState({ pointToolActive: true })
          break;
        case LINE:
          this.togglePanning(false)
          this.setState({ lineToolActive: true })
          break;
        default:
          break;
      }
    })
  }
  togglePanning = (value, forWindMill) => {
    if (forWindMill) {
      if (this.checkEditAccess()) {
        if (this.state.task.tasktype == 4 || this.state.editAnnotations) {
          this.setState({
            panning: value === false ? false : (value === true ? true : !this.state.panning), selecteWindMillRegion: undefined
          })
        } else {
          this.setError("Please select the 'Edit' mode to modify the detection result.")
        }
      } else {
        this.setError()
      }
    }
    else {

      if (this.checkEditAccess()) {
        this.onCopyRegion()
        if (this.isFilterEnabled()) {
          this.showFilterRemoveWarning()
        } else if (this.state.task.tasktype == 4 || this.state.editAnnotations) {
          this.showSeverity()

          // this.state.isThermalImage && this.highlightAnnotation()
          this.setState({
            panning: value === false ? false : (value === true ? true : !this.state.panning), selectedRegion: undefined
          })
        } else {
          this.setError("Please select the 'Edit' mode to modify the detection result.")
        }
      } else {
        this.setError()
      }

    }
  }


  close() {
    this.setState((prevState) => ({
      ...prevState,
      showPopup: false,
      addObjectPopup: false,
      newObject: ""
    }));
  }

  initObjects = async () => {
    let ob = this.state.task.type;
    var temp = { selected: false, type: "", id: 0 };
    var tmp = [];
    ob.forEach((type, key) => {
      let index = this.state.type.findIndex((data) => { return data == type })
      temp.selected = index > -1 ? true : false;
      temp.type = type;
      temp.id = key;
      tmp.push(temp);
      temp = {};
    });
    tmp.push({})
    this.setState((state) => ({
      ...state,
      obj: tmp,
      filter:tmp
    }), () => this.getRegions());
  }
  isDetectionInImg = (img) => {

    const detectionList = Object.entries(img.result.detections).filter(det => det[1].count > 0).map(det => det[0])
    for (let i = 0; i < this.state.type.length; i++) {
      if (detectionList.indexOf(this.state.type[i]) === -1) return false
    }
    return true
  }
  componentDidUpdate(prevProps, prevStates) {
    if (this.state.imageList?.length > 0 && this.state.type.length > 0 && (prevStates.imageList?.length !== this.state.imageList?.length || prevStates.type !== this.state.type)) {
      if (this.state.selectAll) { // if user removed filter ,show all images 
        this.setState({ imageList: this.state.allImagesList||this.state.imageList })
      }
      if (!this.state.allImagesList) { //set allListOfImages only if it is not defined,because we want to set it only once

        this.setState({ allImagesList: this.state.imageList }) //this is the list of all images ,without filter,this will always be full list of images
      }
      if (this.state.selectAll || this.state.type.length === 0) {
        //if filter is removed 

      } else {
        if (this.state.allImagesList) {

          this.setState({ imageList: this.state.allImagesList.filter(img => this.isDetectionInImg(img)) }, () => {
            this.setState((state) => ({
              ...state,
              selected: 0,
              showDiv: true,
              // imageLoad: true
            }), () => {
              this.getImageDataOnImageChange().then(
                () => this.changeImage(0)
              )
            });
          }) //apply filter
        } else {
          this.setState({ imageList: this.state.imageList.filter(img => this.isDetectionInImg(img)) }, () => {
            // this.setState({imageSrc:imageList[0].})
            this.setState((state) => ({
              ...state,
              selected: 0,
              showDiv: true,
              imageLoad: true
            }), () => {
              this.getImageDataOnImageChange().then(
                () => this.changeImage(0)
              )
            });
          }) //apply filter

        }
      }


    }
  }
  componentWillMount() {
    document.title = "PicStork -  Report Image";

    if (this.props) {
      let { user, type, task, report, key, selectedImage, plan, project, selectedSeverity, showAllImages } = this.props.data;
      let detection = report[key];
      this.setState(
        (state) => ({
          ...state,
          user,
          type,
          task,
          plan,
          project,
          detection: detection,
          imageList: report,
          imageLoad: true,
          key,
          selectedImage,
          selected: key,
          zoomcheck: key,
          selectedSeverity: selectedSeverity ? selectedSeverity : 'All',
          showAllImages,
          selectAll: showAllImages,
          editAnnotations: task && task.tasktype == 4,
          // panning: true
        }),
        async () => {
          await this.fetchThermalMeta();
          await this.getReportDetails()
          this.getTask()
          API.getUserSubbedServices().then((response) => {
            let services = response[0];
            if (services.length == 1 && services[0].title == "AeroStream") {
              this.setState((state) => ({
                ...state,
                showBackIcon: false,
              }));
            } else {
              this.setState((state) => ({
                ...state,
                showBackIcon: true,
              }));
            }
          });
        }
      );
    } else {
      API.getUserType().then(
        ([blah, type, user]) => {
          this.setState(
            (state) => ({
              ...state,
              user,
              type,
            }),
            () => {
              this.fetchThermalMeta();
              this.getRegions();

            }
          );
        },
        (e) => {
          window.location.replace("/login");
        }
      );
    }
  }
  zoomIn = () => {
    var myImg = document.getElementById("action-image")
    var currWidth = myImg.clientWidth;
    var currHeight = myImg.clientHeight;
    myImg.style.width = (currWidth + 100) + "px";
    this.setState((state) => ({
      ...state,
      scale: this.state.scale + 1
    }))
    myImg.style.height = (currHeight + 100) + "px";
  }
  reset = () => {
    var myImg = document.getElementById("action-image")
    var currWidth = myImg.clientWidth;
    var currHeight = myImg.clientHeight;
    myImg.style.width = "75%";
    this.setState((state) => ({
      ...state,
      scale: 0
    }))
    myImg.style.height = "84vh";
  }
  zoomOut = () => {
    if (this.state.scale != 0) {
      var myImg = document.getElementById("action-image")
      var currWidth = myImg.clientWidth;
      var currHeight = myImg.clientHeight;
      this.setState((state) => ({
        ...state,
        scale: this.state.scale - 1
      }))
      myImg.style.width = (currWidth - 100) + "px";
      myImg.style.height = (currHeight - 100) + "px";
    }
  }
  /*-------Changes Images on Click image grid------- */
  changeImage = (val) => {
    let imageData = this.state.image_data.find((data) => { return data.file_name == this.state.imageList[val]?.image })
    if (this.state.isWindMillData) {

      this.setState((state) => ({
        ...state,
        isWindMillData: imageData.windmillData ? true : false,
        selectedWindMillImage: imageData,
        windMillRegions: imageData?.windmillData?.annotations ? imageData.windmillData.annotations.map(reg => {
          return {
            data: { datatype: "", id: 1, note: undefined, percentage: "100", severity: undefined }, className: "",
            type: "box",
            open: undefined,
            color: AppConstants.PICSTORK_WINDMILL_MARKER.COLOR,
            className: "",
            ...(this.convertRegion(reg))
          }
        }) : [],
        image: this.state.imageList[val]?.image,
        detection: this.state.imageList[val],
        selected: val,
        regions: [],
        regionLoaded: false,
        key: val,
        imageSize: Number(imageData?.file_size),
        imageHeight: Number(imageData?.height),
        imageWidth: Number(imageData?.width)
      }), () => {
        this.getRegions();
        this.getBladeSide()
      });
    } else {
      this.setState((state) => ({
        ...state,

        image: this.state.imageList[val]?.image,
        detection: this.state.imageList[val],
        selected: val,
        regions: [],
        regionLoaded: false,
        key: val,
        imageSize: Number(imageData?.file_size),
        imageHeight: Number(imageData?.height),
        imageWidth: Number(imageData?.width)
      }), () => {
        this.state.isThermalImage && this.fetchThermalMeta()
        this.getRegions();
      });
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyPress, false);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyPress, false);
  }

  taskIsInCreating = () => {
    // return this.state.task.status === AppConstants.TASK.STATUS.CREATING
    return this.checkEditAccess() && (this.state.editAnnotations || this.state.task.tasktype == 4)
  }

  // pass id for highlight one region only
  highlightClassRegions = (cls, id) => {
    this.setState({
      activeClass: cls,
      regions: this.state.regions.map(region => id && region.id === id ? { ...region, highlight: true } : (region.className === cls ? { ...region, highlight: true } : { ...region, highlight: false }))
    })
  }
  setError = (e, title, showMsgOnly) => {
    !showMsgOnly && this.showSeverity()
    this.setState((state) => ({
      ...state,
      showProcessPopup: true,
      processPopupIcon: "ERROR",
      title: title || (this.state.task.sharedBy?.isShared || !this.props.permissions?.[PICSTORK_FEATURES.REPORTS].EDIT ? "Modification restricted" : "Select Edit"),
      processMessage: !this.props.permissions?.[PICSTORK_FEATURES.REPORTS].EDIT ? "You are not allowed." : (this.state.task.sharedBy?.isShared ? `Cannot modify detections as your task is shared by ${this.state.task.sharedBy?.first_name}`
        : (e || "Please select 'Edit' mode to modify the detection result."))
    }))
  }

  closeActivityPopup = () => {
    this.setState({ showProcessPopup: false })
  }

  onCopyRegion = (region) => {

    // this.highlightAnnotation();
    this.setState({
      regionToCopy: region,
      regions: this.state.regions.map((r) => region && r.id == region.id ?
        {
          ...r,
          color: this.state.isThermalImage ? r.color : AppConstants.COLORS.COPYANNOTATIONCOLOR,
          copy: true,
          tempColor: this.state.isThermalImage ? r.tempColor : AppConstants.COLORS.COPYANNOTATIONCOLOR
        }
        : {
          ...r,
          color: this.state.isThermalImage ? r.id === this.state?.selectedRegion?.id ? AppConstants.THERMAL.COLOR.SELECTED : (r.type === "point" ? AppConstants.THERMAL.COLOR.POINT : r.type === "box" ? AppConstants.THERMAL.COLOR.BOX : r.type === "line" ? AppConstants.THERMAL.COLOR.LINE : this.state.classes.find(cls => cls.name === r.className).color) : this.state.classes.find(cls => cls.name === r.className).color,
          copy: false,
          tempColor: this.state.isThermalImage ? r.id === this.state?.selectedRegion?.id ? AppConstants.THERMAL.COLOR.SELECTED_TEMP : (r.type === "point" ? AppConstants.THERMAL.COLOR.POINT : r.type === "box" ? AppConstants.THERMAL.COLOR.BOX : r.type === "line" ? AppConstants.THERMAL.COLOR.LINE : AppConstants.COLORS.COPYANNOTATIONCOLOR) : AppConstants.COLORS.COPYANNOTATIONCOLOR
        }
      )
    });
  }

  deleteClass = (className) => {
    this.setState({
      classToDelete: className,
      classDelete: true,
      itemName: "Class",
      processAction: "DELETE",
      processPopupIcon: "WARNING",
      processMessage: `All the annotations of '${className}' will be deleted, are you sure you want to delete this class label ?`,
      showProcessPopup: true,
    })
  }

  editClassName = (oldName, newName) => {
    if (this.taskIsInCreating()) {
      let objects = this.state.task.type
      let currentObjectIndex = objects.findIndex(o => o == oldName)
      objects[currentObjectIndex] = newName
      let classes = this.state.classes
      let currentClassIndex = classes.findIndex(c => c.name == oldName)
      classes[currentClassIndex].name = newName

      this.setState({
        regions: this.state.regions.map((r) => {
          if (r.className == oldName) {
            r.className = newName
            r.data.datatype = newName
          }
          return r
        }),
        type: objects,
        objects,
        classes: classes,
        selectedRegion: this.state.selectedRegion && this.state.selectedRegion.className === oldName ? { ...this.state.selectedRegion, className: newName } : this.state.selectedRegion
      }, () => {
        let detectionData = this.state.detection
        let detections = detectionData.result.detections[oldName]
        detectionData.result.detections[newName] = detections
        delete detectionData.result.detections[oldName]
        detectionData.result.objects = this.state.type
        API.updateTaskDetails(this.state.task.task_id, { classes: this.state.type }).then((result) => {
          this.props.onClassListUpdated(this.state.type)
          this.setState({ task: result.task, detection: detectionData }, async () => {
            await this.initObjects()
            this.saveFile(false)
          })
        })
      })
    }
  }

  deleteClassData = () => {
    let { classToDelete, type, objects, classes } = this.state
    let updatedClassses = this.state.type.filter((t) => t != classToDelete)
    this.setState({
      objects: updatedClassses,
      type: updatedClassses,
      classes: this.state.classes.filter((c) => c.name != this.state.classToDelete),
      regions: this.state.regions.filter((r) => r.className != classToDelete),
      selectedRegion: undefined,
    }, async () => {
      this.showSeverity()
      let detectionData = this.state.detection
      delete detectionData.result.detections[classToDelete]
      detectionData.result.objects = this.state.type
      API.updateTaskDetails(this.state.task.task_id, { classes: this.state.type }).then((result) => {
        this.props.onClassListUpdated(this.state.type)
        this.setState({ task: result.task, detection: detectionData }, async () => {
          await this.initObjects()
          this.saveFile(false)
        })
      })
      this.setState({
        showProcessPopup: false,
        classToDelete: "",
      })
    })
  }
  checkEditAccess = () => {
    return this.props.permissions?.[PICSTORK_FEATURES.REPORTS].EDIT && (this.props.data.task.sharedBy && this.props.data.task.sharedBy?.isShared ? this.props.data.task.sharedBy?.access_level == AppConstants.accessLevels.DOWNLOAD : true)
  }
  setRegionEditable = (id, forWindMill) => {

    if (forWindMill && this.taskIsInCreating()) this.setState({ windMillRegions: this.state.windMillRegions.map(r => r.id === id ? { ...r, editable: true } : { ...r, editable: false }), selecteWindMillRegion: { ...this.state.selecteWindMillRegion, editable: id ? true : false } })
    else if (this.taskIsInCreating()) this.setState({ regions: this.state.regions.map(r => r.id === id ? { ...r, editable: true } : { ...r, editable: false }), selectedRegion: { ...this.state.selectedRegion, editable: id ? true : false } });
    else if (id) this.setError()
    if (id) {
      if (forWindMill) {
        this.setState(state => {
          const index = state.windMillRegions.findIndex(reg => reg.id === id);
          const clickedRegion = state.windMillRegions[index]
          const updatedPolygons = [
            ...state.windMillRegions.slice(0, index),
            ...state.windMillRegions.slice(index + 1),
            clickedRegion,
          ];

          return {
            ...state, clickedRegion, windMillRegions: updatedPolygons
          }
        })
      } else {

        this.setState(state => {
          const index = state.regions.findIndex(reg => reg.id === id);
          const clickedRegion = state.regions[index]
          const updatedPolygons = [
            ...state.regions.slice(0, index),
            ...state.regions.slice(index + 1),
            clickedRegion,
          ];

          return {
            ...state, clickedRegion, regions: updatedPolygons
          }
        })
      }
    }
  }

  onChange(regions) {
    if (this.state.editAnnotations) {
      this.setState((prevState) => ({
        ...prevState,
        regions: regions,
      }));
    }
  }

  onClickRegion = (region, forWindMill, calledFromList) => {

    if (calledFromList) this.toggleClickedFromList(true)
    else this.toggleClickedFromList(false)
    this.showSeverity(region?.id);

    this.setState({
      selectedRegion: region,
    })
    if (region.id && region.id !== this.state.clickedRegion?.id) {
      if (forWindMill) {
        this.setState(state => {
          const index = state.windMillRegions.findIndex(reg => reg.id === region.id);
          const clickedRegion = state.windMillRegions[index]
          const updatedPolygons = [
            clickedRegion,
            ...state.windMillRegions.slice(0, index),
            ...state.windMillRegions.slice(index + 1),
          ];

          return {
            ...state, clickedRegion, windMillRegions: updatedPolygons
          }
        })
      } else {

        this.setState(state => {
          const index = state.regions.findIndex(reg => reg.id === region.id);
          const clickedRegion = state.regions[index]
          const updatedPolygons = [
            clickedRegion,
            ...state.regions.slice(0, index),
            ...state.regions.slice(index + 1),
          ];

          return {
            ...state, clickedRegion, regions: updatedPolygons
          }
        })
      }
    }


  }

  changeRegionData(index, event) {
    let regions = this.state.regions
    let obj = {
      datatype: event && event.target ? event.target.value : event,
      percentage: this.state.regions[index].data.percentage ? this.state.regions[index].data.percentage : '100',
      id: this.state.regions[index].data.id ? this.state.regions[index].data.id : this.state.regions.length
    }
    regions[index].className = event.target ? event.target.value : event
    regions[index].data = obj
    this.onChange(regions)
  }
  relocateImage = (e) => {
    // this is used to relocate image
    this.setState({
      emptyImage: true
    }, () => {
      this.setState({ emptyImage: false })
    })
  }

  importRegions = (regions, newClasses) => {
    if (this.state.editAnnotations && this.checkEditAccess()) {
      let objects = [...this.state.type.map((t, i) => {
        return { id: i, type: t, selected: true }
      }), ...(newClasses.map((cls, i) => {
        return { id: this.state.type.length + i, type: cls.name, selected: true }
      }))]
      let tempType = [...this.state.type, ...newClasses.map((cls) => cls.name)]
      this.setState({
        classes: [...this.state.classes, ...newClasses],
        obj: [...objects, {}],
        filter: [...objects, {}],
        type: tempType,
        regions: [...this.state.regions, ...regions]
      }, () => {
        API.updateTaskDetails(this.state.task.task_id, { classes: this.state.classes }).then((result) => {
          this.props.onClassListUpdated(this.state.type)
          this.saveFile(true)
          this.setState({ task: result.task })
        })
      })
    }
    else {
      this.setError()
    }
  }

  showFilterRemoveWarning = () => {
    this.setState((prevState) => ({
      ...prevState,
      editAnnotations: this.state.task.tasktype == 4,
      itemName: "",
      processPopupIcon: "WARNING",
      processAction: "",
      processMessage: "All filters will be removed, are you sure you want to edit the report ? ",
      showProcessPopup: true,
    }));
  }

  highlightAnnotation = (id) => {
    this.togglePanning(true)
    this.setState({ lineToolActive: false, pointToolActive: false })
    this.onClickRegion(this.state.regions.find(reg => reg.id === id), false, true)
  }

  render() {
       const regionStyle = {
      background: "rgba(255, 0, 0, 0.1)",
      outline: 'black solid 1px',
      border: 'none'
    };
    const { imageList, imageListindex, indexLength, detection, type, sidebarMode,
      selected, showBackIcon, report, task, user } = this.state;

    const textAlignCenter = {
      textAlign: "center",
      padding: "7px",
      borderBottom: "1px solid #fffff",
    };
    const imageContainer = {
      height: "100%",
      width: "100%",
    };
    const itemImg = {
      width: "100%",
      height: "100%",
    };

    return user.username ? (
      <div className="wrapper" style={{ width: '100%' }}>
        <div style={{ width: '100%' }}>
          <Modal show={this.state.showPopup} onHide={() => this.close()} centered>
            <Modal.Body>
              <p>{this.state.message}</p>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="add-user-form-submit-button"
                style={{ width: "fit-content" }}
                onClick={() => this.close()}
              >
                Ok
              </button>
            </Modal.Footer>
          </Modal>
          <Modal show={this.state.addObjectPopup} onHide={() => this.close()} centered>
            <Modal.Body>
              <div className="add-user-form-row-left">
                <div className="add-user-form-label">
                  Add object
                </div>
                <div className="add-user-form-text-wrapper">
                  <input
                    className="add-user-form-text"
                    type="text"
                    name="newObject"
                    placeholder="Enter object Name"
                    onChange={this.onChangeListener}
                    value={this.state.newObject}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                <button
                  className="add-user-form-submit-button"
                  style={{ width: "fit-content" }}
                  onClick={() => {
                    this.changeRegionData(this.state.regionIndex, "-1")
                    this.close()
                  }}
                >
                  Cancel
                </button>
                <button
                  className="add-user-form-submit-button"
                  style={{ width: "fit-content" }}
                  onClick={() => this.setState((state) => ({
                    ...state,
                    addObjectPopup: false
                  }), () => this.addNewObject())}
                >
                  Add
                </button>
              </div>
            </Modal.Footer>
          </Modal>

          {/*------------------------------ Apply Filter popup -----------------------------*/}
          <AnimatedModal
            height="400px"
            width="600px"
            onRequestClose={() => {
              this.setState((state) => ({
                ...state,
                showFilterPopup: false,
                severity: this.state.selectedSeverity || "All"
              }))
            }}
            isOpen={this.state.showFilterPopup}
          >
            <div>
              <div style={{ borderBottom: "1px solid #EAEAEA", margin: "1% 2%", display: "flex", justifyContent: "left", alignItems: "center", height: "60px" }}>
                <div className='content-text' style={{ fontSize: "15px", fontWeight: "500", marginLeft: "3%", color: "#3C3C3C" }}>
                  {"Select Filter Setting"}
                </div>
                <div style={{ position: 'absolute', top: '10px', right: '12px', }}>
                  <img src={CLOSE} onClick={() => {
                    this.setState((state) => ({
                      ...state,
                      showFilterPopup: false
                    }))
                  }} style={{ cursor: 'pointer', height: '20px', width: '20px' }} />
                </div>
              </div>
              <><div className='content-text' style={{ fontSize: "14px", fontWeight: "400", textAlign: "left", margin: "3% 5%" }}>
                <div style={{ marginBottom: '15px' }}>
                  <div className="add-user-form-label" style={{ color: "#3C3C3C" }}>
                    Severity
                  </div>
                  <div className="add-user-form-text-wrapper">
                    <DropDownWithButton
                      selectedItem={this.state.severity == "All" ? "All" : getSevirityNumber(this.state.severity)}
                      width="160px" textFontSize="11px"
                      isBorder
                      list={[
                        { name: AppConstants.SEVIRITY._1, onClick: () => { this.setState({ severity: '1' }) } },
                        { name: AppConstants.SEVIRITY._2, onClick: () => { this.setState({ severity: '2' }) } },
                        { name: AppConstants.SEVIRITY._3, onClick: () => { this.setState({ severity: '3' }) } },
                        { name: AppConstants.SEVIRITY._4, onClick: () => { this.setState({ severity: '4' }) } },
                        { name: AppConstants.SEVIRITY._5, onClick: () => { this.setState({ severity: '5' }) } },
                        { name: "All", onClick: () => { this.setState({ severity: "All" }) } },
                      ]}
                    />
                  </div>
                </div>
                <div>
                  {this.state.type && this.state.type.length > 0 ? (
                    <div className="add-user-form-label" style={{ color: "#3C3C3C" }}>
                      Objects <br />
                      <div style={{ margin: "1%", display: "flex", padding: "10px", marginLeft: "-5px", overflow: "auto", height: "155px" }}>
                        <div
                          className="rpas-model-info-primary-details-value1"
                          style={{ marginLeft: "-12px", marginTop: "-4px" }}
                        >

                          <>  {this.state.obj && this.state.obj.length > 0 ? <>
                            {this.state.obj.map((ob, key) => {
                              return (
                                <>
                                  <button
                                    style={{
                                      backgroundColor: (!ob.type && this.state.selectAll) || (this.state.obj[key].type && this.state.obj[key].selected) ? "#c4c4c4" : "#FFFFFF",
                                      color: (!ob.type && this.state.selectAll) || (this.state.obj[key].type && this.state.obj[key].selected) ? "#000000c7" : "#666666",
                                      fontSize: "11px",
                                      margin: "2px 4px",
                                      borderRadius: "15px",
                                      padding: "2px 12px",
                                      outline: "none",
                                      border: (!ob.type && this.state.selectAll) || (this.state.obj[key].type && this.state.obj[key].selected) ? "1px solid #FFFFFF" : "1px solid #666666"
                                    }}
                                    onClick={() => {
                                      if (ob?.type) {
                                        let temp = this.state.obj;
                                        temp[key].selected =
                                          !this.state.obj[key].selected;
                                        this.setState({ filter: temp })

                                      } else {
                                        this.setState((state) => ({
                                          ...state,
                                          selectAll: !this.state.selectAll
                                        }), () => this.toggleSelecetAll())
                                      }
                                    }}
                                  >
                                    {ob.type ? ob.type : "Select all"}
                                  </button>
                                </>
                              );
                            })}
                          </> : (
                            ""
                          )}</>

                        </div>
                      </div>
                    </div>
                  ) : <></>}
                </div>
              </div>
                <div style={{ display: "flex", position: "absolute", bottom: "4%", right: "3%" }}>
                  <button
                    disabled={this.state.filter.filter((data) => { return data.type && data.selected == true }).length===0}
                    style={{ paddingTop: "7px", paddingBottom: "7px", background: "#2989CF", color: "white",opacity:this.state.filter.filter((data) => { return data.type && data.selected == true }).length===0?'0.5':'1' }}
                    className="addcustomer-button-empty-dashboard popup-btn-right btn-ok"
                    onClick={async () => {
                      let selectedObj = this.state.filter.filter((data) => { return data.type && data.selected == true })
                      if (selectedObj.length === 0) {
                        this.toggleSelecetAll()
                      } else {

                        this.setState(
                          (state) => ({
                            ...state,
                            obj: this.state.filter,
                            showAllImages: selectedObj.length === this.state.task.type.length ? true : false,
                            selectAll: selectedObj.length === this.state.task.type.length ? true : false
                          })
                          , () => {
                            this.setState({
                              type: this.state.obj.filter(ob => ob?.selected && ob?.type).map(ob => ob?.type),
                            })
                          });
                      }
                      this.setState({
                        selectedSeverity: this.state.severity,
                        panning: true,
                        editAnnotations: (this.state.task.tasktype == 4) || false
                      }, () => {
                        this.getRegions()
                      })
                    }}
                  >
                    Apply
                  </button>
                </div>
              </>

            </div>
          </AnimatedModal>
          {/*-------------------------------------------------------------------------------------------- */}
          {/* -------------- Activity Popup ----------------------- */}
          <ActivityPopup
            item={this.state.itemName}
            open={this.state.showProcessPopup}
            icon={this.state.processPopupIcon}
            title={this.state.title}
            action={this.state.processAction}
            msg={this.state.processMessage}
            onClickOk={() => {
              if (this.state.classDelete)
                this.deleteClassData()
              else
                this.setState(
                  (state) => ({
                    ...state,
                    showProcessPopup: false,
                    editAnnotations: !this.state.editAnnotations,
                    panning: false,
                    // showDiv: !this.state.editAnnotations,
                    type: this.state.task.type,
                    showAllImages: true,
                    selectedSeverity: 'All',
                    selectAll: true,
                  }), () =>
                  this.initObjects())
            }}
            close={() => this.closeActivityPopup()}
          />
          {/* --------------------------------------------------------------- */}

          {/* -------------- Activity Popup to clear Annotations --------------------------------------- */}
          <ActivityPopup
            item="WARNING"
            open={this.state.clearAnnotationPopup}
            icon="WARNING"
            title="WARNING"
            msg={"Are you sure you want to clear all annotations ?"}
            onClickOk={() => {
              this.setState({ regions: [], clearAnnotationPopup: false, selectedRegion: undefined }, async () => {
                this.saveFile(true)
              })
            }}
            close={() => { this.setState({ clearAnnotationPopup: false }) }}
          />
          {/* ------------------------------------------------------------------------------------------ */}

          {/*<TopBar user={user} sidebarMode={sidebarMode} type={type} pageTitle = {'SERVICES'}></TopBar>*/}
          {
            this.state.loading ? (
              <div style={{ height: "100%", width: "100%", display: "flex", textAlign: "center" }}>
                <img src={Loader} style={{ height: "30vh", width: "100%", margin: "auto" }} />
              </div>
            ) : (
              <div style={{ background: "none", width: '100%', height: '100%' }}>
                <>
                  <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
                    <div>
                      <span className={"onhoverext hoverPointer"}
                        onClick={() => this.props.changeView(1)}
                        style={{
                          color: "#2989cf",
                          fontSize: "12px",
                        }}>Task</span><span style={{
                          fontSize: "12px",
                        }}>&nbsp; /&nbsp; </span><span className={"onhoverext hoverPointer"}
                          onClick={() => this.props.changeView(2)}
                          style={{
                            color: "#2989cf",
                            fontSize: "12px",
                          }}>Report</span><span style={{
                            fontSize: "12px",
                          }}>&nbsp; /&nbsp; </span>Image
                    </div>

                    <div style={{ display: "flex" }}>
                      <div><ButtonWithIcon text="Clear" width="90px"
                        isDisabled={!((this.state.regions?.length) && (this.props.permissions?.[PICSTORK_FEATURES.REPORTS].EDIT))}
                        onClick={() => {
                          this.setState({ clearAnnotationPopup: true })
                          // this.showSeverity()
                        }}
                      /></div>
                      <div>
                        {!this.state.isThermalImage && <ImportExportImgRegion
                          imageData={
                            {
                              imageSize: this.state.imageSize,
                              height: this.state.imageHeight,
                              width: this.state.imageWidth,
                              imageName: this.state.selectedImage,
                            }
                          }
                          regions={
                            this.state.regions
                          }
                          classes={this.state.classes}
                          importRegions={this.importRegions}
                          importDisable={!((this.state.selectedSeverity == "All" && this.state.selectAll) && this.props.permissions?.[PICSTORK_FEATURES.REPORTS].EDIT && ((this.props.data.task.sharedBy && this.props.data.task.sharedBy?.isShared) ? this.props.data.task.sharedBy?.access_level == AppConstants.accessLevels.DOWNLOAD : true))}
                          exportDisable={!(this.state.regions && this.state.regions.length && this.props.permissions?.[PICSTORK_FEATURES.REPORTS]?.DOWNLOAD)}
                          addSeverityData
                        />}
                      </div>
                    </div>
                  </div>

                  <div id='container-full-screen' style={{ display: "flex", justifyContent: 'space-between', marginTop: "8px", width: "100%", height: "94%", background: 'white', padding: '10px 0px 10px 10px' }}>
                    {this.state.imageLoad ?
                      <img src={Loader} style={{ position: "absolute", zIndex: 5, height: "20%", width: "60%", marginTop: "15%" }} />
                      : ''
                    }
                    <div style={{ height: '100%', backgroundColor: "#3C3C3C" }} className="thermal-annotation-width" >
                      {/* Image header */}
                      <div
                        style={{
                          height: '38px',
                          display: "flex",
                          width: "100%",
                          zIndex: 3,
                          borderRadius: "3px 3px 0 0",
                          padding: "0px 10px",
                          alignItems: "center",
                          justifyContent: 'center',
                          color: "#FFFFFF",
                          backgroundColor: 'rgb(0 0 0 / 0.6)',
                        }}>
                        <div style={{ fontFamily: "Poppins", fontSize: '13px' }}>{detection?.image}</div>
                        <div style={{ fontFamily: "Poppins", marginLeft: '1%', fontSize: '12px' }}>{` (${this.state.key + 1} out of ${this.state.imageList?.length})`}</div>
                        <div style={{ display: "flex", marginLeft: 'auto', alignItems: "center" }}>
                          {this.state.polygonToolActive ? <div style={{ marginTop: "4px", fontSize: "10px" }}>
                            (Use right click to save polygon)
                          </div> : ''}
                          {this.state.task.tasktype != 4 ? <div className="bar-text" onClick={() => {
                            if (!this.state.imageLoad) {
                              if (this.checkEditAccess()) {
                                if (this.state.editAnnotations) {
                                  this.showSeverity()
                                  this.onCopyRegion()
                                  this.setState({
                                    editAnnotations: false,
                                    panning: true,
                                    showDiv: false
                                  })
                                } else {
                                  if (this.state.selectedSeverity == "All" && this.state.selectAll) {
                                    this.setState(
                                      (state) => ({
                                        ...state,
                                        showProcessPopup: false,
                                        editAnnotations: !this.state.editAnnotations,
                                        panning: false,
                                        showDiv: !this.state.editAnnotations,
                                        type: this.state.task.type,
                                        showAllImages: true,
                                        selectAll: true,
                                        selectedSeverity: 'All',
                                      }), () =>
                                      this.initObjects())
                                  } else {
                                    this.showFilterRemoveWarning()
                                  }
                                }
                              } else {
                                this.setError()
                              }
                            }
                          }}>
                            {!this.state.editAnnotations ? <img width={"22px"} src={whitePenIcon} /> : <img width={"21px"} src={whiteTickIcon} />}
                          </div> : <></>}
                          {(!this.state.editAnnotations || this.state.task.tasktype == 4) && this.state.task?.task_purpose !== "Counting" ? <div className="bar-text" onClick={() => { this.showFilterPopup() }}>
                            <img width={"21px"} src={whiteFilterIcon} />
                          </div> : <></>}
                        </div>
                      </div>
                      {this.state.regions && !this.state.emptyImage ? <><div style={{ display: 'flex', height: "calc(100% - 40px)" }}>

                        <Annotation
                          ref={this.Annotator}
                          disabledUpdate={!(this.taskIsInCreating())}
                          setRegionEditable={this.setRegionEditable}
                          setActiveClass={(cls) => this.setState({ activeClass: cls })}
                          showSeverity={this.showSeverity}
                          highlightClassRegions={this.highlightClassRegions}
                          onCopyRegion={this.onCopyRegion}
                          regionToCopy={this.state.regionToCopy}
                          onClickRegion={this.onClickRegion}
                          panning={this.state.panning}
                          togglePanning={this.togglePanning}
                          regionAdded={this.onRegionAdded}
                          userWidth={this.state.isWindMillData ? "88%" : ""}
                          // onClassUpdate={this.onClassUpdate}
                          classList={this.state.type}
                          onDeleteRegion={this.onDeleteRegion}
                          onRecenter={this.relocateImage}
                          regions={
                            this.state.regions
                          }
                          removeRegion={(regionId) => this.setState({ regions: this.state.regions.filter(region => region.id !== regionId) })}
                          classesWithColor={this.state.classes}
                          imageSrc={this.state.emptyImage || this.state.imageURL}
                          onImageOrVideoLoaded={(e) => this.setState({ imageLoad: false })}
                          setError={(e, title, showMsgOnly) => this.setError(e, title, showMsgOnly)}
                          imgWidth={this.state.imageWidth}
                          imgHeight={this.state.imageHeight}
                          permissions={this.props.permissions}
                          isThermalImage={this.state.isThermalImage}
                          getSinglePixleTempMethod={this.getSinglePixleTempMethod}
                          pointToolActive={this.state.pointToolActive}
                          lineToolActive={this.state.lineToolActive}
                          polygonToolActive={this.state.polygonToolActive}
                          autoSegmentationToolActive={this.state.autoSegmentationToolActive}
                          disableTemp={this.state.disableTemp}
                          changeTool={this.changeTool}
                          setActivityPopup={(activity) => {
                            this.setState({ ...activity })
                          }}
                        // currTempCalculated={this.state.currTempCalculated}
                        // toggleCurrTempCalculated={(value)=>{this.setState({currTempCalculated:value})}}
                        />
                        {this.state.imageList?.length > 0 ?
                          <div style={{ display: "flex", justifyContent: 'space-between', flexDirection: 'column', padding: "2px", alignItems: 'end' }}>
                            <div style={{ display: "flex", height: '', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: "column", cursor: 'pointer', width: "35px", margin: '3px', borderRadius: "10px", background: 'rgba(0,0,0,0.5)', gap: "15px", padding: "15px" }}>
                              {/* cursor */}
                              <img src={((this.state.lineToolActive || this.state.pointToolActive) && this.state.isThermalImage) || this.state.polygonToolActive ? cursorInactive : this.state.panning ? cursorActive : cursorInactive} style={{
                                pointerEvents: 'all',
                                width: this.state.panning ? '23px' : '23px',
                              }}
                                onClick={() => {
                                  this.setState({ pointToolActive: false, lineToolActive: false, polygonToolActive: false, autoSegmentationToolActive: false })
                                  this.togglePanning(true)
                                }} />


                              {this.state.isThermalImage &&
                                <>
                                  {/* line tool */}
                                  <img src={this.state.lineToolActive ? LineToolActive : LineToolInactive} style={{
                                    pointerEvents: 'all',
                                    width: this.state.panning ? '23px' : '23px',
                                    opacity: this.props.permissions?.[PICSTORK_FEATURES.ANNOTATIONS].CREATE ? '1' : '0.5',
                                  }}
                                    onClick={() => {
                                      if (this.props.permissions?.[PICSTORK_FEATURES.ANNOTATIONS].CREATE) {
                                        this.togglePanning(false)
                                        this.setState({ pointToolActive: false, lineToolActive: true })
                                      }
                                    }
                                    }
                                  />


                                  {/* point tool */}
                                  <img src={this.state.pointToolActive ? PointToolActive : PointToolInactive} style={{
                                    pointerEvents: 'all',
                                    width: '19px',
                                    marginBottom: "4px",
                                    opacity: this.props.permissions?.[PICSTORK_FEATURES.ANNOTATIONS].CREATE ? '1' : '0.5',
                                  }}
                                    onClick={() => {
                                      if (this.props.permissions?.[PICSTORK_FEATURES.ANNOTATIONS].CREATE) {
                                        this.togglePanning(false)
                                        this.setState({ pointToolActive: true, lineToolActive: false, polygonToolActive: false })
                                      }
                                    }
                                    }
                                  />
                                </>
                              }

                              {/* box tool */}
                              <img src={((this.state.lineToolActive || this.state.pointToolActive) && this.state.isThermalImage) || this.state.polygonToolActive || this.state.autoSegmentationToolActive ? BoxAnnotation : this.state.panning ? BoxAnnotation : BoxAnnotationActive} style={{
                                pointerEvents: "all",
                                width: "18px",
                                marginBottom: "4px 0px",
                                opacity: this.props.permissions?.[PICSTORK_FEATURES.ANNOTATIONS].CREATE ? '1' : '0.5',
                              }}
                                onClick={() => {
                                  if (this.props.permissions?.[PICSTORK_FEATURES.ANNOTATIONS].CREATE) {
                                    this.togglePanning(false);
                                    this.setState({ pointToolActive: false, lineToolActive: false, polygonToolActive: false, autoSegmentationToolActive: false })
                                  }
                                }} />

                              {/* polygon tool */}
                              {!this.state.isThermalImage && <img src={this.state.polygonToolActive ? PolygonToolActive : PolygonToolInActive} style={{
                                pointerEvents: "all",
                                width: "20px",
                                marginBottom: "4px 0px"
                              }}
                                onClick={() => {
                                  this.togglePanning(false);
                                  this.setState({ pointToolActive: false, lineToolActive: false, polygonToolActive: true, autoSegmentationToolActive: false })

                                }} />}
                              {/* auto segmentation tool */}
                              {/* <img src={this.state.autoSegmentationToolActive ? AutoSegmentationToolActive : AutoSegmentationTool} style={{
                                pointerEvents: 'all',
                                width: "22px",
                              }}
                                onClick={() => {
                                  this.togglePanning(false);
                                  this.setState({ pointToolActive: false, lineToolActive: false, polygonToolActive: false, autoSegmentationToolActive: true })
                                }} /> */}

                              {/* recenter tool */}
                              <img src={locateWhiteIcon} style={{
                                pointerEvents: 'all',
                                width: "22px",
                              }}
                                onClick={() => {
                                  this.relocateImage();
                                }} />


                            </div>
                            {
                              this.state.isWindMillData &&
                              <div style={{ width: "118px", zIndex: "99", height: "45%", position: "relative", background: "white" }}>

                                <Annotation
                                  ref={this.WindMillAnnotator}
                                  checkForWindMill={true}
                                  disabledUpdate={!(this.taskIsInCreating())}
                                  setRegionEditable={(id) => this.setRegionEditable(id, true)}
                                  setActiveClass={() => { }}
                                  showSeverity={() => { }}
                                  highlightClassRegions={() => { }}
                                  onCopyRegion={() => { }}
                                  regionToCopy={undefined}
                                  onClickRegion={(region) => this.onClickRegion(region, true)}
                                  panning={this.state.panning}
                                  togglePanning={(val) => this.togglePanning(val, true)}
                                  regionAdded={(region) => this.onRegionAdded(region, false, true)}
                                  onClassUpdate={() => { }}
                                  classList={[]}
                                  onDeleteRegion={(id) => this.onDeleteRegion(id, true)}
                                  onRecenter={this.relocateImage}
                                  regions={
                                    this.state.windMillRegions
                                  }
                                  ponitDrawing
                                  removeRegion={(regionId) => this.setState({ windMillRegions: this.state.windMillRegions.filter(region => region.id !== regionId) })}
                                  classesWithColor={[]}
                                  imageSrc={this.state.bladeSide}
                                  onImageOrVideoLoaded={(e) => this.setState({ imageLoad: false })}
                                  setError={(e) => this.setError(e)}
                                />
                                <div style={{ padding: "0px", position: "absolute", top: "6px", right: "18px", color: "white", fontSize: "12px", zIndex: "999", cursor: "pointer", fontWeight: "500" }}><button style={{ backgroundColor: "transparent", color: "#3c3c3c", padding: "1px" }} onClick={() => {
                                  if (this.state.windMillRegions.length > 0) this.onDeleteRegion(undefined, true)
                                }} >Undo</button>
                                </div>
                                <div style={{ left: "9px", bottom: "12px", position: "absolute", fontSize: "23px", color: "#3c3c3c" }}>{this.state.selectedWindMillImage.windmillData.side}</div>
                              </div>
                            }
                            <div id="myAnimation" className="slide" style={{ pointerEvents: "all", cursor: 'pointer', height: this.state.showDiv ? "130px" : '32px' }} >
                              <img src={this.state.showDiv ? closeButton : SliderIcon} style={{
                                width: "30px",
                                marginRight: "3px",
                                borderRadius: "30px",
                                backgroundColor: 'rgba(0,0,0,0.8)',
                                position: 'relative',
                                zIndex: 999
                              }}
                                onClick={() => {
                                  this.setState((state) => ({
                                    ...state,
                                    showDiv: !this.state.showDiv
                                  }))
                                }} />
                            </div>
                          </div>
                          :
                          <></>}
                      </div>

                      </> : <></>}
                      {this.state.regions ? <>
                        {this.state.showDiv ?
                          (
                            <ReusableImageSlider
                              imageList={this.state.imageList}
                              selectedImageIndex={this.state.selected}
                              onClickImage={(image, selected) => {
                                this.setState((state) => ({
                                  ...state,
                                  selected: selected,
                                  showDiv: true,
                                  imageLoad: true
                                }), () => {
                                  this.getImageDataOnImageChange().then(
                                    () => this.changeImage(selected)
                                  )
                                });
                              }}
                              onClickNext={
                                () => {
                                  this.setState({ imageLoad: true })
                                  this.getImageDataOnImageChange().then(
                                    () => this.nextImage()
                                  );
                                }
                              }
                              onClickPrev={
                                () => {
                                  this.setState({ imageLoad: true })
                                  this.getImageDataOnImageChange().then(
                                    () => this.prevImage()
                                  );

                                }
                              }
                              permissions={this.props.permissions}
                              requiredData={[task.image_collection_id]}
                            />
                          )
                          : ''}
                      </>
                        : <></>
                      }
                    </div>

                    {/* class with colour */}
                    <div style={{ display: "flex", justifyContent: 'space-between', cursor: 'pointer', right: '0%', pointerEvents: "none", zIndex: 10 }} className="thermal-width">
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          zIndex: 10,
                          pointerEvents: "all",
                          height: '100%',
                          width: "100%",
                          bottom: '0px',
                          color: '#3c3c3c'
                        }}>

                        {/* classes list */}
                        {/* <div style={{ color: "white", cursor: "default", paddingBottom: "60px" }}> */}
                        <ClassListWithColor
                          isThermalDataLoaded={this.state.isThermalDataLoaded}
                          disabledUpdate={this.state.regionToCopy || !this.checkEditAccess()}
                          onClassUpdate={this.onClassUpdate}
                          onEditClassColor={this.onEditClassColor}
                          // onEditClass={this.state.task.tasktype == 2 ? undefined : this.editClassName}
                          // onDeleteClass={this.state.task.tasktype == 2 ? undefined : this.deleteClass}
                          updateData={(key, value) => {
                            if (this.state.selectedRegion) {
                              let region = this.state.selectedRegion
                              region.data[key] = value
                              this.setState({
                                selectedRegion: region
                              })
                            }
                            if (key === 'userName') {
                              this.setState({
                                mentionedUser: value
                              })
                            } else if (this.state.selectedRegion) {
                              let region = this.state.selectedRegion
                              region.data[key] = value
                              this.setState({
                                selectedRegion: region
                              })
                            }

                          }}
                          onRegionDataUpdate={this.onRegionDataUpdate}
                          classListWithColor={this.state.classes}
                          addNewObject={this.addNewObject}
                          onClickClass={(cls) => {
                            if (this.state.panning) {
                              this.highlightClassRegions(cls.name)
                            } else {
                              this.Annotator.current.setDrawingRegion(cls.name)
                            }
                          }}
                          taskType={this.state.task.tasktype}
                          imageHeight={Number(this.state.imageHeight)}
                          imageWidth={Number(this.state.imageWidth)}
                          regions={this.state.regions}
                          activeClass={this.state.activeClass}
                          selectedRegion={this.state.selectedRegion}
                          taskIsInCreating={this.taskIsInCreating}
                          permissions={this.props.permissions}
                          isThermalImage={this.state.isThermalImage}                                //for thermal image
                          thermalMeta={this.state.thermalMeta}
                          highlightAnnotation={this.highlightAnnotation}
                          toggleClickedFromList={this.toggleClickedFromList}
                          clickFromList={this.state.clickFromList}
                          task_purpose={this.state.task.task_purpose}
                          toggleTemperature={this.toggleTemperature}
                        //for thermal meta 
                        />
                        {/* </div> */}
                        {/* classesList */}
                      </div>
                    </div>
                  </div>
                  {/* ---------------------------------- */}
                </>
              </div>
            )
          }
        </div >
      </div >
    ) : (
      <div />
    );
  }
}

export default TaskReportImageSlider;