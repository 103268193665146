import React from "react";
import L from "leaflet";
import "leaflet-draw";
import "leaflet-editable"
import "leaflet.path.drag"
import MarkerIcon from '../../assets/marker.png'
import { DRONENAKSHA_FEATURES } from "../../Teams"

// ///////3d poly area start ////////////////////

function cross(u, v) {
    return [u[1] * v[2] - u[2] * v[1],
    u[2] * v[0] - u[0] * v[2],
    u[0] * v[1] - u[1] * v[0]
    ];
}

function subtract(u, v) {
    return [u[0] - v[0], u[1] - v[1], u[2] - v[2]];
}

function length(v) {
    return Math.sqrt(v[0] * v[0] + v[1] * v[1] + v[2] * v[2]);
}

export const get3dPolygonArea = (coordinates, unit, showUnit) => {
    return new Promise(resolve => {
        let area = 0;
        const vertices = coordinates.map(coord => [coord.x, coord.y, coord.elevation])
        for (let i = 2; i < vertices.length; i++) {
            const v1 = vertices[i - 1];
            const v2 = vertices[i];
            const crossProduct = cross(subtract(v2, v1), subtract(vertices[0], v1));
            area += length(crossProduct) / 2;
        }

        switch (unit) {
            case "km":
                resolve(`${(area * 1 / 1000).toFixed(2)}  ${showUnit ? 'km' : ''}`);
                break;
            case "m":
                resolve(`${area.toFixed(2)}  ${showUnit ? 'm' : ''}`)
                break;
            case "ft":
                resolve(`${(area * 10.7639).toFixed(2)}  ${showUnit ? 'ft' : ''}`)
                break;
            default: resolve(null)
        }
    })
}


///////3d poly area end ////////////////////

////////////3d length////////////

export const getDistanceIn3d = (coordinates, unit, showUnit) => {
    return new Promise((resolve, reject) => {
        const getVector3Length = (vector1, vector2) => {
            return Math.sqrt((((vector2.x - vector1.x) ** 2) +
                ((vector2.y - vector1.y) ** 2) +
                ((vector2.elevation - vector1.elevation) ** 2)))
        }

        let threedLength = 0
        coordinates.forEach((coord, key) => {
            if (key > 0) {
                if (key === coordinates.length - 1) {
                    const totalLength = threedLength + getVector3Length(coordinates[key - 1], coord)
                    resolve(unit === "ft" ? (showUnit ? `${(totalLength * 3.2808).toFixed(2)} ft` : (totalLength * 3.2808).toFixed(2)) : (showUnit ? `${totalLength.toFixed(2)} m` : totalLength.toFixed(2)))
                }
                else {
                    threedLength += getVector3Length(coordinates[key - 1], coord)
                }
            }
        })
    })


}
//////////////end///////////////

const calculateAngle = (p1, p2, p3) => {
    const angle1 = Math.atan2(p1.lat - p2.lat, p1.lng - p2.lng);
    const angle2 = Math.atan2(p3.lat - p2.lat, p3.lng - p2.lng);
    let angle = Math.abs(angle1 - angle2) * (180 / Math.PI);
    if (angle > 180) {
        angle = 360 - angle;
    }
    return angle;
};

const removeExistingMeasurementTooltips = async (layer) => {
    if (layer.measurementTooltips) {
        for (const tooltip of layer.measurementTooltips) {
            await tooltip.remove()
        }
        layer.measurementTooltips = undefined
        return
    } else return
}

const toggleMeasurementTooltips = async (show, layer, map, unit, measurement) => {
    layer.title = measurement.name
    layer.type = measurement.type
    layer.volume = measurement.volume
    if (show) {
        layer.openTooltip()
        if (layer.measurementTooltips) {
            for (const tooltip of layer.measurementTooltips) {
                await tooltip.addTo(map)
            }
            return
        } else if (layer) addMeasurementTooltips(layer, map, unit, show)
    } else {
        layer.closeTooltip()
        if (layer.measurementTooltips) {
            for (const tooltip of layer.measurementTooltips) {
                await tooltip.remove()
            }
            return
        } else return
    }


}

const addMeasurementTooltips = async (layer, map, unit, show) => {
    const latLngs = layer instanceof L.Polyline && !(layer instanceof L.Polygon) ? layer.getLatLngs() : layer instanceof L.Polygon ? layer.getLatLngs()[0] : []
    layer.area = getArea(latLngs, unit, false, false)
    layer.distance = getDistance(latLngs, unit, false)
    layer.selectedUnit = unit
    if (latLngs.length > 1 && show) {
        for (let i = 0; i < latLngs.length; i++) {
            const p1 = latLngs[i];
            const p2 = latLngs[(i + 1) % latLngs.length];
            const p3 = latLngs[(i + 2) % latLngs.length];

            const angle = calculateAngle(p1, p2, p3);
            const distance = getDistance([p1, p2], unit, true)
            const midPoint = L.latLng((p1.lat + p2.lat) / 2, (p1.lng + p2.lng) / 2);
            if (layer instanceof L.Polyline && !(layer instanceof L.Polygon)) {

                if (i < latLngs.length - 1) {
                    const distanceToolTip = new L.tooltip({ direction: "top", offset: [0, -3], permanent: true, className: "map-popup" })
                        .setContent(`
                    ${distance}`)
                        .setLatLng(midPoint).addTo(map)
                    if (layer.measurementTooltips) layer.measurementTooltips.push(distanceToolTip)
                    else layer.measurementTooltips = [distanceToolTip]
                }
                if (!(i === latLngs.length - 2 || i === latLngs.length - 1)) {
                    const angleToolTip = new L.tooltip({ direction: "top", offset: [0, -10], permanent: true, className: "map-popup" })
                        .setContent(`${angle.toFixed(2) + '°'}`)
                        .setLatLng(p2).addTo(map)
                    if (layer.measurementTooltips) layer.measurementTooltips.push(angleToolTip)
                    else layer.measurementTooltips = [angleToolTip]
                }
            } else {
                const distanceToolTip = new L.tooltip({ direction: "top", offset: [0, -3], permanent: true, className: "map-popup" })
                    .setContent(`
                    ${distance}`)
                    .setLatLng(midPoint).addTo(map)
                if (layer.measurementTooltips) layer.measurementTooltips.push(distanceToolTip)
                else layer.measurementTooltips = [distanceToolTip]

                const angleToolTip = new L.tooltip({ direction: "top", offset: [0, -10], permanent: true, className: "map-popup" })
                    .setContent(`${angle.toFixed(2) + '°'}`)
                    .setLatLng(p2).addTo(map)
                if (layer.measurementTooltips) layer.measurementTooltips.push(angleToolTip)
                else layer.measurementTooltips = [angleToolTip]
            }
        }
    }

    const p = new L.tooltip({ direction: "top", offset: layer.type === "Polygon" ? [0, -10] : layer.type === "Polyline" ? [0, -3] : [0, -30], permanent: true, className: "map-popup" })
        .setContent(
            `${layer.type === "Polyline" ? `${layer.title}<span style="white-space: pre-line"></span> ${layer.distance} ${unit}`
                : (layer.type === "Polygon" ? `${layer.title}<span style="white-space: pre-line"></span>
                ${layer.area} ${unit}<sup>2</sup> 
          ${layer.volume && layer.volume !== null
                        ? `, ${layer.volume} ${unit}<sup>3</sup>` : ""}`
                    : `
            ${layer.title > 40 ?
                        `${layer.title.substring(0, 40)}...`
                        : `${layer.title}`
                    }
            `)}`)
        .setLatLng(layer.coordinates)
    layer.bindTooltip(p)
    if (show) layer.openTooltip()
    else layer.closeTooltip()
}

export const getDistance = (coords, unit, showUnit, from3d) => {
    var length = 0;
    if (from3d && coords[0].x) {
        for (var i = 0; i < coords.length - 1; i++) {
            const dx = coords[i + 1].x - coords[i].x;
            const dy = coords[i + 1].y - coords[i].y;
            length += Math.sqrt(dx * dx + dy * dy);
        }
    } else {
        for (var i = 0; i < coords.length - 1; i++) {
            length += L.latLng(coords[i].lat, coords[i].lng).distanceTo(L.latLng(coords[i + 1].lat, coords[i + 1].lng));
        }
    }

    switch (unit) {
        case "km":
            return (`${(length * 1 / 1000).toFixed(2)}  ${showUnit ? 'km' : ''}`);
        case "m":
            return (`${length.toFixed(2)}  ${showUnit ? 'm' : ''}`)
        case "ft":
            return (`${(length * 3.2808).toFixed(2)}  ${showUnit ? 'ft' : ''}`)
        default: return null
    }
}

const polygonArea = (x, y) => {
    var area = 0,
        len = x.length,
        j = len - 1;
    for (var i = 0; i < len; i++) {
        area += (x[j] + x[i]) * (y[j] - y[i]);
        j = i;
    }
    return Math.abs(area / 2);
}
export const getCenterOfPolygon = (points) => {
    const bounds = L.latLngBounds(points);
    const center = bounds.getCenter();
    return center.lat && center.lng ? `{${(center.lat).toFixed(5)},${(center.lng).toFixed(5)}}` : "-";
}
export const getCenterOfLine = (points) => {
    if (points.length < 2) {
        return null;
    }

    let totalLat = 0;
    let totalLng = 0;

    // Calculate the total sum of latitudes and longitudes
    points.forEach(point => {
        totalLat += point.lat;
        totalLng += point.lng;
    });

    // Calculate the average
    const center = {
        lat: totalLat / points.length,
        lng: totalLng / points.length,
    };

    return center.lat && center.lng ? `{${(center.lat).toFixed(5)},${(center.lng).toFixed(5)}}` : "-";
}
export const getArea = (coord, unit, showUnit, from3d) => {
    let areaInMeter = 0
    if (from3d && coord[0].x) {
        var x = coord.map(vertex => vertex.x);
        var y = coord.map(vertex => vertex.y);
        areaInMeter = polygonArea(x, y)
    } else {
        areaInMeter = L.GeometryUtil.geodesicArea(coord)
    }

    switch (unit) {
        case "km":
            return (`${(areaInMeter * 1 / 1000000).toFixed(2)} ${showUnit ? 'sq.km' : ''}`)
        case "m":
            return (`${areaInMeter.toFixed(2)} ${showUnit ? 'sq.m' : ''}`)
        case "acre":
            return (`${(areaInMeter / 4047).toFixed(2)} ${showUnit ? 'acres' : ''}`)
        case "hectare":
            return (`${(areaInMeter / 10000).toFixed(2)} ${showUnit ? 'hect.' : ''}`)
        case "ft":
            return (`${(areaInMeter * 10.7639).toFixed(2)} ${showUnit ? 'sq.ft' : ''}`)
        default: return null
    }
}

export const addEditControl = (map) => {
    L.EditControl = L.Control.extend({

        options: {
            position: 'topleft',
            callback: null,
            kind: '',
            html: ''
        },

        onAdd: function (map) {
            var container = L.DomUtil.create('div', 'leaflet-control leaflet-bar'),
                link = L.DomUtil.create('a', '', container);

            link.href = '#';
            link.title = 'Create a new ' + this.options.kind;
            link.innerHTML = this.options.html;
            L.DomEvent.on(link, 'click', L.DomEvent.stop)
                .on(link, 'click', function () {
                    window.LAYER = this.options.callback.call(map.editTools);
                }, this);

            return container;
        }

    });
}

export const layerAddedEvent = (map, setActiveEditLayer) => {
    map.on('layeradd', function (e) {
        // if ((e.layer instanceof L.Polygon) || (e.layer instanceof L.Polyline)) e.layer.on('dblclick', L.DomEvent.stop).on('dblclick', e.layer.toggleEdit);
        // setActiveEditLayer(e.layer)
    })
}

export const layerRemovedEvent = (map) => {
    map.on('layerremove', function (e) {
        // if ((e.layer instanceof L.Polygon) || (e.layer instanceof L.Polyline)) e.layer.off('dblclick', L.DomEvent.stop).off('dblclick', e.layer.toggleEdit);
    });
}

export const editEnableEvent = (map, setActiveEditLayer) => {
    map.editTools.on('editable:enable', function (e) {
        if (this.currentPolygon) this.currentPolygon.disableEdit();
        this.currentPolygon = e.layer;
        setActiveEditLayer(e.layer)
        this.fire('editable:enabled');
    });
}
export const polyLineAdded = (map, setActiveEditLayer) => {
    map.on('layeradd', function (e) {
        if ((e.layer instanceof L.Polygon) || (e.layer instanceof L.Polyline)) {
            // e.layer.on('dblclick', L.DomEvent.stop).on('dblclick', e.layer.toggleEdit);
            setActiveEditLayer(e.layer)
        }
    })
}



export const editDisableEvent = (map, saveLayer, cancel) => {
    map.editTools.on('editable:disable', function (e) { //editable:vertex:new
        let layer = e.layer
        if (layer instanceof L.Polygon) {
            if (layer.getLatLngs()[0].length > 2) {
                saveLayer(layer.getLatLngs()[0].map((latlng) => { return { lat: latlng.lat, lng: latlng.lng } }), "Polygon", layer)
            } else {
                cancel()
            }
        }
        if (layer instanceof L.Polyline && !(layer instanceof L.Polygon)) {
            if (layer.getLatLngs().length > 1) {
                saveLayer(layer.getLatLngs().map((latlng) => { return { lat: latlng.lat, lng: latlng.lng } }), "Polyline", layer)
            } else {
                cancel()
            }
        }
        delete this.currentPolygon;
    })
}

export const mapClickedEvent = (map, mapClicked) => {
    map.on("click", (evt) => {
        let clickedPoint = map.mouseEventToLatLng(evt.originalEvent)
        mapClicked(clickedPoint)
    })
}
export const drawMeasurements = (unit, toolTipShow, map, measurements, measurementClickedEvent, markerDragEnd, manualIndex, permissions) => {
    let drawnMeasurements = []
    let count = 0
    return new Promise((resolve) => {
        let polyLineRenderer = L.canvas({ padding: 0.1, tolerance: 10 ,});
        measurements.forEach((data, index) => {
            if (data.type === "Polygon" && data.coordinates[0].lat) {
                let polygon = new L.Polygon(data.coordinates, {
                    color: data.color,
                    fillOpacity: 0.1,
                    weight: 3,
                }).addTo(map).on('click', () => {
                    // polygon.enableEdit();
                    polygon.bringToBack()
                    measurementClickedEvent(data.id)
                })
                polygon.dragging = false
                polygon.volume = data.volume && Number(data.volume).toFixed(2)
                polygon.title = data.name
                polygon.type = data.type
                addMeasurementTooltips(polygon, map, unit, toolTipShow)
                drawnMeasurements.push(polygon)

            }
            if (data.type === "Polyline" && data.coordinates[0].lat) {
                let polyline = new L.Polyline(
                    data.coordinates,
                    {
                        renderer:polyLineRenderer,
                        color: data.color,
                        weight: 3,
                    }
                ).addTo(map)
                    .on('click', (e) => {
                        // polyline.enableEdit();
                        measurementClickedEvent(data.id)
                    })
                polyline.dragging = false
                polyline.title = data.name
                polyline.type = data.type
                addMeasurementTooltips(polyline, map, unit, toolTipShow)
                drawnMeasurements.push(polyline)
            }
            if (data.type === "Marker" && data.coordinates.lat) {
                var markerOptions = {
                    title: data.name,
                    clickable: true,
                    draggable: permissions?.[DRONENAKSHA_FEATURES.MAPS].EDIT ? true : false,
                    autoPanOnFocus: true,
                    icon: new L.Icon({ iconUrl: MarkerIcon, iconSize: [20, 28], iconAnchor: [10, 28] })
                }
                let Marker = new L.Marker(data.coordinates, markerOptions).addTo(map).on('click', (e) => {
                    measurementClickedEvent(data.id)
                }).on('dragend', (e) => {
                    markerDragEnd(data.id, e.target._latlng)
                })
                Marker.title = data.name
                Marker.type = data.type
                drawnMeasurements.push(Marker)
                addMeasurementTooltips(Marker, map, unit, toolTipShow)
            }

            if (++count == measurements.length) {

                resolve({ drawnMeasurements })
            }
        })
    })
}

export const hideMeasurements = async (drawnMeasurements) => {
    drawnMeasurements.forEach((layer) => {
        removeExistingMeasurementTooltips(layer)
        layer.remove()
    })
    return "done"
}

export const drawingEndEvent = (map, drawingEnd) => {
    map.editTools.on('editable:drawing:end', function (e) {
        drawingEnd(e)
    })
}

const measurementUpdated = (e, saveLayer, cancel) => {

    let layer = e.layer
    removeExistingMeasurementTooltips(layer)
    if (layer instanceof L.Polygon) {
        if (layer.getLatLngs()[0].length > 2) {
            saveLayer(layer.getLatLngs()[0].map((latlng) => { return { lat: latlng.lat, lng: latlng.lng } }), "Polygon", layer)
        }
    }
    if (layer instanceof L.Polyline && !(layer instanceof L.Polygon)) {
        if (layer.getLatLngs().length > 1) {
            saveLayer(layer.getLatLngs().map((latlng) => { return { lat: latlng.lat, lng: latlng.lng } }), "Polyline", layer)
        }
    }

}


export const vertexAddedEvent = (map, vertex) => {
    map.editTools.on('editable:vertex:new', function (e) {
        vertex(e)
    })
}


export const existingMeasurementUpdatedEvent = (map, saveLayer) => {
    map.editTools.on('editable:dragend', e => measurementUpdated(e, saveLayer))
    map.editTools.on('editable:vertex:dragend', e => measurementUpdated(e, saveLayer))
    map.editTools.on('editable:vertex:deleted', e => measurementUpdated(e, saveLayer))
}


export const onUpdateLayer = (map, updatedLayer) => {
    map.editTools.on('editable:dragend', e => updatedLayer(e.layer))
    map.editTools.on('editable:vertex:dragend', e => updatedLayer(e.layer))
    map.editTools.on('editable:vertex:deleted', e => updatedLayer(e.layer))
}
export const onRightClick = (map, vertexRightClicked) => {
    map.editTools.on('editable:vertex:contextmenu', e => vertexRightClicked(e))
}

export const isCirclesOverlapping = (circle1, circle2) => {
    return circle1._leaflet_id !== circle2._leaflet_id && ((circle1.getLatLng()).distanceTo(circle2.getLatLng())) < circle1.getRadius() + circle2.getRadius();
}

export { removeExistingMeasurementTooltips, toggleMeasurementTooltips, addMeasurementTooltips }
