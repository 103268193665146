import React, { Component } from "react";
import { Chart } from "react-google-charts";
import { connect } from "react-redux";
import * as API from "../../api.js";
// import backbutton from "../../Icon/backbutton.png";
import Loader from "../../icons/loader.svg";
import { BreadCrumbs } from '../../ReusableComponents/BreadCrumbs';
import edit from '../../icons/pen.png'
import saveImage from '../../icons/saveImage.png'
import close from '../../assets/Icons/Close.png'
import { SelectComponent } from "../../ReusableComponents/SelectComponent";
import { ActivityPopup } from "../../ReusableComponents/ActivityPopup.js";
import AppConstants, { BLOB_URL } from "../../AppConstants.js";
import { checkURLExist, getLimitedName } from "../../ReusableComponents/reusableFunctions.js";
import { ActiveRole, getFeaturesPermissions, PICSTORK_FEATURES } from "../../Teams/index.js";
import DropDownWithButton from "../../ReusableComponents/DropDownWithButton.js";
import Button from "../../ReusableComponents/Button/Button.js";

// import dateFormat from "dateformat";

class AeroCapturePdfDownload extends Component {
  state = {
    task: {
      project_name: "asd",
      plan_name: "asd",
      task_name: "asd",
      type: ["missing screws", "rusting"],
    },
    user: {},
    type: "",
    progress: 0,
    selectedObj: [],
    objectsDetected: 0,
    showDownloadPopup: false,
    loader: true,
    services: [],
    isDetectionDirty: false,
    obj: [],
    data: [],
    report: [],
    report1: [],
    objects: [],
    projects: [],
    colors: { ...this.props.location.state.task.classes.map((cls, i) => ({ color: cls.color })) },
    showBackIcon: true,
    cancel: false,
    loading: true,
    objectsDetected: 0,
    sidebarMode: this.props.location.state
      ? this.props.location.state.sidebarMode
      : "shrunk",

    reportheader: {},
    reportTitle: '',
    reportSubTitle: '',
    company_logo: '',
    company_name: '',
    editHeader: false,
    company_logo1: undefined,
    titleEdit: false,
    subTitleEdit: false,
    showDTM: true,
    reportData: [
      { title: 'Analysis Report', visibleInReport: true, reportView: 'List Small' },
      { title: 'Detailed Analysis', visibleInReport: true, reportView: 'List Small' },
      { title: 'Severity-1 Detections', visibleInReport: true, reportView: 'List Small' },
      { title: 'Severity-2 Detections', visibleInReport: true, reportView: 'List Small' },
      { title: 'Severity-3 Detections', visibleInReport: true, reportView: 'List Small' },
      { title: 'Severity-4 Detections', visibleInReport: true, reportView: 'List Small' },
      { title: 'Severity-5 Detections', visibleInReport: true, reportView: 'List Small' }
    ],
    severity1Data: [],
    severity2Data: [],
    severity3Data: [],
    severity4Data: [],
    severity5Data: [],
    objectsToDisplay: [],
    reportViews: [{ value: 'List Small' }, { value: 'List Large' }, { value: 'Grid Small' }, { value: 'Grid Large' }],
    imgData: [],
    showAnnotation: true,
    showProcessPopup: false,
    processMessage: null,
    itemName: "",
    processPopupIcon: "",
    processAction: "",
    total_resolution: 0,
    total_storage: 0,
    report_generated: '',
    report_modified: '',
    downloadFlag: '',
    generate_request: '',
    minutesDiff: 0,
    generation_status: false,
    downloadWarning: false,
    downloadProgress: false
  };

  // setColors = () => {
  //   let colors = []
  //   this.state.task.type.forEach((element, i) => {
  //     let c = "#" + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0').toUpperCase();
  //     colors.push(c)
  //   });
  //   this.setState({ colors })
  // }

  parentSidebarSwitch = () => {
    this.setState((state) => ({
      ...state,
      sidebarMode: state.sidebarMode == "shrunk" ? "expanded" : "shrunk",
    }));
  };
  updateReportHeader = () => {
    API.updateReportHeader(this.state.reportTitle, this.state.reportSubTitle, this.state.company_name, this.state.reportheader.id).then((data) => {
      console.log(data);
      if (data.status == 200) {
        this.setState((state) => ({
          ...state,
          editHeader: false,
          generation_status: true
        }))
        this.getReportHeader();
      } else {
        this.getReportHeader();
      }
    })
  }

  calculateDateDiff = () => {
    if (this.state.generated_date == null) {
      let date = new Date(0).toISOString()
      let diff = date - this.state.generate_request
      let diff1 = Math.floor(diff / 60e3)

      this.setState({
        minutesDiff: diff1
      })
    }
  }


  getReportHeader = () => {
    let task_id = this.state.task.task_id
    let report_type = 'detection'
    API.getReportHeader(task_id, report_type).then((data) => {
      if (data.data == null) {
        console.log("call add report function", data.data)
        this.addReportHeader();
      } else {
        console.log("get report header data", data.data)
        let profileURL = ""

        if (this.state.task.sharedBy && this.state.task.sharedBy.isShared) {
          profileURL = `/images/${this.state.task.sharedBy.sharedBy.first_name[0].toLowerCase()}.PNG`
        } else if (data.data.comapany_logo != null && data.data.company_logo.includes('pdrlImage')) {
          profileURL = `${data.data.companyLogo}?${this.state.permissions?.st}`
        } else if (data.data.company_logo != null) {
          profileURL = data.data.company_logo
        } else if (this.state.avatar_url && this.state.avatar_url.includes('pdrlImage')) {
          profileURL = `${this.state.avatar_url}?${this.state.permissions?.st}`
        } else if (this.state.avatar_url != null && this.state.avatar_url.includes('http')) {
          profileURL = this.state.avatar_url
        } else {
          profileURL = `/images/${this.state.user.first_name[0].toLowerCase()}.PNG`
        }

        this.setState((state) => ({
          ...state,
          reportheader: data.data,
          reportTitle: data.data.title,
          reportSubTitle: data.data.subtitle,
          company_logo: profileURL,
          company_name: this.state.task.sharedBy && this.state.task.sharedBy.isShared ? `${this.state.task.sharedBy.sharedBy.first_name} ${this.state.task.sharedBy.sharedBy.last_name}` : data.data.name,
          report_generated: data.data.generated_date,
          report_modified: data.data.modified_date,
          generate_request: data.data.generate_request,
        }), () => {
          this.calculateDateDiff()
        })
      }
    }, (e) => {
      console.log(e);
    })
  }

  addReportHeader = () => {
    let task_id = this.state.task.task_id
    let report_type = 'detection'
    let title = this.state.task.task_name
    let subtitle = this.state.task.project_name
    let company_logo = null
    // this.state.user.avatar_url ? `${this.state.user.avatar_url}?${this.state.permissions?.st}&t${new Date().getTime()}` : `/images/${this.state.user.first_name[0].toLowerCase()}.PNG`
    API.addReportHeader(task_id, report_type, title, company_logo, subtitle).then((data) => {
      if (data.status == 201) {
        this.getReportHeader();
      } else {
        this.setState((state) => ({
          ...state,
          reportTitle: this.state.task.task_name,
          reportSubTitle: this.state.task.project_name,
          company_logo: this.state.avatar_url ? `${this.state.avatar_url}?${this.state.permissions?.st}&t${new Date().getTime()}` : `/images/${this.state.user.first_name[0].toLowerCase()}.PNG`,
          company_name: `${this.state.user.first_name} ${this.state.user.last_name}`,
        }))
      }
    })
  }

  isReportEditable = () => {
    return this.state.permissions[PICSTORK_FEATURES.REPORTS].EDIT && !(this.state.task.sharedBy && this.state.task.sharedBy.isShared)
  }


  getSelectedReportData = (array, selectedType) => {
    console.log(array.length, " : ", selectedType);
    let finalArr = [], len = 0
    let det = document.getElementById('detailedReport')
    console.log(det);
    if (selectedType.toLowerCase() == "grid small") {
      let shortArr = []
      for (let i = 0; i < array.length; i++) {
        if (len < 2) {
          shortArr.push(array[i])
          len++
          if (i == array.length - 1)
            finalArr.push(shortArr)
        }
        else {
          shortArr.push(array[i])
          finalArr.push(shortArr)
          len = 0;
          shortArr = []
        }
      }
      console.log(finalArr);
    } else {
      let shortArr = []
      for (let i = 0; i < array.length; i++) {
        if (len < 1) {
          shortArr.push(array[i])
          len++
          if (i == array.length - 1)
            finalArr.push(shortArr)
        }
        else {
          shortArr.push(array[i])
          finalArr.push(shortArr)
          len = 0;
          shortArr = []
        }
      }
      console.log(finalArr);
    }
    return finalArr
  }
  getSummaryDetails = async () => {
    let temp = [];
    let objects = this.state.isDetectionDirty ? temp : [];
    let severity1Data = []
    let severity2Data = []
    let severity3Data = []
    let severity4Data = []
    let severity5Data = []

    API.getDetections(this.state.task.task_id, objects).then(
      (data) => {
        if (data.length == 0) {
          this.setState((state) => ({
            ...state,
            loading: false,
          }));
        } else {
          this.setState(
            (state) => ({
              ...state,
              report: data.ret.sort((a, b) => a.image > b.image ? 1 : -1),
              report1: data.image_data.sort((a, b) => a.file_name > b.file_name ? 1 : -1),
              isDetectionDirty: true,
              imgData: data.image_data
            }),
            () => {
              if (this.state.report.length > 0) {
                let counter = 0;
                let temp = [];
                let objectsDetected = 0;
                let types = objects.length > 0 ? objects : this.state.task.type;
                this.state.report.forEach((el) => {
                  let cnt = 0;
                  types.forEach((type) => {
                    if (el.result.detections && el.result.detections[type]) {
                      if (objects.length > 0) {
                        let obj = el.result.detections[type];
                        el.result.detections = {};
                        el.result.detections[type] = obj;
                      } else {
                      }
                      cnt += el.result.detections[type].count;
                      objectsDetected += el.result.detections[type].count;
                    }
                  });
                  el.objectsDetected = cnt;
                  temp.push(el);
                  counter++;
                  if (counter == this.state.report.length) {
                    this.setState((state) => ({
                      ...state,
                      objectsDetected: objectsDetected,
                      report: temp,
                    }));
                  }
                });
              }
              if (this.state.report.length > 0) {
                let counter = 0;
                let objectsDetected = 0;
                let temp = [];
                let types = objects.length > 0 ? objects : this.state.task.type;
                this.state.report.forEach((element) => {
                  this.state.obj.forEach((ob) => {
                    ob.count += (element.result && element.result.detections && element.result.detections[ob.type] ? element.result.detections[ob.type].count : 0);
                  });
                  counter++;
                  if (counter == this.state.report.length) {
                    let temp = [];
                    let tempColor = {};
                    let cntr = 0;
                    let totasev1count = 0;
                    this.state.obj.forEach((el) => {
                      let dt = [];
                      dt[0] = getLimitedName(el.type, AppConstants.OBJECT_NAME_LIMIT)
                      dt[1] = el.count;
                      totasev1count += el.count;
                      temp.push(dt);
                      cntr++;
                      if (cntr == this.state.obj.length) {
                        this.setState((state) => ({
                          ...state,
                          loading: false,
                          data: temp,
                          totasev1objectsDetected: totasev1count,
                        }));
                      }
                    });
                  }
                });
              }

              let repo = this.state.report
              for (let i = 0; i < data.ret.length; i++) {
                let ele = data.ret[i]
                let sev3 = {}, sev1 = {}, sev2 = {}, sev4 = {}, sev5 = {}, sev3count = 0, sev1count = 0, sev2count = 0, sev4count = 0, sev5count = 0, sev1objects = [], sev4objects = [], sev5objects = [], sev3objects = [], sev2objects = [], objects = [];
                sev1.image = sev2.image = sev3.image = sev4.image = sev5.image = ele.image
                sev1.report = []
                sev2.report = []
                sev3.report = []
                sev4.report = []
                sev5.report = []

                this.state.task.type.forEach((type) => {
                  let displayObj = ` ${getLimitedName(type, AppConstants.OBJECT_NAME_LIMIT)}`
                  if (ele.result.detections && ele.result.detections[type]) {

                    //1 severity report
                    let det1 = ele.result.detections[type].data.filter((dt) => {
                      if (dt.severity === '1')
                        sev1count++
                      return dt.severity === '1'
                    })
                    if (det1.length > 0) {
                      sev1.report.push({ object: displayObj, count: det1.length })
                      sev1objects.push(displayObj)
                    }

                    //2 severity report
                    let det2 = ele.result.detections[type].data.filter((dt) => {
                      if (dt.severity === '2')
                        sev2count++
                      return dt.severity === '2'
                    })
                    if (det2.length > 0) {
                      sev2.report.push({ object: displayObj, count: det2.length })
                      sev2objects.push(displayObj)
                    }

                    // 3 severity reports
                    let det = ele.result.detections[type].data.filter((dt) => {
                      if (dt.severity === '3')
                        sev3count++
                      return dt.severity === '3'
                    })
                    if (det.length > 0) {
                      sev3.report.push({ object: displayObj, count: det.length })
                      sev3objects.push(displayObj)
                    }

                    // 4 severity reports
                    let det3 = ele.result.detections[type].data.filter((dt) => {
                      if (dt.severity === '4')
                        sev4count++
                      return dt.severity === '4'
                    })
                    if (det3.length > 0) {
                      sev4.report.push({ object: displayObj, count: det3.length })
                      sev4objects.push(displayObj)
                    }

                    // 5 severity reports
                    let det4 = ele.result.detections[type].data.filter((dt) => {
                      if (dt.severity === '5')
                        sev5count++
                      return dt.severity === '5'
                    })
                    if (det4.length > 0) {
                      sev5.report.push({ object: displayObj, count: det4.length })
                      sev5objects.push(displayObj)
                    }

                    if (ele.result.detections[type].count > 0)
                      objects.push(displayObj)
                  }
                });
                sev1.file_size = repo[i].file_size = sev2.file_size = sev3.file_size = sev4.file_size = sev5.file_size = (this.state.report1.find(f => f?.file_name == ele?.image)).file_size
                if (sev3count > 0) {
                  sev3.count = sev3count
                  sev3.objects = sev3objects.toString()
                  severity3Data.push(sev3);
                }
                if (sev2count > 0) {
                  sev2.count = sev2count
                  sev2.objects = sev2objects.toString()
                  severity2Data.push(sev2)
                }
                if (sev1count > 0) {
                  sev1.count = sev1count
                  sev1.objects = sev1objects.toString()
                  severity1Data.push(sev1)
                }
                if (sev4count > 0) {
                  sev4.count = sev4count
                  sev4.objects = sev4objects.toString()
                  severity4Data.push(sev4)
                }
                if (sev5count > 0) {
                  sev5.count = sev5count
                  sev5.objects = sev5objects.toString()
                  severity5Data.push(sev5)
                }
                repo[i].objects = objects.toString()
              }
              repo = repo.filter((data) => { return data.objectsDetected > 0 })
              this.setState({ severity1Data, severity3Data, severity2Data, severity4Data, severity5Data, report: repo }, () => {
                this.setState({
                  report_generated: null,
                  generation_status: true,
                })
              })
              this.calculateImageData()
            }
          );
        }
      },
      (e) => {
        this.setState((state) => ({
          ...state,
          report: [],
          report1: [],
          loading: false,
        }));
      }
    );
  };

  downloadPdf = async () => {
    const exist = await checkURLExist(`${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${this.state.task.image_collection_id}/reports/Detection-${this.state.task.task_id}.${this.state.filetype}?${this.state.permissions?.st}`)
    if (exist) {
      this.setState({ downloadProgress: true })
      API.downloadPdfFile(this.state.task.task_id, 'Detection', this.state.task.image_collection_id, this.state.task.tasktype, this.state.filetype).then(res => {
        this.setState({
          downloadProgress: false,
          downloadWarning: false,
        })
      })
    } else {
      this.setState({ showProcessPopup: true })
      this.generatePdf()
    }

  }

  generatePdf = async () => {

    let viewData = {
      detailedAnalysis: this.state.reportData[1].visibleInReport ? this.state.reportData[1] : {},
      severity1: { details: this.state.reportData[2].visibleInReport ? this.state.reportData[2] : {}, severity1Data: this.state.severity1Data ? this.state.severity1Data : [] },
      severity2: { details: this.state.reportData[3].visibleInReport ? this.state.reportData[3] : {}, severity2Data: this.state.severity2Data ? this.state.severity2Data : [] },
      severity3: { details: this.state.reportData[4].visibleInReport ? this.state.reportData[4] : {}, severity3Data: this.state.severity3Data ? this.state.severity3Data : [] },
      severity4: { details: this.state.reportData[5].visibleInReport ? this.state.reportData[5] : {}, severity4Data: this.state.severity4Data ? this.state.severity4Data : [] },
      severity5: { details: this.state.reportData[6].visibleInReport ? this.state.reportData[6] : {}, severity5Data: this.state.severity5Data ? this.state.severity5Data : [] },
    }
    console.log("view data", viewData)
    let fileData = this.state.report

    let pdfData = {
      planName: this.state.reportSubTitle,
      taskName: this.state.reportTitle,
      imageSetName: this.state.task.collection_name,
      taskId: this.state.task.task_id,
      collectionId: this.state.task.image_collection_id,
      totalImages: this.state.imgData.length - 1,
      pixels: this.state.total_resolution,
      total_size: this.state.total_storage,
      objectDetected: this.state.totasev1objectsDetected,
      avgDetections: Math.round(this.state.totasev1objectsDetected / this.state.report.length),
      avatar_url: this.state.company_logo,
      username: this.state.company_name,
      filetype: this.state.filetype
    }
    let imgData = this.state.report.map(r => {
      let splitedImg = r.image.split(".");
      let img_name = splitedImg[0];
      let extension = splitedImg[1];
      let finalImg = img_name + "." + extension
      return { ...r, image: finalImg }
    })
    let pieData = this.state.data
    let colors = this.state.colors
    API.generateDetectionPdf(viewData, pdfData, pieData, colors, imgData).then((res) => {
      if (res.status == 200) {
        this.setState((state) => ({
          showProcessPopup: false,
          downloadWarning: false,
          generation_status: false,
        }))
      }
    })
  }


  calculateImageData = () => {
    let data = this.state.imgData
    let report = this.state.report
    var total_storage = 0
    var total_resolution = 0

    for (let j = 0; j < report.length; j++) {
      if (report[j].objectsDetected > 0) {

        let imgdata = data.find(temp => {
          return temp.file_name === report[j].image
        }
        )

        total_storage = total_storage + Number(imgdata.file_size)
        total_resolution = total_resolution + Number(imgdata.resolution)
      }
    }
    this.setState((state) => ({
      ...state,
      total_storage: total_storage.toFixed(2),
      total_resolution: total_resolution.toFixed(2)
    }))
  }

  changeCompanyLogo = (event) => {
    // let companyLogo = event.target.value
    let targetFilesArr = [];
    targetFilesArr = Array.from(event.target.files);
    let companyLogo = targetFilesArr[0]
    this.setState((state) => ({
      ...state,
      company_logo1: companyLogo,
    }), () => {
      this.uploadCompanLogo()
    })
  }
  uploadCompanLogo = () => {
    const data = new FormData()
    data.append("file", this.state.company_logo1)
    API.uploadCompanyLogo(this.state.reportheader.id, data).then((uploadlogo) => {
      console.log(uploadlogo.status)
      if (uploadlogo.status == 200) {
        this.setState((state) => ({
          ...state,
          companyLogo1: undefined,
        }), () => {
          this.getReportHeader()
        })
      } else {
        console.log('error while uploading logo')
      }
    })
  }

  componentWillMount() {
    document.title = "PicStork Report";
    getFeaturesPermissions([PICSTORK_FEATURES.REPORTS])
      .then((permissions) => {
        if (this.props.location.state) {
          let { user, type, task } = this.props.location.state;
          this.setState((state) => ({
            ...state,
            permissions,
            avatar_url: `${BLOB_URL}/${permissions?.container}/profileImage/${(permissions?.container?.split("user-"))[1]}.jpeg`,
            user,
            type,
            task,
          }),
            () => {
              //this.setColors()
              this.getReportHeader()
              let colors = this.state.colors
              // for (let i = 0; i < this.state.task.type.length; i++) {
              //   let c = "#" + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0').toUpperCase();
              //   colors[i] = { color: c }
              // }
              // this.setState({ colors })
              if (this.state.task && this.state.task.type) {
                let ob = this.state.task.type;
                let temp = {};
                let tmp = [];
                let counter = 0;
                ob.forEach((type, key) => {
                  temp.selected = true;
                  temp.type = type;
                  temp.id = key;
                  temp.count = 0;
                  temp.color = colors[counter].color;
                  tmp.push(temp);
                  temp = {};
                  counter++;
                  if (counter == ob.length) {
                    this.setState((state) => ({
                      ...state,
                      obj: tmp,
                    }));
                  }
                });
              }
              this.getSummaryDetails();
              API.getUserSubbedServices().then((response) => {
                let services = response[0];
                if (services.length == 1 && services[0].title == "AeroStream") {
                  this.setState((state) => ({
                    ...state,
                    showBackIcon: false,
                  }));
                } else {
                  this.setState((state) => ({
                    ...state,
                    showBackIcon: true,
                  }));
                }
              });

            })
        } else {
          API.getUserType().then(
            ([blah, type, user]) => {
              this.setState(
                (state) => ({
                  ...state,
                  user,
                  type,
                }),
                () => {
                  this.getSummaryDetails();
                }
              );
            },
            (e) => {
              window.location.replace("/login");
            }
          );
        }
      })
      .catch(e => window.location.replace("/"))

  }

  closeActivityPopup = () => {
    this.getReportHeader()
    this.setState((state) => ({
      ...state,
      showProcessPopup: false,
      downloadWarning: true,
      generation_status: false,
      processMessage: null
    }))
  }

  handleChange = (e) => {
    let t = e.target;
    this.setState((prevState) => ({
      ...prevState,
      [t.name]: t.value,
    }))
  }
  checkedTrue(index) {
    let temp = this.state.reportData
    temp[index].visibleInReport = !temp[index].visibleInReport
    this.setState((state) => ({
      ...state,
      reportData: temp,
      generation_status: true,
      report_generated: null
    }))
  }
  render() {

    let {
      user,
      type,
      sidebarMode,
      showBackIcon,
      task,
      obj,
      data,
      projects,
      report,
      report1,
      taskDetails,
      detailReport,
      colors,
      severity1Data,
      severity3Data,
      severity2Data,
      severity4Data,
      severity5Data
    } = this.state;

    let totasev1countPerImage = [];
    let defect;
    let size;
    const handleClick = (event) => {
      document.getElementById("hiddenFileInput").click();
    };
    return (
      <>
        <div className="top-bar" style={{ position: 'fixed', width: '100%' }}>
          <ActivityPopup
            item={"Report"}
            open={this.state.showProcessPopup}
            icon={this.state.downloadWarning ? this.state.processPopupIcon : "WAIT"}
            action={this.state.downloadWarning ? this.state.processAction : "UPDATE"}
            title={"Report progress"}
            msg={this.state.downloadWarning ? this.state.processMessage : ' '}
            close={() => this.closeActivityPopup()}
            onClickOk={() => {
              this.setState({
                showProcessPopup: false
              })
              this.downloadPdf()
            }}
          >{!this.state.downloadWarning ? <>
            <div id='message' className='content-text' style={{ fontSize: "13px", fontWeight: "500", textAlign: "left", margin: "0px 30px", color: '#555C67' }}>
              Your report is being generated, we will notify you on email once your report is available for download.
            </div>
            <div style={{ display: "flex", position: "absolute", bottom: "22px", right: "5%" }}>
              <Button text={"Ok"} isBtnActive onClick={() => {
                this.closeActivityPopup()
              }} />
            </div>
          </>
            : <></>}

          </ActivityPopup>

          {this.state.downloadProgress ? <ActivityPopup
            item={"Report"}
            open={this.state.downloadProgress}
            icon={"WAIT"}
            action={""}
            title={"Report progress"}
            msg={' '}
            close={() => this.closeActivityPopup()}
          >
            <div id='message' className='content-text' style={{ fontSize: "13px", fontWeight: "500", textAlign: "left", margin: "0px 30px", color: '#555C67' }}>
              Getting your file to download, please wait.
              <img src={Loader} style={{ height: "70px", width: "100%", marginLeft: "15px" }} />
            </div>
          </ActivityPopup>
            : <></>}

          <div className="breadCrumbs">
            <BreadCrumbs data={[
              {
                displayName: "projects",
                pathname: "/picstork/dashboard",
                state: {
                  type,
                  user,
                }
              },
              {
                displayName: task.project_name,

                pathname: "/picstork/project",
                state: {
                  type,
                  user,
                  project: { "project_name": task.project_name, "id": task.project_id },
                }
              },
              // {
              //   displayName: task.plan_name,

              //   pathname: "/picstork/plan",
              //   state: {
              //     type,
              //     user,
              //     plan: { "plan_name": task.plan_name, "id": task.plan_id },
              //     project: { "project_name": task.project_name, "id": task.project_id }
              //   }
              // },
              {
                displayName: task.task_name
              }
            ]} />
            <ActiveRole />
          </div>
        </div>
        {/* <div className="topbarprintbutton1">
          <button className="add-user-form-submit-button" onClick={window.print}>
            Download
          </button>
        </div> */}
        {this.state.loading ? <div style={{ height: "100vh", width: "100vw", display: "flex", textAlign: "center" }}>
          <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
        </div> : <div style={{ display: 'flex', margin: '60px 15px', justifyContent: 'center' }}>
          <div style={{ width: '77%' }}>
            <div style={{ padding: "20px" }}>
              <div
                id="pageprint"
                style={{
                  margin: "auto",
                  padding: "50px 30px 20px 30px",
                  width: "250mm",
                  boxShadow: "0px 6px 18px rgba(0, 0, 0, 18%)",
                }}
              >

                {/* -------------Report header ------------ */}
                <div>
                  <input
                    name="company_logo"
                    id="hiddenFileInput"
                    type="file"
                    className=""
                    required
                    onChange={this.changeCompanyLogo}
                    accept='image/*'
                    // multiple
                    style={{ display: "none" }}
                  />
                  <div style={{ width: '885px', display: 'flex', justifyContent: 'space-between', marginTop: '20px', }}>
                    <div style={{ width: '60%' }}>
                      {this.state.titleEdit ? <div style={{ height: '47px' }}>
                        {/* <label style={{ fontSize: '16px', fontWeight: '500' }} >Title</label><br /> */}
                        <input maxLength={'80'} type="text" value={this.state.reportTitle} name='reportTitle'
                          className="add-user-form-text2" style={{ width: '300px', fontSize: '18px' }} onChange={this.handleChange} />
                        <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "25px", width: "22px", cursor: 'pointer', }} src={saveImage} onClick={() => {
                          this.setState(
                            (state) => ({
                              ...state,
                              titleEdit: false,
                              editHeader: false,
                            }), () => {
                              this.updateReportHeader()
                            })
                        }} />
                        <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "22px", width: "22px", cursor: 'pointer' }} src={close} onClick={() => {
                          this.setState(
                            (state) => ({
                              ...state,
                              titleEdit: false,
                              editHeader: false,
                            }))
                        }} />
                      </div> : <div style={{ display: 'flex', alignItems: 'center', height: '47px', }}>
                        <div style={{ fontSize: '20px', fontWight: '500', marginBottom: '10px' }}>{this.state.reportTitle}</div>
                        {!(this.isReportEditable()) ? <></> : <div style={{ height: '100%', display: "flex", marginTop: '0px', alignItems: 'center' }}>
                          <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', marginBottom: '10px', height: "15px", width: "15px", cursor: 'pointer' }} src={edit} onClick={() => {
                            this.setState(
                              (state) => ({
                                ...state,
                                titleEdit: true,
                                editHeader: true
                              }))
                          }} />
                        </div>}
                      </div>
                      }

                      {this.state.subTitleEdit ? <div style={{ display: 'flex', alignItems: 'center', height: '47px', marginTop: '10px' }}>
                        {/* <label style={{ fontSize: '16px', fontWeight: '500' }} >Sub Title</label><br /> */}
                        <input maxLength={'50'} type="text" value={this.state.reportSubTitle} name='reportSubTitle'
                          className="add-user-form-text2" style={{ width: '300px', fontSize: '16px' }} onChange={this.handleChange} />
                        <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "25px", width: "22px", cursor: 'pointer' }} src={saveImage} onClick={() => {
                          this.setState(
                            (state) => ({
                              ...state,
                              subTitleEdit: false,
                              editHeader: false,
                            }), () => {
                              this.updateReportHeader()
                            })
                        }} />
                        <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "22px", width: "22px", cursor: 'pointer' }} src={close} onClick={() => {
                          this.setState(
                            (state) => ({
                              ...state,
                              subTitleEdit: false,
                              editHeader: false,
                            }))
                        }} />
                      </div> : <div style={{ display: 'flex', alignItems: 'center', height: '47px', marginTop: '10px' }}>
                        <div style={{ fontSize: '18px', fontWight: '500' }}>{this.state.reportSubTitle}</div>
                        {!(this.isReportEditable()) ? <></> :
                          <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "15px", width: "15px", cursor: 'pointer' }} src={edit} onClick={() => {
                            this.setState(
                              (state) => ({
                                ...state,
                                subTitleEdit: true,
                                editHeader: true,
                              }))
                          }} />}
                      </div>}
                    </div>

                    <div style={{}}>
                      <div style={{ textAlign: 'end', marginRight: '25px' }}>
                        {!(this.isReportEditable()) ? <></> :
                          <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '105px', position: 'absolute', height: "15px", width: "15px", cursor: 'pointer', marginRight: "105px" }} src={edit} onClick={handleClick} />}
                        <img style={{ maxHeight: "80px", minHeight: "50px", minWidth: "70px", maxWidth: "100px", borderRadius: '10px', cursor: 'pointer' }} src={`${this.state.company_logo}?${this.state.permissions?.st}`} onError={(e) => { e.target.onerror = null; e.target.src = `/images/${this.state.user.first_name[0].toLowerCase()}.PNG` }} />
                      </div>

                      {this.state.companyNameEdit ? <div style={{ display: 'flex', alignItems: 'center', height: '47px' }}>
                        {/* <label style={{ fontSize: '16px', fontWeight: '500' }} >Company Name</label><br /> */}


                        <input maxLength={'30'} style={{ width: '200px', fontSize: '16px' }} type="text" value={this.state.company_name} name='company_name'
                          className="add-user-form-text2" onChange={this.handleChange} />
                        <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "25px", width: "22px", cursor: 'pointer' }} src={saveImage} onClick={() => {
                          this.setState(
                            (state) => ({
                              ...state,
                              companyNameEdit: false,
                              editHeader: false
                            }), () => {
                              this.updateReportHeader()
                            })
                        }} />
                        <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "22px", width: "22px", cursor: 'pointer' }} src={close} onClick={() => {
                          this.setState(
                            (state) => ({
                              ...state,
                              companyNameEdit: false,
                              editHeader: false,
                            }))
                        }} />
                      </div> :
                        <div style={{ display: 'flex', alignItems: 'center', height: '47px' }}>

                          <div style={{ fontSize: '20px' }}>{this.state.company_name}</div>
                          {!(this.isReportEditable()) ? <></> :
                            <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "15px", width: "15px", cursor: 'pointer' }} src={edit} onClick={() => {
                              this.setState(
                                (state) => ({
                                  ...state,
                                  companyNameEdit: true,
                                  editHeader: true,
                                }))
                            }} />}
                        </div>
                      }
                    </div>
                  </div>
                  <hr style={{ width: '100%', margin: '10px 0px 20px 0px', height: '1px', padding: '0px' }} />
                </div>
                {/* ---------------------------------------*/}
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: '50px' }}>
                  <p>Total Images:<span style={{ fontWeight: 'bold', fontSize: '20px' }}> {this.state.imgData.length - 1} </span></p>
                  <p>Pixels Processed: <span style={{ fontWeight: 'bold', fontSize: '20px' }}>{this.state.total_resolution} MP</span></p>
                  <p>Total Storage: <span style={{ fontWeight: 'bold', fontSize: '20px' }}>{this.state.total_storage} {report1.total_size > 1024 ? " GB" : " MB"}</span></p>
                </div>

                {/* Analysis Report*/}
                <><div className="header" style={{ fontWeight: 600, fontSize: '18px' }}>
                  Analysis Summary
                </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', margin: "10px 0px" }}>
                    <div>
                      {" "}
                      Total Objects Detected:
                      <span style={{ fontWeight: 'bold', fontSize: '20px' }}>
                        {" "}
                        {this.state.totasev1objectsDetected}
                      </span>
                    </div>
                    <div>
                      {" "}
                      Average Detections Per Image:
                      <span style={{ fontWeight: 'bold', fontSize: '20px' }}>
                        {" "}
                        {Math.round(this.state.totasev1objectsDetected / report.length)}
                      </span>
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '50%', height: '400px' }}>
                      <Chart
                        width={"100%"}
                        height={"100%"}
                        chartType="PieChart"
                        loader={
                          <div
                            style={{
                              width: "30vw",
                              height: "50vh",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            Loading Chart
                          </div>
                        }
                        data={[["defect", "count"], ...data]}
                        options={{
                          legend: "none",
                          pieSliceText: "none",
                          slices: this.state.colors
                        }}
                        rootProps={{ "data-testid": "5" }}
                      />
                    </div>
                    <div className="summary" style={{ width: '50%', marginLeft: '', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <div className="analysisTable1" style={{ verticalAlign: 'middle', width: "100%" }}>
                        <table style={{ width: "100%" }}>
                          <tr style={{ width: "100%", display: "flex", alignItems: "center" }}>
                            <th style={{ width: "40px" }}></th>
                            <th style={{ width: "100%" }}>Object</th>
                            <th style={{ textAlign: 'center', width: "95px" }}>Count</th>
                            <th style={{ textAlign: 'center', width: "70px" }}>%</th>
                          </tr>
                          {obj.length === 0 || this.state.totasev1objectsDetected == 0 ? (
                            <tr>
                              <td>No Detections Available</td>
                            </tr>
                          ) : (
                            obj
                              .filter((element) => {
                                if (element.count > 0) {
                                  return element;
                                }
                              })
                              .map((ob) => (
                                <tr style={{ width: "100%", display: "flex", alignItems: "baseline" }}>
                                  <td className="color" style={{ textAlign: 'right' }}>
                                    <div
                                      className="graph-legend-shape"
                                      style={{ backgroundColor: ob.color }}
                                    ></div>
                                  </td>
                                  <td className="object" style={{ width: "100%", wordBreak: "break-all", lineHeight: "13px" }}>
                                    {getLimitedName(ob.type, AppConstants.OBJECT_NAME_LIMIT)}
                                  </td>
                                  <td className="detected" style={{ textAlign: 'center', width: "95px" }}>{ob.count}</td>
                                  <td className="percentage" style={{ textAlign: 'center', width: "70px" }}>
                                    {(
                                      (ob.count / this.state.totasev1objectsDetected) *
                                      100
                                    ).toFixed(2)}
                                    %
                                  </td>
                                </tr>
                              ))
                          )}
                        </table>
                      </div>
                    </div>
                  </div></>
                {/* ------------------ */}

                {/*------ Detailed Report ------ */}
                {this.state.reportData[0].visibleInReport ?
                  <p style={{ pageBreakAfter: "always" }}></p> : <></>}
                {this.state.reportData[1].visibleInReport ?
                  this.state.reportData[1].reportView.includes("List") ?
                    <><div className="header" style={{ fontWeight: 600, fontSize: '18px', margin: '50px 0px 20px 0px' }}>Detailed Analysis</div>
                      <div id="detailedReport">
                        <table style={{ borderRadius: '4px', width: '100%', boxShadow: '0px 6px 18px rgba(0,0,0,0.06)' }}>
                          <col style={{ width: "13%" }} />
                          <col style={{ width: "57%" }} />
                          <col style={{ width: "15%" }} />
                          <col style={{ width: "15%" }} />
                          <tr style={{ padding: '5px', backgroundColor: '#e5e5ea', color: '#3c3c3c', borderRadius: '4px', height: '45px ' }}>
                            <th style={{ textAlign: 'center', fontWeight: 500 }}>Image</th>
                            <th style={{ textAlign: 'center', fontWeight: 500 }}>Objects</th>
                            <th style={{ textAlign: 'center', fontWeight: 500 }}>Count</th>
                            <th style={{ textAlign: 'center', fontWeight: 500 }}>Size</th>
                          </tr>
                          {report.map((report, key) => {
                            if (report.objectsDetected == 0) {
                              return (<></>)
                            } else {
                              return (
                                <tr style={{ border: '#EBEFF2', borderStyle: 'solid', borderWidth: '2px', borderRadius: '4px', height: '45px ' }}>
                                  <td style={{ textAlign: 'center', padding: '5px' }}>
                                    <img
                                      style={{ height: this.state.reportData[1].reportView.includes("Small") ? '50px' : '130px' }}
                                      src={`${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${task.image_collection_id}/thumb-${report.image}?${this.state.permissions?.st}`}
                                    />
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        fontSize: "10px",
                                        margin: '0',
                                        fontWeight: 500
                                      }}
                                    >
                                      {report.image.length > 12
                                        ? `${report.image.substring(0, 11)}...`
                                        : `${report.image}`}
                                    </p>
                                  </td>
                                  {report.objectsDetected == 0 ? "" : <td style={{ textAlign: 'center', fontSize: '14px' }}>
                                    {
                                      report.objects
                                    }
                                  </td>}
                                  <td style={{ textAlign: 'center', fontSize: '14px' }}>
                                    {`${report.objectsDetected}`}
                                  </td>
                                  <td style={{ textAlign: 'center', fontSize: '14px' }}>
                                    {report.file_size} MB
                                  </td>
                                </tr>
                              )
                            }
                          })}
                        </table>
                      </div> </> :
                    <>
                      {/* --------- Grid view --------- */}
                      <div className="header" style={{ fontWeight: 600, fontSize: '18px', margin: '50px 0px 20px 0px' }}>Detailed Analysis</div>
                      <div style={{ width: '900px' }}>
                        {this.state.reportData[1].gridData.map((reportArr, key) => {
                          return (
                            <>
                              <p style={(this.state.reportData[1].reportView.includes("Small") && key > 0 && key != this.state.reportData[1].gridData.length && (key % 4 == 0)) || (this.state.reportData[1].reportView.includes("Large") && key > 1 && (key % 3 == 0)) ? { pageBreakAfter: "always" } : {}}></p>
                              <div style={{ display: 'flex', marginTop: "60px", width: '99%' }}>
                                {reportArr.map((report) => {
                                  return (
                                    <>
                                      <div key={key} style={this.state.reportData[1].reportView.includes("Small") ? { width: "270px", borderRadius: "10px", marginRight: '35px' } : { borderRadius: "10px", marginRight: '35px' }}>
                                        <div className="card-header customer-cards-map project-card-header" style={this.state.reportData[1].reportView.includes("Small") ? { height: '190px', borderRadius: "6px 6px 0px 0px", border: '#A0A0A0 solid 1px' } : { width: "425px", height: '290px', borderRadius: "6px 6px 0px 0px", border: '#A0A0A0 solid 1px' }}>
                                          <img id={"ProjectImage2"} style={{ height: this.state.reportData[1].reportView.includes("Small") ? '190px' : '290px', borderRadius: '6px 6px 0px 0px' }} loading="true"
                                            src={`${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${task.image_collection_id}/thumb-${report.image}?${this.state.permissions?.st}`}
                                          //onError={(e) => { e.currentTarget.src = PlanThumb; }} 
                                          />
                                        </div>
                                        <div style={{ width: this.state.reportData[1].reportView.includes("Small") ? '270px' : 'auto', background: 'white', color: 'black', border: '#A0A0A0 solid 1px' }}
                                          className="card-body change-color customer-cards-dashboard-text-map project-card-body">
                                          <div style={{ fontSize: '14px', display: 'flex', height: "20px", alignItems: "center", justifyContent: 'space-between' }}>
                                            <div style={{ overflow: "hidden", }}>
                                              {
                                                report.image.length > 12 ?
                                                  `${report.image.substring(0, 12)}...` :
                                                  `${report.image}`
                                              }
                                            </div>
                                            <div style={{ overflow: "hidden" }}>{report.file_size} MB</div>
                                          </div>
                                          <div style={{ fontSize: this.state.reportData[1].reportView.includes("Small") ? '11px' : '12px', display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
                                            <div style={{ width: "80%", overflowY: 'hidden' }}>Detections : {report.objects}</div>
                                            <div style={{ width: "20%", overflowY: 'hidden', textAlign: 'end' }}>{report.objectsDetected}</div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )
                                })}
                              </div>
                            </>
                          )
                        })}
                      </div>
                    </> : <></>
                }
                {/* --------------------------- */}

                {/*------ Severity1 Report ------ */}
                {this.state.severity1Data && this.state.severity1Data.length > 0 ?
                  <>{this.state.reportData[0].visibleInReport || this.state.reportData[1].visibleInReport ?
                    <p style={{ pageBreakAfter: "always" }}></p> : <></>}
                    {this.state.reportData[2].visibleInReport ?
                      this.state.reportData[2].reportView.includes("List") ?
                        <><div className="header" style={{ fontWeight: 600, fontSize: '18px', margin: '50px 0px 20px 0px' }}>Severity-1 Detections</div>
                          <div>
                            <table style={{ borderRadius: '4px', width: '100%', boxShadow: '0px 6px 18px rgba(0,0,0,0.06)' }}>
                              <col style={{ width: "13%" }} />
                              <col style={{ width: "57%" }} />
                              <col style={{ width: "15%" }} />
                              <col style={{ width: "15%" }} />
                              <tr style={{ padding: '5px', backgroundColor: '#e5e5ea', color: '#3c3c3c', borderRadius: '4px', height: '45px ' }}>
                                <th style={{ textAlign: 'center', fontWeight: 500 }}>Image</th>
                                <th style={{ textAlign: 'center', fontWeight: 500 }}>Objects</th>
                                <th style={{ textAlign: 'center', fontWeight: 500 }}>Count</th>
                                <th style={{ textAlign: 'center', fontWeight: 500 }}>Size</th>
                              </tr>
                              {this.state.severity1Data.map((report, key) => {
                                if (report.count == 0) {
                                  return (<></>)
                                } else {
                                  return (
                                    <tr style={{ border: '#EBEFF2', borderStyle: 'solid', borderWidth: '2px', borderRadius: '4px', height: '45px ' }}>
                                      <td style={{ textAlign: 'center', padding: '5px' }}>
                                        <img
                                          style={{ height: this.state.reportData[2].reportView.includes("Small") ? '50px' : '130px' }}
                                          src={`${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${task.image_collection_id}/thumb-${report.image}?${this.state.permissions?.st}`}
                                        />
                                        <p
                                          style={{
                                            overflow: "hidden",
                                            fontSize: "10px",
                                            margin: '0',
                                            fontWeight: 500
                                          }}
                                        >
                                          {report.image.length > 12
                                            ? `${report.image.substring(0, 11)}...`
                                            : `${report.image}`}
                                        </p>
                                      </td>
                                      {report.count == 0 ? "-" : <td style={{ textAlign: 'center', fontSize: '14px' }}>
                                        {report.objects}
                                      </td>}
                                      <td style={{ textAlign: 'center', fontSize: '14px' }}>
                                        {report.count}
                                      </td>
                                      <td style={{ textAlign: 'center', fontSize: '14px' }}>
                                        {report.file_size} MB
                                      </td>
                                    </tr>
                                  );
                                }
                              })}
                            </table>
                          </div> </> :
                        <>
                          {/* Grid view */}
                          {/* --------- Grid view --------- */}
                          <div className="header" style={{ fontWeight: 600, fontSize: '18px', margin: '50px 0px 20px 0px' }}>Severity-1 Detections</div>
                          <div style={{ width: '900px' }}>
                            {this.state.reportData[2].gridData.map((reportArr, key) => {
                              return (
                                <>
                                  <p style={(this.state.reportData[2].reportView.includes("Small") && key > 0 && key != this.state.reportData[2].gridData.length && (key % 4 == 0)) || (this.state.reportData[2].reportView.includes("Large") && key > 1 && (key % 3 == 0)) ? { pageBreakAfter: "always" } : {}}></p>
                                  <div style={{ display: 'flex', marginTop: "60px", width: '99%' }}>
                                    {reportArr.map((report) => {
                                      return (
                                        <>
                                          <div key={key} style={this.state.reportData[2].reportView.includes("Small") ? { width: "270px", borderRadius: "10px", marginRight: '35px' } : { borderRadius: "10px", marginRight: '35px' }}>
                                            <div className="card-header customer-cards-map project-card-header" style={this.state.reportData[2].reportView.includes("Small") ? { height: '190px', borderRadius: "6px 6px 0px 0px", border: '#A0A0A0 solid 1px' } : { width: "425px", height: '290px', borderRadius: "6px 6px 0px 0px", border: '#A0A0A0 solid 1px' }}>
                                              <img id={"ProjectImage2"} style={{ height: this.state.reportData[2].reportView.includes("Small") ? '190px' : '290px', borderRadius: '6px 6px 0px 0px' }} loading="true"
                                                src={`${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${task.image_collection_id}/thumb-${report.image}?${this.state.permissions?.st}`}
                                              //onError={(e) => { e.currentTarget.src = PlanThumb; }} 
                                              />
                                            </div>
                                            <div style={{ width: this.state.reportData[2].reportView.includes("Small") ? '270px' : 'auto', background: 'white', color: 'black', border: '#A0A0A0 solid 1px' }}
                                              className="card-body change-color customer-cards-dashboard-text-map project-card-body">
                                              <div style={{ fontSize: '14px', display: 'flex', height: "20px", alignItems: "center", justifyContent: 'space-between' }}>
                                                <div style={{ overflow: "hidden", }}>
                                                  {
                                                    report.image.length > 12 ?
                                                      `${report.image.substring(0, 12)}...` :
                                                      `${report.image}`
                                                  }
                                                </div>
                                                <div style={{ overflow: "hidden" }}>{report.file_size} MB</div>
                                              </div>
                                              <div style={{ fontSize: this.state.reportData[2].reportView.includes("Small") ? '11px' : '12px', display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
                                                <div style={{ width: "80%", overflowY: 'hidden' }}>Detections : {report.objects}</div>
                                                <div style={{ width: "20%", overflowY: 'hidden', textAlign: 'end' }}>{report.count}</div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )
                                    })}
                                  </div>
                                </>
                              )
                            })}
                          </div>
                        </> : <></>
                    }</> : ''}
                {/* --------------------------- */}

                {/*------ 2 Severity Report ------ */}
                {this.state.severity2Data && this.state.severity2Data.length > 0 ?
                  <>{this.state.reportData[0].visibleInReport || this.state.reportData[1].visibleInReport || this.state.reportData[2].visibleInReport ?
                    <p style={{ pageBreakAfter: "always" }}></p> : <></>}
                    {this.state.reportData[3].visibleInReport ?
                      this.state.reportData[3].reportView.includes("List") ?
                        <><div className="header" style={{ fontWeight: 600, fontSize: '18px', margin: '50px 0px 20px 0px' }}>Severity-2 Detections</div>
                          <div>
                            <table style={{ borderRadius: '4px', width: '100%', boxShadow: '0px 6px 18px rgba(0,0,0,0.06)' }}>
                              <col style={{ width: "13%" }} />
                              <col style={{ width: "57%" }} />
                              <col style={{ width: "15%" }} />
                              <col style={{ width: "15%" }} />
                              <tr style={{ padding: '5px', backgroundColor: '#e5e5ea', color: '#3c3c3c', borderRadius: '4px', height: '45px ' }}>
                                <th style={{ textAlign: 'center', fontWeight: 500 }}>Image</th>
                                <th style={{ textAlign: 'center', fontWeight: 500 }}>Objects</th>
                                <th style={{ textAlign: 'center', fontWeight: 500 }}>Count</th>
                                <th style={{ textAlign: 'center', fontWeight: 500 }}>Size</th>
                              </tr>
                              {this.state.severity2Data.map((report, key) => {
                                if (report.count == 0) {
                                  return (<></>)
                                } else {
                                  return (
                                    <tr style={{ border: '#EBEFF2', borderStyle: 'solid', borderWidth: '2px', borderRadius: '4px', height: '45px ' }}>
                                      <td style={{ textAlign: 'center', padding: '5px' }}>
                                        <img
                                          style={{ height: this.state.reportData[3].reportView.includes("Small") ? '50px' : '130px' }}
                                          src={`${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${task.image_collection_id}/thumb-${report.image}?${this.state.permissions?.st}`}
                                        />
                                        <p
                                          style={{
                                            overflow: "hidden",
                                            fontSize: "10px",
                                            margin: '0',
                                            fontWeight: 500
                                          }}
                                        >
                                          {report.image.length > 12
                                            ? `${report.image.substring(0, 11)}...`
                                            : `${report.image}`}
                                        </p>
                                      </td>
                                      {report.count == 0 ? "" : <td style={{ textAlign: 'center', fontSize: '14px' }}>
                                        {report.objects}
                                      </td>}
                                      <td style={{ textAlign: 'center', fontSize: '14px' }}>
                                        {`${report.count}`}
                                      </td>
                                      <td style={{ textAlign: 'center', fontSize: '14px' }}>
                                        {report.file_size} MB
                                      </td>
                                    </tr>
                                  );
                                }
                              })}
                            </table>
                          </div> </> :
                        <>
                          {/* --------- Grid view --------- */}
                          <div className="header" style={{ fontWeight: 600, fontSize: '18px', margin: '50px 0px 20px 0px' }}>Severity-2 Detections</div>
                          <div style={{ width: '900px' }}>
                            {this.state.reportData[3].gridData.map((reportArr, key) => {
                              return (
                                <>
                                  <p style={(this.state.reportData[3].reportView.includes("Small") && key > 0 && key != this.state.reportData[3].gridData.length && (key % 4 == 0)) || (this.state.reportData[3].reportView.includes("Large") && key > 1 && (key % 3 == 0)) ? { pageBreakAfter: "always" } : {}}></p>
                                  <div style={{ display: 'flex', marginTop: "60px", width: '99%' }}>
                                    {reportArr.map((report) => {
                                      return (
                                        <>
                                          <div key={key} style={this.state.reportData[3].reportView.includes("Small") ? { width: "270px", borderRadius: "10px", marginRight: '35px' } : { borderRadius: "10px", marginRight: '35px' }}>
                                            <div className="card-header customer-cards-map project-card-header" style={this.state.reportData[3].reportView.includes("Small") ? { height: '190px', borderRadius: "6px 6px 0px 0px", border: '#A0A0A0 solid 1px' } : { width: "425px", height: '290px', borderRadius: "6px 6px 0px 0px", border: '#A0A0A0 solid 1px' }}>
                                              <img id={"ProjectImage2"} style={{ height: this.state.reportData[3].reportView.includes("Small") ? '190px' : '290px', borderRadius: '6px 6px 0px 0px' }} loading="true"
                                                src={`${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${task.image_collection_id}/thumb-${report.image}?${this.state.permissions?.st}`}
                                              //onError={(e) => { e.currentTarget.src = PlanThumb; }} 
                                              />
                                            </div>
                                            <div style={{ width: this.state.reportData[3].reportView.includes("Small") ? '270px' : 'auto', background: 'white', color: 'black', border: '#A0A0A0 solid 1px' }}
                                              className="card-body change-color customer-cards-dashboard-text-map project-card-body">
                                              <div style={{ fontSize: '14px', display: 'flex', height: "20px", alignItems: "center", justifyContent: 'space-between' }}>
                                                <div style={{ overflow: "hidden", }}>
                                                  {
                                                    report.image.length > 12 ?
                                                      `${report.image.substring(0, 12)}...` :
                                                      `${report.image}`
                                                  }
                                                </div>
                                                <div style={{ overflow: "hidden" }}>{report.file_size} MB</div>
                                              </div>
                                              <div style={{ fontSize: this.state.reportData[3].reportView.includes("Small") ? '11px' : '12px', display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
                                                <div style={{ width: "80%", overflowY: 'hidden' }}>Detections : {report.objects}</div>
                                                <div style={{ width: "20%", overflowY: 'hidden', textAlign: 'end' }}>{report.count}</div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )
                                    })}
                                  </div>
                                </>
                              )
                            })}
                          </div>
                        </> : <></>
                    }</> : ''}
                {/* --------------------------- */}

                {/*------ Severity3 Report ------ */}
                {this.state.severity3Data && this.state.severity3Data.length > 0 ?
                  <>{this.state.reportData[0].visibleInReport || this.state.reportData[1].visibleInReport || this.state.reportData[2].visibleInReport || this.state.reportData[3].visibleInReport ?
                    <p style={{ pageBreakAfter: "always" }}></p> : <></>}
                    {this.state.reportData[4].visibleInReport ?
                      this.state.reportData[4].reportView.includes("List") ?
                        <><div className="header" style={{ fontWeight: 600, fontSize: '18px', margin: '50px 0px 20px 0px' }}>Severity-3 Detections</div>
                          <div>
                            <table style={{ borderRadius: '4px', width: '100%', boxShadow: '0px 6px 18px rgba(0,0,0,0.06)' }}>
                              <col style={{ width: "13%" }} />
                              <col style={{ width: "57%" }} />
                              <col style={{ width: "15%" }} />
                              <col style={{ width: "15%" }} />
                              <tr style={{ padding: '5px', backgroundColor: '#e5e5ea', color: '#3c3c3c', borderRadius: '4px', height: '45px ' }}>
                                <th style={{ textAlign: 'center', fontWeight: 500 }}>Image</th>
                                <th style={{ textAlign: 'center', fontWeight: 500 }}>Objects</th>
                                <th style={{ textAlign: 'center', fontWeight: 500 }}>Count</th>
                                <th style={{ textAlign: 'center', fontWeight: 500 }}>Size</th>
                              </tr>
                              {this.state.severity3Data.map((report, key) => {
                                if (report.count == 0) {
                                  return (<></>)
                                } else {
                                  return (
                                    <tr style={{ border: '#EBEFF2', borderStyle: 'solid', borderWidth: '2px', borderRadius: '4px', height: '45px ' }}>
                                      <td style={{ textAlign: 'center', padding: '5px' }}>
                                        <img
                                          style={{ height: this.state.reportData[4].reportView.includes("Small") ? '50px' : '130px' }}
                                          src={`${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${task.image_collection_id}/thumb-${report.image}?${this.state.permissions?.st}`}
                                        />
                                        <p
                                          style={{
                                            overflow: "hidden",
                                            fontSize: "10px",
                                            margin: '0',
                                            fontWeight: 500
                                          }}
                                        >
                                          {report.image.length > 12
                                            ? `${report.image.substring(0, 11)}...`
                                            : `${report.image}`}
                                        </p>
                                      </td>
                                      {report.count == 0 ? "" : <td style={{ textAlign: 'center', fontSize: '14px' }}>
                                        {report.objects}
                                      </td>}
                                      <td style={{ textAlign: 'center', fontSize: '14px' }}>
                                        {`${report.count}`}
                                      </td>
                                      <td style={{ textAlign: 'center', fontSize: '14px' }}>
                                        {report.file_size} MB
                                      </td>
                                    </tr>
                                  );
                                }
                              })}
                            </table>
                          </div> </> :
                        <>
                          {/* --------- Grid view --------- */}
                          <div className="header" style={{ fontWeight: 600, fontSize: '18px', margin: '50px 0px 20px 0px' }}>Severity-3 Detections</div>
                          <div style={{ width: '900px' }}>
                            {this.state.reportData[4].gridData.map((reportArr, key) => {
                              return (
                                <>
                                  <p style={(this.state.reportData[4].reportView.includes("Small") && key > 0 && key != this.state.reportData[4].gridData.length && (key % 4 == 0)) || (this.state.reportData[4].reportView.includes("Large") && key > 1 && (key % 3 == 0)) ? { pageBreakAfter: "always" } : {}}></p>
                                  <div style={{ display: 'flex', marginTop: "60px", width: '99%' }}>
                                    {reportArr.map((report) => {
                                      return (
                                        <>
                                          <div key={key} style={this.state.reportData[4].reportView.includes("Small") ? { width: "270px", borderRadius: "10px", marginRight: '35px' } : { borderRadius: "10px", marginRight: '35px' }}>
                                            <div className="card-header customer-cards-map project-card-header" style={this.state.reportData[4].reportView.includes("Small") ? { height: '190px', borderRadius: "6px 6px 0px 0px", border: '#A0A0A0 solid 1px' } : { width: "425px", height: '290px', borderRadius: "6px 6px 0px 0px", border: '#A0A0A0 solid 1px' }}>
                                              <img id={"ProjectImage2"} style={{ height: this.state.reportData[4].reportView.includes("Small") ? '190px' : '290px', borderRadius: '6px 6px 0px 0px' }} loading="true"
                                                src={`${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${task.image_collection_id}/thumb-${report.image}?${this.state.permissions?.st}`}
                                              //onError={(e) => { e.currentTarget.src = PlanThumb; }} 
                                              />
                                            </div>
                                            <div style={{ width: this.state.reportData[4].reportView.includes("Small") ? '270px' : 'auto', background: 'white', color: 'black', border: '#A0A0A0 solid 1px' }}
                                              className="card-body change-color customer-cards-dashboard-text-map project-card-body">
                                              <div style={{ fontSize: '14px', display: 'flex', height: "20px", alignItems: "center", justifyContent: 'space-between' }}>
                                                <div style={{ overflow: "hidden", }}>
                                                  {
                                                    report.image.length > 12 ?
                                                      `${report.image.substring(0, 12)}...` :
                                                      `${report.image}`
                                                  }
                                                </div>
                                                <div style={{ overflow: "hidden" }}>{report.file_size} MB</div>
                                              </div>
                                              <div style={{ fontSize: this.state.reportData[4].reportView.includes("Small") ? '11px' : '12px', display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
                                                <div style={{ width: "80%", overflowY: 'hidden' }}>Detections : {report.objects}</div>
                                                <div style={{ width: "20%", overflowY: 'hidden', textAlign: 'end' }}>{report.count}</div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )
                                    })}
                                  </div>
                                </>
                              )
                            })}
                          </div>
                        </>
                      : <></>
                    }</> : ''}
                {/* --------------------------- */}

                {/*------ Severity4 Report ------ */}
                {this.state.severity4Data && this.state.severity4Data.length > 0 ?
                  <>
                    {this.state.reportData[5].visibleInReport ?
                      this.state.reportData[5].reportView.includes("List") ?
                        <><div className="header" style={{ fontWeight: 600, fontSize: '18px', margin: '50px 0px 20px 0px' }}>Severity-4 Detections</div>
                          <div>
                            <table style={{ borderRadius: '4px', width: '100%', boxShadow: '0px 6px 18px rgba(0,0,0,0.06)' }}>
                              <col style={{ width: "13%" }} />
                              <col style={{ width: "57%" }} />
                              <col style={{ width: "15%" }} />
                              <col style={{ width: "15%" }} />
                              <tr style={{ padding: '5px', backgroundColor: '#e5e5ea', color: '#3c3c3c', borderRadius: '4px', height: '45px ' }}>
                                <th style={{ textAlign: 'center', fontWeight: 500 }}>Image</th>
                                <th style={{ textAlign: 'center', fontWeight: 500 }}>Objects</th>
                                <th style={{ textAlign: 'center', fontWeight: 500 }}>Count</th>
                                <th style={{ textAlign: 'center', fontWeight: 500 }}>Size</th>
                              </tr>
                              {this.state.severity4Data.map((report, key) => {
                                if (report.count == 0) {
                                  return (<></>)
                                } else {
                                  return (
                                    <tr style={{ border: '#EBEFF2', borderStyle: 'solid', borderWidth: '2px', borderRadius: '4px', height: '45px ' }}>
                                      <td style={{ textAlign: 'center', padding: '5px' }}>
                                        <img
                                          style={{ height: this.state.reportData[5].reportView.includes("Small") ? '50px' : '130px' }}
                                          src={`${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${task.image_collection_id}/thumb-${report.image}?${this.state.permissions?.st}`}
                                        />
                                        <p
                                          style={{
                                            overflow: "hidden",
                                            fontSize: "10px",
                                            margin: '0',
                                            fontWeight: 500
                                          }}
                                        >
                                          {report.image.length > 12
                                            ? `${report.image.substring(0, 11)}...`
                                            : `${report.image}`}
                                        </p>
                                      </td>
                                      {report.count == 0 ? "" : <td style={{ textAlign: 'center', fontSize: '14px' }}>
                                        {report.objects}
                                      </td>}
                                      <td style={{ textAlign: 'center', fontSize: '14px' }}>
                                        {`${report.count}`}
                                      </td>
                                      <td style={{ textAlign: 'center', fontSize: '14px' }}>
                                        {report.file_size} MB
                                      </td>
                                    </tr>
                                  );
                                }
                              })}
                            </table>
                          </div> </> :
                        <>
                          {/* --------- Grid view --------- */}
                          <div className="header" style={{ fontWeight: 600, fontSize: '18px', margin: '50px 0px 20px 0px' }}>Severity-4 Detections</div>
                          <div style={{ width: '900px' }}>
                            {this.state.reportData[5].gridData.map((reportArr, key) => {
                              return (
                                <>
                                  <div style={{ display: 'flex', marginTop: "60px", width: '99%' }}>
                                    {reportArr.map((report) => {
                                      return (
                                        <>
                                          <div key={key} style={this.state.reportData[5].reportView.includes("Small") ? { width: "270px", borderRadius: "10px", marginRight: '35px' } : { borderRadius: "10px", marginRight: '35px' }}>
                                            <div className="card-header customer-cards-map project-card-header" style={this.state.reportData[5].reportView.includes("Small") ? { height: '190px', borderRadius: "6px 6px 0px 0px", border: '#A0A0A0 solid 1px' } : { width: "425px", height: '290px', borderRadius: "6px 6px 0px 0px", border: '#A0A0A0 solid 1px' }}>
                                              <img id={"ProjectImage2"} style={{ height: this.state.reportData[5].reportView.includes("Small") ? '190px' : '290px', borderRadius: '6px 6px 0px 0px' }} loading="true"
                                                src={`${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${task.image_collection_id}/thumb-${report.image}?${this.state.permissions?.st}`}
                                              //onError={(e) => { e.currentTarget.src = PlanThumb; }} 
                                              />
                                            </div>
                                            <div style={{ width: this.state.reportData[5].reportView.includes("Small") ? '270px' : 'auto', background: 'white', color: 'black', border: '#A0A0A0 solid 1px' }}
                                              className="card-body change-color customer-cards-dashboard-text-map project-card-body">
                                              <div style={{ fontSize: '14px', display: 'flex', height: "20px", alignItems: "center", justifyContent: 'space-between' }}>
                                                <div style={{ overflow: "hidden", }}>
                                                  {
                                                    report.image.length > 12 ?
                                                      `${report.image.substring(0, 12)}...` :
                                                      `${report.image}`
                                                  }
                                                </div>
                                                <div style={{ overflow: "hidden" }}>{report.file_size} MB</div>
                                              </div>
                                              <div style={{ fontSize: this.state.reportData[5].reportView.includes("Small") ? '11px' : '12px', display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
                                                <div style={{ width: "80%", overflowY: 'hidden' }}>Detections : {report.objects}</div>
                                                <div style={{ width: "20%", overflowY: 'hidden', textAlign: 'end' }}>{report.count}</div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )
                                    })}
                                  </div>
                                </>
                              )
                            })}
                          </div>
                        </>
                      : <></>
                    }</> : ''}
                {/* --------------------------- */}

                {/*------ Severity5 Report ------ */}
                {this.state.severity5Data && this.state.severity5Data.length > 0 ?
                  <>
                    {this.state.reportData[6].visibleInReport ?
                      this.state.reportData[6].reportView.includes("List") ?
                        <><div className="header" style={{ fontWeight: 600, fontSize: '18px', margin: '50px 0px 20px 0px' }}>Severity-5 Detections</div>
                          <div>
                            <table style={{ borderRadius: '4px', width: '100%', boxShadow: '0px 6px 18px rgba(0,0,0,0.06)' }}>
                              <col style={{ width: "13%" }} />
                              <col style={{ width: "57%" }} />
                              <col style={{ width: "15%" }} />
                              <col style={{ width: "15%" }} />
                              <tr style={{ padding: '5px', backgroundColor: '#e5e5ea', color: '#3c3c3c', borderRadius: '4px', height: '45px ' }}>
                                <th style={{ textAlign: 'center', fontWeight: 500 }}>Image</th>
                                <th style={{ textAlign: 'center', fontWeight: 500 }}>Objects</th>
                                <th style={{ textAlign: 'center', fontWeight: 500 }}>Count</th>
                                <th style={{ textAlign: 'center', fontWeight: 500 }}>Size</th>
                              </tr>
                              {this.state.severity5Data.map((report, key) => {
                                if (report.count == 0) {
                                  return (<></>)
                                } else {
                                  return (
                                    <tr style={{ border: '#EBEFF2', borderStyle: 'solid', borderWidth: '2px', borderRadius: '4px', height: '45px ' }}>
                                      <td style={{ textAlign: 'center', padding: '5px' }}>
                                        <img
                                          style={{ height: this.state.reportData[6].reportView.includes("Small") ? '50px' : '130px' }}
                                          src={`${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${task.image_collection_id}/thumb-${report.image}?${this.state.permissions?.st}`}
                                        />
                                        <p
                                          style={{
                                            overflow: "hidden",
                                            fontSize: "10px",
                                            margin: '0',
                                            fontWeight: 500
                                          }}
                                        >
                                          {report.image.length > 12
                                            ? `${report.image.substring(0, 11)}...`
                                            : `${report.image}`}
                                        </p>
                                      </td>
                                      {report.count == 0 ? "" : <td style={{ textAlign: 'center', fontSize: '14px' }}>
                                        {report.objects}
                                      </td>}
                                      <td style={{ textAlign: 'center', fontSize: '14px' }}>
                                        {`${report.count}`}
                                      </td>
                                      <td style={{ textAlign: 'center', fontSize: '14px' }}>
                                        {report.file_size} MB
                                      </td>
                                    </tr>
                                  );
                                }
                              })}
                            </table>
                          </div> </> :
                        <>
                          {/* --------- Grid view --------- */}
                          <div className="header" style={{ fontWeight: 600, fontSize: '18px', margin: '50px 0px 20px 0px' }}>Severity-5 Detections</div>
                          <div style={{ width: '900px' }}>
                            {this.state.reportData[6].gridData.map((reportArr, key) => {
                              return (
                                <>
                                  <div style={{ display: 'flex', marginTop: "60px", width: '99%' }}>
                                    {reportArr.map((report) => {
                                      return (
                                        <>
                                          <div key={key} style={this.state.reportData[6].reportView.includes("Small") ? { width: "270px", borderRadius: "10px", marginRight: '35px' } : { borderRadius: "10px", marginRight: '35px' }}>
                                            <div className="card-header customer-cards-map project-card-header" style={this.state.reportData[6].reportView.includes("Small") ? { height: '190px', borderRadius: "6px 6px 0px 0px", border: '#A0A0A0 solid 1px' } : { width: "425px", height: '290px', borderRadius: "6px 6px 0px 0px", border: '#A0A0A0 solid 1px' }}>
                                              <img id={"ProjectImage2"} style={{ height: this.state.reportData[6].reportView.includes("Small") ? '190px' : '290px', borderRadius: '6px 6px 0px 0px' }} loading="true"
                                                src={`${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${task.image_collection_id}/thumb-${report.image}?${this.state.permissions?.st}`}
                                              //onError={(e) => { e.currentTarget.src = PlanThumb; }} 
                                              />
                                            </div>
                                            <div style={{ width: this.state.reportData[6].reportView.includes("Small") ? '270px' : 'auto', background: 'white', color: 'black', border: '#A0A0A0 solid 1px' }}
                                              className="card-body change-color customer-cards-dashboard-text-map project-card-body">
                                              <div style={{ fontSize: '14px', display: 'flex', height: "20px", alignItems: "center", justifyContent: 'space-between' }}>
                                                <div style={{ overflow: "hidden", }}>
                                                  {
                                                    report.image.length > 12 ?
                                                      `${report.image.substring(0, 12)}...` :
                                                      `${report.image}`
                                                  }
                                                </div>
                                                <div style={{ overflow: "hidden" }}>{report.file_size} MB</div>
                                              </div>
                                              <div style={{ fontSize: this.state.reportData[6].reportView.includes("Small") ? '11px' : '12px', display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
                                                <div style={{ width: "80%", overflowY: 'hidden' }}>Detections : {report.objects}</div>
                                                <div style={{ width: "20%", overflowY: 'hidden', textAlign: 'end' }}>{report.count}</div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )
                                    })}
                                  </div>
                                </>
                              )
                            })}
                          </div>
                        </>
                      : <></>
                    }</> : ''}
                {/* --------------------------- */}

                {/* ------------ FOOTER --------- */}
                {/* <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: '120px' }}>
                  <img style={{ height: "45px", borderRadius: '10px', cursor: 'pointer' }} src={this.state.company_logo} />
                </div> */}
                {/* ----------------------------- */}

              </div>
            </div>
          </div>
          <div style={{ width: '23%', paddingRight: "20px" }}>
            {/* <button style={{
              position: 'fixed',
              top: '60px', right: '150px',
              opacity: this.state.permissions?.[PICSTORK_FEATURES.REPORTS].DOWNLOAD ? 1 : 0.5,
              cursor: this.state.permissions?.[PICSTORK_FEATURES.REPORTS].DOWNLOAD ? "pointer" : "not-allowed"
            }} className="add-user-form-submit-button"
              onClick={() => {
                if (this.state.permissions?.[PICSTORK_FEATURES.REPORTS].DOWNLOAD) {
                  this.setState((state) => ({
                    ...state,
                    itemName: "Report",
                    processPopupIcon: "WARNING",
                    processMessage: "Your report generation request is still in progress, This will download your previously saved report. Are you sure you want to continue ? ",
                    showProcessPopup: this.state.downloadWarning ? true : false,
                    filetype: 'pdf'
                  }), () => {
                    if (!this.state.downloadWarning) this.downloadPdf()
                  })
                }
              }}>
              Download PDF
            </button>

            <button style={{
              position: 'fixed', top: '60px', right: '15px',
              opacity: this.state.permissions?.[PICSTORK_FEATURES.REPORTS].DOWNLOAD ? 1 : 0.5,
              cursor: this.state.permissions?.[PICSTORK_FEATURES.REPORTS].DOWNLOAD ? "pointer" : "not-allowed"
            }} className="add-user-form-submit-button"
              onClick={() => {
                if (this.state.permissions?.[PICSTORK_FEATURES.REPORTS].DOWNLOAD) {
                  this.setState((state) => ({
                    ...state,
                    itemName: "Report",
                    processPopupIcon: "WARNING",
                    processMessage: "Your report generation request is still in progress, This will download your previously saved report. Are you sure you want to continue ? ",
                    showProcessPopup: this.state.downloadWarning ? true : false,
                    filetype: 'xlsx'
                  }), () => {
                    if (!this.state.downloadWarning) this.downloadPdf()
                  })
                }
              }}>
              Download Excel
            </button> */}
            <div style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>

              <DropDownWithButton
                isButton
                selectedItem={"Download"}
                width={"100px"}
                isDisabled={this.state.isEdit || (!this.state.permissions?.[PICSTORK_FEATURES.REPORTS].DOWNLOAD)}
                list={[
                  {
                    name: "PDF",
                    onClick: () => {
                      if (this.state.permissions?.[PICSTORK_FEATURES.REPORTS].DOWNLOAD) {
                        this.setState((state) => ({
                          ...state,
                          itemName: "Report",
                          processPopupIcon: "WARNING",
                          processMessage: "Your report generation request is still in progress, This will download your previously saved report. Are you sure you want to continue ? ",
                          showProcessPopup: this.state.downloadWarning ? true : false,
                          filetype: 'pdf'
                        }), () => {
                          if (!this.state.downloadWarning) this.downloadPdf()
                        })
                      }
                    }
                  },
                  {
                    name: "Excel",
                    onClick: () => {
                      if (this.state.permissions?.[PICSTORK_FEATURES.REPORTS].DOWNLOAD) {
                        this.setState((state) => ({
                          ...state,
                          itemName: "Report",
                          processPopupIcon: "WARNING",
                          processMessage: "Your report generation request is still in progress, This will download your previously saved report. Are you sure you want to continue ? ",
                          showProcessPopup: this.state.downloadWarning ? true : false,
                          filetype: 'xlsx'
                        }), () => {
                          if (!this.state.downloadWarning) this.downloadPdf()
                        })
                      }
                    }
                  }
                ]}
              />
              {!(this.isReportEditable()) ? <></> :
                (this.state.report_generated == null && this.state.minutesDiff > 30) || (this.state.report_generated !== null && this.state.report_generated < this.state.report_modified) || (this.state.report_generated == null && this.state.generation_status) || (this.state.generation_status && this.state.report_generated != null) ?
                  <div style={{ position: '', top: '90px', right: '20px', color: "#3c3c3c", fontSize: '10px', fontWeight: '300', textAlign: "right", marginTop: "5px", marginRight: "5px" }}>
                    Your report data was modified,
                    <div clasName="reportLabel" style={{ color: "#3c3c3c" }}>click here to <span style={{ cursor: "pointer", color: "#2989cf" }} onClick={() => {
                      this.setState((state) => ({
                        ...state,
                        showProcessPopup: true,
                        filetype: 'pdf'
                      }), () => this.generatePdf()
                      )
                    }}>Update report</span></div>
                  </div>
                  : <></>

              }
            </div>



            <div style={{ height: '78vh', overflow: 'auto', position: '', top: '135px', marginTop: "20px", marginRight: "5px" }}>
              {this.state.reportData.length == 0 ? <div style={{ width: '100%', textAlign: 'center' }}>No records found</div> :
                <table style={{ borderRadius: '4px', width: '100%', boxShadow: '0px 6px 18px rgba(0,0,0,0.06)' }}>
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "70%" }} />
                  <col style={{ width: "20%" }} />
                  {/* <tr style={{ border: 'solid 1px #EBEFF2', padding: '5px', borderRadius: '4px', height: '35px ', fontSize: '14px' }}>
                                        <th style={{ border: 'solid 1px #EBEFF2', padding: '0px 20px' }}></th>
                                        <th style={{ border: 'solid 1px #EBEFF2', padding: '0px 20px' }}>Algorithm</th>
                                        <th style={{ border: 'solid 1px #EBEFF2', padding: '0px 20px' }}>Action</th>
                                    </tr> */}
                  {this.state.reportData.map((report, key) => {
                    return <>
                      {key > 0 && (key == 1 || (key == 2 && severity1Data.length > 0) || (key == 3 && severity2Data.length > 0) || (key == 4 && severity3Data.length > 0) || (key == 5 && severity4Data.length > 0) || (key == 6 && severity5Data.length > 0)) ?
                        <tr style={{ border: 'solid 1px #EBEFF2', padding: '5px', borderRadius: '4px', height: '35px ', fontSize: '14px' }}>
                          <td style={{ border: 'solid 1px #EBEFF2', padding: '0px 20px' }}>
                            <input type='checkbox' style={{ height: '15px', width: '15px', cursor: 'pointer' }}
                              checked={report.visibleInReport}
                              onClick={() => {
                                this.checkedTrue(key)
                                // this.state.reportData[key].visibleInReport = false
                              }}
                            />
                          </td>
                          <td colSpan={key > 0 ? 1 : 2} style={{ border: 'solid 1px #EBEFF2', padding: '0px 20px' }}>{report.title}</td>
                          <td style={{ border: 'solid 1px #EBEFF2', padding: '0px 10px' }} align="center">
                            <SelectComponent
                              selected={[{ value: report.reportView }]}
                              itemsList={this.state.reportViews}
                              onchangeHandler={(value) => {
                                let data = this.state.reportData
                                data[key].reportView = value[0].value
                                let arr = key == 1 ? this.state.report : key == 2 ? this.state.severity1Data : key == 3 ? this.state.severity2Data : key == 4 ? this.state.severity3Data : key == 5 ? this.state.severity4Data : key == 6 ? this.state.severity5Data : []
                                if (value[0].value == 'Grid Small' || value[0].value == 'Grid Large')
                                  data[key].gridData = this.getSelectedReportData(arr, value[0].value)
                                this.setState({
                                  reportData: data,
                                  report_generated: null,
                                  generation_status: true,
                                })
                              }}
                              fontSize={12}
                              fontWeight={400}
                              backgroundColor={"white"}
                              border={"1px solid #efefef"}
                              height={"24px"}
                              width={'100px'}
                              minWidth={"80px"}
                              dropdownGap={1}
                              labelField={"value"}
                            />
                          </td>
                          {/*{this.state.isOwner ? <td style={{ border: 'solid 1px #EBEFF2', padding: '0px 20px', textAlign: 'center' }}>
                        <img src={DeleteIcon} style={{ width: '20px', height: '20px', opacity: "0.5" }}
                        // onClick={() => {
                        //     this.setState((state) => ({
                        //         ...state,
                        //         deleteReport_id: report.report_id,
                        //         itemName: "Customise report item",
                        //         processPopupIcon: "WARNING",
                        //         processAction: "DELETE",
                        //         processMessage: null,
                        //         showProcessPopup: true,
                        //     }))
                        // }} 
                        />
                      </td> : <></>} */}
                        </tr> : <></>}
                    </>
                  })}

                </table>}
            </div>
          </div>
        </div>
        }

      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    SubscriptionData: state.subscription.subscriptionData,
  };
};

export default connect(mapStateToProps)(AeroCapturePdfDownload);
