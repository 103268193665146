import React, { Component } from 'react';
import service from "../../sidebaricon/Services.png";
import { Modal } from "react-bootstrap";
import dashboardIcon from '../../icons/AeroCaptureInactive.png';
import projectActive from '../../assets/sideBarIcons/Project.png';
import { connect } from "react-redux";
import { BreadCrumbs } from '../../ReusableComponents/BreadCrumbs';
import * as API from '../../api.js';
import Plus from '../../assets/Icons/plus.png';
import Loader from '../../icons/loader.svg';
import { Link, Redirect } from 'react-router-dom';
import Menu from '../assets/icons/menu.svg';
import dateFormat from 'dateformat';
import { getLimitedName, getPicstorkDefaultPlan, getUniqueCollectionName } from '../../ReusableComponents/reusableFunctions';
import ImageSetThumb from '../../icons/ImageSetThumb.png'
import { CreateProjectPlanModal } from '../../ReusableComponents/CreateProjectPlanModal';
import { ActivityPopup } from '../../ReusableComponents/ActivityPopup.js';
import helpIcon from "../../sidebaricon/Help.png"
import AeroMeghLogo from "../../aeromeghLog.png";
import { addUserAnalytic, updateOutTime } from '../../ReusableComponents/reusableFunctions';
import { DeleteComponentTable, DeleteComponentGrid } from '../../ReusableComponents/Menu';
import { SortComponent } from '../../ReusableComponents/SortComponent';
import AppConstants, { BLOB_URL, SERVICES } from '../../AppConstants';
import { AnimatedModal } from '../../ReusableComponents/AnimatedModal';
import { ActiveRole, getFeaturesPermissions, PICSTORK_FEATURES, setActiveRoleForProject } from '../../Teams';
import ButtonWithIcon from '../../ReusableComponents/ButtonWithIcon';
import Planner from '../../Teams/Planner';
import EntitySelector from '../../ReusableComponents/EntitySelector';
import DropDownWithButton from '../../ReusableComponents/DropDownWithButton';
import whitePlus from "../../assets/Icons/whiteplus.png";
import Notask from '../../assets/Images/Notask.png';
import TrainingTask from "../../assets/Images/trainingTask.png";
import DetectionTask from "../../assets/Images/detectionTask.png";
import AnnotationTask from "../../assets/Images/annotationTask.png";
import Noimageset from '../../assets/Images/Noimageset.png';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <img src={Menu} alt="I am alt" />
    </a>
));
class AeroCaptureProject extends Component {

    state = {
        user: {},
        type: "",
        progress: 0,
        key: " ",
        currentpage: 1,
        showDownloadPopup: false,
        loader: true,
        collection_name: "",
        services: [],
        plans: [],
        tasks: [],
        project: [],
        collections: [],
        userperpage: 5,
        showBackIcon: true,
        cancel: false,
        loading: false,
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        thumbURLs: [],
        sortType: 'Last Modified',
        sortTypeName: false,
        sortTypeDate: false,
        outId: "",
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.state.notificationId != this.props.location.state.notificationId) {
            getFeaturesPermissions([PICSTORK_FEATURES.IMAGECOLLECTIONS, PICSTORK_FEATURES.TASKS])
                .then(permissions => {
                    let { user, type, project } = this.props.location.state;
                    this.setState(state => ({
                        ...state,
                        permissions,
                        user,
                        type,
                        project
                    }), async () => {
                        this.getCollections()
                        this.getTasks()
                    })
                })
        }
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }
    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null,
            showEntitySelector: false
        }))
    }

    deleteTask = () => {
        this.setState((state) => ({
            itemName: "Task",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: null,
            showProcessPopup: true,
        }))
        API.deleteAerocaptureTask(this.state.selectedTaskId).then((data) => {
            //console.log(data);
            console.log("TASK ID DELETED : ", this.state.selectedTaskId);
            this.setState((state) => ({
                itemName: "Task",
                processPopupIcon: "COMPLETE",
                processAction: "DELETE",
                processMessage: null,
                showProcessPopup: true,
                tasks: this.state.tasks.filter((task) => task.task_id != this.state.selectedTaskId)
            }))
        }, (e) => {
            //console.log(e);
            this.setState((state) => ({
                itemName: "Task",
                processPopupIcon: "ERROR",
                processAction: "DELETE",
                processMessage: e,
                showProcessPopup: true,
            }))
        })
    }


    getCollections = (redirect) => {
        let { user, type, sidebarMode, project } = this.state
        this.setState((state) => ({
            ...state,
            loadingImageset: true
        }))
        if (this.state.permissions[PICSTORK_FEATURES.IMAGECOLLECTIONS].READ) API.getLatestImageSets("project", 1000000, this.state.project.id, 0).then((data) => {
            this.setState((state) => ({
                ...state,
                collections: data.imageCollections,
                collectionCount: data.count,
                loadingImageset: false
            }), () => {
                if (redirect) {
                    this.props.history.push({ pathname: "/picstork/project", state: { user, type, sidebarMode, project } })
                    this.setState((state) => ({
                        ...state,
                        redirect: true
                    }))
                }
            })
            // this.loadTiff();
        }, (e) => {
            console.log(e);
        })
        else {
            this.setState((state) => ({
                ...state,
                collections: [],
                collectionCount: 0,
                loadingImageset: false
            }))
        }
    }


    downloadCollection = () => {
        let colName = this.state.collections.find((col) => col.id == this.state.selectedCollectionId)
        API.donwloadCollectionZip(this.state.selectedCollectionId, colName.collection_name)
    }


    getTasks = () => {
        const { user, type, userdisplay, currentpage, key, counts, requestStatus, userperpage } = this.state;
        let state = this.props.location.state;
        const lastIndex = currentpage * userperpage;
        const firstpage = lastIndex - userperpage;
        this.setState(state => ({
            ...state,
            counts: 0,
            taskLoading: true
        }))
        if (this.state.permissions[PICSTORK_FEATURES.TASKS].READ) API.getAllTask(this.state.project.id, 1, key, 0, 100000, "PicStork").then(async (data) => {
            console.log("tasks", data.tasks)
            if (data.tasks && data.tasks.length == 0) {
                this.setState((state) => ({
                    ...state,
                    tasks: [],
                    taskLoading: false,
                }))
            } else {
                try {
                    let pendingTasks = await data.tasks.filter((task) => {
                        if (task.status == 'Pending') {
                            return task
                        }
                    });
                    let tasks = await data.tasks.filter((task) => {
                        if (task.status != 'Pending') {
                            return task
                        }
                    });
                    let allTasks = await pendingTasks.concat(tasks)
                    this.setState((state) => ({
                        ...state,
                        tasks: allTasks,
                        taskLoading: false,
                        counts: data.count,
                        statusCount: data.statusCount,
                    }), () => {
                        if (this.state.sortType === 'A-Z') {
                            this.sortByName()
                        } else if (this.state.sortType === 'Z-A') {
                            this.reverseByName()
                        } else if (this.state.sortType === 'First Modified') {
                            this.sortByDate()
                        } else if (this.state.sortType === 'Last Modified') {
                            this.reverseByDate()
                        }
                    })
                } catch (e) {
                    console.log(e);
                }
            }
        }, (e) => {
            //console.log(e);
        })
        else {
            this.setState((state) => ({
                ...state,
                tasks: [],
                taskLoading: false,
            }))
        }
    }

    setAddImagesetError = () => {
        this.setState((state) => ({
            ...state,
            itemName: "Imageset",
            processPopupIcon: "ERROR",
            processAction: "ADD",
            processMessage: "Something went wrong",
            showProcessPopup: true,
        }))
    }
    createCollectionCopy = async () => {
        this.setState((state) => ({
            ...state,
            showCopyImagePopup: false,
            itemName: "Imageset",
            processPopupIcon: "WAIT",
            processAction: "ADD",
            processMessage: "Duplicating your image collection please Wait...",
            showProcessPopup: true,
        }))
        try {
            const plan = await getPicstorkDefaultPlan(this.state.project)
            const data = await API.createImageCollection(this.state.project.id, plan.id, this.state.newCollectionName)
            if (data.collection) {
                API.duplicateImageSet(this.state.permissions.container, this.state.oldCollectionId, data.collection.id, AppConstants.SERVICES.PICSTORK).then((res) => {
                    if (res.status == 201 || res.status == 202) {
                        this.getCollections();
                        this.setState((state) => ({
                            ...state,
                            newCollecton: data.collection,
                            itemName: "Imageset",
                            processPopupIcon: "COMPLETE",
                            processAction: "ADD",
                            processMessage: 'Imageset copied successfully.',
                            showProcessPopup: true,
                        }))
                    } else {
                        this.setState((state) => ({
                            ...state,
                            newCollecton: data.collection,
                            itemName: "Imageset",
                            processPopupIcon: "ERROR",
                            processAction: "ADD",
                            processMessage: 'Something went wrong when copying images.',
                            showProcessPopup: true,
                        }), () => {
                            API.deleteAerocaptureImageCollection(data.collection.id, SERVICES.PICSTORK).then((data) => {
                                console.log(data);
                                this.getCollections()
                            })
                        })
                    }

                })
            } else if (data.status == 409) {
                this.setState((state) => ({
                    ...state,
                    itemName: "Imageset",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: "Imageset already exist with same name.",
                    showProcessPopup: true,
                }))
            } else {
                this.setAddImagesetError()
            }
        } catch (e) {
            this.setAddImagesetError()
        }
    }
    sortByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'A-Z'
        }));
        let sort = this.state.tasks.sort((a, b) => a.task_name.localeCompare(b.task_name))
        this.setState((state) => ({
            ...state,
            tasks: sort,
        }));

    }

    reverseByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Z-A'
        }));
        let sort = this.state.tasks.sort((a, b) => b.task_name.localeCompare(a.task_name))
        this.setState((state) => ({
            ...state,
            tasks: sort,
        }));
    }

    sortByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'First Modified'
        }));
        let sort = this.state.tasks.sort((a, b) => a.date_created.localeCompare(b.date_created))
        this.setState((state) => ({
            ...state,
            tasks: sort,
        }));
    }

    reverseByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Last Modified'
        }));
        let sort = this.state.tasks.sort((a, b) => b.date_created.localeCompare(a.date_created))
        this.setState((state) => ({
            ...state,
            tasks: sort,
        }));
    }

    handleChange = (event) => {
        let format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (format.test(event.target.value)) {
        } else
            if (event.target.value.includes(' ')) {
            } else {
                this.setState({ collection_name: event.target.value });
            }
    }



    createCollection = async () => {
        let { user, project, type, sidebarMode } = this.state
        this.setState((state) => ({
            ...state,
            showAddImagePopup: false,
            itemName: "Imageset",
            processPopupIcon: "WAIT",
            processAction: "ADD",
            processMessage: null,
            showProcessPopup: true,
        }))
        try {
            const plan = await getPicstorkDefaultPlan(this.state.project)
            const data = await API.createImageCollection(this.state.project.id, plan.id, this.state.collection_name)
            if (data.collection) {
                this.setState((state) => ({
                    ...state,
                    newCollecton: data.collection,
                    itemName: "Imageset",
                    processPopupIcon: "COMPLETE",
                    processAction: "ADD",
                    processMessage: null,
                    showProcessPopup: true,
                    collection_name: ''
                }), () => {
                    this.getCollections(true);

                })
            } else if (data.status == 409) {
                this.setState((state) => ({
                    ...state,
                    itemName: "Imageset",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: "Imageset already exist with same name.",
                    showProcessPopup: true,
                    collection_name: ''
                }))
            } else {
                this.setAddImagesetError()
            }
        } catch (e) {
            this.setAddImagesetError()
        }
    }

    handleChangeCopy = (e) => {
        let format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;

        if (format.test(e.target.value)) {
            // setError("Spacial characters are not allowed.")
        } else
            if (e.target.value.includes(' ')) {
                // setError("Spaces are not allowed.")
            } else {
                // setError(undefined)
                this.setState({ newCollectionName: e.target.value });
            }
    }

    successRedirectComponent = () => {
        if (this.state.redirect) {
            return <Redirect to={{
                pathname: "/picstork/images",
                state: {
                    user: this.state.user,
                    type: this.state.type,
                    plan: this.state.plan,
                    project: this.state.project,
                    sidebarMode: this.state.sidebarMode,
                    collection: this.state.newCollecton
                }
            }} />
        }
    }

    deleteImageCollection = () => {
        this.setState((state) => ({
            itemName: "Imageset",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: null,
            showProcessPopup: true,
        }))
        API.deleteAerocaptureImageCollection(this.state.selectedCollectionId, AppConstants.SERVICES.PICSTORK).then((data) => {
            this.setState((state) => ({
                itemName: "Imageset",
                processPopupIcon: "COMPLETE",
                processAction: "DELETE",
                processMessage: null,
                showProcessPopup: true,
                selectedCollectionId: '',
                allItemsLoader: true,
                hasMoreItems: true,
                collections: this.state.collections.filter((col) => col.id != this.state.selectedCollectionId),
                collectionCount: this.state.collectionCount - 1,
            }))
        }, (e) => {
            //console.log(e);
            this.setState((state) => ({
                itemName: "Imageset",
                processPopupIcon: "ERROR",
                processAction: "DELETE",
                processMessage: e,
                showProcessPopup: true,
            }))
        })
    }


    handleImportImagesets = async (selectedEntities) => {
        let plan = {}
        try {

            const response = await getPicstorkDefaultPlan(this.state.project)
            console.log(response)
            plan = response
        } catch (err) { console.log(err) }
        switch (selectedEntities.entityType) {
            case "projects": break;
            case "plans": break;
            case "imagesets":
                // let importedImagesets = []
                let errorOccured = false;
                for (const entity of selectedEntities.entites) {
                    if (errorOccured) break;
                    await new Promise((resolve, reject) => {
                        API.createImageCollection(this.state.project.id, plan.id, `${getUniqueCollectionName(entity.collection_name)}`).then(data => {
                            // const res = await data.json()
                            if (data.collection) {
                                API.duplicateImageSet(this.state.permissions.container, entity.id, data.collection.id, AppConstants.SERVICES.PICSTORK).then((res) => {
                                    if (res.status == 201 || res.status == 202) {
                                        resolve()
                                    } else {
                                        errorOccured = true;
                                        API.deleteAerocaptureImageCollection(data.collection.id, AppConstants.SERVICES.PICSTORK).then((data) => {
                                            resolve()
                                        }).catch(err => {
                                            errorOccured = true
                                            resolve()
                                        })
                                    }

                                }).catch(err => {
                                    errorOccured = true
                                    resolve()
                                }
                                )
                            }
                            else if (data?.status === 409) {
                                errorOccured = true;
                                resolve()
                            }
                        }).catch(err => {
                            errorOccured = true
                            resolve()
                        })
                    })
                }
                if (errorOccured) {
                    this.setState({ showProcessPopup: true, processMessage: "Something went wrong while importing imagesets", processPopupIcon: "ERROR" })
                } else {
                    this.setState({
                        processMessage: "Collections imported succesfully", processPopupIcon: "COMPLETE",
                    }, () => {
                        this.getCollections()
                    })
                }

                break;
            default:
        }
    }
    componentWillUnmount() {
        updateOutTime(this.state.outId)
        localStorage.removeItem('created_task')
    }

    componentWillMount() {
        var style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = '.popup-content { width: 30% !important; }';
        document.getElementsByTagName('head')[0].appendChild(style);
        document.title = "PicStork - Project";
        setActiveRoleForProject(this.props.location.state.project.id).then(role => {
            if (!role) window.location.replace("/");
            else getFeaturesPermissions([PICSTORK_FEATURES.IMAGECOLLECTIONS, PICSTORK_FEATURES.TASKS])
                .then(permissions => {
                    addUserAnalytic(this.props.location.pathname)
                        .then(id => {
                            this.setState((state) => ({
                                outId: id
                            }))
                        })
                    if (this.props.location.state) {

                        let { user, type, project } = this.props.location.state;
                        this.setState((state) => ({
                            ...state,
                            user,
                            type,
                            permissions,
                            project
                        }), async () => {
                            this.getCollections()
                            this.getTasks()
                        })
                    } else {
                        API.getUserType().then(([blah, type, user]) => {
                            this.setState((state) => ({
                                ...state,
                                user,
                                type
                            }), () => {
                                this.getTasks()
                                this.getCollections()
                            })
                        }, (e) => {
                            window.location.replace("/login");
                        })
                    }
                }).catch(e => {
                    console.log(e)
                    window.location.replace("/");
                })
        }).catch(e => {
            console.log(e)
            window.location.replace("/");
        })
    }

    render() {
        let { user, type, services, sidebarMode, showBackIcon, tasks, plans, project, statusCount, collections, calculateNoOfDays } = this.state
        return (
            user.username ? <div className="wrapper">
                {this.successRedirectComponent()}
                <div style={{ display: 'flex' }}>
                    <div className="sidebar-expanded" style={{ paddingTop: "0px" }}>
                        <div style={{ display: "flex", height: "40px" }}>
                            <div style={{ height: "40px", transition: "0.3s", margin: 'auto' }}>
                                <img
                                    src={AeroMeghLogo}
                                    style={{ width: "65px", height: "20px", marginTop: "10px" }}
                                />
                            </div>
                        </div>
                        <ul className="sidebar-nav" id="sidebar-nav">
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/",
                                            state: {
                                                type,
                                                user,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={service}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                // style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"Services"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>


                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/picstork/dashboard",
                                            state: {
                                                type,
                                                user,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={dashboardIcon}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                // style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"PicStork"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"selectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/picstork/project",
                                            state: {
                                                type,
                                                user,
                                                project: this.props.location.state.project,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={projectActive}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"Project"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <div
                                        className="onhoverext"
                                        onClick={
                                            () => {
                                                window.open("https://dronenaksha-docs.aeromegh.com/", "_blank")
                                            }
                                        }
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={helpIcon}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                            >
                                                {"Help"}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>
                {/* <SideBar config={sidebarConfigurations} showMainDashboardIcons={this.state.showBackIcon} parentSidebarSwitch={this.parentSidebarSwitch} /> */}
                <div className="right-content"
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >

                    {/* Entity selector */}
                    {this.state.showEntitySelector &&
                        <EntitySelector
                            allowMultipleSelect
                            serviceList={[AppConstants.SERVICES.DRONENAKSHA, AppConstants.SERVICES.AEROGCS]}
                            btnTitle={"Import"}
                            show={this.state.showEntitySelector}
                            entity={"imagesets"}
                            onRequestClose={() => {
                                this.setState({ showEntitySelector: false })
                            }}
                            callback={async (selectedEntities) => {
                                this.setState({
                                    showProcessPopup: true,
                                    processPopupIcon: "WAIT",
                                    processMessage: `Importing ${selectedEntities.entityType} please wait...`
                                }, () => {
                                    this.handleImportImagesets(selectedEntities)
                                })
                            }}
                        />}
                    {/* -------------- Activity Popup ----------------------- */}
                    <ActivityPopup
                        item={this.state.itemName}
                        open={this.state.showProcessPopup}
                        icon={this.state.processPopupIcon}
                        action={this.state.processAction}
                        msg={this.state.processMessage}
                        close={() => this.closeActivityPopup()}
                        onClickOk={() => this.state.itemName == 'Imageset' ? this.deleteImageCollection() : this.deleteTask()}
                    />
                    {/* --------------------------------------------------------------- */}

                    {/* Copy collection popup */}
                    <Modal
                        style={{ marginTop: '150px' }}
                        show={this.state.showCopyImagePopup}
                        onHide={() => {
                            this.setState((state) => ({
                                ...state,
                                showCopyImagePopup: false,
                                newCollectionName: ''

                            }));
                        }}
                    >
                        <Modal.Body >
                            <div style={{ marginBottom: "30px" }}>
                                <div className="services-content-title">{"Copy an Imageset"}</div>
                                {/* <label className="font-color-secondary" style={{ fontSize: "14px" }}>To upload images you need to create an imageset.</label> */}
                                <input type="text" value={this.state.newCollectionName} className="add-user-form-text" placeholder="eg. Imageset Name" maxLength={AppConstants.IMAGESET_NAME_LIMIT}
                                    onChange={(e) => { this.handleChangeCopy(e) }} />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                className="addcustomer-button-empty-dashboard popup-btn-left"
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        showCopyImagePopup: false,
                                    }));
                                }}
                            >
                                cancel
                            </button>
                            {this.state.newCollectionName ?
                                <button
                                    className="add-user-form-submit-button popup-btn-right btn-ok"
                                    onClick={() => {
                                        this.createCollectionCopy()
                                    }}
                                >
                                    Ok
                                </button> :
                                <button
                                    className="add-user-form-submit-button popup-btn-right btn-ok"
                                    style={{ opacity: '0.5', cursor: 'default' }}
                                >
                                    Ok
                                </button>}

                        </Modal.Footer>
                    </Modal>
                    {/* --------------------- */}

                     <CreateProjectPlanModal
                        serviceName={AppConstants.SERVICES.PICSTORK}
                        user={this.state.user}
                        type={this.state.type}
                        createProjectPlanModal={this.state.createProjectPlanModal}
                        closeCreateProjectPlanModal={(newItem) => {
                            if (newItem && newItem.includes("PLAN"))
                                this.getPlans();
                            this.setState({ createProjectPlanModal: false })
                        }}

                        project={this.state.project}
                        createType={"plan"}
                    />

                    {/*------------- Add Imageset Popup ---------------*/}
                    <Modal
                        style={{ marginTop: '150px' }}
                        show={this.state.showAddImagePopup}
                        onHide={() => {
                            this.setState((state) => ({
                                ...state,
                                showAddImagePopup: false,
                                collection_name: ''

                            }));
                        }}
                    >
                        <Modal.Body >
                            <div style={{ marginBottom: "30px" }}>
                                <div className="services-content-title" >{"Create an Imageset"}</div>
                                <label className="font-color-secondary" style={{ fontSize: "13px" }}>To upload images you need to create an imageset.</label>
                                <input type="text" value={this.state.collection_name} autoFocus maxLength={AppConstants.POPUP_INPUT_TEXT_LIMIT} className="add-user-form-text" placeholder="eg. Imageset Name"
                                    onChange={this.handleChange} />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }} >
                                <ButtonWithIcon
                                    text="cancel"
                                    onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            showAddImagePopup: false,
                                        }));
                                    }} />
                                <ButtonWithIcon
                                    text="Ok" isDisabled={!this.state.collection_name} isBtnActive width="74px"
                                    onClick={(e) => {
                                        if (this.state.collection_name) this.createCollection();
                                    }} />

                            </div>
                        </Modal.Footer>
                    </Modal>

                    {/* ----------------------------------   TASK TYPE SELECTION POPUP -----------------------------*/}
                    <AnimatedModal
                        height="325px"
                        width="670px"
                        zIndex={5}
                        onRequestClose={() => this.setState({ selectTask: false })}
                        isOpen={this.state.selectTask}
                    >
                        <div style={{ padding: '30px 30px 10px 30px' }}>
                            <div style={{ display: 'flex' }}>

                                <div className='taskSelectionCard'
                                >
                                    <Link
                                        to={{
                                            pathname: "trainingTask",
                                            state: {
                                                type,
                                                user,
                                                project: this.state.project,
                                                plan: this.state.plan
                                            },
                                        }}
                                        style={{ color: "#212529" }}
                                    >
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <img
                                                src={TrainingTask}
                                                style={{
                                                    margin: "0px 10px 10px 10px",
                                                    height: "90px",
                                                    width: "90px",
                                                }}
                                            />
                                        </div>
                                        <div className='taskSelectionTitle'>
                                            Training
                                        </div>
                                        <div style={{ textAlign: "center", fontSize: "12px" }}>
                                            This type of task will create a detector after training.
                                        </div>
                                    </Link>
                                </div>


                                <div className='taskSelectionCard'>
                                    <Link
                                        to={{
                                            pathname: "annotationReportTask",
                                            state: {
                                                type,
                                                user,
                                                project: this.state.project,
                                                plan: this.state.plan
                                            },
                                        }}
                                        style={{ color: "#212529" }}
                                    >
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <img
                                                src={AnnotationTask}
                                                style={{
                                                    margin: "0px 10px 0px 10px",
                                                    height: "100px",
                                                    width: "100px",
                                                }}
                                            />
                                        </div>
                                        <div className='taskSelectionTitle'>
                                            Annotation Report
                                        </div>
                                        <div style={{ textAlign: "center", fontSize: "12px" }}>
                                            This type of task will include manual annotation.
                                        </div>
                                    </Link>
                                </div>


                                <div className='taskSelectionCard' style={{ marginRight: '0px' }}>
                                    <Link
                                        to={{
                                            pathname: "detectionTask",
                                            state: {
                                                type,
                                                user,
                                                project: this.state.project,
                                                plan: this.state.plan
                                            },
                                        }}
                                        style={{ color: "#212529" }}
                                    >
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <img
                                                src={DetectionTask}
                                                style={{
                                                    margin: "0px 10px 0px 10px",
                                                    height: "100px",
                                                    width: "100px",
                                                }}
                                            />
                                        </div>
                                        <div className='taskSelectionTitle'>
                                            Detection
                                        </div>
                                        <div style={{ textAlign: "center", fontSize: "12px" }}>
                                            This type of task will run a detector on selected images.
                                        </div>
                                    </Link>
                                </div>
                            </div>

                            <div style={{ width: '100%', marginTop: '24px', display: 'flex', justifyContent: 'end' }}>
                                {/* <button
                                    className="add-user-form-submit-button"
                                    onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            selectTask: false,
                                        }));
                                    }}
                                >
                                    Cancel
                                </button> */}
                                <ButtonWithIcon
                                    text="Cancel" isBtnActive
                                    onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            selectTask: false,
                                        }));
                                    }}
                                />
                            </div>
                        </div>

                    </AnimatedModal>


                    <div className="top-bar">
                        <div className="breadCrumbs">
                            <BreadCrumbs data={[
                                {
                                    displayName: "projects",
                                    pathname: "/picstork/dashboard",
                                    state: {
                                        type,
                                        user,
                                    }
                                },
                                {
                                    displayName: project.project_name
                                }
                            ]} />
                            <ActiveRole />
                        </div>
                    </div>

                    {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                        <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                    </div> :
                        <div className="main-content" style={{ display: "flex", height: "94%", width: '100%', marginTop: '' }} onClick={
                            () => {
                                this.setState((state) => ({
                                    ...state, sidebarMode: 'shrunk'
                                }))
                            }
                        }>
                            <div style={{ width: '100%', display: "flex", zIndex: '1', gap: "20px" }}>
                                <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "95%" }}>
                                    <div style={{ width: '100%', height: '50%', display: "flex", flexDirection: "column" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", height: '45px', alignItems: 'center' }}>
                                            <div className="services-content-title">{"Tasks"}
                                                <span className='data-count'>({this.state.tasks.length})</span>
                                            </div>
                                            <div style={{ filter: (this.state.project.sharedData && this.state.project.sharedData.isShared) || !this.state.permissions[PICSTORK_FEATURES.TASKS].CREATE ? "grayscale(100%)" : "" }}>
                                                <ButtonWithIcon text="New Task" icon={Plus} onClick={
                                                    () => {
                                                        this.setState((state) => ({
                                                            ...state,
                                                            selectTask: true,
                                                        }))
                                                    }

                                                }
                                                    isDisabled={!this.state.permissions[PICSTORK_FEATURES.TASKS].CREATE}
                                                />

                                            </div>
                                        </div>


                                        <div className='scroll-container' style={{ border: '1px solid #c2c2c2', height: '91%', backgroundColor: '#FAFAFA', borderRadius: '5px', overflowY: 'auto' }}>
                                            {this.state.taskLoading ?
                                                <div style={{ width: "100%", height: "100%", display: "flex", textAlign: "center", justifyContent: "center", paddingLeft: "10%" }}>
                                                    <img src={Loader} style={{ height: "40%", width: "40%", margin: "auto" }} />
                                                </div>
                                                :
                                                tasks.length === 0 ? <div
                                                    className="rmp-dashboard-empty-data-message"
                                                    style={{ marginTop: '8%' }}
                                                >
                                                    <img style={{ height: '20%', width: '20%', padding: '0px', margin: '0px' }} src={Notask} />
                                                    <p style={{ marginTop: '10px' }}>No Tasks Available</p>
                                                </div> :
                                                    <table className="customers-table " style={{ marginTop: '0px' }}>
                                                        <col style={{ width: '20%' }} />
                                                        <col style={{ width: '20%' }} />
                                                        <col style={{ width: '20%' }} />
                                                        <col style={{ width: '20%' }} />
                                                        <col style={{ width: '20%' }} />
                                                        <tr className="customers-table-header-row">
                                                            <th>Name
                                                                <SortComponent
                                                                    style={{ marginLeft: '5px' }}
                                                                    sortAscending={this.sortByName}
                                                                    sortDecending={this.reverseByName}
                                                                    sortASC={false}
                                                                />

                                                            </th>
                                                            <th style={{ textAlign: '' }}>Status</th>
                                                            <th style={{ textAlign: '' }}>Type</th>
                                                            <th style={{ textAlign: '' }}>Images</th>
                                                            <th style={{ textAlign: '' }}>Date Created
                                                                <SortComponent
                                                                    style={{ marginLeft: '5px' }}

                                                                    sortAscending={this.sortByDate}
                                                                    sortDecending={this.reverseByDate}
                                                                    sortASC={false}
                                                                />
                                                            </th>
                                                            <th></th>
                                                        </tr>
                                                        {this.state.taskLoading ?
                                                            <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                                                <td colspan="7">  <img src={Loader} style={{ margin: "auto", height: '6%', width: '6%' }} /></td>
                                                            </tr>
                                                            :
                                                            tasks.length === 0 ?
                                                                <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                                                    <td colspan="6">No Tasks Available</td>
                                                                </tr> :
                                                                tasks.map((task) =>
                                                                    <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                                                        <td>{task.video_task == true ?
                                                                            <Link
                                                                                style={{ color: "#707683" }}
                                                                                to={{
                                                                                    pathname: "/picstork/videoTask",
                                                                                    state: {
                                                                                        user,
                                                                                        type,
                                                                                        sidebarMode: this.state.sidebarMode,
                                                                                        task: { task_id: task.task_id },
                                                                                        project: { project_name: task.project_name, id: task.project_id },
                                                                                        plan: { plan_name: task.plan_name, id: task.plan_id, project_name: task.project_name },
                                                                                    },
                                                                                }}
                                                                            >
                                                                                <span title={task.classes?.length > 0 ? task.classes.map(c => c.name).join(",") : "No Classes Available"}>{getLimitedName(task.task_name, 15)}</span>
                                                                            </Link> :
                                                                            <Link
                                                                                style={{ color: "#707683" }}
                                                                                to={{
                                                                                    pathname: task.tasktype == 1 ? "/picstork/trainingTask" : task.tasktype == 2 ? "/picstork/detectionTask" : "/picstork/annotationReportTask",
                                                                                    state: {
                                                                                        user,
                                                                                        type,
                                                                                        task: { task_id: task.task_id },
                                                                                        collection: { id: task.image_collection_id },
                                                                                        project: { project_name: task.project_name, id: task.project_id },
                                                                                        plan: { plan_name: task.plan_name, id: task.plan_id, project_name: task.project_name },
                                                                                        sidebarMode: this.state.sidebarMode
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <span title={task.classes?.length > 0 ? task.classes.map(c => c.name).join(",") : "No Classes Available"}>{getLimitedName(task.task_name, 15)}</span>
                                                                            </Link>
                                                                        }
                                                                        </td>
                                                                        {/* <td>{task.tasktype == 1 ? "Training" : task.tasktype == 2 ? "Detection" : "Annotation Report"}</td> */}
                                                                        <td
                                                                            // <div className="aerocapture-detection-type-list"
                                                                            style={{
                                                                                color: task.status == AppConstants.TASK.STATUS.COMPLETED ? '#009900' : task.status == AppConstants.TASK.STATUS.PENDING ? '#2989cf' : task.status == AppConstants.TASK.STATUS.FAILED ? '#F02020' : '#ffcc00',
                                                                                // background: data.displayData[1] == AppConstants.TASK.STATUS.COMPLETED ? '#009900' : data.displayData[1] == AppConstants.TASK.STATUS.PENDING ? '#2989cf' : data.displayData[1] == AppConstants.TASK.STATUS.FAILED ? '#F02020' : '#ffcc00',
                                                                                // fontWeight: 400, margin: "2px 4px"
                                                                            }}>
                                                                            {task.status}
                                                                        </td>
                                                                        <td>
                                                                            {task.tasktype == 1 ? "Training" : task.tasktype == 2 ? "Detection" : task.tasktype === 4 ? "Annotation Report" : "Unknown"}
                                                                        </td>
                                                                        <td style={{ textAlign: '' }}>{task.images}</td>
                                                                        <td style={{ textAlign: '' }}>
                                                                            {
                                                                                (() => {
                                                                                    let date = (new Date(task.date_created)).toGMTString().split(' ').slice(1, 4).join(' ');
                                                                                    return `${date}`
                                                                                })()
                                                                            }
                                                                        </td>

                                                                        <td>
                                                                            <DeleteComponentTable
                                                                                style={{ margin: "0 10px" }}
                                                                                disableDelete={!this.state.permissions[PICSTORK_FEATURES.TASKS].DELETE}
                                                                                onClickDelete={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.setState((state) => ({
                                                                                        ...state,
                                                                                        selectedTaskId: task.task_id,
                                                                                        itemName: "Task",
                                                                                        processPopupIcon: "WARNING",
                                                                                        processAction: "DELETE",
                                                                                        processMessage: null,
                                                                                        showProcessPopup: true,
                                                                                    }))
                                                                                }}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                )}

                                                    </table>
                                            }
                                        </div>
                                    </div>

                                    <div style={{ width: '100%', height: '50%', display: "flex", flexDirection: "column" }}>
                                        <div style={{ height: '100%', marginBottom: '', width: "100%", display: "flex", flexDirection: "column" }}>
                                            <div style={{ display: "flex", justifyContent: 'space-between', height: '45px', alignItems: 'center', padding: "20px 0px" }}>
                                                <div className="services-content-title">{"Imagesets"}
                                                    <span className='data-count'>({this.state.collections.length})</span>
                                                </div>

                                                <div style={{ display: 'flex', alignItems: "center" }}>
                                                    <DropDownWithButton
                                                        icon={whitePlus}
                                                        isButton
                                                        isBtnActive
                                                        selectedItem={"Imageset"}
                                                        isBtnList
                                                        textColor={"white"}
                                                        isDisabled={!this.state.permissions[PICSTORK_FEATURES.IMAGECOLLECTIONS].CREATE}
                                                        list={
                                                            [
                                                                {
                                                                    name: "Import",
                                                                    onClick: () => this.setState({ showEntitySelector: true })
                                                                },
                                                                {
                                                                    name: "Create",
                                                                    onClick: () => {
                                                                        this.setState((state) => ({
                                                                            ...state,
                                                                            showAddImagePopup: true,
                                                                        }))
                                                                    },

                                                                }
                                                            ]
                                                        }

                                                    />
                                                </div>
                                            </div>
                                            <div style={{ height: "95%", display: "flex", flexDirection: "column", gap: "10px" }}>
                                                <div className='scroll-container' style={{ height: '100%', border: '1px solid #c2c2c2', backgroundColor: '#FAFAFA', borderRadius: '5px', overflowY: 'auto' }}>
                                                    {this.state.loadingImageset ?
                                                        <div style={{ width: "100%", height: "100%", display: "flex", textAlign: "center", justifyContent: "center", paddingLeft: "10%" }}>
                                                            <img src={Loader} style={{ height: "40%", width: "40%", margin: "auto" }} />
                                                        </div>
                                                        :
                                                        collections.length ?
                                                            <div className="aerocapture-cards-tray-projects" style={{
                                                                gridTemplateColoumn: "repeat(auto-fill, minmax(190px, 200px))", height: 'auto', padding: '10px'
                                                            }}>
                                                                {collections.map((collection, key) => {
                                                                    return (
                                                                        <div style={{ height: '110px' }}>
                                                                            <Link to={{
                                                                                pathname: `/picstork/images`,
                                                                                state: { user, type, collection, project, sidebarMode: this.state.sidebarMode }
                                                                            }}>
                                                                                {<div key={key} className="customer-cards-dashboard card"
                                                                                    style={{ boxShadow: '0px 6px 10px rgb(0 0 0 / 30%)' }}>
                                                                                    <div className="card-header customer-cards-dashboard-logo project-card-header"
                                                                                        style={{
                                                                                            dispaly: "relative",
                                                                                        }}>
                                                                                        {
                                                                                            collection && collection.file && collection.file.file_name ?
                                                                                                <img id="ProjectImageRPM" loading="lazy" style={{ objectFit: 'cover' }}
                                                                                                    src={`${BLOB_URL}/${this.state.permissions.container}/FlightImages/collection-${collection.id}/thumb-${collection.file.file_name}?${this.state.permissions.st}`} onError={(e) => { e.target.onerror = null; e.target.src = ImageSetThumb }} />

                                                                                                :
                                                                                                < img id='ProjectImageRPM' loading="lazy" src={ImageSetThumb} />
                                                                                        }
                                                                                        <div >
                                                                                            <DeleteComponentGrid
                                                                                                style={{ position: "absolute", top: "5px", right: "5px" }}
                                                                                                canDuplicate={this.state.permissions[PICSTORK_FEATURES.IMAGECOLLECTIONS].CREATE && !(this.state.project.sharedData && this.state.project.sharedData.isShared) && collection.images_count > 0}
                                                                                                canDownload={this.state.permissions[PICSTORK_FEATURES.IMAGECOLLECTIONS].DOWNLOAD && collection.images_count > 0}
                                                                                                disableDelete={!this.state.permissions[PICSTORK_FEATURES.IMAGECOLLECTIONS].DELETE }
                                                                                                onClickDelete={(e) => {
                                                                                                    e.preventDefault()
                                                                                                    this.setState((state) => ({
                                                                                                        ...state,
                                                                                                        selectedCollectionId: collection.id,
                                                                                                        deleteItem: "IMAGESET",
                                                                                                        itemName: "Imageset",
                                                                                                        processPopupIcon: "WARNING",
                                                                                                        processAction: "DELETE",
                                                                                                        processMessage: "All data including orthomosaic images, tasks, annotations, gcp points, images will be deleted." +
                                                                                                            "\n Are you sure you want to delete this Imageset ?",
                                                                                                        showProcessPopup: true,
                                                                                                    }))
                                                                                                }}
                                                                                                onclickDuplicate={(e) => {
                                                                                                    e.preventDefault()
                                                                                                    this.setState((state) => ({
                                                                                                        ...state,
                                                                                                        showCopyImagePopup: true,
                                                                                                        oldCollectionId: collection.id,
                                                                                                        newCollectionName: `${collection.collection_name}Copy`,
                                                                                                    }))
                                                                                                }}
                                                                                                onClickDownload={(e) => {
                                                                                                    e.preventDefault()
                                                                                                    this.setState((state) => ({
                                                                                                        ...state,
                                                                                                        selectedCollectionId: collection.id,
                                                                                                    }), () => {
                                                                                                        this.downloadCollection()
                                                                                                    })
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="card-body change-color customer-cards-dashboard-text project-card-body">
                                                                                        <div>
                                                                                            <span>{
                                                                                                collection.collection_name.length > 14 ?
                                                                                                    `${collection.collection_name.substring(0, 13)}...` :
                                                                                                    `${collection.collection_name}`
                                                                                            }</span>
                                                                                            <div style={{ display: "flex", flexDirection: "row", fontSize: "10px", justifyContent: "space-between" }}>
                                                                                                <span>{
                                                                                                    <span>{collection.images_count}</span>
                                                                                                }</span>
                                                                                                <span>{
                                                                                                    (() => {
                                                                                                        let date = (new Date(collection.created_on)).toLocaleDateString('en-US')
                                                                                                        return `${dateFormat(date, "dd-mmm-yyyy")}`
                                                                                                    })()
                                                                                                }</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>}
                                                                            </Link>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div> :
                                                            <div
                                                                className="rmp-dashboard-empty-data-message"
                                                                style={{ marginTop: '8%' }} >
                                                                <img style={{ height: '20%', width: '20%' }} src={Noimageset} />
                                                                <p style={{ marginTop: '10px' }}>No Imagesets available</p>
                                                            </div>
                                                    }

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Planner
                                    service={SERVICES.PICSTORK}
                                    atProjectLevel={true}
                                    selectedProject={this.state.project}
                                    location={this.props.location}
                                    hasTeamsAccess={this.state.permissions[PICSTORK_FEATURES.TASKS].CREATE}
                                />


                            </div>
                        </div>}
                </div>
            </div > : <div />


        );
    }
}

const mapStateToProps = state => {
    return {
        SubscriptionData: state.subscription.subscriptionData,
    };
};

export default connect(mapStateToProps)(AeroCaptureProject);
