import React, { Component } from "react";
import * as API from "../../api.js";
import Loader from "../../icons/loader.svg";
import { ActivityPopup } from '../../ReusableComponents/ActivityPopup.js';
import AppConstants, { BLOB_URL } from "../../AppConstants";
import { PICSTORK_FEATURES } from "../../Teams";
import ButtonWithIcon from "../../ReusableComponents/ButtonWithIcon";
import DropDownWithButton from "../../ReusableComponents/DropDownWithButton";

class TaskDetailedReport extends Component {
  state = {
    isThermalTask: undefined,
    user: {},
    type: "",
    progress: 0,
    selectedObj: [],
    outId: "",
    objectsDetected: 0,
    showDownloadPopup: false,
    loader: true,
    services: [],
    isDetectionDirty: false,
    task: [],
    obj: [],
    report: [],
    objects: [],
    projects: [],
    showBackIcon: true,
    cancel: false,
    loading: true,
    showAllImages: false,
    sidebarMode: this.props
      ? this.props.sidebarMode
      : "shrunk",
    selectAll: true,
    initialized: false,
    itemName: "",
    showProcessPopup: false,
    processPopupIcon: "",
    processAction: "",
    processMessage: "",
    selectedSeverityName: "All",
    selectedSeverity: undefined,
    isTiff: false,
    callCount: 0,
    analisedImages: 0,
  };

  fetchThermalMeta = async (report) => {
    await API.getThermalMeta(
      this.props.permissions?.container,
      this.props.permissions?.st,
      this.state.task.image_collection_id,
      report.image
    ).then((res) => {
      this.setState((state) => ({
        ...state,
        isThermalTask: res.ImageInfo ? true : false,
        loading: false,
      }), () => {
      }
      )
    }).catch(err=>console.log(err))
  }

  checkIsTiffavailable = () => {
    if ((this.state.report?.[0]?.image.toLowerCase()).includes("tif") || (this.state.report?.[0]?.image.toLowerCase()).includes("tiff")) {
      this.setState((state) => ({
        ...state,
        isTiff: true,
      }))
    }
    else {
      this.fetchThermalMeta(this.state.report[0])
    }
    this.setState({
      loading: false,
      imageListLoading: false
    })
  }

  parentSidebarSwitch = () => {
    this.setState((state) => ({
      ...state,
      sidebarMode: state.sidebarMode == "shrunk" ? "expanded" : "shrunk",
    }));
  };

  getTask = async () => {
    await API.getAeroCaptureTask(this.state.task.task_id).then((data) => {
      this.setState((state) => ({
        ...state,
        task: data
      }))
    }, (e) => {
      console.log(e);
    })
  }

  getReportDetails = async () => {
    let objects = [];
    let sel = []
    this.setState((prevState) => ({
      ...prevState,
      callCount: prevState.callCount + 1
    }))
    this.state.obj.map((el) => {
      if (el.selected === true && el.type) {
        objects.push(el.type);
      }
      if (el.type)
        sel.push(el.type)
    });
    this.setState((state) => ({
      ...state,
      selectAll: this.state.initialized && objects.length != sel.length ? false : true,
      selectedObj: objects,
      initialized: true
    }));
    await API.getDetections(this.state.task.task_id, objects).then(
      (data) => {
        if (data.ret.length > 0) {
          this.setState((state) => ({
            ...state,
            report: data.ret,
            isDetectionDirty: true,
          }),
            () => {
              if (this.state.report.length > 0) {
                let counter = 0;
                let temp1 = [];
                let objectsDetected = 0;
                let types = !this.state.selectAll || objects.length > 0 ? objects : this.state.task.type;
                this.state.report.forEach((el) => {
                  let severityMatched = false;
                  if (this.state.callCount === 1) {
                    let tempObjArr = [...this.state.obj]
                    for (let i = 0; i < tempObjArr.length; i++) {
                      const cls = tempObjArr[i]
                      if (el.result.detections?.[cls.type]) {
                        tempObjArr[i].count = (tempObjArr[i].count || 0) + el.result.detections[cls.type].count
                      }
                    }
                    this.setState(state => ({
                      ...state,
                      obj: tempObjArr
                    }))
                  }

                    let cnt = 0;
                    types.forEach((type) => {
                      if (el.result.detections && el.result.detections[type]) {
                        if (el.result.detections[type].data.length === 0 && this.state.selectedSeverity === undefined) severityMatched = true
                        el.result.detections[type].data.forEach((dt) => {
                          if ((this.state.selectedSeverity === undefined) || (((dt?.severity == this.state.selectedSeverity) || (dt?.data?.severity == this.state.selectedSeverity)))) {
                            cnt += 1;
                            objectsDetected += 1;
                            severityMatched = true;
                          }
                        })

                      }
                    });
                    if (severityMatched) {
                      temp1.push(el);
                    }
                    el.objectsDetected = cnt;
                    counter++;
                    if (counter == this.state.report.length) {
                      let showAllImages = this.state.selectAll|| (objectsDetected == 0 && !this.state.selectAll)
                      this.setState((state) => ({
                        ...state,
                        objectsDetected: objectsDetected,
                        showAllImages,
                        report: temp1,
                        loading: true,
                      }), () => {
                        if (this.state.report.length > 0)
                          this.checkIsTiffavailable()
                        else {
                          this.setState({ loading: false, imageListLoading: false })
                        }
                      });
                    }
                  });
                }
              }
            )
          }
          else {
            let showAllImages = this.state.task.tasktype == 4
            this.setState((state) => ({
              ...state,
              loading: false,
              imageListLoading: false,
              objectsDetected: 0,
              showAllImages,
              report: []
            }))
          }
        },
        (e) => {
          console.log(e);
          this.setState((state) => ({
            ...state,
            report: [],
            loading: false,
            imageListLoading: false
          }));
        }
      );
  };

  //Export ZIp

  exportData = () => {
    API.exportDetectionZip(this.state.task.task_id)
      .then(
        (res) => {
          res.blob().then(blob => window.URL.createObjectURL(blob))
            .then(uril => {
              var link = document.createElement("a");
              link.href = uril;
              link.download = `${this.state.task.task_id}.zip`;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              this.setState((state) => ({
                ...state,
                //showPopup: false,
                savingLoader: false
              }));
            })
        }
      ).catch((e) => {
        console.log(e.message)
        this.setState((state) => ({
          ...state,
          itemName: "Annotationset",
          processPopupIcon: "ERROR",
          processAction: "ADD",
          processMessage: e.message,
          showProcessPopup: true,
        }))
      })
  }

  closeActivityPopup = () => {
    this.setState((state) => ({
      ...state,
      showProcessPopup: false,
      processMessage: null,
    }))
  }

  //toggle select
  toggleSelecetAll = () => {
    let objects = []
    this.state.obj.map((el, i) => {
      if (this.state.selectAll) {
        el.selected = true
        objects.push(el)
      } else {
        el.selected = false
        objects.push(el)
      }
    });
    this.setState((state) => ({
      ...state,
      obj: objects,
    }), () => {
      this.getReportDetails()
    });
  }

  componentWillMount() {
    document.title = "PicStork - Detailed Report";

    if (this.props) {
      let { user, type, task, plan, project } = this.props;
      this.setState(
        (state) => ({
          ...state,
          user,
          type,
          task,
          plan,
          project,
          objects: task && task.type && task.type.length ? task.type : [],
        }),
        async () => {
          await this.getTask()

          if (this.state.task && this.state.task.type) {
            let ob = this.state.task.type;
            var temp = { selected: false, type: "", id: 0 };
            var tmp = [];
            ob.forEach((type, key) => {
              temp.selected = true;
              temp.type = type;
              temp.id = key;
              tmp.push(temp);
              temp = {};
            });
            tmp.push({})
            this.setState((state) => ({
              ...state,
              obj: tmp,
            }));
          }

          await this.getReportDetails();

          let img = this.state?.report?.map(img => {
            if (img.objectsDetected != 0) {
              this.setState({ analisedImages: this.state.analisedImages + 1 })
            }
          })

          API.getUserSubbedServices().then((response) => {
            let services = response[0];
            if (services.length == 1 && services[0].title == "AeroStream") {
              this.setState((state) => ({
                ...state,
                showBackIcon: false,
              }));
            } else {
              this.setState((state) => ({
                ...state,
                showBackIcon: true,
              }));
            }
          });
        }
      );
    } else {
      API.getUserType().then(
        ([blah, type, user]) => {
          this.setState(
            (state) => ({
              ...state,
              user,
              type,
            }),
            () => {
              this.getReportDetails();
            }
          );
        },
        (e) => {
          window.location.replace("/login");
        }
      );
    }
  }

  render() {
    const removePadding = {
      padding: "0px",
    };

    let { user, type, projects, sidebarMode, showBackIcon, task, report, plan, project } = this.state;

    return user.username ? (
      <div className="wrapper" style={{ width: '100%' }}>

        <ActivityPopup
          item={this.state.itemName}
          open={this.state.showProcessPopup}
          icon={this.state.processPopupIcon}
          action={this.state.processAction}
          msg={this.state.processMessage}
          close={() => this.closeActivityPopup()}
          onClickOk={() => { this.closeActivityPopup() }}
        >
        </ActivityPopup>

        <div style={{ width: '100%' }}>
          {this.state.loading ? (
            <div style={{ height: "85vh", width: "100%", display: "flex", textAlign: "center" }}>
              <img src={Loader} style={{ height: "30vh", width: "100%", margin: "auto" }} />
            </div>
          ) : (
            <div
              style={{ background: "none", width: '100%', margin: "1% 0%" }}
              onClick={() => {
                this.setState((state) => ({
                  ...state,
                  sidebarMode: "shrunk",
                }));
              }}
            >
              {/* <SnackBar user={user} sidebarMode={sidebarMode} type={type} /> */}
              <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <div>
                  <div className="services-content-title">
                    {"Detailed Report"}
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: "center" }}>
                  <ButtonWithIcon
                    text={this.state.isThermalTask === undefined ?"Loading report...":"View report"}
                    isDisabled={this.state.isThermalTask === undefined || this.state.objectsDetected === 0 || !(task?.task_purpose)}
                    linkData={
                      {
                        pathname: task?.task_purpose === AppConstants.TASKPURPOSE.COUNTING ? "/picstork/Pdf_Download" : this.state.isThermalTask ? "/picstork/thermal_report" : "/picstork/defect_report",
                        state: {
                          user: user,
                          type: type,
                          task: task,
                        }
                      }
                    } />
                  <ButtonWithIcon
                    text={"Export Data"}
                    isDisabled={!(this.props.permissions[PICSTORK_FEATURES.REPORTS].DOWNLOAD && this.state.objectsDetected !== 0)}
                    onClick={this.exportData}
                  />
                  <ButtonWithIcon
                    text={"Task Details"}
                    onClick={() => this.props.changeView(1)}
                  />
                </div>
              </div>
              {this.state.task.tasktype == 4 && this.state.objectsDetected == 0 ?
                <div style={{ color: "#3c3c3c", fontSize: "12px" }}>
                  {/* (Not annotated any images yet,annotatated images required for reports.) */}
                </div>
                : <>
                </>}

              <div style={{ margin: "1rem", width: "95%", display: "flex", height: '75px', overflowY: 'auto' }}>

                <>  {this.state.obj && this.state.obj.length ? (
                  <>
                    <div>
                      {this.state.obj.sort((a, b) => {
                        const countA = a.count;
                        const countB = b.count;
                        return countB - countA;
                      }).map((ob, key) => {
                        return (
                          < >
                            {this.state.obj.length - 1 != key ?
                              <button
                                style={{
                                  backgroundColor: (!ob.type && this.state.selectAll) || (this.state.obj[key].type && this.state.obj[key].selected) ? "#c4c4c4" : "#FFFFFF",
                                  color: (!ob.type && this.state.selectAll) || (this.state.obj[key].type && this.state.obj[key].selected) ? "#000000c7" : "#666666",
                                  border: (!ob.type && this.state.selectAll) || (this.state.obj[key].type && this.state.obj[key].selected) ? "1px solid #FFFFFF" : "1px solid #666666",
                                  fontSize: "11px",
                                  margin: "2px 4px",
                                  borderRadius: "15px",
                                  padding: "4px 5px 4px 12px",
                                  outline: "none",
                                }}
                                onClick={() => {

                                  this.setState(
                                    (state) => ({
                                      ...state,
                                      imageListLoading: true,
                                      obj: this.state.obj.map(item => {
                                        if (item.id === ob.id) return { ...item, selected: !item.selected };
                                        else return item
                                      }),
                                      selectAll: this.state.obj.length > 0 ? true : false
                                    }),
                                    () => {
                                      this.getReportDetails();
                                    }
                                  );
                                }}
                                title={ob.type}
                              >
                                <div style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: "15px",
                                  margin: "0 5px"
                                }}>
                                  <span>
                                    {ob.type.length >= 20 ? ob.type.substring(0, 21) + "..." : ob.type}
                                  </span>
                                  <span
                                    style={{
                                      width: "max-containt",
                                      height: "18px",
                                      color: "#666666",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      fontSize: "11px",
                                    }}
                                  >
                                    ({ob.count || 0})
                                  </span>
                                </div>
                              </button>
                              :
                              <ButtonWithIcon
                                text={this.state.selectAll ? "Select None" : "Select All"}
                                onClick={() => {
                                  this.setState((state) => ({
                                    ...state,
                                    imageListLoading: true,
                                    selectAll: !this.state.selectAll
                                  }), () => {
                                    this.toggleSelecetAll()
                                  })
                                }}
                                isBtnActive={this.state.selectAll}
                              />
                            }
                          </ >
                        );
                      })}
                    </div>

                  </>
                ) : (
                  ""
                )}</>
              </div>

              {/*Search Filter Result in Number */}
              {true ? (
                <div
                  className=""
                  style={{
                    display: "grid",
                    fontSize: "14px",
                    margin: "10px 0%",
                    marginBottom: "5px",
                    gridTemplateColumns: "auto auto auto auto auto",
                    width: "930px",
                  }}
                >
                  <div className="" style={{ removePadding }}>
                    <div style={{ color: "#2989CF" }}>
                      <b>Objects Detected</b>
                    </div>
                    <div>
                      <b>{this.state.objectsDetected}</b>
                    </div>
                  </div>

                  {this.state.isTiff ? <></> :
                    <>
                      <div className="" style={{ removePadding }}>
                        <div style={{ color: "#2989CF" }}>
                          <b>Images Analyzed</b>
                        </div>
                        <div>
                          <b>{this.state.analisedImages}</b>
                        </div>
                      </div>

                      <div className="" style={{ removePadding }}>
                        <div style={{ color: "#2989CF" }}>
                          <b>Total Images</b>
                        </div>
                        <div>
                          <b>{this.state.task.images}</b>
                        </div>
                      </div>


                      {this.state.task.task_purpose !== "Counting" && <div className="" style={{ removePadding }}>
                        <div style={{ color: "#2989CF" }}>
                          <b>Severity</b>
                        </div>
                        <div>
                          <div >
                            <DropDownWithButton
                              selectedItem={this.state.selectedSeverityName == undefined ? "All" : this.state.selectedSeverityName}
                              isBorder height="20px" textFontSize="13px" width="100px"
                              list={[
                                {
                                  name: "All", onClick: () => {
                                    this.setState({ imageListLoading: true, selectedSeverity: undefined, selectedSeverityName: "All" }, () => {
                                      this.getReportDetails()
                                    })
                                  }
                                },
                                {
                                  name: AppConstants.SEVIRITY._1, onClick: () => {
                                    this.setState({ imageListLoading: true, selectedSeverity: 1, selectedSeverityName: AppConstants.SEVIRITY._1 }, () => {
                                      this.getReportDetails()
                                    })
                                  }
                                },
                                {
                                  name: AppConstants.SEVIRITY._2, onClick: () => {
                                    this.setState({ imageListLoading: true, selectedSeverity: 2, selectedSeverityName: AppConstants.SEVIRITY._2 }, () => {
                                      this.getReportDetails()
                                    })
                                  }
                                },
                                {
                                  name: AppConstants.SEVIRITY._3, onClick: () => {
                                    this.setState({ imageListLoading: true, selectedSeverity: 3, selectedSeverityName: AppConstants.SEVIRITY._3 }, () => {
                                      this.getReportDetails()
                                    })
                                  }
                                },
                                {
                                  name: AppConstants.SEVIRITY._4, onClick: () => {
                                    this.setState({ imageListLoading: true, selectedSeverity: 4, selectedSeverityName: AppConstants.SEVIRITY._4 }, () => {
                                      this.getReportDetails()
                                    })
                                  }
                                },
                                {
                                  name: AppConstants.SEVIRITY._5, onClick: () => {
                                    this.setState({ imageListLoading: true, selectedSeverity: 5, selectedSeverityName: AppConstants.SEVIRITY._5 }, () => {
                                      this.getReportDetails()
                                    })
                                  }
                                },
                              ]}
                            />
                          </div>
                        </div>
                      </div>}
                    </>
                  }

                </div>
              ) : (
                ""
              )}
              {this.state.imageListLoading ?
                <div style={{ height: "65vh", width: "100%", display: "flex", textAlign: "center" }}>
                  <img src={Loader} style={{ height: "200px", width: "100%", margin: "auto" }} />
                </div>
                : report.length ? (
                  <div style={{ height: '56vh', overflow: 'auto' }}>
                    <div className="aerocapture-cards-tray-projects" style={{ justifyContent: "left", gridGap: "30px", columnGap: "30px" }}>
                      {report.filter(data => this.state.showAllImages || data.objectsDetected > 0).sort((a, b) => {
                        const countA = a.objectsDetected;
                        const countB = b.objectsDetected;
                        return countB - countA;
                      }).map((report, key) => {
                        return (
                          <div onClick={() => this.props.changeView(3, {
                            user,
                            type: this.state.selectedObj,
                            task,
                            report: this.state.report.filter(data => this.state.showAllImages || data.objectsDetected > 0),
                            key: key,
                            selectedImage: report.image,
                            selectedSeverity: this.state.selectedSeverity,
                            plan,
                            project,
                            showAllImages: this.state.showAllImages,
                          })}>
                            {
                              <div
                                key={key}
                                className="customer-cards-dashboard card"
                                style={{
                                  cursor: 'pointer',
                                  boxShadow: "0px 6px 10px rgb(0 0 0 / 30%)",
                                }}
                              >
                                <div
                                  className="card-header customer-cards-dashboard-logo project-card-header"
                                  style={
                                    {
                                      // backgroundImage=`url(${'./assets/map_placeholder.png'})`
                                    }
                                  }
                                >
                                  <img
                                    id="ProjectImageFarm"
                                    style={{ objectFit: "cover", height: "108px" }}
                                    src={`${BLOB_URL}/${this.props.permissions?.container}/FlightImages/collection-${task.image_collection_id}/thumb-${report.image}?${this.props.permissions?.st}`}
                                  />
                                </div>
                                <div
                                  className="customer-cards-dashboard-text"
                                  style={{
                                    marginTop: "15px",
                                    padding: "3px 5px",
                                    backgroundColor: 'rgba(0,0,0,.5)',
                                    color: "white",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      height: "24px",
                                      overflow: "hidden",
                                      fontSize: "11px",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    {report.image.length > 12
                                      ? `${report.image.substring(0, 11)}...`
                                      : `${report.image}`}
                                  </div>
                                  <div
                                    style={{
                                      width: "18px",
                                      height: "18px",
                                      borderRadius: "50%",
                                      backgroundColor: "white",
                                      color: "#666666",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      fontSize: "11px",
                                    }}
                                  >{`${report.objectsDetected}`}</div>
                                </div>
                              </div>
                            }
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div className="rmp-dashboard-empty-data-message">
                    No images available
                  </div>
                )}
            </div>
          )}
        </div>

      </div>
    ) : (
      <div />
    );
  }
}

export default TaskDetailedReport;