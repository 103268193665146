import React, { Component } from 'react';
import dashboardIcon from '../../assets/sideBarIcons/dashboard.png';
import projectsActive from '../../assets/sideBarIcons/Projects.png';
import * as API from '../../api.js';
import service from "../../sidebaricon/Services.png";
import Loader from '../../icons/loader.svg';
import { Link } from 'react-router-dom';
import SideBar from '../../SideBar';
import Menu from '../assets/icons/menu.svg';
import Noproject from '../../assets/Images/Noproject.png';
import { connect } from "react-redux";
import { addUserAnalytic, updateOutTime, checkSubscriptionAndStorage } from '../../ReusableComponents/reusableFunctions.js';
import { CreateProjectPlanModal } from '../../ReusableComponents/CreateProjectPlanModal';
import ProjectThumb from '../../assets/sideBarIcons/ProjectInactive.png';
import myMaps from "../../assets/sideBarIcons/myMaps.png";
import Plus from "../../icons/addBlue.png"
import { DeleteComponentTable } from '../../ReusableComponents/Menu'
import { SortComponent } from '../../ReusableComponents/SortComponent';
import { RoleNameComponent, getActiveTeam } from '../../Teams';
import { ManageProjectAccess } from '../../Teams/ManageProjectAccess';
import ButtonWithIcon from '../../ReusableComponents/ButtonWithIcon';
import AppConstants, { BLOB_URL } from '../../AppConstants';
import SubscriptionExpireModal from '../../subscription/Reusable/SubscriptionExpireModal';
import { ActivityPopup } from '../../ReusableComponents/ActivityPopup';
import DeleteProject from '../../ReusableComponents/DeleteProject';
class AeroCaptureAllProjects extends Component {
    state = {
        activeTeam: undefined,
        user: {},
        type: "",
        key: " ",
        progress: 0,
        showDownloadPopup: false,
        loader: false,
        services: [],
        currentStep: 0,
        projects: [],
        showBackIcon: true,
        cancel: false,
        userperpage: 15,
        currentpage: 1,
        counts: 0,
        loading: true,
        userdisplay: [],
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        showDeletePopup: false,
        allData: [],
        deleteItemType: "",
        deleteLoader: true,
        projectThumbURL: [],
        sortType: 'Last Modified',
        sortTypeName: false,
        sortTypeDate: false,
        outId: "",
        selectedMangeAccessProject: false,
        selectedProjectData: undefined,
        showSubscriptionExpirePopup: false,
        ActivityPopupObj: undefined,
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    isUserOwnerOfCollection = async (id) => {
        return new Promise((resolve, reject) => {
            API.isUserProjectOwner(id)
                .then((result) => {
                    resolve({
                        isOwner: result.isShared ? false : true,
                        isAllowToDownload: result.access_level == "DOWNLOAD" ? true : false,
                        sharedBy: result.sharedBy
                    })
                })
                .catch((err) => {
                    this.setState((state) => ({
                        ...state,
                        itemName: "",
                        processPopupIcon: "ERROR",
                        processAction: "ADD",
                        processMessage: "Something went wrong while getting owner information",
                        showProcessPopup: true,
                        loading: false,
                        isAllowToDownload: false
                    }))
                })
        })
    }

    getProjects = () => {
        const { currentpage, userperpage, key } = this.state;
        API.getAllProjects(key == "" ? " " : key, userperpage, currentpage - 1, undefined, undefined, AppConstants.SERVICES.DRONENAKSHA).then(async (data) => {
            if (data.projects && data.projects.length == 0) {
                this.setState((state) => ({
                    ...state,
                    projects: [],
                    loading: false
                }))
            } else {
                this.setState((state) => ({
                    ...state,
                    projects: data.projects,
                    loading: false,
                    counts: data.count
                }), () => {
                    if (this.state.sortType === 'A-Z') {
                        this.sortByName()
                    } else if (this.state.sortType === 'Z-A') {
                        this.reverseByName()
                    } else if (this.state.sortType === 'First Modified') {
                        this.sortByDate()
                    } else if (this.state.sortType === 'Last Modified') {
                        this.reverseByDate()
                    }
                })
                this.loadProjectTiff()
            }
        }, (e) => {
            console.log(e);
        })
    }




    sortByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'A-Z'
        }));
        let sort = this.state.projects.sort((a, b) => a.project_name.localeCompare(b.project_name))
        this.setState((state) => ({
            ...state,
            projects: sort,
        }));

    }

    reverseByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Z-A'
        }));
        let sort = this.state.projects.sort((a, b) => b.project_name.localeCompare(a.project_name))
        this.setState((state) => ({
            ...state,
            projects: sort,
        }));
    }

    sortByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'First Modified'
        }));
        let sort = this.state.projects.sort((a, b) => a.created_date.localeCompare(b.created_date))
        this.setState((state) => ({
            ...state,
            projects: sort,
        }));
    }

    reverseByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Last Modified'
        }));
        let sort = this.state.projects.sort((a, b) => b.created_date.localeCompare(a.created_date))
        this.setState((state) => ({
            ...state,
            projects: sort,
        }));
    }



    // function to load tiff thumbnails of project
    loadProjectTiff = async () => {
        let { projects, user } = this.state
        var thumbURL = []
        for (let i = 0; i < this.state.projects.length; i++) {
            if (projects[i].collection_id && projects[i].collection_id != undefined) {
                let name = projects[i].image.toLowerCase()
                let turl = `${BLOB_URL}/${this.state.activeTeam.teamOwner.container}/FlightImages/collection-${projects[i].collection_id}/thumb-${projects[i].image}?${this.state.activeTeam.teamOwner.st}`
                thumbURL[i] = turl
                this.setState((state) => ({
                    ...state,
                    projectThumbURL: thumbURL,
                }))
            }
        }
    }

    firstpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: 1
            }), () => {
                this.getProjects();
            });
        }
    }

    prevpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: this.state.currentpage - 1
            })
                , () => {
                    this.getProjects();
                });
        }
    }
    nextpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: state.currentpage + 1
            }), () => {
                this.getProjects();
            });
        }
    }

    lastpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: Math.ceil(this.state.counts / this.state.userperpage)
            }), () => {
                this.getProjects();
            });
        }
    }

    changepage(num) {
        if (isNaN(num) || num < 1 || num > Math.ceil(this.state.counts / this.state.userperpage)) {

            return null;
        }
        else {
            this.setState((state) => ({
                ...state,
                currentpage: num,
            }), () => this.getProjects());
        }

    }
    search(key) {
        this.setState((state) => ({
            ...state,
            key,
            currentpage: 1
        }),
            () => this.getProjects());
    }

    // function to Get all data under the given project
    getAllDataToDelete = () => {
        this.setState((state) => ({
            ...state,
            deleteLoader: true,
            showDeletePopup: true,
        }))
        API.getProjectData(this.state.deleteItemType, this.state.selectedProject.project_name, this.state.selectedProject.id).then((data) => {
            let keys = Object.keys(data)
            let alldata = []
            keys = keys.reverse()
            keys.forEach(element => {
                let obj = { "name": element, "count": data[element].length, "processIcon": "", data: data[element] }
                alldata.push(obj)
            });
            this.setState((state) => ({
                ...state,
                allData: data.isEmptyProject ? null : alldata,
                deleteLoader: false
            }))
        }, (e) => {
            console.log(e);
        })
    }

    componentWillUnmount() {
        updateOutTime(this.state.outId)
    }

    componentWillMount() {
        getActiveTeam().then((activeteam) => {
            this.setState({ activeTeam: activeteam }, () => {

                addUserAnalytic(this.props.location.pathname)
                    .then(id => {
                        this.setState((state) => ({
                            outId: id
                        }))
                    })
                var style = document.createElement('style');
                style.type = 'text/css';
                style.innerHTML = '.popup-content { width: 30% !important; }';
                document.getElementsByTagName('head')[0].appendChild(style);

                document.title = "DroneNaksha - Projects";
                if (this.props.location.state) {
                    let { user, type } = this.props.location.state;
                    this.setState((state) => ({
                        ...state,
                        user,
                        type
                    }), () => {
                        API.getUserSubbedServices().then(
                            (response) => {
                                let services = response[0];
                                if (services.length == 1 && services[0].title == 'AeroStream') {
                                    this.setState((state) => ({
                                        ...state,
                                        showBackIcon: false
                                    }))
                                } else {
                                    this.setState((state) => ({
                                        ...state,
                                        showBackIcon: true
                                    }), () => {
                                        this.getProjects()
                                        checkSubscriptionAndStorage(AppConstants.SERVICES.DRONENAKSHA).then(data => {
                                            if (data.error || data.storageExceed || data.isExpired || !data.isValidStoragePlan) this.setState({ showSubscriptionExpirePopup: { ...data, user: this.props?.location?.state?.user } });
                                            else this.setState({ showSubscriptionExpirePopup: false, infoPopup: true });
                                        })
                                    })
                                }
                            }
                        )
                    })
                } else {
                    API.getUserType().then(([blah, type, user]) => {
                        this.setState((state) => ({
                            ...state,
                            user,
                            type
                        }), () => {
                            this.getProjects()
                            checkSubscriptionAndStorage(AppConstants.SERVICES.DRONENAKSHA).then(data => {
                                if (data.error || data.storageExceed || data.isExpired || !data.isValidStoragePlan) this.setState({ showSubscriptionExpirePopup: { ...data, user: this.props?.location?.state?.user } });
                                else this.setState({ showSubscriptionExpirePopup: false, infoPopup: true });
                            })
                        })
                    }, (e) => {
                        window.location.replace("/login");
                    })
                }
            })
        })
    }
    changeColor = (e) => {
        e.preventDefault()
        const refs = ['home', 'customer', 'service']
        refs.map(ref => this.refs[ref].classList.remove('text-color'))
        e.target.classList.add('text-color')
        const { name } = Object.values(e.target)[1]
        this.setState(state => ({
            ...state,
            selected: name
        }))
    }
    render() {
        const { currentpage } = this.state;
        let { user, type, projects } = this.state
        let sidebarConfigurations = {
            options: this.state.activeTeam?.isOwner ? [
                {
                    icon: service,
                    text: "Services",
                    path: "/"
                },
                {
                    icon: dashboardIcon,
                    text: "DroneNaksha",
                    path: "/dronenaksha/dashboard",
                },
                {
                    icon: myMaps,
                    text: "All Maps",
                    path: "/dronenaksha/myMaps",
                },
                {
                    icon: projectsActive,
                    text: "Projects",
                    path: "/dronenaksha/projects",
                    selected: true,
                }
            ] : [{
                icon: service,
                text: "Services",
                path: "/"
            },
            {
                icon: dashboardIcon,
                text: "DroneNaksha",
                path: "/dronenaksha/dashboard",
            },
            {
                icon: projectsActive,
                text: "Projects",
                path: "/dronenaksha/projects",
            }
            ],
            user,
            type,
            mode: this.state.sidebarMode,
            droneNakshaHelp: true
        }
        return (
            user.username ? <div className="wrapper">
                <ManageProjectAccess
                    projectId={this.state.selectedMangeAccessProject}
                    closeManageAccess={() => {
                        this.setState({
                            selectedMangeAccessProject: false,
                            selectedProjectData: undefined,
                        })
                    }}
                    senderData={user}
                    propsData={this.state.selectedProjectData}
                />
                <SideBar config={sidebarConfigurations} showMainDashboardIcons={this.state.showBackIcon} parentSidebarSwitch={this.parentSidebarSwitch} />
                <div className="right-content"
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >
                    {
                        this.state.showSubscriptionExpirePopup ?
                            <SubscriptionExpireModal
                                data={this.state.showSubscriptionExpirePopup}
                            /> : <></>
                    }

                    <ActivityPopup
                        item={this.state.ActivityPopupObj?.item}
                        open={this.state.ActivityPopupObj?.open}
                        icon={this.state.ActivityPopupObj?.icon}
                        msg={this.state.ActivityPopupObj?.msg}
                        close={() => this.setState({ ActivityPopupObj: undefined })}
                        onClickOk={() => this.setState({ ActivityPopupObj: undefined })}
                    />

                    {/* ---------------Component to delete project--------------------- */}
                    <DeleteProject
                        service={AppConstants.SERVICES.DRONENAKSHA}
                        projectId={this.state?.selectedProject?.id}
                        show={this.state.showDeletePopup}
                        onClose={(isDeleted) => {
                            this.setState({
                                showDeletePopup: false
                            }, () => {
                                if (isDeleted)
                                    this.getProjects()
                            })
                        }}
                    />

                    <div className="top-bar"><div className="top-bar-text">PROJECTS</div></div>
                    {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                        <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                    </div> : <div className="main-content" style={{ background: 'none' }}>
                        <CreateProjectPlanModal
                            serviceName={AppConstants.SERVICES.DRONENAKSHA}
                            user={this.state.user}
                            type={this.state.type}
                            createProjectPlanModal={this.state.createProjectPlanModal}
                            closeCreateProjectPlanModal={(newItem) => {
                                if (newItem && newItem.includes("PROJECT"))
                                    this.getProjects()
                                this.setState({ createProjectPlanModal: false })
                            }}
                            createType={"project"}
                        />
                        <div style={{ display: "flex" }}>
                            <div className="services-content-title">{"Projects"}
                                <span style={{ fontSize: "14px", color: "#2989cf", marginLeft: "5px", marginTop: "2px" }}>({this.state.counts})</span>
                            </div>
                            <div className="add-user-form-text-wrapper" style={{ marginLeft: "auto", filter: this.state.activeTeam.isOwner ? "" : "grayscale(100%)", opacity: this.state.activeTeam.isOwner ? "1" : "0.5" }}>
                                <ButtonWithIcon icon={Plus} text="Project" onClick={() => {
                                    this.state.activeTeam.isOwner && this.setState({
                                        createProjectPlanModal: true
                                    })
                                }} />
                            </div>
                        </div>
                        {projects.length === 0 ?
                            <div className="rmp-dashboard-empty-data-message"
                                style={{ display: "flex", flexDirection: "column", height: "70vh", alignItems: "center", justifyContent: "center" }}
                            >
                                <img style={{ height: '200px', width: '200px' }} src={Noproject} />
                                <p style={{ margin: '10px' }}>No project available</p></div> :
                            <table className="customers-table scroll-container">
                                <col style={{ width: '5%' }} />
                                <col style={{ width: '30%' }} />
                                <col style={{ width: '10%' }} />
                                <col style={{ width: '20%' }} />
                                <col style={{ width: '10%' }} />
                                <col style={{ width: '20%' }} />
                                <col style={{ width: '5%' }} />
                                <tr className="customers-table-header-row">
                                    <th></th>
                                    <th>Name
                                        <SortComponent
                                            style={{ marginLeft: '5px' }}
                                            sortAscending={this.sortByName}
                                            sortDecending={this.reverseByName}
                                            sortASC={false}
                                        />
                                    </th>
                                    <th>Tasks</th>
                                    <th>Date Created
                                        <SortComponent
                                            style={{ marginLeft: '5px' }}
                                            sortAscending={this.sortByDate}
                                            sortDecending={this.reverseByDate}
                                            sortASC={false}
                                        />
                                    </th>
                                    <th>Images</th>
                                    <th>Location</th>
                                    <th></th>
                                </tr>
                                {
                                    projects.map((project, key) =>
                                        <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                            <td>
                                                {
                                                    project && project.image ?
                                                        <img id="ProjectTableImage" style={{ objectFit: 'cover', padding: 'unset' }} loading="true" src={this.state.projectThumbURL[key]} onError={(e) => { e.target.onerror = null; e.target.style = { undefined }; e.target.src = ProjectThumb }} /> :
                                                        <img id="ProjectTableImage" src={ProjectThumb} />
                                                }
                                            </td>
                                            <td>
                                                <Link
                                                    style={{ color: "#707683" }}
                                                    to={{
                                                        pathname: "/dronenaksha/project",
                                                        state: {
                                                            user,
                                                            type,
                                                            sidebarMode: this.state.sidebarMode,
                                                            project
                                                        },
                                                    }}
                                                >
                                                    {project.project_name}<span style={{ color: "#2989cf" }}>{project.sharedData.isShared ? ' (Shared)' : ''}</span>{project.role_name && <RoleNameComponent roleName={project.role_name} />}
                                                </Link>
                                            </td>
                                            <td>{project.tasks}</td>
                                            <td>
                                                {
                                                    (() => {
                                                        let date = (new Date(project.created_date)).toGMTString().split(' ').slice(1, 4).join(' ');
                                                        return `${date}`
                                                    })()
                                                }
                                            </td>
                                            <td>
                                                {project.images == null ? 0 : project.images}
                                            </td>
                                            <td>
                                                {project.location_name ? project.location_name.length > 23 ? `${project.location_name.substring(0, 23)}...` : project.location_name : '-'}
                                            </td>
                                            <td>
                                                <DeleteComponentTable
                                                    style={{ marginRight: '10px' }}
                                                    canManage={this.state.activeTeam.isOwner && !project.sharedData?.isShared}
                                                    onClickManage={() => {
                                                        this.setState({
                                                            selectedMangeAccessProject: project.id,
                                                            selectedProjectData: { pathname: "/dronenaksha/project", state: { project: project } }
                                                        })
                                                    }}
                                                    disableDelete={!this.state.activeTeam.isOwner}
                                                    onClickDelete={(e) => {
                                                        e.preventDefault()
                                                        this.setState((state) => ({
                                                            ...state,
                                                            selectedProject: project,
                                                            deleteItemType: "project"
                                                        }), () => {
                                                            this.getAllDataToDelete()
                                                        })
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    )}
                            </table>}
                        <footer className="footer"
                            style={{ visibility: (this.state.counts > 8 ? "visible" : "hidden") }}
                        >
                            <div style={{ float: "left", marginTop: "0.5rem", display: "flex" }}>
                                <div style={{ color: "#109cf1", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }} >
                                    Page : {currentpage}
                                </div>
                            </div>
                            <div style={{ float: "right", marginTop: "0.5rem", display: "flex" }}>
                                <ButtonWithIcon
                                    text="First"
                                    isBtnActive
                                    isDisabled={this.state.currentpage == 1}
                                    onClick={this.state.currentpage == 1 ? false : this.firstpage}
                                />
                                <ButtonWithIcon
                                    text="Prev"
                                    isDisabled={(this.state.currentpage == 1)}
                                    onClick={(this.state.currentpage == 1) ? false : this.prevpage}
                                />
                                <form>
                                    <div style={{ display: "flex" }}>
                                        <div className="add-user-form-label" style={{ color: "#109cf1", marginTop: "7px", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }}>
                                            Go to page :
                                        </div>
                                        <div className="add-user-form-text-wrapper">
                                            <input type="text" className="add-user-form-text" style={{ height: "40px", width: "40px", textAlign: "center", color: "#109cf1", fontSize: "13px", marginLeft: "5px", backgroundColor: "#F5F6F8" }}
                                                onKeyPress={(event) => { if (event.key === "Enter") { event.preventDefault(); } }}
                                                onChange={(event) => this.changepage(parseInt(event.target.value))} name="currentpage" value={currentpage} />
                                        </div>
                                    </div>
                                </form>
                                <ButtonWithIcon
                                    text="Next"
                                    isDisabled={(this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage))}
                                    onClick={(this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage)) ? false : this.nextpage}
                                />
                                <ButtonWithIcon
                                    text="Last"
                                    isBtnActive
                                    isDisabled={(this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage))}
                                    onClick={(this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage)) ? false : this.lastpage}
                                />
                            </div>
                        </footer>
                    </div>}
                </div>
            </div> : <div />
        );
    }
}

const mapStateToProps = state => {
    return {
        SubscriptionData: state.subscription.subscriptionData,
    };
};


export default connect(mapStateToProps, null)(AeroCaptureAllProjects);

